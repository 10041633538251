import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Layout, Notification, Sidebar} from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import {darkTheme, lightTheme} from '../themes/themes';
import {AppState} from '../types';
import useLocalStorage from "../utils/useLocalStorage";
import {changeTheme} from "../configuration/actions";

const CustomSidebar = (props: any) => <Sidebar {...props} size={320} />;
const MyNotification = (props: any) => <Notification {...props} autoHideDuration={5000}/>;

export default (props: any) => {
    const [theme] = useLocalStorage<string>('theme', 'light');

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeTheme(theme));
    }, [theme, dispatch]);


    const themeConfig = useSelector((state: AppState) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
    
    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}
            menu={Menu}
            theme={themeConfig}
            notification={MyNotification}
            className={theme === 'dark' ? 'dark' : 'light'}
        />
    );
};
