import * as React from 'react';
import {
  required,
  TextInput,
  useTranslate,
  ImageField,
  ImageInput,
} from 'react-admin';
import { Box, Typography, Grid, Container, CircularProgress } from '@material-ui/core';
import { signedUploadQuery, SignedUploadVariables, SignedDownloadVariables, signedDownloadQuery } from '../../types';
import { useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import axios from 'axios';
import { useForm } from "react-final-form";
import { useState, useEffect } from 'react';
import NFacilityMasterRI from '../n_facility_master/ReferenceInput';
import jaLocale from "date-fns/locale/ja";
import {DateInput} from '../../utils/DatePicker';
import FileInput from '../components/FileInput';

const GET_DOWNLOAD_URL = gql`
    query GetDownload($download_s3_input: Downloads3UrlInput!) {
        generate_download_s3_url(download_s3_input: $download_s3_input) {
            signedUrls
        }
    }
`;

const GET_UPLOAD_URL = gql`
    mutation signedQuery($input: GenerateUploadUrlInput!) {
      generate_upload_url(input: $input) {
          presigned_url
          # error
          filename
        }
    }
`;

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

const PreviewImage = ({ record, source }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [url, setUrl] = useState<string | null>(null);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (typeof (record) == "string") {
      apolloClient.query<signedDownloadQuery, SignedDownloadVariables>({
        query: GET_DOWNLOAD_URL,
        fetchPolicy: 'network-only',
        variables: {
          download_s3_input: {
            files: [`${encodeURI(record)}`],
          }
        },
      }).then(({ data, error }) => {
        if (error) {
          console.error(error);
          return;
        }
        setUrl(data.generate_download_s3_url.signedUrls[0]);
        setIsLoading(false);
      }).catch(reason => {
        console.error(reason);
      });
    } else {
      setIsLoading(false);
    }
  }, [record, isLoading, apolloClient]);

  if (isLoading) {
    return (<CircularProgress
      size={25}
      thickness={2}
    />);
  }

  if (typeof (record) == "string" && url) {
    return <img src={url} className="sizeImage" alt={record} />
  } else {
    return <ImageField record={record} source={source} className="sizeImage" />
  }
};


const NMasterFrom = (props: any) => {
  const translate = useTranslate();
  const { editForm } = props;
  const apolloClient = useApolloClient();
  const form = useForm();
  const [uploading, setUploading] = useState<boolean>(false);

  const setLoading = (isLoading: boolean) => {
    setUploading(isLoading);
  }

  const upload = async (index: number, file: any, fileKey: string) => {
    setLoading(true);
    const ext = file.name;
    const { data: response } = await apolloClient.mutate<signedUploadQuery, SignedUploadVariables>({
      mutation: GET_UPLOAD_URL,
      variables: {
        input: {
          filename: encodeURI(file.name || "")
        }
      },
    });
    if (!response || !response.generate_upload_url.presigned_url) {
      return;
    }
    const serverFileName = response.generate_upload_url.filename;
    await axios.put(response.generate_upload_url.presigned_url, file);
    form.change(fileKey, serverFileName);
    setLoading(false);
  };

  const onRemove = (fileKey: string) => {
    form.change(fileKey, null);
  };

  const onDrop = async (index: number, fileKey: string, files: any, fileRejections: [any]) => {
    if (files && files.length > 0) {
      await upload(index, files[0], fileKey);
    }
  };

  return <Container>
    <SectionTitle label={editForm ? "evidence.edit" : "evidence.add_new"} />
    <Separator />
    <Grid container spacing={10}>
      <Grid item xs={6}>
        <TextInput
          autoFocus
          source="title"
          validate={requiredValidate}
          label={translate('evidence.title')}
          fullWidth
          variant="outlined"
        />
        <DateInput
           source="received"
           validate={requiredValidate}
           label={translate('evidence.received')}
           fullWidth
          variant="outlined"
          providerOptions={{ locale: jaLocale }}
          options={{
            placeholder: '年/月/日',
            format: 'yyyy/MM/dd',
            ampm: false,
            cancelLabel: translate('common.btn_cancel'),
            okLabel: translate('common.btn_ok')
          }}
        />
        <NFacilityMasterRI
          autoFocus
          disabled={editForm ? true : false}
        />
      </Grid>
      <Grid item xs={6}>
        <FileInput
          source="filename"
          validate={requiredValidate}
          label={translate('evidence.filename')}
        />
        <TextInput
          source="note"
          multiline
          label={translate('evidence.note')}
          fullWidth
          variant="outlined"
        />
      </Grid>
    </Grid>
  </Container>;
};

export default NMasterFrom;