import * as React from 'react';
import {useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import {useTranslate} from 'react-admin';
import {CircularProgress, Grid, Paper, Typography} from '@material-ui/core'
import useStyles from './styles'
import classnames from 'classnames'
import {useMutation} from '@apollo/client'
import gql from 'graphql-tag'
import {Link, useParams} from 'react-router-dom'
import {AppState} from "../../types";
import {confirm, confirmVariables} from "../../generated/confirm";
import createAuthClient from "../../apolloClient";

const ACTIVE_USER = gql`
    mutation confirm($clientId: Int!, $token: String!) {
        confirm(clientId: $clientId, token: $token) {
            ... on ActionResult {
                success
            }
            ... on ValidationResult {
                code
                message
            }
        }
    }
`;
const authClient = createAuthClient();

const ActiveUser = () => {
    const translate = useTranslate();
    const classes = useStyles();
    let { token } = useParams();
    const client = useSelector((appState: AppState) => appState.client);


    // @ts-ignore
    const [confirmEmail, { data, loading, error }] = useMutation<confirm, confirmVariables>(ACTIVE_USER, {
        client: authClient
    });

    if (loading)
        return (<CircularProgress size={26} />);

    if (data === undefined && error === undefined) {
        confirmEmail({
            variables: { token: token, clientId: client ? client.id : 1 },
        }).then(({data}) => {
            console.log(data);
        });
    }

    const isSuccess = (data && data.confirm.__typename === 'ActionResult' && data.confirm.success);

    return (

        <Grid container className={classes.container}>
            <div className={classes.logotype} style={{ marginBottom: 30 }}>
                <Typography
                    variant="h3"
                    className={classes.logotypeText}
                    style={{ marginBottom: 30 }}
                >
                    {client ? client.name : ''}
                </Typography>
            </div>
            <Paper classes={{ root: classes.paperRoot }}>
                <Typography
                    variant="h5"
                    color="primary"
                    style={{ marginBottom: 30 }}
                    className={classnames(classes.textRow, classes.errorCode)}
                >
                    {translate('activeUser.title')}
                </Typography>
                {isSuccess && (
                    <Typography variant="h5" className={classes.textRow}>
                        {translate('activeUser.success')}
                    </Typography>
                )}
                {!isSuccess && (
                    <Typography
                        variant="h5"
                        style={{ marginBottom: 30 }}
                        className={classes.textRow}
                    >
                        {translate('activeUser.error')}
                    </Typography>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/login"
                    size="large"
                    className={classes.backButton}
                >
                    {translate('activeUser.btn_login')}
                </Button>
            </Paper>
        </Grid>
    );
};



export default ActiveUser;
