export const STATUS = [
  {id: 1, name: '交渉中'},
  {id: 2, name: '履行中'},
  {id: 3, name: '中途解約'},
  {id: 4, name: '満了解約'},
  {id: 5, name: '強制解約'},
]

const DATA = [
  { name: 'n_master_id', type: 'reference', reference: 'n_master', searchField: 'name', nullable: false, editable: false, export: true, import: true },
  { name: 'name', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'opportunity_id', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'contract_date', type: 'date', nullable: false, editable: false, export: true, import: true },
  { name: 'status', type: 'selectbox', options: STATUS, nullable: false, editable: false, export: true, import: true },
  { name: 'evidence_id', type: 'reference', reference: 'evidence', nullable: false, editable: true, export: true, import: false },
  { name: 'created', type: 'hidden', nullable: true, editable: false, export: true, import: true },
  { name: 'created_by', type: 'hidden', nullable: true, editable: true, export: true, import: true },
]

export default DATA;