import { gql } from '@apollo/client';

export const UPDATE_CONFIRMED_AT = gql`
  mutation UpdateInvoiceDetail($id: Int!, $objects: invoice_detail_set_input!) {
    update_invoice_detail_by_pk(pk_columns: {id: $id}, _set: $objects) {
      id
      invoice_year
      invoice_month
      service_id
      payment_received_amount
      payment_received_date
      step3_reseller_amount
      step2_reseller_amount
      step1_reseller_amount
      step3_reseller_id
      step2_reseller_id
      step1_reseller_id
      service {
        step1_reseller_rate
        step2_reseller_rate
        step3_reseller_rate
      }
    }
  }`;

export const INSERT_INCENTIVES = gql`
  mutation InsertIncentive($objects: [incentive_insert_input!]!) {
    insert_incentive(objects: $objects) {
      affected_rows
    }
  }
`;