import * as React from 'react';
import { Fragment } from 'react';
import {
  useTranslate,
  List,
  ResourceContextProvider,
  TextField,
  DateField,
  Datagrid,
  ReferenceField,
  SelectField,
  NumberField,
  BooleanField,
} from 'react-admin';
import ListPagination from "../../components/ListPagination";
import IDFields from '../dataset';
import { Typography } from '@material-ui/core';
import ServiceReferenceField from '../../service/ReferenceField';
import BulkUpdateBtn from './BulkUpdateBtn';

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h4" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const UnpaidInvoiceDetails = (props: any) => {
  const translate = useTranslate();
  const { invoice_id, invoice_year, invoice_month, service_id } = props

  return <ResourceContextProvider value="invoice_detail">
    <SectionTitle label='invoice.unpaid_invoice_details' />
    <List
      hasCreate={false}
      hasShow
      hasEdit
      hasList
      actions={false}
      resource='invoice_detail'
      bulkActionButtons={<BulkUpdateBtn invoiceId={invoice_id} />}
      sort={{ field: 'created', order: 'DESC' }}
      perPage={5}
      pagination={<ListPagination />}
      syncWithLocation
      empty={<></>}
      title={' '}
      basePath="/invoice_detail"
      filter={{
        payment_received_flg: false,
        'invoice_id@_neq': invoice_id,
        'payment_received_date@_is_null': true,
        'invoice_year@_lte': invoice_year,
        'invoice_month@_lt': invoice_month,
        'service_id@_eq': service_id,
        'status@_eq': 3, //請求書発行済み
      }
      }>
      <Datagrid>
        {IDFields.map((f, idx) => {
          if (!Boolean(f)) return <></>
          const { name, reference, searchField, type, options, numberFormat } = f || {}
          if (type === 'textinput') {
            if (numberFormat) {
              return <NumberField source={name} label={translate(`invoice_detail.${name}`)} />
            }
            return <TextField source={name} label={translate(`invoice_detail.${name}`)} />
          }

          if (type === 'reference') {
            if (name === 'service_id') {
              return <ServiceReferenceField label={translate('service.refer_field')} source={name} reference={reference} key={idx} />
            } else {
              return <ReferenceField label={translate(`invoice_detail.${name}`)} source={name} reference={reference} key={idx}>
                <TextField source={searchField} />
              </ReferenceField>
            }
          }

          if (type === 'date') {
            return <DateField source={name} label={translate(`invoice_detail.${name}`)} locales="ja-JP" />
          }

          if (type === 'checkbox') {
            return <BooleanField source={name} label={translate(`invoice_detail.${name}`)} />
          }

          if (type === 'selectbox') {
            return <SelectField
              source={name}
              choices={options}
              label={translate(`invoice_detail.${name}`)}
            />
          }

          return <></>
        })}

        <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
        <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
          <TextField source="full_name" />
        </ReferenceField>
      </Datagrid>

    </List>
  </ResourceContextProvider>
};

export default UnpaidInvoiceDetails;
