import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  useGetIdentity,
  Loading,
  useTranslate,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import { Grid, DialogActions, DialogContent, DialogTitle, Dialog } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';

function QuickCreateButton(props: any) {
  const { resource, quickCreateFields, onChange } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate(resource);
  const notify = useNotify();
  const form = useForm();
  const { identity, loading: identityLoading } = useGetIdentity();
  const translate = useTranslate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values: any) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }: any) => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          form.change(`${resource}_id`, data.id);
          onChange();
        },
        onFailure: ({ error }: any) => {
          notify(error.message, 'error');
        }
      }
    );
  };

  if (identityLoading) {
    return <Loading />;
 }

  return (
    <>
      <Button onClick={handleClick} label={translate('common.new_registration')}>
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{translate(`${resource}.add_new`)}</DialogTitle>

        <FormWithRedirect
          resource={resource}
          save={handleSubmit}
          initialValues={{created_by: identity.id}}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }: any) => (
            <>
              <DialogContent>
                <Grid container justify='center' spacing={2}>
                  {quickCreateFields.map((d: any, idx: number) => {
                    return <Grid item xs={d.type === 'fileinput' ? 12 : 6} key={idx}>
                      <InputRenderer source={resource} data={d} />
                    </Grid>
                  })}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default QuickCreateButton;
