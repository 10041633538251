import * as React from 'react';
import {
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';
import { ProcessNmaster } from "../../utils/Utils";



const filterToQuery = (searchText: any) => {
  const nNumber = parseInt(searchText.replace('N', ''));
  if (!isNaN(nNumber)) {
    return { n_master_id: nNumber }
  }
  return { name: searchText }
}

const CustomReferenceInput = (props: any) => {
  return <ReferenceInput
    autoFocus
    source="contract_id"
    reference="contract"
    fullWidth
    variant="outlined"
    filterToQuery={filterToQuery}
    {...props}>
    <AutocompleteInput
      optionText={(r: any) => Boolean(r?.n_master_id) ? `N${ProcessNmaster(r?.n_master_id)} ${r?.name}` : ''}
      resettable={true}
    />
  </ReferenceInput>
}

export default React.memo(CustomReferenceInput);