import * as React from 'react';
import {
  TextField,
  ReferenceField,
  Datagrid,
  DateField,
  useTranslate,
  ResourceContextProvider,
  List
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import DownloadButton from '../evidence/DownloadButton';
import BtnCreateNew from '../components/BtnCreateNew'
import ListPagination from "../components/ListPagination";

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const EvidenceList = (props: any) => {
  const translate = useTranslate();
  const { nFacilityMasterId } = props;

  return <Box className="formContent full100">
    <SectionTitle label="service.evidences" />
    <Box>
      <BtnCreateNew
        resource_field_name='n_facility_master_id'
        resource_val={nFacilityMasterId}
        target_name='evidence' />
    </Box>
    <ResourceContextProvider value="evidence">
      <List
        hasCreate={false}
        hasShow
        hasEdit
        hasList
        actions={false}
        resource='evidence'
        bulkActionButtons={false}
        sort={{ field: 'created', order: 'DESC' }}
        perPage={25}
        pagination={<ListPagination />}
        syncWithLocation
        basePath="/evidence"
        filter={{ 'n_facility_master_id@_eq': nFacilityMasterId }}
        title=" ">
        <Datagrid optimized rowClick="edit">
          <TextField source="id" label={translate('evidence.id')} />
          <DateField source="received" type="date" label={translate('evidence.received')} locales="ja-JP" />
          <TextField source="title" label={translate('evidence.title')} />
          <TextField source="note" label={translate('evidence.note')} />
          <TextField source="filename" label={translate('evidence.filename')} />
          <DownloadButton label={translate('evidence.filename')} />
          <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
          <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
            <TextField source="full_name" />
          </ReferenceField>
        </Datagrid>
      </List>
    </ResourceContextProvider>
  </Box>
}

export default EvidenceList;