import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import CustomReferenceInput from '../components/CustomReferenceInput';
import { ProcessNFacility, ProcessNmaster } from "../../utils/Utils";

const filterToQuery = (searchText: any) => {
  if (!searchText) {
    return {};
  }
  
  const nNumber = parseInt(searchText.replace('N', ''));
  if (!isNaN(nNumber)) {
    return { n_master_id: nNumber }
  }
  return { name: searchText }
}

const ReferenceInput = (props: any) => {
  const translate = useTranslate();

  return <CustomReferenceInput
    source="n_facility_master_id"
    reference="n_facility_master"
    label={translate('n_facility_master.refer_input')}
    filterToQuery={filterToQuery}
    optionText={(r: any) => `N${ProcessNmaster(r?.n_master_id)}-${ProcessNFacility(r?.n_facility_number)} ${r?.name}`}
    resource={'n_facility_master'}
    {...props} />
}

export default ReferenceInput;