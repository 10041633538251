import {makeStyles} from "@material-ui/styles";

export default makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#FAFAFA',
    },
    formContainer: {
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        alignItems: "center",
        justifyContent: "center",
    },    
    customFormContainer: {
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "auto",
        alignItems: "center",
    },  
    tabsButtom:{
        marginBottom:50,
    },    
    form: {
        width: "450px",
        background:"#FFF",
        padding:"50px",
        borderRadius:10,
        boxShadow: "0 10px 20px #ececec",
        boxSizing:'border-box'
    }, 
    formButtons: {
        width: "100%",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }, 
      loginLoader: {
        marginLeft: '5px'
      },         
    tab: {
        fontWeight: 400,
        fontSize: 18
    },           
    forgetButton: {
        textTransform: "none",
        fontWeight: 400
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: '#000'
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: '#000'
    },
    titleForm: {
        fontSize: '24px',
        marginBottom: '15px',
    },
    errorMessage: {
        textAlign: "center"
      },
}));
