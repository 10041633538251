import * as React from 'react';
import {FC} from 'react';
import Link from '@material-ui/core/Link';
import {Client, FieldProps} from '../../types';

const ClientLinkField: FC<FieldProps<Client>> = props =>
    props.record ? (
        <Link href={`http://${props.record.domain}`} target="_blank" color="primary">
            {props.record.domain}
        </Link>
    ) : null;

ClientLinkField.defaultProps = {
    source: 'domain',
    addLabel: true,
};

export default ClientLinkField;
