import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import CustomReferenceInput from '../components/CustomReferenceInput';
import Fields from './qcDataset';

const QCFields = Fields.filter(f => !f.nullable);

const ReferenceInput = (props: any) => {
  const translate = useTranslate();

  return <CustomReferenceInput
    source="evidence_id"
    reference="evidence"
    label={translate('evidence.refer_input')}
    filterToQuery={(searchText: string) => ({title: searchText})}
    optionText={(r: any) => r.title }
    resource={'evidence'}
    quickCreateFields={QCFields}
    {...props} />
}

export default ReferenceInput;