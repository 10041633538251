import * as React from 'react';
import {FC} from 'react';
import {
    BooleanInput,
    Create,
    email,
    PasswordInput,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    useTranslate
} from 'react-admin';
import {Box, Typography} from '@material-ui/core';
import {CreateComponentProps} from '../../types';

const ClientCreate: FC<CreateComponentProps> = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const onFailure = (error: any) => {
        notify(`${translate('common.could_not')} ${error.message}`, 'warning');
    };

    const PostCreateToolbar = (props: any) => (
        <Toolbar {...props}>
            <SaveButton
                label={translate('common.btn_save')}
                submitOnEnter={true}
                redirect='list'
            />
        </Toolbar>
    );

    return (
        <Create {...props} className="formContent"
            onSuccess={() => {
                notify(`${translate('common.msg_add_success')}`, 'info');
            }}
            onFailure={onFailure}
            title={translate('menu.client.client_add')}
        >
            <SimpleForm className="form" redirect="show"
                toolbar={<PostCreateToolbar />}
            >
                <SectionTitle label="client_list.create_new" />
                <Separator />
                <TextInput
                    source="name"
                    validate={requiredValidate}
                    fullWidth
                    label={translate('client_list.name')}
                    variant="outlined"
                />
                <TextInput
                    source="domain"
                    validate={requiredValidate}
                    fullWidth
                    label={translate('client_list.domain')}
                    variant="outlined"
                />
                <TextInput
                    type="email"
                    source="from_email"
                    label={translate('client_list.from_email')}
                    validate={[email(), required()]}
                    fullWidth
                    variant="outlined"
                />
                <TextInput
                    source="mailer_host"
                    fullWidth
                    label={translate('client_list.mailer_host')}
                    variant="outlined"
                />
                <TextInput
                    source="mailer_port"
                    fullWidth
                    label={translate('client_list.mailer_port')}
                    variant="outlined"
                />
                <BooleanInput label={translate('client_list.mailer_secure')} source="mailer_secure" />
                <TextInput
                    source="mailer_user"
                    fullWidth
                    label={translate('client_list.mailer_user')}
                    variant="outlined"
                />
                <PasswordInput
                    source="mailer_password"
                    fullWidth
                    variant="outlined"
                    label={translate('client_list.mailer_password')}
                />
                <TextInput
                    source="s3_region"
                    fullWidth
                    label={translate('client_list.s3_region')}
                    variant="outlined"
                />
                <TextInput
                    source="s3_key"
                    fullWidth
                    label={translate('client_list.s3_key')}
                    variant="outlined"
                />
                <TextInput
                    source="s3_secret"
                    fullWidth
                    label={translate('client_list.s3_secret')}
                    variant="outlined"
                />                                                                
                <TextInput
                    source="s3_bucket"
                    fullWidth
                    label={translate('client_list.s3_bucket')}
                    variant="outlined"
                />
                <TextInput
                    source="s3_bucket_import"
                    fullWidth
                    label={translate('client_list.s3_bucket_import')}
                    variant="outlined"
                    resource="client"
                />
                <TextInput
                    source="s3_bucket_bill"
                    fullWidth
                    label={translate('client_list.s3_bucket_bill')}
                    variant="outlined"
                    resource="client"
                />
                <TextInput
                    source="twilio_sid"
                    fullWidth
                    label={translate('client_list.twilio_sid')}
                    variant="outlined"
                />                
                <TextInput
                    source="twilio_token"
                    fullWidth
                    label={translate('client_list.twilio_token')}
                    variant="outlined"
                />
                <TextInput
                    source="twilio_phone"
                    fullWidth
                    label={translate('client_list.twilio_phone')}
                    variant="outlined"
                />
                <BooleanInput label={translate('client_list.is_active')} source="is_active" />
            </SimpleForm>
        </Create>
    )
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};
const Separator = () => <Box pt="1em" />;
export default ClientCreate;
