import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const { editForm } = props;
  return <Container>
    <SectionTitle label={editForm ? "payment.edit" : "payment.add_new"} />
    <Separator />
    <Grid container justify='center' spacing={2}>
      {Fields.map((d, idx) => {
        return <Grid item xs={4} key={idx}>
          <InputRenderer source='payment' data={d} />
        </Grid>
      })}
    </Grid>
  </Container>;
};

export default NFrom;