import * as React from 'react';
import {BooleanInput, Edit, email, PasswordInput, required, SimpleForm, TextInput, useTranslate,} from 'react-admin';
import {Box, Typography} from '@material-ui/core';
import UpdateToolbar from "../components/UpdateToolbar";
import EditField from '../components/editField';


const ClientEdit = (props: any) => {
    const translate = useTranslate();
    return (
        <Edit title={<EditField titlePage={translate('menu.client.client_menu')}/>} {...props} className="formContent">
            <SimpleForm redirect="edit" toolbar={<UpdateToolbar />} style={{ width: '50%', margin: 'auto' }}>
                <Box display={{ md: 'block', lg: 'flex' }} style={{ width: '100%' }}>
                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                        <SectionTitle label="client_list.update_title" />
                        <Separator />
                        <TextInput
                            source="name"
                            resource="client"
                            validate={requiredValidate}
                            fullWidth
                            label={translate('client_list.name')}
                            variant="outlined"
                        />
                        <TextInput
                            source="domain"
                            resource="client"
                            validate={requiredValidate}
                            fullWidth
                            label={translate('client_list.domain')}
                            variant="outlined"
                        />
                        <TextInput
                            type="email"
                            source="from_email"
                            label={translate('client_list.from_email')}
                            resource="client"
                            validate={[email(), required()]}
                            fullWidth
                            variant="outlined"
                        />
                        <TextInput
                            source="mailer_host"
                            resource="client"
                            validate={requiredValidate}
                            fullWidth
                            label={translate('client_list.mailer_host')}
                            variant="outlined"
                        />
                        <TextInput
                            source="mailer_port"
                            resource="client"
                            validate={requiredValidate}
                            fullWidth
                            label={translate('client_list.mailer_port')}
                            variant="outlined"
                        />
                        <BooleanInput label={translate('client_list.mailer_secure')} source="mailer_secure" />
                        <TextInput
                            source="mailer_user"
                            resource="client"
                            validate={requiredValidate}
                            fullWidth
                            label={translate('client_list.mailer_user')}
                            variant="outlined"
                        />
                        <PasswordInput
                            source="mailer_password"
                            resource="client"
                            fullWidth
                            variant="outlined"
                            label={translate('client_list.mailer_password')}
                        />
                        <TextInput
                            source="s3_region"
                            fullWidth
                            label={translate('client_list.s3_region')}
                            variant="outlined"
                            resource="client"
                        />
                        <TextInput
                            source="s3_key"
                            fullWidth
                            label={translate('client_list.s3_key')}
                            variant="outlined"
                            resource="client"
                        />
                        <TextInput
                            source="s3_secret"
                            fullWidth
                            label={translate('client_list.s3_secret')}
                            variant="outlined"
                            resource="client"
                        />
                        <TextInput
                            source="s3_bucket"
                            fullWidth
                            label={translate('client_list.s3_bucket')}
                            variant="outlined"
                            resource="client"
                        />
                        <TextInput
                            source="s3_bucket_import"
                            fullWidth
                            label={translate('client_list.s3_bucket_import')}
                            variant="outlined"
                            resource="client"
                        />
                        <TextInput
                            source="s3_bucket_bill"
                            fullWidth
                            label={translate('client_list.s3_bucket_bill')}
                            variant="outlined"
                            resource="client"
                        />
                        <TextInput
                            source="twilio_sid"
                            resource="client"
                            validate={requiredValidate}
                            fullWidth
                            label={translate('client_list.twilio_sid')}
                            variant="outlined"
                        />
                        <TextInput
                            source="twilio_token"
                            resource="client"
                            validate={requiredValidate}
                            fullWidth
                            label={translate('client_list.twilio_token')}
                            variant="outlined"
                        />
                        <TextInput
                            source="twilio_phone"
                            resource="client"
                            validate={requiredValidate}
                            fullWidth
                            label={translate('client_list.twilio_phone')}
                            variant="outlined"
                        />
                        <BooleanInput label={translate('client_list.is_active')} source="is_active" />
                    </Box>
                </Box>
            </SimpleForm>
        </Edit>
    )
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};
const Separator = () => <Box pt="1em" />;
export default ClientEdit;
