import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
  useAuthState,
  Loading,
  useRedirect,
  useGetIdentity,
  Confirm
} from 'react-admin';
import { gql, useApolloClient } from '@apollo/client';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import { Box, Paper, FormControl, Select, MenuItem, InputLabel, Checkbox, Button } from '@material-ui/core';
import moment from 'moment';
import { YEARS, MONTHS } from './dataset';
import { numberWithCommas as NF, stringToNumber as UNF } from '../../utils/Utils'
import { ProcessNmaster, ProcessNFacility } from "../../utils/Utils";

const FETCH_DATA = gql`
  query QuerySales($month: Int!, $year: Int!, $no_confirmed: Boolean) {
    sales(where: {month: {_eq: $month}, year: {_eq: $year}, cost_confirmed: {_is_null: $no_confirmed}}) {
      id
      month
      year
      type
      sales_amount
      sales_total_amount
      total_incentive_amount
      adjustment_reason
      cost_confirmed
      step1_reseller_amount
      step2_reseller_amount
      step3_reseller_amount
      reseller {
        code
        name
      }
      resellerByStep2ResellerId {
        code
        name
      }
      resellerByStep3ResellerId {
        code
        name
      }
      service {
        id
        plan_code
        rate_type
        step1_reseller_rate
        step2_reseller_rate
        step3_reseller_rate
        n_facility_master {
          n_master_id
          n_facility_number
          name
        }
      }
    }
  }`;

const UPDATE_CONFIRMED_AT = gql`
  mutation UpdateConfirmedAt($id: Int!, $dt: timestamptz, $user_id: Int,  $step1_reseller_amount: numeric, $step2_reseller_amount: numeric, $step3_reseller_amount: numeric, $total_incentive_amount: numeric) {
    update_sales_by_pk(
      pk_columns: {id: $id}, 
      _set: {
        cost_confirmed: $dt,
        cost_confirmed_by: $user_id,
        step1_reseller_amount: $step1_reseller_amount,
        step2_reseller_amount: $step2_reseller_amount,
        step3_reseller_amount: $step3_reseller_amount,
        total_incentive_amount: $total_incentive_amount,
      }
    ) {
      cost_confirmed
    } 
  }`;

const UPDATE_INVOICE_DETAIL_CONFIRMED_AT = gql`
  mutation UpdateInvoiceDetailConfirmedAt(
    $id: Int!, 
    $user_id: Int, 
    $step1_reseller_amount: numeric, 
    $step2_reseller_amount: numeric, 
    $step3_reseller_amount: numeric,
    $sales_month: Int, 
    $sales_year: Int, 
    $type: Int, 
    ) {
    update_invoice_detail(
      where: {service: {sales: {id: {_eq: $id}}},
      sales_month: {_eq: $sales_month}, 
      sales_year: {_eq: $sales_year}, 
      type: {_eq: $type}}
      _set: {
        step1_reseller_amount: $step1_reseller_amount,
        step2_reseller_amount: $step2_reseller_amount,
        step3_reseller_amount: $step3_reseller_amount,
        confirmed_by: $user_id
      }
    ) {
      affected_rows
    }
  }
`

const BATCH_UPDATE_CONFIRMED_AT = gql`
  mutation BatchUpdateConfirmedAt($ids: [Int!], $dt: timestamptz, $user_id: Int) {
    update_sales(where: {id: {_in: $ids}}, , _set: {cost_confirmed: $dt, confirmed_by: $user_id}) {
      returning {
        id
        cost_confirmed
        confirmed_by
      }
    } 
  }`;
const GET_DATA_SALE = gql`
  query get_data_sales($id: Int) {
    sales(where: {id: {_eq: $id}}) {
      step1_reseller_amount
      step2_reseller_amount
      step3_reseller_amount
      total_incentive_amount
      month
      year
      type
      service_id
    }
  }
  `;
  const GET_DATA_INVOICE_DETAIL = gql`
  query get_data_invoice_detail($service_id: Int,$invoice_month: Int, $invoice_year: Int) {
    invoice_detail(where: {service_id: {_eq: $service_id}, invoice_month: {_eq: $invoice_month}, invoice_year: {_eq: $invoice_year}}) {
      step1_reseller_amount
      step2_reseller_amount
      step3_reseller_amount
    }
  }
  `;
  const GET_DATA_SALE_BY_ID = gql`
  query QuerySales($sale_id: Int) {
    sales(where: {id: {_eq: $sale_id}}) {
      id
      month
      year
      type
      sales_amount
      sales_total_amount
      total_incentive_amount
      adjustment_reason
      cost_confirmed
      step1_reseller_amount
      step2_reseller_amount
      step3_reseller_amount
      reseller {
        code
        name
      }
      resellerByStep2ResellerId {
        code
        name
      }
      resellerByStep3ResellerId {
        code
        name
      }
      service {
        id
        plan_code
        rate_type
        step1_reseller_rate
        step2_reseller_rate
        step3_reseller_rate
        n_facility_master {
          n_master_id
          n_facility_number
          name
        }
      }
    }
  }`;

const CONFIRMED_FLAG = [
  { id: 0, name: 'FALSE' },
  { id: 1, name: 'TRUE' }
]

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 15,
    marginBottom: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const NList = () => {
  const { loading, authenticated } = useAuthState();
  const [rows, setRows] = useState([] as any);
  const [month, setMonth] = useState(moment().month() + 1);
  const [year, setYear] = useState(moment().year());
  const [confirmed, setConfirmed] = useState(0);
  const [selectedRows, setSelectedRows] = useState([] as any);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const apolloClient = useApolloClient();
  const translate = useTranslate()
  const classes = useStyles();
  const redirect = useRedirect();
  const { identity, loading: identityLoading } = useGetIdentity();

  useEffect(() => {
    if (!loading && authenticated) {
      fetchData();
    }
  }, [month, year, confirmed, loading])


  const handleChange = async (e: any, params: any) => {
    const { target: { checked } } = e;
    const sale_id = params?.row?.id;

    const step1ResellerAmount = params?.row?.step1_reseller_amount || 0;
    const step2ResellerAmount = params?.row?.step2_reseller_amount || 0;
    const step3ResellerAmount = params?.row?.step3_reseller_amount || 0;
    const totalIncentiveAmount = params?.row?.total_incentive_amount || 0;

    const sales_month = params?.row?.month;
    const sales_year = params?.row?.year;
    const type = params?.row?.type;

    if (Boolean(sale_id)) {
      Promise.all([
        await apolloClient.mutate({
          mutation: UPDATE_CONFIRMED_AT,
          variables: {
            id: sale_id,
            dt: checked ? moment() : null,
            user_id: checked ? identity.id : null,
            step1_reseller_amount: step1ResellerAmount,
            step2_reseller_amount: step2ResellerAmount,
            step3_reseller_amount: step3ResellerAmount,
            total_incentive_amount: totalIncentiveAmount,
          }
        }),
        await apolloClient.mutate({
          mutation: UPDATE_INVOICE_DETAIL_CONFIRMED_AT,
          variables: {
            id: sale_id,
            user_id: checked ? identity.id : null,
            step1_reseller_amount: step1ResellerAmount,
            step2_reseller_amount: step2ResellerAmount,
            step3_reseller_amount: step3ResellerAmount,
            sales_month: sales_month,
            sales_year: sales_year,
            type: type
          }
        })
      ])

      // reload data
      fetchData();
    }
  }

  const renderActionCell = (params: GridCellParams) => {
    // console.log(params);
    return <Checkbox
      checked={params?.row?.checked}
      onChange={(e: any) => handleChange(e, params)}
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  }

  const columns = [
    { field: 'id', headerName: 'ID', typename: 'number', width: 40, hide: true },
    { field: 'n_number', headerName: translate('sales.confirm.n_number'), width: 120, typename: 'string', disableColumnMenu: true },
    { field: 'n_facility_name', headerName: translate('sales.confirm.n_facility_name'), width: 250, typename: 'string', disableColumnMenu: true },
    { field: 'sales_amount', headerName: translate('sales.sales_amount'), typename: 'number', disableColumnMenu: true },
    { field: 'plan_code', headerName: translate('service.plan_code'), typename: 'string', width: 120, disableColumnMenu: true },
    { field: 'step3_reseller_code', headerName: translate('sales.cost_confirm.step3_reseller_code'), typename: 'string', width: 120, disableColumnMenu: true },
    { field: 'step3_reseller_name', headerName: translate('sales.cost_confirm.step3_reseller_name'), typename: 'string', width: 150, disableColumnMenu: true },
    { field: 'step2_reseller_code', headerName: translate('sales.cost_confirm.step2_reseller_code'), typename: 'string', width: 120, disableColumnMenu: true },
    { field: 'step2_reseller_name', headerName: translate('sales.cost_confirm.step2_reseller_name'), typename: 'string', width: 150, disableColumnMenu: true },
    { field: 'step1_reseller_code', headerName: translate('sales.cost_confirm.step1_reseller_code'), typename: 'string', width: 150, disableColumnMenu: true },
    { field: 'step1_reseller_name', headerName: translate('sales.cost_confirm.step1_reseller_name'), typename: 'string', width: 150, disableColumnMenu: true },
    { field: 'step3_reseller_rate', headerName: translate('sales.cost_confirm.step3_reseller_rate'), typename: 'number', width: 120, disableColumnMenu: true },
    { field: 'step3_reseller_amount', headerName: translate('sales.cost_confirm.step3_reseller_amount'), typename: 'number', width: 120, disableColumnMenu: true },
    { field: 'step2_reseller_rate', headerName: translate('sales.cost_confirm.step2_reseller_rate'), typename: 'number', width: 120, disableColumnMenu: true },
    { field: 'step2_reseller_amount', headerName: translate('sales.cost_confirm.step2_reseller_amount'), typename: 'number', width: 120, disableColumnMenu: true },
    { field: 'step1_reseller_rate', headerName: translate('sales.cost_confirm.step1_reseller_rate'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'step1_reseller_amount', headerName: translate('sales.cost_confirm.step1_reseller_amount'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'month', headerName: translate('sales.month'), typename: 'number', disableColumnMenu: true },
    { field: 'year', headerName: translate('sales.year'), typename: 'number', disableColumnMenu: true, hide: true },
    { field: 'type', headerName: translate('sales.type'), typename: 'number', disableColumnMenu: true, hide: true },
    { field: 'total_incentive_amount', headerName: translate('sales.sales_total_amount'), typename: 'number', width: 120, disableColumnMenu: true },
    { field: 'action', headerName: translate('sales.confirm.action'), renderCell: renderActionCell },
  ]


  const convertData = (data: [any]) => {
    if (data) {
      return data.map((d: any) => {
        const nNumber = `N${ProcessNmaster(d?.service?.n_facility_master?.n_master_id)}` + '-' + ProcessNFacility(d?.service?.n_facility_master?.n_facility_number)
        const step3ResellerAmount = Math.ceil((d.sales_total_amount / 100) * d.service.step3_reseller_rate);
        let step2ResellerAmount = 0;
        let step1ResellerAmount = 0;
        // 獲得店、紹介店、その他店の入力だった場合→獲得店＆紹介店：切上 →その他店：切り下げ
        // check step1_reseller 
        if (Boolean(d.reseller?.name)) {
          step2ResellerAmount = Math.ceil((d.sales_total_amount / 100) * d.service.step2_reseller_rate);
          step1ResellerAmount = Math.floor((d.sales_total_amount / 100) * d.service.step1_reseller_rate);
        } else {
          //その他店が未入力（＝算出不要）だった場合→獲得店：切上 →紹介店：切り下げ
          step2ResellerAmount = Math.floor((d.sales_total_amount / 100) * d.service.step2_reseller_rate);
        }
      //console.log('totalIncentiveAmount',totalIncentiveAmount);
        return {
          id: d.id,
          n_number: nNumber,
          n_facility_name: d?.service?.n_facility_master?.name,
          sales_amount: NF(d.sales_total_amount),
          plan_code: d.service?.plan_code,
          step1_reseller_code: d.reseller?.code,
          step1_reseller_name: d.reseller?.name,
          step2_reseller_code: d.resellerByStep2ResellerId?.code,
          step2_reseller_name: d.resellerByStep2ResellerId?.name,
          step3_reseller_code: d.resellerByStep3ResellerId?.code,
          step3_reseller_name: d.resellerByStep3ResellerId?.name,
          step1_reseller_rate: d.service?.step1_reseller_rate,
          step1_reseller_amount: step1ResellerAmount,
          step2_reseller_rate: d.service?.step2_reseller_rate,
          step2_reseller_amount: step2ResellerAmount,
          step3_reseller_rate: d.service?.step3_reseller_rate,
          step3_reseller_amount: step3ResellerAmount,
          month: d.month,
          year: d.year,
          type: d.type,
          total_incentive_amount: (
            Math.ceil(
              step1ResellerAmount +
              step2ResellerAmount +
              step3ResellerAmount
            )
          ),
          checked: Boolean(d.cost_confirmed),
        }
      })

    }

    return []
  }

  const fetchData = async () => {
    const { data } = await apolloClient.query({
      query: FETCH_DATA,
      fetchPolicy: 'network-only',
      variables: {
        month: month,
        year: year,
        no_confirmed: !confirmed,
      }
    });

    if (!data) { return };

    const nData = convertData(data.sales);
    setRows(nData);
  }

  const handleSelectBoxChange = (event: any) => {
    const { target: { value, name } } = event;
    if (name === 'year') {
      setYear(value);
    } else if (name === 'month') {
      setMonth(value);
    } else if (name === 'confirmed') {
      setConfirmed(value);
    }
  }

  const batchConfirm = async () => {
    setShowConfirmPopup(true);
  }

  const handleBatchUpdate = async () => {
    setShowConfirmPopup(false);
    for (let index = 0; index < selectedRows.length; index++) {
      // get data sales
      const sale_id = selectedRows[index];
      console.log('sale_id',sale_id);
      
      const { data: { sales } } = await apolloClient.query({
        query: GET_DATA_SALE_BY_ID,
        fetchPolicy: 'network-only',
        variables: {
          sale_id: sale_id,
        }
      });
      const DataSale = sales[0];
      
      // update data sales
      const step3ResellerAmount = Math.ceil((DataSale.sales_total_amount / 100) * DataSale.service.step3_reseller_rate);
        let step2ResellerAmount = 0;
        let step1ResellerAmount = 0;
        // 獲得店、紹介店、その他店の入力だった場合→獲得店＆紹介店：切上 →その他店：切り下げ
        // check step1_reseller 
        if (Boolean(DataSale.reseller?.name)) {
          step2ResellerAmount = Math.ceil((DataSale.sales_total_amount / 100) * DataSale.service.step2_reseller_rate);
          step1ResellerAmount = Math.floor((DataSale.sales_total_amount / 100) * DataSale.service.step1_reseller_rate)
        } else {
          //その他店が未入力（＝算出不要）だった場合→獲得店：切上 →紹介店：切り下げ
          step2ResellerAmount = Math.floor((DataSale.sales_total_amount / 100) * DataSale.service.step2_reseller_rate);
        }
      
      // const step1ResellerAmount = dataConvert.step1_reseller_amount || 0;
      // const step2ResellerAmount = dataConvert.step2_reseller_amount || 0; 
      // const step3ResellerAmount = dataConvert.step3_reseller_amount || 0;
      const totalIncentiveAmount = (
        Math.ceil(
          step1ResellerAmount +
          step2ResellerAmount +
          step3ResellerAmount
        )
      );
      const sales_month = sales[0].month;
      const sales_year = sales[0].year;
      const type = sales[0].type;

      if (Boolean(sale_id)) {
        Promise.all([
          await apolloClient.mutate({
            mutation: UPDATE_CONFIRMED_AT,
            variables: {
              id: sale_id,
              dt: moment(),
              user_id: identity.id,
              step1_reseller_amount: step1ResellerAmount,
              step2_reseller_amount: step2ResellerAmount,
              step3_reseller_amount: step3ResellerAmount,
              total_incentive_amount: totalIncentiveAmount,
            }
          }),
          await apolloClient.mutate({
            mutation: UPDATE_INVOICE_DETAIL_CONFIRMED_AT,
            variables: {
              id: sale_id,
              user_id: identity.id,
              step1_reseller_amount: step1ResellerAmount,
              step2_reseller_amount: step2ResellerAmount,
              step3_reseller_amount: step3ResellerAmount,
              sales_month: sales_month,
              sales_year: sales_year,
              type: type
            }
          })
        ])

      }
    }

    // await apolloClient.mutate({
    //   mutation: BATCH_UPDATE_CONFIRMED_AT,
    //   variables: {
    //     ids: selectedRows,
    //     dt: moment(),
    //     user_id: identity.id,
    //   }
    // });

    // reload data
    fetchData();
    setSelectedRows([]);
  }

  if (loading) {
    return <Loading />;
  }

  if (!authenticated) {
    redirect('/login');
  }

  if (identityLoading || !identity) {
    return <Loading />;
  }

  return <Box width='100%' height='100%'>
    <Paper className={classes.toolbar}>
      <FormControl className={classes.formControl}>
        <InputLabel id="year-select-label">{translate('sales.year')}</InputLabel>
        <Select
          labelId="year-select-label"
          id="year-select"
          value={year}
          name='year'
          onChange={handleSelectBoxChange}
        >
          {YEARS.map((m, idx) => {
            return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
          })}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="month-select-label">{translate('sales.month')}</InputLabel>
        <Select
          labelId="month-select-label"
          id="month-select"
          value={month}
          name='month'
          onChange={handleSelectBoxChange}
        >
          {MONTHS.map((m, idx) => {
            return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
          })}
        </Select>
      </FormControl>
      {/* 確認 */}
      <FormControl className={classes.formControl}>
        <InputLabel id="confirmed-select-label">{translate('sales.confirm.action')}</InputLabel>
        <Select
          labelId="confirmed-select-label"
          id="confirmed-select"
          value={confirmed}
          name='confirmed'
          onChange={handleSelectBoxChange}
        >
          {CONFIRMED_FLAG.map((m, idx) => {
            return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
          })}
        </Select>
      </FormControl>
      {/* 一括確認ボタン */}
      <FormControl className={classes.formControl}>
        <Button
          disabled={(selectedRows.length === 0) || Boolean(confirmed)}
          variant="contained"
          color="primary"
          onClick={batchConfirm}>
          {translate('sales.confirm.batch_update')}
        </Button>
      </FormControl>
    </Paper>
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={20}
      checkboxSelection={!confirmed}
      onSelectionModelChange={(newSelections) => {
        console.log('newSelections', newSelections);

        const { selectionModel } = newSelections;
        setSelectedRows(selectionModel);
      }} />

    <Confirm
      isOpen={showConfirmPopup}
      title={translate('common.batch_update_confirm_title')}
      content={`${selectedRows.length}${translate('common.batch_update_confirm_content')} ${translate('common.batch_update_confirmation')}`}
      onConfirm={handleBatchUpdate}
      onClose={() => setShowConfirmPopup(false)} />
  </Box>
};

export default NList;
