import * as React from 'react';
import {FC} from 'react';
import {ReferenceField, TextField} from 'react-admin';
import {ReferenceFieldProps} from '../../types';

const GroupReferenceField: FC<
    Omit<ReferenceFieldProps, 'reference' | 'children'>
    > = props => (
    <ReferenceField
        label="Group"
        source="group_id"
        reference="user_group"
        {...props}
    >
        <TextField source="name" />
    </ReferenceField>
);

GroupReferenceField.defaultProps = {
    source: 'group_id',
    addLabel: true,
};

export default GroupReferenceField;
