import moment from 'moment';
export const YEARS = [3,2,1,0,-1,-2,-3,-4,-5,-6,-7,-8,-9].map(num => ({ id: moment().year() - num, name: `${moment().year() - num}` }))

export const MONTHS = [1,2,3,4,5,6,7,8,9,10,11,12].map(m=>({id: m, name: `${m}`}))

export const CONFIRMED_ISNULL_FLAGS = [
  {id: false, name: 'TRUE'},
  {id: true, name: 'FALSE'}
]

const DATA = [
  { name: 'service_id', type: 'reference', reference: 'service', searchField: '', options: [], nullable: false, editable: true, export: true, import: true },
  { name: 'company_name', type: 'virtual_reference', reference: 'service'},
  { name: 'n_master_id', type: 'virtual_reference', export: true },
  { name: 'n_master_name', type: 'virtual_reference', export: true },
  { name: 'invoice_year', type: 'selectbox', options: YEARS, nullable: false, editable: true, export: true, import: true },
  { name: 'invoice_month', type: 'selectbox', options: MONTHS, nullable: false, editable: true, export: true, import: true },
  { name: 'invoice_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'payment_received_date', type: 'date', nullable: true, editable: true, export: true, import: true },
  { name: 'payment_received_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'plan_code', type: 'virtual_reference', reference: 'service'},
  { name: 'transfer_flag', type: 'virtual_reference', reference: 'service'},
  { name: 'step3_reseller_code', type: 'virtual_reference', reference: 'reseller'},
  { name: 'step3_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: false, editable: true, export: true, import: true },
  { name: 'step2_reseller_code', type: 'virtual_reference', reference: 'reseller'},
  { name: 'step2_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: false, editable: true, export: true, import: true },
  { name: 'step1_reseller_code', type: 'virtual_reference', reference: 'reseller'},
  { name: 'step1_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: true, editable: true, export: true, import: true },
  { name: 'step3_reseller_rate', type: 'virtual_reference', reference: 'service'},
  { name: 'step3_reseller_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'step2_reseller_rate', type: 'virtual_reference', reference: 'service'},
  { name: 'step2_reseller_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'step1_reseller_rate', type: 'virtual_reference', reference: 'service'},
  { name: 'step1_reseller_amount', type: 'textinput', nullable: true, editable: true, numberFormat: true, export: true, import: true },
  { name: 'sales_year', type: 'selectbox', options: YEARS, nullable: false, editable: true, export: true, import: true },
  { name: 'sales_month', type: 'selectbox', options: MONTHS, nullable: false, editable: true, export: true, import: true },
  { name: 'total_reseller_amounts', type: 'virtual_reference'},
]

export default DATA;