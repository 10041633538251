import * as React from 'react';
import {FC} from 'react';
import {FieldProps} from '../../types';
import {EditButton} from 'react-admin';
import BtnCreateNew from '../components/BtnCreateNew';
import BtnShowList from '../components/BtnShowList';

const ActionsField: FC<FieldProps<any>> = ({ record, basePath, resource }) => {
  return record ? (
        <span>
            <BtnShowList 
              target_name='service'
              filter_name='contract_id'
              filter_val={record.id} />
            <BtnCreateNew 
              resource_field_name='contract_id' 
              resource_val={record.id} 
              target_name='service' />
        </span>
    ) : null;
}

export default ActionsField;
