
import { gql } from '@apollo/client';

export const FETCH_INVOICE_DETAIL_SERVICE = gql`
query QueryInvoiceDetailService($invoice_year: Int, $invoice_month: Int, $sales_year: Int, $sales_month: Int, $status: Int, $payment_received_date: date, $payment_received_flg: Boolean) {
    invoice_detail(order_by: {id: desc}, where: {invoice_year: {_eq: $invoice_year}, invoice_month: {_eq: $invoice_month}, sales_year: {_eq: $sales_year}, sales_month: {_eq: $sales_month}, status: {_eq: $status}, payment_received_date: {_eq: $payment_received_date}, payment_received_flg: {_eq: $payment_received_flg}}, limit: 100000) {
        id
        service_id
        invoice_year
        invoice_month
        sales_year
        sales_month
        invoice_id
        invoice_amount
        expected_invoice_amount
        service_usage
        invoice_amount
        number_of_month
        total_months
        step1_reseller_id
        step1_reseller_amount
        step1_reseller_number_of_month
        step1_reseller_total_months
        step2_reseller_id
        step2_reseller_amount
        step2_reseller_number_of_month
        step2_reseller_total_months
        step3_reseller_id
        step3_reseller_amount
        step3_reseller_number_of_month
        step3_reseller_total_months
        payment_received_date
        payment_received_flg
        payment_method
        payment_received_amount
        adjustment_amount
        adjustment_reason
        confirmed
        confirmed_by
        incentive_paid
        status
        scheduled_transfer_date
        scheduled_implementation_date
        note
        created
        created_by
        service {
            n_facility_master {
                n_facility_number
                name
            }
            n_master {
                name
                id
            }
        }
    }
}`;
export const GET_SERVICE_BY_ID = gql`
query get_service($service_id: Int) {
    service(where: {id: {_eq: $service_id}}) {
      rate_type
    }
  }
`; 
export const GET_DATA_SERVICE = async (service_id: number, apolloClient: any) => {
    const result = await apolloClient.query({
        query: GET_SERVICE_BY_ID,
        fetchPolicy: 'network-only',
        variables: {
            service_id: service_id
        }
    });
    return result.data.service[0].rate_type;
}