import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  useTranslate,
  useAuthState,
  Loading,
  useRedirect,
  useNotify,
  useGetIdentity,
  Confirm,
  EditButton,
} from 'react-admin';
import { useApolloClient } from '@apollo/client';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import { Box, Checkbox } from '@material-ui/core';
import { numberWithCommas as NF, ProcessNFacility, stringToNumber as UNF } from '../../../utils/Utils'
import { useLocation } from 'react-router-dom';
import ToolBar from './ToolBar';
import Providers from './dataProvider'
import moment from 'moment-business-days';
import * as holiday_jp from '@holiday-jp/holiday_jp';
import { ProcessNmaster } from "../../../utils/Utils";
import { async } from 'q';


moment.locale('ja');


const ConfirmPage = () => {
  const { loading, authenticated } = useAuthState();
  const redirect = useRedirect();
  const [rows, setRows] = useState([] as any);
  const [invoiceMonth, setInvoiceMonth] = useState(moment().month() + 1);
  const [invoiceYear, setInvoiceYear] = useState(moment().year());
  const [n_master, setNMaster] = useState<string>('');
  const [confirmed, setConfirmed] = useState(0);
  const [selectedRows, setSelectedRows] = useState([] as any);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [diffCheck, setDiffCheck] = useState(0);
  const apolloClient = useApolloClient();
  const translate = useTranslate()
  const notify = useNotify();
  const { identity, loading: identityLoading } = useGetIdentity();

  let query = new URLSearchParams(useLocation().search);
  const invoiceType = query.get('invoice_type');

  const onGenerateInvoiceBtnClick = async () => {
    const ids = rows.filter((r:any) => r.checked).map((o:any) => o.id);
    if (ids.length > 0) {
      const updateDone = await Providers.createInvoice(identity.id, ids, apolloClient);
      if(updateDone) {
        // suceess
        notify(translate('invoice_detail.updated_status'));
      }
    }
  }

  const takeOutWeekend = (totalDay:number,month:number,year:number)=>{
    let data = [];
    for(let i=1;i<=totalDay;i++){
      var newDate = new Date(year,month,i)
      if(newDate.getDay()==0){   //if Sunday
        data.push(i);
      }
      if(newDate.getDay()==6){   //if Saturday
        data.push(i);
      }
    }
    return data;
  }

  const handleChange = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;
    const id = params?.row?.id;

    const invoiceAmount = params?.row?.invoice_amount_check === 0 ? UNF(params?.row?.invoice_amount) : params?.row?.invoice_amount_check;
    const serviceUsage = UNF(params?.row?.service_usage);
    let step1ResellerAmount = params?.row?.step1_reseller_amount;
    let step2ResellerAmount = params?.row?.step2_reseller_amount;
    let step3ResellerAmount = params?.row?.step3_reseller_amount;
    const serviceId = params?.row?.service_id;
    const initialServiceFeeAmount = typeof params?.row?.initial_service_fee_amount === "string" ? UNF(params?.row?.initial_service_fee_amount) : params?.row?.initial_service_fee_amount;
    // 
    step3ResellerAmount = params?.row?.invoice_amount_check === 0 ? params?.row?.step3_reseller_amount : Math.ceil(invoiceAmount/100*params?.row?.step3ResellerRate);
        // 獲得店、紹介店、その他店の入力だった場合→獲得店＆紹介店：切上 →その他店：切り下げ
        // check step1_reseller 
        if (Boolean(params?.row?.step1ResellerRate)) {
          step2ResellerAmount = params?.row?.invoice_amount_check === 0 ? params?.row?.step2_reseller_amount : Math.ceil((invoiceAmount/100)*params?.row?.step2ResellerRate);
          step1ResellerAmount = params?.row?.invoice_amount_check === 0 ? params?.row?.step1_reseller_amount : Math.floor((invoiceAmount/100)*params?.row?.step1ResellerRate)
        } else {
          //その他店が未入力（＝算出不要）だった場合→獲得店：切上 →紹介店：切り下げ
          step2ResellerAmount = params?.row?.invoice_amount_check === 0 ? params?.row?.step2_reseller_amount : Math.floor((invoiceAmount/100)*params?.row?.step2ResellerRate);
        }
        
    if (Boolean(id)) {
      Promise.all([
        await Providers.updateConfirmedAtFlag(
          id,
          checked ? moment() : null,
          checked ? identity.id : null,
          invoiceAmount,
          serviceUsage,
          apolloClient,
          invoiceType === '3' ? params?.row?.scheduled_implementation_date : null,
          checked ? 2 : 1,
          step1ResellerAmount,
          step2ResellerAmount,
          step3ResellerAmount
        ),
        await Providers.updateService(
          serviceId,
          initialServiceFeeAmount,
          apolloClient
        )
      ])

      // reload data
      fetchData();
    }
  }

  const renderActionCell = (params: GridCellParams) => {
    
    return <Box> 
      <Checkbox
        checked={Boolean(params?.row?.checked)}
        onChange={(e: any) => handleChange(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <EditButton basePath='/invoice_detail' record={params?.row} label='' />
    </Box>
  }

  const annualPayCols = [
    { field: 'id', headerName: 'ID', typename: 'number', width: 40, hide: true },
    { field: 'diff', headerName: '差分有無', typename: 'string', width: 100, disableColumnMenu: true },
    { field: 'n_number', headerName: 'Ｎ番号 施設名', typename: 'number', width: 250, disableColumnMenu: true },
    { field: 'prev_service_usage', headerName: '前月利用金', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'prev_discount_amount', headerName: '前月通常調整額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'prev_adjustment_amount', headerName: '前月特別調整額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'prev_invoice_amount', headerName: '前月請求額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'year_month', headerName: '対象年月', typename: 'string', width: 150, disableColumnMenu: true},
    { field: 'sales_amount', headerName: '計上額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'service_usage', headerName: '利用料金', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'discount_amount', headerName: '通常調整額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'adjustment_amount', headerName: '特別調整額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'adjustment_reason', headerName: '調整理由', typename: 'string', width: 150, disableColumnMenu: true },
    { field: 'invoice_amount', headerName: '請求額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'action', headerName: '確認', width: 100, renderCell: renderActionCell},
    { field: 'step1_reseller_amount', headerName: 'step1_reseller_amount', typename: 'number', width: 40, hide: true },
    { field: 'step2_reseller_amount', headerName: 'step2_reseller_amount', typename: 'number', width: 40, hide: true },
    { field: 'step3_reseller_amount', headerName: 'step3_reseller_amount', typename: 'number', width: 40, hide: true },
  ]

  const bankTransferCols = [
    { field: 'id', headerName: 'ID', typename: 'number', width: 40, hide: true },
    { field: 'diff', headerName: '差分有無', typename: 'string', width: 100, disableColumnMenu: true },
    { field: 'n_number', headerName: 'Ｎ番号 施設名', typename: 'number', width: 250, disableColumnMenu: true },
    { field: 'prev_service_usage', headerName: '前月利用金', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'prev_discount_amount', headerName: '前月通常調整額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'prev_adjustment_amount', headerName: '前月特別調整額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'prev_invoice_amount', headerName: '前月請求額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'year_month', headerName: '対象年月', typename: 'string', width: 150, disableColumnMenu: true},
    { field: 'sales_amount', headerName: '計上額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'service_usage', headerName: '利用料金', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'discount_amount', headerName: '通常調整額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'adjustment_amount', headerName: '特別調整額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'invoice_amount', headerName: '請求額', typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'scheduled_implementation_date', headerName: '振替実施予定日', typename: 'string', width: 150, disableColumnMenu: true},
    { field: 'action', headerName: '確認', width: 100, renderCell: renderActionCell},
    { field: 'step1_reseller_amount', headerName: 'step1_reseller_amount', typename: 'number', width: 40, hide: true },
    { field: 'step2_reseller_amount', headerName: 'step2_reseller_amount', typename: 'number', width: 40, hide: true },
    { field: 'step3_reseller_amount', headerName: 'step3_reseller_amount', typename: 'number', width: 40, hide: true },
  ]

  const monthlyPayCols = [
    { field: 'id', headerName: 'ID', typename: 'number', width: 40, hide: true },
    { field: 'diff', headerName: translate('invoice_detail.confirm.diff'), typename: 'string', width: 100, disableColumnMenu: true },
    { field: 'n_number', headerName: 'Ｎ番号 施設名', typename: 'number', width: 250, disableColumnMenu: true },
    { field: 'prev_service_usage', headerName: translate('invoice_detail.confirm.prev_expected_invoice_amount'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'prev_discount_amount', headerName: translate('invoice_detail.confirm.prev_discount_amount'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'prev_adjustment_amount', headerName: translate('invoice_detail.confirm.prev_adjustment_amount'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'prev_invoice_amount', headerName: translate('invoice_detail.confirm.prev_invoice_amount'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'delay_payment_amount', headerName: translate('invoice_detail.confirm.delay_payment_amount'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'sales_amount', headerName: translate('invoice_detail.confirm.sales_amount'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'service_usage', headerName: translate('invoice_detail.confirm.service_usage'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'discount_amount', headerName: translate('invoice_detail.confirm.discount_amount'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'adjustment_amount', headerName: translate('invoice_detail.confirm.adjustment_amount'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'adjustment_reason', headerName: translate('invoice_detail.confirm.adjustment_reason'), typename: 'string', width: 150, disableColumnMenu: true },
    { field: 'invoice_amount', headerName: translate('invoice_detail.confirm.invoice_amount'), typename: 'number', width: 150, disableColumnMenu: true },
    { field: 'action', headerName: translate('invoice_detail.confirm.confirmed'), width: 100, renderCell: renderActionCell},
    { field: 'step1_reseller_amount', headerName: 'step1_reseller_amount', typename: 'number', width: 100, hide: true },
    { field: 'step2_reseller_amount', headerName: 'step2_reseller_amount', typename: 'number', width: 100, hide: true },
    { field: 'step3_reseller_amount', headerName: 'step3_reseller_amount', typename: 'number', width: 100, hide: true },
    { field: 'initial_service_fee_amount', headerName: 'initialServiceFeeAmount', typename: 'number', width: 40, hide: true },
    { field: 'service_id', headerName: 'serviceId', typename: 'number', width: 40, hide: true },
  ]

  const hasDiff = (currData: any, prevData: any) => {
    return currData.expected_invoice_amount !== prevData.service_usage ||
      currData.discount_amount !== prevData.discount_amount ||
      currData.adjustment_amount !== prevData.adjustment_amount
  }

  const getBusinessDay = (year: number, month: number) => {
    const scheduled_implementation_date = `${year}-${month}-26`;
    const date = new Date(scheduled_implementation_date), y = date.getFullYear(), m = date.getMonth();
    const lastDay = new Date(y, m + 1, 0).getDate();
    const holidays = holiday_jp.between(new Date(`${y}-${m}-01`), new Date(`${y}-${m}-${lastDay}`));
    const holidaysFormat = holidays.map(item=>{
      return item.date.getDate();
    })

    const weekend = takeOutWeekend(lastDay,m,y);
    const dayOff = weekend.concat(holidaysFormat);
  
    let WithdrawalDate = new Date(y, m, 26);
    while (dayOff.includes(WithdrawalDate.getDate())) {
      WithdrawalDate.setDate(WithdrawalDate.getDate() + 1);
    }
    return moment(WithdrawalDate).format('YYYY-MM-DD');
  }

  const convertData =  (data: [any], dataPrevAll: [any], prevData: [any]) => {
    if (data && data.length > 0) {
      const arrayHashmap = data.reduce((obj:any, item) => {
        return obj[item.invoice_detail_id] ? {...obj, [item.invoice_detail_id]: {...obj[item.invoice_detail_id], service_discounts: [...obj[item.invoice_detail_id].service_discounts, ...item.service_discounts]}} : {...obj, [item?.invoice_detail_id]: {...item}};
      }, {});
      const mergedArray = Object.values(arrayHashmap);
      console.log('mergedArray',mergedArray);
      
      return mergedArray.map((d: any) => {
        const prevMonthData = prevData?.find(pd => (pd.service_id === d.service_id && pd.type === d.type)) || {};
        const delayPaymentAmount = dataPrevAll.reduce((total:any, item:any)=>{
            return item.service_id === d.service_id ? total + (item.invoice_amount - item.payment_received_amount) : total;
        }, 0)
        console.log(delayPaymentAmount);
        let month, year;
        if(d.sales_month < 11){
          month = d.sales_month + 2;
          year = d.sales_year
        } else {
          month = d.sales_month == 11 ? 1 : 2;
          year = d.sales_year + 1;
        }
        const invoice_month_convert =  d?.sales_month; 
        const invoice_year_convert =  d?.sales_year;
        const dateSale1 = `${invoice_year_convert}-${invoice_month_convert}-01 12:00`
        const day_of_start_date = new Date(d?.service_start_date);
        const SaleStartDate = GetStartDateSaleDiscount(invoice_month_convert,invoice_year_convert);
        const SaleEndDate = GetEndDateSaleDiscount(invoice_month_convert,invoice_year_convert);

        const data = {
          SaleStartDate, 
          SaleEndDate, 
          n_number: d?.n_master_id, 
          day_of: day_of_start_date.getDate()
        }

        // const dataConvertDateSale = Date.parse(dateSale);    
        // const dataConvertStartDate = Date.parse(d?.discount_start_date);
        // const dataConvertEndDate = Date.parse(d?.discount_end_date);
        //const service_start_date = Date.parse(d?.discount_end_date)

        //console.log('mergedArray1',dataConvertDateSale);
        let discountAmount: number = 0;
            for (const service_discount of d?.service_discounts) {
              let discount_start_date = Date.parse(service_discount?.discount_start_date);
              let discount_end_date = Date.parse(service_discount?.discount_end_date);

              if(service_discount?.increase_flag === true && SaleStartDate >= discount_start_date && SaleEndDate <= discount_end_date){
                discountAmount = discountAmount + service_discount?.discount_amount;
                }
                else if (service_discount?.increase_flag === false && SaleStartDate >= discount_start_date && SaleEndDate <= discount_end_date) {
                discountAmount = discountAmount - service_discount?.discount_amount;
                }
            }
          //   discountAmount = d?.service_discounts.reduce((total:any, currentValue:any) => {
          //   let discount_start_date = Date.parse(currentValue?.discount_start_date);
          //   let discount_end_date = Date.parse(currentValue?.discount_end_date);
          //   //let data_total =  currentValue?.increase_flag ? total + currentValue?.discount_amount : total - currentValue?.discount_amount;
          //   let data_total =  0;
    
          //   if(currentValue?.increase_flag === true && dataConvertDateSale >= discount_start_date && dataConvertDateSale <= discount_end_date){
          //   data_total = total + currentValue?.discount_amount;
          //   }
          //   else if (currentValue?.increase_flag === false && dataConvertDateSale >= discount_start_date && dataConvertDateSale <= discount_end_date) {;
          //   data_total = total - currentValue?.discount_amount;
          //   }
          //   return data_total;
          // }, 0);
      //  console.log('mergedArray2',d?.discount_start_date);
      //  console.log('mergedArray3',d?.discount_end_date);
      //  console.log('mergedArray4',dateSale);

      //  console.log('mergedArray5',dataConvertDateSale);
      //  console.log('mergedArray6',dataConvertStartDate);
      //  console.log('mergedArray7',dataConvertEndDate);

       
        const step1ResellerRate = d?.step1_reseller_rate;
        const step2ResellerRate = d?.step2_reseller_rate;
        const step3ResellerRate = d?.step3_reseller_rate;

        const invoiceAmount = ((d.invoice_amount === 0 || (d.rate_type === 2 && ((d?.sales_amount || 0) + d.adjustment_amount + discountAmount) ===0) || ( d.rate_type !== 2 && (d.expected_invoice_amount + d.adjustment_amount + discountAmount) === 0)) && confirmed === 0) ?  (d.rate_type === 2 ? NF((d?.sales_amount || 0) + d.adjustment_amount + discountAmount) : NF(d.expected_invoice_amount + d.adjustment_amount + discountAmount)) : NF(d.invoice_amount);
        const initialServiceFeeAmount = d?.type === 1 ? 
        (d.rate_type === 2 ? NF((d?.sales_amount || 0) + d.adjustment_amount + discountAmount) : NF((d.initial_service_fee_amount || 0) + d.adjustment_amount + discountAmount))
        : d.initial_service_fee_amount;

        const step3ResellerAmount = Math.ceil(UNF(invoiceAmount)/100*step3ResellerRate);
        let step2ResellerAmount = 0;
        let step1ResellerAmount = 0;
        // 獲得店、紹介店、その他店の入力だった場合→獲得店＆紹介店：切上 →その他店：切り下げ
        // check step1_reseller 
        if (Boolean(step1ResellerRate)) {
          step2ResellerAmount = Math.ceil((UNF(invoiceAmount)/100)*step2ResellerRate);
          step1ResellerAmount = Math.floor((UNF(invoiceAmount)/100)*step1ResellerRate)
        } else {
          //その他店が未入力（＝算出不要）だった場合→獲得店：切上 →紹介店：切り下げ
          step2ResellerAmount = Math.floor((UNF(invoiceAmount)/100)*step2ResellerRate);
        }
        console.log('discountAmount',discountAmount);
        
        return {
          id: d.invoice_detail_id,
          diff: hasDiff(d, prevMonthData) ? '有' : '無',
          n_master_id: d?.master_id,
          n_master_name: d?.master_name,
          prev_service_usage: NF(prevMonthData?.service_usage),
          prev_discount_amount: NF(prevMonthData?.discount_amount_sales),
          prev_adjustment_amount: NF(prevMonthData?.adjustment_amount),
          prev_invoice_amount: NF(prevMonthData?.invoice_amount),
          delay_payment_amount: NF(delayPaymentAmount), //NF(d.invoice_amount - d.payment_received_amount),
          sales_amount: NF(d?.sales_amount),
          service_usage: d.rate_type === 2 ? NF(d.sales_amount) : NF(d.expected_invoice_amount),
          discount_amount: NF(Math.abs(discountAmount)),
          adjustment_amount: NF(d.adjustment_amount),
          adjustment_reason: d.adjustment_reason,
          invoice_amount: invoiceAmount,
          checked: Boolean(d.confirmed),
          year_month: `${d.sales_year}/${d.sales_month}`,
          scheduled_implementation_date: getBusinessDay(year, month),
          initial_service_fee_amount: initialServiceFeeAmount,
          n_number: `N${ProcessNmaster(d?.n_master_id)}-${ProcessNFacility(d?.n_facility_number)} ${d?.name}`,
          step1_reseller_amount: step1ResellerAmount,
          step2_reseller_amount: step2ResellerAmount,
          step3_reseller_amount: step3ResellerAmount,
          service_id: d.service_id, 
          invoice_amount_check : d.invoice_amount, 
          step1ResellerRate : step1ResellerRate, 
          step2ResellerRate: step2ResellerRate, 
          step3ResellerRate: step3ResellerRate
        }
      })
    }

    return []
  }

  const fetchData = async () => {

    const [dataCurr, dataPrevAll] = await Promise.all([
      await Providers.fetchCurrInvoiceDetails(
        invoiceMonth, 
        invoiceYear, 
        confirmed, 
        invoiceType,
        n_master, 
        apolloClient),
      await Providers.fetchPreAllInvoiceDetails(
        invoiceMonth, 
        invoiceYear, 
        invoiceType, 
        apolloClient)
    ])
   
    if (!dataCurr?.data) { return };

    const sIds = dataCurr?.data.invoice_detail_confirm.map((s: any) => s.service_id);
    const serviceIds = sIds.filter((element: number, index: number) => sIds.indexOf(element) === index);
    const prevData = await Providers.fetchPrevMonthInvoiceDetails(
      invoiceMonth, 
      invoiceYear, 
      serviceIds, 
      invoiceType, 
      apolloClient
    );

    let nData = convertData(dataCurr?.data.invoice_detail_confirm, dataPrevAll?.data?.invoice_detail_confirm, prevData?.data?.invoice_detail_confirm);

    // diff check
    //TODO: backend pagination
    if (diffCheck !== 0) {
      nData = nData.filter(d => d.diff === ((diffCheck === 1) ? '有' : '無'))
    }
    setRows(nData);
  }

  useEffect(() => {
    if (!loading && authenticated) {
      fetchData();
    }
  }, [invoiceMonth, invoiceYear, confirmed, loading, invoiceType, diffCheck, n_master])

  const handleSelectBoxChange = (event:any) => {
    const {target: {value, name}} = event;

    
    if (name ===  'invoiceYear') {
      setInvoiceYear(value);
    } else if (name === 'invoiceMonth') {
      setInvoiceMonth(value);
    } else if (name === 'confirmed') {
      setConfirmed(value);
    } else if (name === 'diffCheck') {
      setDiffCheck(value);
    }
    else if (name === 'n_master') {
      setNMaster(value);
    }
  }

  const batchConfirm = async () => {
    setShowConfirmPopup(true);
  }

  const handleBatchUpdate = async () => {
    setShowConfirmPopup(false);
    /*
    objects = [
      {
        id: 1, 
        confirmed: '2021-05-06', 
        confirmed_by: 4, 
        invoice_amount: 2000, 
        service_usage: 3000, 
        scheduled_implementation_date: null,
        status: 2 // 確認済み
      }
    ]
    */
    const objects = rows.filter((o: any) => selectedRows.includes(o.id.toString())).map((o: any) => ({
      id: o.id,
      confirmed: moment(),
      confirmed_by: identity.id,
      invoice_amount: o.invoice_amount_check === 0 ? UNF(o.invoice_amount) : o.invoice_amount_check, 
      service_usage: UNF(o.service_usage), 
      scheduled_implementation_date: invoiceType === '3' ? o.scheduled_implementation_date : null,
      service_id: 1, // upsert mutation
      invoice_year: 2021,
      invoice_month: 1,
      sales_year: 2021,
      sales_month: 1,
      created_by: 1,
      status: 2,
    }))
    
    await Providers.batchUpdateConfirmedAtFlag(
      objects,
      apolloClient,
    );

    // reload data
    fetchData();
    setSelectedRows([]);
  }

  const getTableCols = () => {
    if (invoiceType === '2') {
      return annualPayCols;
    } else if (invoiceType === '3') {
      return bankTransferCols;
    } else {
      return monthlyPayCols;
    }
  }

  if (loading) {
    return <Loading />;
  }
  
  if (!authenticated) {
    redirect('/login');
  } 

  if (identityLoading || !identity) {
    return <Loading />;
  }

  return <Box width='100%' height='100%'>
    <ToolBar 
      onGenerateBtnClick={onGenerateInvoiceBtnClick}
      onSelectBoxChange={handleSelectBoxChange}
      onBatchCofirmBtnClick={batchConfirm}
      invoiceMonth={invoiceMonth}
      invoiceYear={invoiceYear}
      confirmed={confirmed}
      selectedRows={selectedRows}
      diffCheck={diffCheck}
      n_master={n_master}
    />
    <DataGrid
      rows={rows}
      columns={getTableCols()}
      pageSize={20}
      checkboxSelection={!confirmed}
      disableSelectionOnClick
      onSelectionModelChange={(newSelections) => {
        const { selectionModel } = newSelections;
        setSelectedRows(selectionModel);
      }} />

    <Confirm
      isOpen={showConfirmPopup}
      title={translate('common.batch_update_confirm_title')}
      content={`${selectedRows.length}${translate('common.batch_update_confirm_content')} ${translate('common.batch_update_confirmation')}`}
      onConfirm={handleBatchUpdate}
      onClose={() => setShowConfirmPopup(false)} />
  </Box>
};

export default ConfirmPage;

const GetStartDateSaleDiscount = (sale_month: number, sale_year: number) => {
  return  Date.parse(`${sale_year}-${sale_month}-01 12:00`);
}
const GetEndDateSaleDiscount = (sale_month: number, sale_year: number) => {
  const getLastDate = getLastDay(sale_year,sale_month - 1);
  return Date.parse(`${sale_year}-${sale_month}-${getLastDate} 01:00`);
}
const getLastDay = (year: number, month: number) => {
  return  new Date(year, month +1, 0).getDate();
}
