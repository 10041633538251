import * as React from 'react';
import {
  Edit,
  FormDataConsumer,
  SimpleForm,
  useTranslate,
  ReferenceManyField,
  TextField,
  ReferenceField,
  Datagrid,
  Show,
  DateField,
  NumberField,
  BooleanField,
  SelectField,
} from 'react-admin';
import EditField from '../components/editField';
import UpdateToolbar from "../components/UpdateToolbar";
import NFrom from './Form';
import { Tabs, Tab } from '@material-ui/core';
import TabPanel, { a11yProps } from '../components/TabPanel';
import EditHistory from '../edit_history';
import { Box, Typography } from '@material-ui/core';
import IDFields from '../invoice_detail/dataset';
import ServiceReferenceField from '../service/ReferenceField';


const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h4" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

const NEdit = (props: any) => {
  const translate = useTranslate()
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return <div>
    <Tabs
      value={value}
      onChange={handleChange}
      textColor="primary"
      centered
    >
      <Tab label={translate('invoice.edit')}  {...a11yProps(0)}>
      </Tab>
      <Tab label={translate('common.edit_history')}  {...a11yProps(1)}>
      </Tab>
    </Tabs>
    <TabPanel value={value} index={0}>
      <Edit
        {...props}
        successMessage="common.msg_update_success"
        undoable={false}
        className="formContent"
        title={<EditField titlePage={translate('invoice.edit')} />}>
        <SimpleForm redirect="list" toolbar={<UpdateToolbar />} className="form">
          <FormDataConsumer>
            {(formDataProps: any) => (
              <NFrom {...formDataProps} editForm={true} />
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>

      {/* Invoice Detail  */}
      <Separator />
      <SectionTitle label='invoice.invoice_detail_list' />
      <Box className="formContent full100">
        <Show actions={<></>} component="div" title={" "}  {...props} >
          <ReferenceManyField
            addLabel={false}
            reference="invoice_detail"
            target="invoice_id"
          >
            <Datagrid optimized rowClick="edit">
              <TextField source="id" label={translate('invoice_detail.id')} />

              {IDFields.map((f, idx) => {
                if (!Boolean(f)) return <></>
                const { name, reference, searchField, type, options, numberFormat } = f || {}
                if (type === 'textinput') {
                  if (numberFormat) {
                    return <NumberField source={name} label={translate(`invoice_detail.${name}`)} />
                  }
                  return <TextField source={name} label={translate(`invoice_detail.${name}`)} />
                }

                if (type === 'reference') {
                  if (name === 'service_id') {
                    return <ServiceReferenceField label={translate('service.refer_field')} source={name} reference={reference} key={idx} />
                  } else {
                    return <ReferenceField label={translate(`invoice_detail.${name}`)} source={name} reference={reference} key={idx}>
                      <TextField source={searchField} />
                    </ReferenceField>
                  }
                }

                if (type === 'date') {
                  return <DateField source={name} label={translate(`invoice_detail.${name}`)} locales="ja-JP" />
                }

                if (type === 'checkbox') {
                  return <BooleanField source={name} label={translate(`invoice_detail.${name}`)} />
                }

                if (type === 'selectbox') {
                  return <SelectField
                    source={name}
                    choices={options}
                    label={translate(`invoice_detail.${name}`)}
                  />
                }

                return <></>
              })}

              <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
              <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                <TextField source="full_name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Show>
      </Box>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <EditHistory {...props} />
    </TabPanel>
  </div>;
};

export default NEdit;
