import * as React from 'react';
import {FC} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslate} from 'react-admin';
import GetApp from '@material-ui/icons/GetApp';

import {DownloadUrlVariables, FieldProps, GetDownload, Import} from '../../types';
import {useApolloClient} from "@apollo/client";
import gql from "graphql-tag";

const GET_DOWNLOAD_URL = gql`
    query GetDownload($signed_input: DownloadSignedInput!) {
        getDownloadUrl(signed_input: $signed_input) {
            signedUrl
        }
    }
`;

const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const DownloadButton: FC<FieldProps<Import>> = ({ record }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const apolloClient = useApolloClient();
    return record && record.error_file ? (
        <Button
            size="small"
            color="primary"
            onClick={async (e: any)=> {
                e.stopPropagation();
                const { data } = await apolloClient.query<GetDownload, DownloadUrlVariables>({
                    query: GET_DOWNLOAD_URL,
                    fetchPolicy: "network-only",
                    variables: {
                        signed_input: {
                            file_name: encodeURI(record.error_file || ""),
                            key: record.error_file || "",
                            bucketType: 'import'
                        }
                    },
                });

                if (data) {
                    window.location.href = data.getDownloadUrl.signedUrl;
                }
            }}
            className={classes.link}
        >
            <GetApp className={classes.icon} />
            {translate('import.download')}
        </Button>
    ) : null;
};

export default DownloadButton;
