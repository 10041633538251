import UserIcon from '@material-ui/icons/People';

import UserGroupFilter from './UserGroupList';
import UserGroupCreate from './UserGroupCreate';
import UserGroupForm from './UserGroupEdit';

export default {
    list: UserGroupFilter,
    create: UserGroupCreate,
    edit: UserGroupForm,
    icon: UserIcon,
};
