import * as React from 'react';
import {FC} from 'react';
import {FieldProps} from '../../types';
import BtnCreateNew from '../components/BtnCreateNew';
import BtnShowList from '../components/BtnShowList';

const ActionsField: FC<FieldProps<any>> = ({ record, basePath, resource }) => {
  return record ? (
        <span>
            <BtnShowList 
              target_name='service_discount'
              filter_name='service_id'
              filter_val={record.id} />
            <BtnCreateNew 
              resource_field_name='service' 
              resource_val={record.id} 
              target_name='service_discount' />
        </span>
    ) : null;
}

export default ActionsField;
