import * as React from 'react';
import {FC} from 'react';
import {
    Datagrid,
    downloadCSV,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField,
    usePermissions,
    TextInput,
    useTranslate
} from 'react-admin';

import RoleField from "./RoleField";
import ActionsField from './Actions';
import ListActions from '../components/actions';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import {FilterProps} from "../../types";
import BulkActionButtons from "../components/BulkActionButtons";
import ListPagination from "../components/ListPagination";

const exporter = (items:any) => {
    const itemsForExport = items.map((item:any) => {
        return [item.name, items[0].client_id  ? item.client_id : ''];
    });
    const csv = '\uFEFF' + convertToCSV({
        data: itemsForExport,
        encoding: 'UTF-8 BOM',
        fields: ['名前', items[0].client_id ? 'マルチテナント用' : ''],
    });
    downloadCSV(csv, 'user_group');
};

const UserGroupFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    return (
        <Filter {...props}>
            {permissions === 'admin'
            && <ReferenceInput source="client_id" reference="client" alwaysOn label={translate('user_group.client')}>
                <SelectInput optionText="name" />
            </ReferenceInput>}
            <SearchInput source="name" alwaysOn />
            <TextInput source="id@_eq" label={translate('question.id')} alwaysOn />
        </Filter>
    )
};

const UserGroupList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    if (permissions === undefined) {
        return null;
    }
    return (
        <List
            {...props}
            filters={<UserGroupFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<BulkActionButtons />}
            pagination={<ListPagination />}
            exporter={exporter}
            actions={<ListActions to="user_group"/>}
            empty={<ListEmpty />}
            title={translate('menu.user_group.user_group_list')}
        >
            <Datagrid optimized rowClick="edit">
                <TextField source="id" label={translate('user_group.id')} />
                {permissions === 'admin' &&
                    <ReferenceField source="client_id" reference="client" label={translate('user_group.client')} >
                        <TextField source="name" />
                    </ReferenceField>
                }
                <TextField source="name" label={translate('user_group.name')} />
                <RoleField source="is_admin" label={translate('user_group.is_admin')} />
                <ActionsField label={translate('user_group.action')} />
            </Datagrid>
        </List>

    );
};

export default UserGroupList;
