import * as React from 'react';
import {
  Edit,
  FormDataConsumer,
  SimpleForm,
  useTranslate
} from 'react-admin';
import EditField from '../components/editField';
import UpdateToolbar from "../components/UpdateToolbar";
import NFrom from './Form';
import { Tabs, Tab } from '@material-ui/core';
import TabPanel, { a11yProps } from '../components/TabPanel';
import EditHistory from '../edit_history';

const NEdit = (props: any) => {
  const translate = useTranslate()
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return <div>
    <Tabs
      value={value}
      onChange={handleChange}
      textColor="primary"
      centered
    >
      <Tab label={translate('invoicing.edit')}  {...a11yProps(0)}>
      </Tab>
      <Tab label={translate('common.edit_history')}  {...a11yProps(1)}>
      </Tab>
    </Tabs>
    <TabPanel value={value} index={0}>
      <Edit
        {...props}
        successMessage="common.msg_update_success"
        undoable={false}
        className="formContent"
        title={<EditField titlePage={translate('menu.invoicing.menu')} />}>
        <SimpleForm redirect="list" toolbar={<UpdateToolbar />} className="form">
          <FormDataConsumer>
            {(formDataProps: any) => (
              <NFrom {...formDataProps} editForm={true} />
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <EditHistory {...props} />
    </TabPanel>
  </div>;
};

export default NEdit;