import * as React from 'react';
import {forwardRef} from 'react';
import {AppBar, MenuItemLink, UserMenu, useTranslate} from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import {makeStyles} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {AppState} from '../types';
import {useSelector} from "react-redux";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        fontSize: '14px',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    avatar: {
        height: 32,
        width: 32,
    },
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    const user = useSelector((appState: AppState) => appState.user);
    return (
        <React.Fragment>
            <MenuItemLink
                ref={ref}
                to="/configuration"
                primaryText={translate('common.configuration')}
                leftIcon={<SettingsIcon />}
                onClick={props.onClick}
            />
            <MenuItemLink
                ref={ref}
                to={`/user/${user.id}`}
                primaryText={translate('menu.settings.profile')}
                leftIcon={<AccountCircleIcon />}
                onClick={props.onClick}
            />
            <MenuItemLink
                ref={ref}
                to={`/change-password`}
                primaryText={translate('menu.settings.change_password')}
                leftIcon={<LockIcon />}
                onClick={props.onClick}
            />            
        </React.Fragment>
    );
});



const MyCustomIcon = () => {
    const classes = useStyles();
    const user = useSelector((appState: AppState) => appState.user);
    if (!user) {
        return null;
    }
    return (
        <div className={classes.root}>
            <Avatar
                className={classes.avatar}
                src={user.image_url ? '' : ''}
                style={{marginRight:'5px'}}
            />
            {user.full_name}
        </div> 
    )
};

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}　>
        <ConfigurationMenu />
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />

            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
