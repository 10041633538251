import moment from 'moment';

export const YEARS = [3,2,1,0,-1,-2,-3,-4,-5,-6,-7,-8,-9].map(num => ({ id: moment().year() - num, name: `${moment().year() - num}` }))

export const MONTHS = [1,2,3,4,5,6,7,8,9,10,11,12].map(m=>({id: m, name: `${m}`}))
export const TYPES = [
  {id: 1, name: '初期手数料'},
  {id: 2, name: 'サービス利用料'}
]

const DATA = [
  { name: 'id', type: 'hidden', export: true, import: true},
  { name: 'service_id', type: 'reference', reference: 'service', searchField: '', options: [], nullable: false, editable: false, edit: true, export: true, import: true },
  { name: 'n_facility_master_name', type: 'virtual_reference', reference: 'service' },
  { name: 'n_master_id', type: 'virtual_reference', export: true },
  { name: 'n_master_name', type: 'virtual_reference', export: true },
  {
    name: 'year', type: 'selectbox',
    options: YEARS, nullable: false, editable: true, export: true, import: true
  },
  {
    name: 'month', type: 'selectbox',
    options: MONTHS, nullable: false, editable: true, export: true, import: true
  },
  { name: 'start_date', type: 'date', nullable: false, editable: true, export: true, import: true },
  { name: 'end_date', type: 'date', nullable: false, editable: true, export: true, import: true },
  { name: 'type', type: 'selectbox', options: TYPES, nullable: false, editable: false, export: true, import: true},
  { name: 'days', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'sales_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'sales_total_amount', type: 'textinput', nullable: false, editable: false, numberFormat: true, export: true, import: true },
  { name: 'expected_sales_amount', type: 'textinput', nullable: true, editable: false, export: true, import: true },
  { name: 'step1_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: true, editable: false, export: true, import: true },
  { name: 'step1_reseller_amount', type: 'textinput', nullable: true, editable: false, numberFormat: true, export: true, import: true},
  {},
  { name: 'step2_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: false, editable: false, export: true, import: true },
  { name: 'step2_reseller_amount', type: 'textinput', nullable: false, editable: false, numberFormat: true, export: true, import: true },
  {},
  { name: 'step3_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: false, editable: false, export: true, import: true },
  { name: 'step3_reseller_amount', type: 'textinput', nullable: false, editable: false, numberFormat: true, export: true, import: true },
  {},
  { name: 'total_incentive_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'discount_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'adjustment_amount', type: 'textinput', nullable: false, editable: false, numberFormat: true, export: true, import: true },
  { name: 'adjustment_reason', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'locked', type: 'checkbox', nullable: false, editable: true, export: true, import: true },
  { name: 'cost_confirmed', type: 'datetime', nullable: true, editable: true, export: true, import: true },
  { name: 'cost_confirmed_by', type: 'reference', reference: 'user', searchField: 'full_name', nullable: true, editable: true, export: true, import: true },
  {},
  { name: 'confirmed', type: 'datetime', nullable: true, editable: true, export: true, import: true },
  { name: 'confirmed_by', type: 'reference', reference: 'user', searchField: 'full_name', nullable: true, editable: true, export: true, import: true },
  { name: 'note', type: 'textinput', multiline: true, nullable: true, editable: true, export: true, import: true },
  { name: 'created', type: 'hidden', nullable: true, editable: false, export: true, import: true },
  { name: 'created_by', type: 'hidden', nullable: true, editable: true, export: true, import: true },
]

export default DATA;