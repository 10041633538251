export const BillingTypes = [
  {id: 1, name: 'Eメール'},
  {id: 2, name: 'FAX'},
  {id: 3, name: '郵送'},
]

export const ACCOUNT_TYPES = [
  {id: 1, name: '普通'},
  {id: 2, name: '当座'},
]

const DATA = [
  {name: 'id', type: 'hidden', export: false, import: true},
  { name: 'n_master_id', type: 'reference', reference: 'n_master', searchField: 'name', nullable: true, editable: false, export: true, import: true },
  { name: 'company_name', type: 'textinput', placeholder: 'テスト株式会社', nullable: false, editable: true, export: true, import: true },
  { name: 'address', type: 'textinput', placeholder: '渋谷区道玄坂', nullable: false, editable: true, export: true, import: true  },
  { name: 'postcode', type: 'textinput', placeholder: '999-9999', nullable: false, editable: true, export: true, import: true  },
  { name: 'billing_name', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'billing_dept', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'billing_title', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'billing_phone', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'billing_fax', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'billing_email', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'billing_type', type: 'selectbox', options: BillingTypes, nullable: true, editable: true, export: true, import: true  },
  { name: 'note', type: 'textinput', nullable: true, multiline: true, editable: true, export: true, import: true  },
  { name: 'bank_code', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'bank_name', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'bank_branch_code', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'bank_branch_name', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'account_name', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'account_type', type: 'selectbox', options: ACCOUNT_TYPES, nullable: true, editable: true, export: true, import: true  },
  { name: 'account_number', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  // { name: 'transferable_month', type: 'date', nullable: false, editable: false, export: true, import: true },
  { name: 'created', type: 'hidden', nullable: true, editable: false, export: true, import: true },
  { name: 'created_by', type: 'hidden', nullable: true, editable: true, export: true, import: true },
  // { name: 'payment_method', type: 'selectbox', options: PAYMENT_METHODS, nullable: true, editable: true, export: true, import: true  },
]

export default DATA;