const DATA = [
  {name: 'id', type: 'hidden', export: true, import: true},
  { name: 'name', type: 'textinput', placeholder: 'テスト株式会社', reference: '', searchField: '', options: [], nullable: false, editable: true, export: true, import: true },
  { name: 'name_furigana', type: 'textinput', placeholder: 'テストカブシキカイシャ', nullable: true, editable: true, export: true, import: true },
  { name: 'company_number', type: 'textinput', placeholder: '9999999999999', nullable: false, editable: true, export: true, import: true },
  { name: 'representative_name', type: 'textinput', placeholder: 'テスト 太郎', nullable: false, editable: true, export: true, import: true },
  { name: 'representative_name_furigana', type: 'textinput', placeholder: 'テスト コタロ', nullable: true, editable: true, export: true, import: true },
  {},
  { name: 'postcode', type: 'textinput', placeholder: '999-9999', nullable: false, editable: true, export: true, import: true },
  { name: 'address', type: 'textinput', placeholder: '東京都渋谷区９９－９９', nullable: false, editable: true, export: true, import: true },
  { name: 'contact_person', type: 'textinput', placeholder: 'テスト 一郎', nullable: false, editable: true, export: true, import: true },
  { name: 'contact_person_furigana', type: 'textinput', placeholder: 'テスト', nullable: true, editable: true, export: true, import: true },
  { name: 'contact_person_dept', type: 'textinput', placeholder: '総務', nullable: true, editable: true, export: true, import: true },
  { name: 'contact_person_title', type: 'textinput', placeholder: '専務', nullable: true, editable: true, export: true, import: true },
  { name: 'contact_phone', type: 'textinput', placeholder: '09-0000-9999', nullable: true, editable: true, export: true, import: true },
  { name: 'contact_fax', type: 'textinput', placeholder: '09-0000-0001', nullable: true, editable: true, export: true, import: true },
  { name: 'contact_email', type: 'textinput', placeholder: 'test@test.com', nullable: true, editable: true, export: true, import: true },
  { name: 'note', type: 'textinput', multiline: true, nullable: true, editable: true, export: true, import: true },
  { name: 'created_by', type: 'hidden', nullable: true, editable: true, export: true, import: true },
  {},
  {},
]

export default DATA;