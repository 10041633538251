import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    DateField,
    Filter,
    List,
    TextField,
    usePermissions,
    useTranslate,
    ReferenceField,
    SelectField,
    SelectInput,
    useRefresh,
    useGetIdentity,
    NumberField,
    BooleanField,
    useNotify,
} from 'react-admin';

import BulkDeleteButton from '../../components/actionsBulkDelete';
import ListEmpty from '../../components/listEmpty';
import { FilterProps } from "../../../types";
import ListPagination from "../../components/ListPagination";
import CustomizableDatagrid from '../../components/CustomizableDatagrid';
import ServiceReferenceField from '../../service/ReferenceField';
import ServiceReferenceField3 from '../../service/ReferenceField3';
import { UPDATE_CONFIRMED_AT, INSERT_INCENTIVES } from './gql';
import { useApolloClient } from '@apollo/client';
import moment from 'moment';
import Fields, { MONTHS, YEARS, CONFIRMED_ISNULL_FLAGS } from '../dataset';
import TotalIncentiveField from '../TotalIncentiveField';
import ConfirmCheckbox from '../ConfirmCheckbox';

// import exporter from '../../utils/exporter';
// import Dataset from './dataset';

// const ExportableFields = [{name: 'id'}, ...Dataset.filter(d => d.export)];

interface Reseller {
  id: number
  rate?: number,
  amount: number,
};

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SelectInput 
        source="payment_received_year@_eq" 
        choices={YEARS} 
        label={translate('incentive.pay_received_year')} alwaysOn />
      <SelectInput 
        source="payment_received_month@_eq" 
        choices={MONTHS} 
        label={translate('incentive.pay_received_month')} alwaysOn />
      
      <SelectInput 
        source="step1_confirmed_at@_is_null" 
        choices={CONFIRMED_ISNULL_FLAGS} 
        label={translate('incentive.confirm')} alwaysOn />
    </Filter>
  )
};

const IncentiveList = (props: any) => {
  const translate = useTranslate();
  const apolloClient = useApolloClient();
  const refresh = useRefresh();
  const { identity, loading: identityLoading } = useGetIdentity();
  const module = 'invoice_detail';
  const notify = useNotify();

  const updateInvoiceDetailAndCreateIncentive = async (checked: boolean, invoiceDetailId: number, objects: any) => {
    // update step_confirmed_at
    const res = await apolloClient.mutate({
      mutation: UPDATE_CONFIRMED_AT,
      variables: {
        id: invoiceDetailId,
        objects 
      }
    });
    const {
      id, 
      payment_received_date, 
      service_id, 
      step3_reseller_amount,
      step2_reseller_amount,
      step1_reseller_amount,
      step3_reseller_id,
      step2_reseller_id,
      step1_reseller_id,
      service: {
        step1_reseller_rate, 
        step2_reseller_rate, 
        step3_reseller_rate
      } 
    } = res?.data?.update_invoice_detail_by_pk;

    let resellers: Reseller[] = [];
    // step1_reseller
    if (step1_reseller_id) {
      resellers.push({
        id: step1_reseller_id,
        amount: step1_reseller_amount,
        rate: step1_reseller_rate
      })
    }
    // step2_reseller
    if (step2_reseller_id) {
      resellers.push({
        id: step2_reseller_id,
        amount: step2_reseller_amount,
        rate: step2_reseller_rate
      })
    }
    // step3_reseller
    if (step3_reseller_id) {
      resellers.push({
        id: step3_reseller_id,
        amount: step3_reseller_amount,
        rate: step3_reseller_rate
      })
    }

    // insert incentives
    //TODO: update for removing check
    if (id && checked) {
      const month = moment(payment_received_date).month()+1;
      const year = moment(payment_received_date).year();
      const objects = resellers.map((reseller: Reseller) => ({
        month,
        year,
        service_id,
        invoice_detail_id: id,
        reseller_id: reseller.id,
        amount: reseller.amount,
        reseller_rate: reseller.rate,
        created_by: identity.id,
      }));
      console.log("Insert Incentive: ", objects);
      if (objects.length > 0) {
        const {data, errors } = await apolloClient.mutate({
          mutation: INSERT_INCENTIVES,
          variables: {
            objects
          }
        });
  
        if (data && data.insert_incentive) {
          notify("確認しました。");
        }
      }
    }
    refresh();
  }

  const confirmChecked = (checked: boolean, invoiceDetailId: number) => {
    const confirmedAt = checked ? moment() : null;
    const data = {
      step1_confirmed_at: confirmedAt,
      step2_confirmed_at: confirmedAt,
      step3_confirmed_at: confirmedAt,
    }
    updateInvoiceDetailAndCreateIncentive(checked, invoiceDetailId, data);
  }

  if (identityLoading || !identity) {
    return <>Loading...</>;
  }

  return (
    <List
      hasCreate={false}
      hasShow
      hasEdit
      hasList
      actions={false}
      resource='invoice_detail'
      bulkActionButtons={<UserBulkActionButtons />}
      // exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'invoice_detail', ExportableFields, translate,fetchRelatedRecords)}
      sort={{ field: 'created', order: 'DESC' }}
      perPage={25}
      pagination={<ListPagination />}
      syncWithLocation
      empty={<ListEmpty />}
      title={translate('invoice_detail.menu_list')}
      filters={<NFilter />}
      filterDefaultValues={{payment_received_flg: true}}
      basePath="/invoice_detail">
        <CustomizableDatagrid>
          {Fields.map((f, idx) => {
            if (!Boolean(f)) return <></>
            const { name, reference, searchField, type, options, numberFormat } = f || {}
            if (type === 'textinput') {
                if (numberFormat) {
                    return <NumberField source={name} label={translate(`${module}.${name}`)} />
                }
                return <TextField source={name} label={translate(`${module}.${name}`)} />
            }

            if (type === 'reference') {
                if (name === 'service_id') {
                    return <ServiceReferenceField label={translate('service.refer_field')} source={name} reference={reference} key={idx} />
                } else {
                    return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference} key={idx}>
                        <TextField source={searchField} />
                    </ReferenceField>
                }
            }

            if (type === 'virtual_reference') {
                if (name === 'company_name') {
                    return <ServiceReferenceField3 label={translate('service.refer_field3')} source={'service_id'} reference={reference} key={idx} />
                } else if (name === 'plan_code') {
                  return <ReferenceField source='service_id' reference='service' link={false} label='service.plan_code'>
                    <TextField source='plan_code' />
                  </ReferenceField>
                } else if (name === 'transfer_flag') {
                  return <ReferenceField source='service_id' reference='service' link={false} label='service.transfer_flag'>
                    <BooleanField source='transfer_flag'/>
                  </ReferenceField>
                } else if (name === 'step1_reseller_code') {
                  return <ReferenceField source='step1_reseller_id' reference='reseller' link={false} label='payment.step1_reseller_code'>
                    <TextField source='code'/>
                  </ReferenceField>
                } else if (name === 'step2_reseller_code') {
                  return <ReferenceField source='step2_reseller_id' reference='reseller' link={false} label='payment.step2_reseller_code'>
                    <TextField source='code'/>
                  </ReferenceField>
                } else if (name === 'step3_reseller_code') {
                  return <ReferenceField source='step3_reseller_id' reference='reseller' link={false} label='payment.step3_reseller_code'>
                    <TextField source='code'/>
                  </ReferenceField>
                } else if (name === 'step1_reseller_rate') {
                  return <ReferenceField source='service_id' reference='service' link={false} label='service.step1_reseller_rate'>
                    <NumberField source='step1_reseller_rate'/>
                  </ReferenceField>
                } else if (name === 'step2_reseller_rate') {
                  return <ReferenceField source='service_id' reference='service' link={false} label='service.step2_reseller_rate'>
                    <NumberField source='step2_reseller_rate'/>
                  </ReferenceField>
                } else if (name === 'step3_reseller_rate') {
                  return <ReferenceField source='service_id' reference='service' link={false} label='service.step3_reseller_rate'>
                    <NumberField source='step3_reseller_rate'/>
                  </ReferenceField>
                } else if (name === 'total_reseller_amounts') {
                  return <TotalIncentiveField label='payment.total_incentive_amount' />
                } 
            }

            if (type === 'date') {
                return <DateField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
            }

            if (type === 'checkbox') {
                return <BooleanField source={name} label={translate(`${module}.${name}`)} />
            }

            if (type === 'selectbox') {
                return <SelectField
                    source={name}
                    choices={options}
                    label={translate(`${module}.${name}`)}
                />
            }

            return <></>
          })}

          <ConfirmCheckbox label="確認" onChecked={confirmChecked}/>
        </CustomizableDatagrid>
      </List>

  );
};

export default IncentiveList;