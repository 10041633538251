import * as React from 'react';
import {FC} from 'react';
import {Chip} from '@material-ui/core';
import {FieldProps, Group} from '../../types';

const RoleField: FC<FieldProps<Group>> = ({ record }) =>
    record ? (
        <span>
            <Chip
            label={
                record.is_admin === true ? 'Admin' : 'User'
            }
            style={{
                color: '#fff',
                height: 16,
                width: 60,
                backgroundColor:
                record.is_admin === true ? '#3CD4A0': '#FF5C93',
                fontSize: 11,
                fontWeight: 'bold',
            }}
        />             
        </span>
       
    ) : null;

export default RoleField;


        

