import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
  useAuthState,
  Loading,
  useRedirect,
  useGetIdentity,
  Confirm,
} from 'react-admin';
import { gql, useApolloClient } from '@apollo/client';
import { DataGrid } from '@material-ui/data-grid';
import { Box, Paper, FormControl, Select, MenuItem, InputLabel, Button } from '@material-ui/core';
import moment from 'moment';
import { YEARS, MONTHS } from '../invoice_detail/dataset';
import PdfCreateBtn from './PdfCreateBtn';
import { numberWithCommas as NF } from '../../utils/Utils'

const FETCH_INCENTIVES = gql`
  query QueryIncentives($month: Int!, $year: Int!) {
    incentive_agg_by_reseller(where: {month: {_eq: $month}, year: {_eq: $year}}) {
      month
      year
      reseller_id
      name
      code
      total_amount
    }
  }`;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 15,
    marginBottom: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const previousMonthYear = (month: number, year: number) => {
  return {
    month: month === 1 ? 12 : (month - 1),
    year: month === 1 ? (year - 1) : year
  }
}

const NList = () => {
  const { loading, authenticated } = useAuthState();
  const redirect = useRedirect();
  const [rows, setRows] = useState([] as any);
  const currMonth = moment().month() + 1;
  const currYear = moment().year();
  const [month, setMonth] = useState(currMonth);
  const [year, setYear] = useState(currYear);
  const [selectedRows, setSelectedRows] = useState([] as any);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const apolloClient = useApolloClient();
  const translate = useTranslate()
  const classes = useStyles();
  const { identity, loading: identityLoading } = useGetIdentity();


  const columns = [
    { field: 'id', headerName: 'ID', typename: 'number', width: 100, hide: true },
    { field: 'year', headerName: '入金年', typename: 'string', width: 100, disableColumnMenu: true },
    { field: 'month', headerName: '入金月', typename: 'string', width: 100, disableColumnMenu: true },
    { field: 'name', headerName: '代理店名', typename: 'string', width: 300, disableColumnMenu: true },
    { field: 'code', headerName: '代理店コード', typename: 'string', width: 200, disableColumnMenu: true },
    { field: 'total_amount', headerName: '支払合計額', typename: 'string', width: 200, disableColumnMenu: true },
  ]

  const fetchData = async () => {
    const prevMonthYear = previousMonthYear(month, year);
    const { data, loading, error } = await apolloClient.query({
      query: FETCH_INCENTIVES,
      fetchPolicy: 'network-only',
      variables: {
        month: prevMonthYear.month,
        year: prevMonthYear.year,
      }
    });

    if (!data || error) { return };
    const nData = data.incentive_agg_by_reseller.map((d:any) => ({
      ...d,
      id: d.reseller_id,
      total_amount: NF(d.total_amount),
    }));
    setRows(nData);
  }


  useEffect(() => {
    if (!loading && authenticated) {
      fetchData();
    }
  }, [month, year, loading])

  const handleSelectBoxChange = (event: any) => {
    const { target: { value, name } } = event;
    if (name === 'year') {
      setYear(value);
    } else if (name === 'month') {
      setMonth(value);
    }
  }

  const batchConfirm = async () => {
    setShowConfirmPopup(true);
  }

  const handleBatchUpdate = async () => {
    setShowConfirmPopup(false);

    // reload data
    fetchData();
    setSelectedRows([]);
  }

  if (loading) {
    return <Loading />;
  }

  if (!authenticated) {
    redirect('/login');
  }

  if (identityLoading || !identity) {
    return <Loading />;
  }

  return <Box width='100%' height='100%'>
    <Paper className={classes.toolbar}>
      <FormControl className={classes.formControl}>
        <InputLabel id="year-select-label">{translate('incentive.pay_received_year')}</InputLabel>
        <Select
          labelId="year-select-label"
          id="year-select"
          value={year}
          name='year'
          onChange={handleSelectBoxChange}
        >
          {YEARS.map((m, idx) => {
            return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
          })}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="month-select-label">{translate('incentive.pay_received_month')}</InputLabel>
        <Select
          labelId="month-select-label"
          id="month-select"
          value={month}
          name='month'
          onChange={handleSelectBoxChange}
        >
          {MONTHS.map((m, idx) => {
            return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
          })}
        </Select>
      </FormControl>

      {/* PDF generate */}
      <FormControl className={classes.formControl}>
        <PdfCreateBtn
          disabled={(selectedRows.length === 0)}
          selectedIds={selectedRows}
          payReceivedMonth={previousMonthYear(month, year).month}
          payReceivedYear={previousMonthYear(month, year).year} />
      </FormControl>

    </Paper>
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={20}
      checkboxSelection={true}
      disableSelectionOnClick
      onSelectionModelChange={(newSelections) => {
        const { selectionModel } = newSelections;
        setSelectedRows(selectionModel);
      }} />

    <Confirm
      isOpen={showConfirmPopup}
      title={translate('common.batch_update_confirm_title')}
      content={`${selectedRows.length}${translate('common.batch_update_confirm_content')} ${translate('common.batch_update_confirmation')}`}
      onConfirm={handleBatchUpdate}
      onClose={() => setShowConfirmPopup(false)} />
  </Box>
};

export default NList;