export const STATUS = [
  {id: 0, name: '未反映'},
  {id: 1, name: '反映済み'},
  {id: -1, name: '反映対象ではない'},
]

const DATA = [
  { name: 'type', type: 'textinput', placeholder: '区切', reference: '', searchField: '', options: [], nullable: true, editable: true, export: true, import: true },
  { name: 'payment_received', type: 'textinput', placeholder: '日付', nullable: false, editable: true, export: true, import: true },
  { name: 'invoice_no', type: 'textinput', placeholder: '0', nullable: false, editable: true, export: true, import: true },
  { name: 'division', type: 'textinput', placeholder: '借方部門コード', nullable: true, editable: true, export: true, import: true },
  { name: 'division_name', type: 'textinput', placeholder: '借方部門名', nullable: true, editable: true, export: true, import: true },
  { name: 'c1', type: 'textinput', placeholder: '借方勘定科目コード', nullable: true, editable: true, export: true, import: true },
  { name: 'c2', type: 'textinput', placeholder: '借方勘定科目名', nullable: true, editable: true, export: true, import: true },
  { name: 'c3', type: 'textinput', placeholder: '借方補助科目コード', nullable: true, editable: true, export: true, import: true },
  { name: 'c4', type: 'textinput', placeholder: '借方補助科目名', nullable: true, editable: true, export: true, import: true },
  { name: 'payment_amount', type: 'textinput', placeholder: '0', nullable: true, editable: true, export: true, import: true },
  { name: 'tax', type: 'textinput', placeholder: '0', nullable: false, editable: true, export: true, import: true },
  { name: 'c5', type: 'textinput', placeholder: '貸方部門コード', nullable: true, editable: true, export: true, import: true },
  { name: 'c6', type: 'textinput', placeholder: '貸方部門名', nullable: true, editable: true, export: true, import: true },
  { name: 'payment_method_code', type: 'textinput', placeholder: '貸方勘定科目コード', nullable: true, editable: true, export: true, import: true },
  { name: 'payment_method_name', type: 'textinput',placeholder: '貸方勘定科目名', nullable: true, editable: true, export: true, import: true },
  { name: 'n_number', nullable: false, type: 'textinput', editable: true, export: true, import: true },
  { name: 'n_facility_name', nullable: true, type: 'textinput', editable: true, export: true, import: true },
  { name: 'payment_received_amount', placeholder: '0', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'target_detail', nullable: false, type: 'textinput', editable: true, export: true, import: true },
  { name: 'status', nullable: true, placeholder: '0', type: 'selectbox', options: STATUS, editable: true, export: true, import: true },
  { name: 'invoice_detail_id', nullable: true, type: 'textinput', editable: true, export: true, import: true },
  { name: 'created', nullable: false, type: 'textinput', editable: true, export: true, import: true },
  { name: 'created_by', nullable: false, type: 'textinput', editable: true, export: true, import: true },
  
  {},
]

export default DATA;