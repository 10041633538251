import * as React from 'react';
import {FC} from 'react';
import {Datagrid, TextField, useTranslate, DateField, ReferenceField, FunctionField} from 'react-admin';
import LinkToRelatedResults from "./LinkToRelatedResults";
import DownloadButton from "./DownloadButton";
import StatusField from './StatusField';
import {Import} from '../../types';


const ImportDataGrid: FC<Import> = ({
    selectedRow,
    ...props
}) => {
    const translate = useTranslate();
    return (
        <Datagrid
            rowClick="show"
            optimized
            {...props}
        >
            <DateField source="created_at" label={translate('import.created_at')} showTime />
            <ReferenceField label={translate('common.created_by')} source="user_id" reference="user">
                <TextField source="full_name" />
            </ReferenceField>
            <FunctionField 
                source="to" 
                label={translate('import.to')}
                render={(record: any) => `${translate(`menu.${record.to}.menu`)}`} />
            <TextField source="total_row" label={translate('import.total_row')} />
            <TextField source="proceed_count" label={translate('import.proceed_count')} />
            <TextField source="success_count" label={translate('import.success_count')} />
            <TextField source="fail_count" label={translate('import.fail_count')} />
            <StatusField source="status" label={translate('import.status')}/>
            <DownloadButton label={translate('import.error_report')} />
            <LinkToRelatedResults label={translate('import.results')} />
        </Datagrid>
    );
};

export default ImportDataGrid;
