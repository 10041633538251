import nMasterDataSet from '../n_master/dataset';
import nFacilityMasterDataSet from '../n_facility_master/dataset';
import serviceDataset from '../service/dataset';
import serviceDiscountDS from '../service_discount/dataset';
import salesDS from '../sales/dataset';
import invoiceDetailDS from '../invoice_detail/dataset';
import contractDS from '../contract/dataset';
import invoicingDS from '../invoicing/dataset';
import paymentDS from '../payment/dataset';


const ImportKeys: any = {
  n_master: nMasterDataSet
    .filter(d => d.import)
    .map(f => f.name),
  n_facility_master: nFacilityMasterDataSet
    .filter(d => d.import)
    .map(f => f.name),
  service: serviceDataset
    .filter(d => d.import)
    .map(f => f.name),
  service_discount: serviceDiscountDS
    .filter(d => d.import)
    .map(f => f.name),
  sales: salesDS
    .filter(d => d.import)
    .map(f => f.name),
  invoice_detail: invoiceDetailDS
  .filter(d => d.import)
  .map(f => f.name),
  contract: contractDS
  .filter(d => d.import)
  .map(f => f.name),
  invoicing: invoicingDS
  .filter(d => d.import)
  .map(f => f.name),
  payment: paymentDS
  .filter(d => d.import)
  .map(f => f.name),
};

export default ImportKeys;