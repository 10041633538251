import * as React from 'react';
import {
  Edit,
  FormDataConsumer,
  SimpleForm,
  useTranslate
} from 'react-admin';
import EditField from '../components/editField';
import UpdateToolbar from "./UpdateToolbar";
import NFrom from './Form';
import { Tabs, Tab, Box } from '@material-ui/core';
import TabPanel, { a11yProps } from '../components/TabPanel';
import EditHistory from '../edit_history';
import EvidenceList from './EvidenceList';
import NoticeChanges from './NoticeChanges';

const NEdit = (props: any) => {
  const translate = useTranslate()
  const [value, setValue] = React.useState(0);
  const [nFacilityMasterId, setNFacilityMasterId] = React.useState(null);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return <div>
    <Tabs
      value={value}
      onChange={handleChange}
      textColor="primary"
      centered
    >
      <Tab label={translate('service.edit')}  {...a11yProps(0)}>
      </Tab>
      <Tab label={translate('common.edit_history')}  {...a11yProps(1)}>
      </Tab>
    </Tabs>
    <TabPanel value={value} index={0}>
      <Edit
        {...props}
        successMessage="common.msg_update_success"
        undoable={false}
        className="formContent"
        title={<EditField titlePage={translate('menu.service.menu')} />}>
        <SimpleForm validateOnBlur={true} redirect="list" toolbar={<UpdateToolbar />} className="form">
          <FormDataConsumer>
            {(formDataProps: any) => {
              setNFacilityMasterId(formDataProps?.record?.n_facility_master_id);
              return <NFrom {...formDataProps} editForm={true} />
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
      <Box pt="1em" />
      { nFacilityMasterId && <EvidenceList nFacilityMasterId={nFacilityMasterId} /> }
      <Box pt="1em" />
      <NoticeChanges {...props} />
    </TabPanel>
    <TabPanel value={value} index={1}>
      <EditHistory {...props} />
    </TabPanel>
  </div>;
};

export default NEdit;