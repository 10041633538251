import * as React from 'react';
import {FC} from 'react';
import {FieldProps, User} from '../../types';
import {useTranslate} from 'react-admin';

const RoleField: FC<FieldProps<User>> = ({ record }) =>  {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate(`role.${record.role}`)}
        </span>
    ) : null;
};
export default RoleField;
