import * as React from 'react';
import {
  Edit,
  FormDataConsumer,
  SimpleForm,
  useTranslate,
  Toolbar,
  Button, SaveButton,
} from 'react-admin';
import EditField from '../components/editField';
import NFrom from './Form';
import { Tabs, Tab } from '@material-ui/core';
import TabPanel, { a11yProps } from '../components/TabPanel';
import EditHistory from '../edit_history';
import { useHistory } from "react-router";

const CustomToolbar = (props: any) => {
  const translate = useTranslate();
  const history = useHistory();
  const cancelButton = () => {
    history.goBack();
  };
  return (
    <Toolbar {...props} style={{ display: 'flex', justifyContent: 'center' }} >
      <SaveButton
        label={translate('common.btn_update')}
        style={{ marginRight: '25px' }}
      />
      <Button 
        label={translate('common.btn_cancel')} 
        onClick={cancelButton} 
        variant="contained" 
        size="medium" color="default" />
    </Toolbar>
  );
};

const NEdit = (props: any) => {
  const translate = useTranslate()
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return <div>
    <Tabs
      value={value}
      onChange={handleChange}
      textColor="primary"
      centered
    >
      <Tab label={translate('contract.edit')}  {...a11yProps(0)}>
      </Tab>
      <Tab label={translate('common.edit_history')}  {...a11yProps(1)}>
      </Tab>
    </Tabs>
    <TabPanel value={value} index={0}>
      <Edit
        {...props}
        successMessage="common.msg_update_success"
        undoable={false}
        className="formContent"
        title={<EditField titlePage={translate('menu.contract.menu')} />}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />} className="form">
          <FormDataConsumer>
            {(formDataProps: any) => (
              <NFrom {...formDataProps} editForm={true} />
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <EditHistory {...props} />
    </TabPanel>
  </div>;
};

export default NEdit;