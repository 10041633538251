import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
} from 'react-admin';
import { ProcessNFacility, ProcessNmaster } from "../../utils/Utils";
 
const CustomField = (props: any) => {
  const { record } = props;
  return <span>N{ProcessNmaster(record['n_master_id'])+ '-' + ProcessNFacility(record['n_facility_number'])}</span>;
}

const CustomReferField = (props: any) => {  
  return <ReferenceField  {...props}>
    <ReferenceField  source="n_facility_master_id" reference="n_facility_master" link={() => `/service/${props.record.service_id}`}>
      <CustomField />
    </ReferenceField>
  </ReferenceField>
}

CustomReferField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
  sortBy: PropTypes.string
};

export default CustomReferField;