import * as React from 'react';
import { FC } from 'react';
import {
   Create,
   FormDataConsumer,
   SimpleForm,
   useNotify,
   useTranslate,
   useGetIdentity,
} from 'react-admin';
import { CreateComponentProps } from '../../types';
import CreateToolbar from "../components/CreateToolbar";
import NMasterFrom from './Form';

const NMasterCreate: FC<CreateComponentProps> = props => {
   const translate = useTranslate();
   const notify = useNotify();
   const { identity, loading: identityLoading } = useGetIdentity();

   if (identityLoading) {
      return <>Loading...</>;
   }

   const onFailure = (error: any) => {
      notify(`Could not create: ${error.message}`, 'warning');
   };

   const defaultValues = () => ({created_by: identity.id});

   return (
      <Create {...props} className="formContent"
         onSuccess={() => {
            notify(`${translate('common.msg_add_success')}`, 'info');
         }}
         onFailure={onFailure}
         undoable={false}
         title={translate('menu.n_master.add')}
      >
         <SimpleForm 
            className="form" 
            redirect="list"
            toolbar={<CreateToolbar />}
            initialValues={defaultValues}
         >
            <FormDataConsumer>
               {(formDataProps: any) => (
                  <NMasterFrom {...formDataProps} />
               )}
            </FormDataConsumer>
         </SimpleForm>
      </Create>
   );
};

export default NMasterCreate;
