import * as React from 'react';
import {FC} from 'react';
import {
    BooleanField,
    BooleanInput,
    BulkDeleteWithConfirmButton,
    Datagrid,
    downloadCSV,
    Filter,
    List,
    SearchInput,
    TextField,
    TextInput,
    useTranslate
} from 'react-admin';

import ActionsField from './Actions';
import ClientLinkField from './ClientLinkField';
import ListActions from '../components/actions';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import {FilterProps} from "../../types";

const exporter = (items: any) => {
    const itemsForExport = items.map((item: any) => {
        return [item.domain, item.from_email, item.is_active, item.mailer_host, item.mailer_password, item.mailer_port, item.mailer_secure, item.mailer_user, item.name, item.s3_region, item.s3_key, item.s3_secret, item.s3_bucket, item.twilio_sid, item.twilio_token, item.twilio_phone, items[0].id  ? item.id : ''];
    });
    const csv = '\uFEFF' + convertToCSV({
        data: itemsForExport,
        encoding: 'UTF-8 BOM',
        fields: ['名ドメイン前', 'From (アドレス)', 'アクティブ', 'SMTPサーバー', 'SMTPパスワード', 'SMTPポート', 'SMTPのSSLかTLSを有効', 'SMTPユーザー名', '名前', 'S3 バケツ', 'S3 キー', 'S3 領域', ' S3 秘密', 'Twilio Phone', 'Twilio SID', 'Twilio Token', items[0].id ? 'マルチテナント用' : ''],
    });
    downloadCSV(csv, 'client');
};

const ClientBulkActionButtons = (props: JSX.IntrinsicAttributes) => (
    <BulkDeleteWithConfirmButton {...props} />
);

const ClientFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name" alwaysOn />
            <TextInput label={translate('client_list.domain')} source="domain" />
            <BooleanInput source="is_active" />
        </Filter>
    )
};

const ClientList = (props: any) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            filters={<ClientFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<ClientBulkActionButtons />}
            exporter={exporter}
            actions={<ListActions to="client" />}
            empty={<ListEmpty />}
            title={translate('menu.client.client_list')}
        >
            <Datagrid optimized>
                <TextField source="id" label={translate('client_list.id')} />
                <TextField source="name" label={translate('client_list.name')} />
                <ClientLinkField label={translate('client_list.domain')} />
                <BooleanField source="is_active" size="small" label={translate('client_list.is_active')} />
                <ActionsField label={translate('client_list.action')} />
            </Datagrid>
        </List>

    );
};

export default ClientList;
