import * as React from 'react';
import {
  required,
  TextInput,
  useTranslate,
  SelectInput,
  useDataProvider,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import NMasterReferenceInput from '../n_master/ReferenceInput';
import { useForm } from 'react-final-form';

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export const BillingTypes = [
  {id: 1, name: 'Eメール'},
  {id: 2, name: 'FAX'},
  {id: 3, name: '郵送'},
]

export const PAYMENT_METHODS = [
  {id: 1, name: '請求書'},
  {id: 2, name: '口座振替予定'},
  {id: 3, name: '口座振替'},
]

export const ACCOUNT_TYPES = [
  {id: 1, name: '普通'},
  {id: 2, name: '当座'},
]

const NMasterFrom = (props: any) => {
  const translate = useTranslate();
  const { editForm } = props;
  const form = useForm();
  const dataProvider = useDataProvider();

  const onNMasterChange = async (nMasterId: React.ChangeEvent<HTMLInputElement>) => {
    if (nMasterId) {
      const { data, error } = await dataProvider.getOne('n_master', { id: nMasterId });
      if (!error) {
        form.change('company_name', data?.name);
        form.change('postcode', data?.postcode);
        form.change('address', data?.address)
      }
    }
  }

  return <Container>
    <SectionTitle label={editForm ? "invoicing.edit" : "invoicing.add_new"} />
    <Separator />
    <Grid container spacing={10}>
      {/* Col 1 */}
      <Grid item xs={6}>
        <NMasterReferenceInput
          autoFocus
          validate={requiredValidate}
          onChange={onNMasterChange}
        />
        <TextInput
          source="company_name"
          validate={requiredValidate}
          placeholder="株式会社テスト"
          label={translate('invoicing.company_name')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          placeholder="999-9999"
          source="postcode"
          validate={requiredValidate}
          label={translate('invoicing.postcode')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="address"
          validate={requiredValidate}
          placeholder="東京都渋谷区９９－９９"
          label={translate('invoicing.address')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="billing_name"
          placeholder="テスト 太郎"
          label={translate('invoicing.billing_name')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="billing_dept"
          placeholder="総務"
          label={translate('invoicing.billing_dept')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="billing_title"
          label={translate('invoicing.billing_title')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="billing_phone"
          placeholder="09-0000-9999"
          label={translate('invoicing.billing_phone')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="billing_fax"
          placeholder="09-0000-0001"
          label={translate('invoicing.billing_fax')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="billing_email"
          placeholder="test@test.com"
          label={translate('invoicing.billing_email')}
          fullWidth
          variant="outlined"
        />
        <SelectInput 
          choices={BillingTypes}
          source="billing_type"
          label={translate('invoicing.billing_type')}
          fullWidth
          variant="outlined"
        />
      </Grid>

      {/* Col 2 */}
      <Grid item xs={6}>
        <TextInput
          source="bank_code"
          placeholder="100"
          label={translate('invoicing.bank_code')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="bank_name"
          placeholder="テスト銀行"
          label={translate('invoicing.bank_name')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="bank_branch_code"
          placeholder="999"
          label={translate('invoicing.bank_branch_code')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="bank_branch_name"
          placeholder="テスト支店"
          label={translate('invoicing.bank_branch_name')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="account_name"
          placeholder="テスト名義人"
          label={translate('invoicing.account_name')}
          fullWidth
          variant="outlined"
        />
        <SelectInput
          source="account_type"
          choices={ACCOUNT_TYPES}
          placeholder="テスト支店"
          label={translate('invoicing.account_type')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="account_number"
          placeholder="9999999"
          label={translate('invoicing.account_number')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="note"
          multiline
          label={translate('invoicing.note')}
          fullWidth
          variant="outlined"
        />
      </Grid>

    </Grid>
  </Container>;
};

export default NMasterFrom;