import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
} from 'react-admin';
import { Paper, FormControl, Select, MenuItem, InputLabel, Button, TextField } from '@material-ui/core';
import { MONTHS, YEARS } from '../dataset';
import PropTypes from 'prop-types';
import { SearchInput } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 15,
    marginBottom: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CONFIRMED_FLAG = [
  {id: 0, name: 'FALSE'},
  {id: 1, name: 'TRUE'}
]

const DIFF_FILTERING = [
  {id: 0, name: ''},
  {id: 1, name: '有'},
  {id: 2, name: '無'}
]

const ToolBar = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles();
  const { 
    invoiceMonth,
    invoiceYear,
    confirmed,
    selectedRows,
    diffCheck,
    onSelectBoxChange, 
    onGenerateBtnClick, 
    n_master,
    onBatchCofirmBtnClick } = props;


  return <Paper className={classes.toolbar}>
    {/* Invoice Year */}
    <FormControl className={classes.formControl}>
      <InputLabel id="invoice-n-master-label">{translate('common.searchByNNumber')}</InputLabel>
      <TextField
        //labelId="invoice-year-select-label"
        id="invoice-n-master"
        value={n_master}
        name='n_master'
        onChange={onSelectBoxChange}
      >
        {YEARS.map((m, idx) => {
          return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
        })}
      </TextField>
    </FormControl>

    <FormControl className={classes.formControl}>
      <InputLabel id="invoice-year-select-label">{translate('invoice_detail.invoice_year')}</InputLabel>
      <Select
        labelId="invoice-year-select-label"
        id="invoice-year-select"
        value={invoiceYear}
        name='invoiceYear'
        onChange={onSelectBoxChange}
      >
        {YEARS.map((m, idx) => {
          return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
        })}
      </Select>
    </FormControl>

    {/* Invoice Month */}
    <FormControl className={classes.formControl}>
      <InputLabel id="invoice-month-select-label">{translate('invoice_detail.invoice_month')}</InputLabel>
      <Select
        labelId="invoice-month-select-label"
        id="invoice-month-select"
        value={invoiceMonth}
        name='invoiceMonth'
        onChange={onSelectBoxChange}
      >
        {MONTHS.map((m, idx) => {
          return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
        })}
      </Select>
    </FormControl>

    {/* 確認 */}
    <FormControl className={classes.formControl}>
      <InputLabel id="confirmed-select-label">{translate('sales.confirm.action')}</InputLabel>
      <Select
        labelId="confirmed-select-label"
        id="confirmed-select"
        value={confirmed}
        name='confirmed'
        onChange={onSelectBoxChange}
      >
        {CONFIRMED_FLAG.map((m, idx) => {
          return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
        })}
      </Select>
    </FormControl>

     {/* 差分有無 */}
     <FormControl className={classes.formControl}>
      <InputLabel id="diff-check-select-label">{translate('invoice_detail.confirm.diff')}</InputLabel>
      <Select
        labelId="diff-check-select-label"
        id="diff-check-select"
        value={diffCheck}
        name='diffCheck'
        onChange={onSelectBoxChange}
      >
        {DIFF_FILTERING.map((m, idx) => {
          return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
        })}
      </Select>
    </FormControl>

    {/* Update invoice status */}
    <FormControl className={classes.formControl}>
      <Button
        disabled={!confirmed}
        variant="contained"
        color="primary"
        onClick={onGenerateBtnClick}>
        請求書作成
    </Button>
    </FormControl>

    {/* 一括確認ボタン */}
    <FormControl className={classes.formControl}>
      <Button
        disabled={(selectedRows.length === 0) || Boolean(confirmed)}
        variant="contained"
        color="primary"
        onClick={onBatchCofirmBtnClick}>
        {translate('sales.confirm.batch_update')}
      </Button>
    </FormControl>
  </Paper>
}

ToolBar.propTypes = {
  onSelectBoxChange: PropTypes.func.isRequired,
  onGenerateBtnClick: PropTypes.func.isRequired,
  onBatchCofirmBtnClick: PropTypes.func.isRequired,
  invoiceMonth: PropTypes.number,
  invoiceYear: PropTypes.number,
  confirmed: PropTypes.number,
  selectedRows: PropTypes.array,
  diffCheck: PropTypes.number,
  n_master: PropTypes.string
}

export default ToolBar;