import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  useNotify
} from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import xlsx from 'xlsx-populate/browser/xlsx-populate';
import { 
  signedDownloadQuery, 
  SignedDownloadVariables,
  ResellerProps,
  IncentiveProps,
  PdfDataProps
} from '../../types';
import { useApolloClient } from "@apollo/client";
import axios from 'axios';
import moment from 'moment';
import {
  DOWNLOAD_URL,
  INCENTIVE_UPLOAD_PRESIGNED_URL,
  INCENTIVE_DOWNLOAD_PRESINGED_URL,
  FETCH_INCENTIVES_DATA,
  FETCH_RESELLERS
} from './gql';
import 'moment/locale/ja';

const PDF_CONVERTER_URL = process.env.REACT_APP_PDF_CONVERTER_URL;
const dateFormat = 'YYYY/MM/DD';

let count = 0;
let errCount = 0;
let fileUrls: string[] = [];

const GeneratePdfBtn = (props: any) => {
  const translate = useTranslate();
  const apolloClient = useApolloClient();
  const [isConverting, setIsConverting] = useState(false);
  const notify = useNotify();
  const { selectedIds, payReceivedMonth, payReceivedYear } = props;
  const totalCount: number = selectedIds ? selectedIds.length : 0;

  const fetchDataAndConvert = async (template: any) => {
    const { selectedIds } = props;
    const resellers: ResellerProps[] = await fetchResellerData(selectedIds);
    console.log("Resellers: ", resellers);
    resellers.map(r => {
      fetchInvoiceDetailDataAndConvert(r, template);
    })
  }

  const fetchInvoiceDetailDataAndConvert = async (reseller: ResellerProps, template: any) => {
    console.log("Fetch invoice data and fill to excel");
    const { data: { incentive } } = await apolloClient.query({
      query: FETCH_INCENTIVES_DATA,
      fetchPolicy: 'network-only',
      variables: {
        resellerId: reseller.id,
        month: payReceivedMonth,
        year: payReceivedYear,
      }
    });

    if (incentive && incentive.length > 0) {
      fileUrls = [];
      const nIncentive = incentive.map((i: any) => ({
        ...i,
        company_name: i.service?.n_master?.name
      }));
      const data: PdfDataProps = {
        reseller,
        incentives: nIncentive
      }
      console.log("PdfData: ", data);
      fillDataAndConvert(data, template);
    }
  }

  const fetchResellerData = async (incentiveIds: number[]) => {
    const { data: { reseller } } = await apolloClient.query({
      query: FETCH_RESELLERS,
      fetchPolicy: 'network-only',
      variables: {
        ids: incentiveIds,
      }
    });

    if (reseller) {
      return reseller;
    }

    return []
  }

  const handleClick = async () => {
    count = 0;
    errCount = 0;
    setIsConverting(true);
    const template = await downloadTemplate();
    if (template) {
      fetchDataAndConvert(template);
    } else {
      console.log("Can't download the template file!");
      setIsConverting(false);
    }
  }

  const getDownloadUrl = async (filename: string) => {
    const { data } = await apolloClient.query({
      query: INCENTIVE_DOWNLOAD_PRESINGED_URL,
      fetchPolicy: 'network-only',
      variables: {
        filename
      }
    });
    if (data) {
      const {generate_download_incentive_s3_url: {signedUrl}} = data;
      return signedUrl;
    }

    return null;
  }

  const fillDataAndConvert = (data: PdfDataProps, template: any) => {
    const { reseller, incentives } = data;
    const filename = reseller.name;
    xlsx.fromDataAsync(template).then((workbook: any) => {
      fillData(data, workbook);
      workbook.outputAsync()
        .then(function (blob: any) {
          uploadXlsToS3(blob, filename);
        });
    });
  }

  const uploadXlsToS3 = async (data: any, filename: string) => {
    const { data: response } = await apolloClient.mutate({
      mutation: INCENTIVE_UPLOAD_PRESIGNED_URL,
      variables: {
        filename: `${filename}.xlsx`
      },
    });

    if (!response || !response.generate_upload_url_incentive.presigned_url) {
      return;
    }

    const presigned_url = response.generate_upload_url_incentive.presigned_url;
    try {
      const res = await axios.put(presigned_url, data);
      if (res.status === 200) {
        const downloadUrl = await getDownloadUrl(`${filename}.xlsx`);
        if (downloadUrl) { 
          fileUrls.push(downloadUrl);
        }

        count++;
        if (count >= totalCount) {
          convertToPdf(filename);
        }
      } 
    } catch (error) {
      errCount++;
      notify(`（${filename}）${translate('invoice.convert_error')}`, 'error');
    }
  }

  const uploadZipToS3 = async (data: any, filename: string) => {
    const { data: response } = await apolloClient.mutate({
      mutation: INCENTIVE_UPLOAD_PRESIGNED_URL,
      variables: {
        filename: filename
      },
    });

    if (!response || !response.generate_upload_url_incentive.presigned_url) {
      return;
    }
    const presigned_url = response.generate_upload_url_incentive.presigned_url;
    const res = await axios.put(presigned_url, data);
    if (res.status === 200) {
      return true;
    } 
    return false;
  }

  // pdf conversion
  const convertToPdf = async (filename: string) => {
    // console.log(fileUrls);
    try {
      const res = await axios(
        { method: 'post', 
          url: PDF_CONVERTER_URL,
          data: {urls: fileUrls}, 
          headers: { 'content-type': 'application/json' },
          responseType: 'arraybuffer',
        });

      if (res.status === 200) {
        const folderName = `${moment().format("YYYYMMDD_HHmmss")}.zip`;
        if (uploadZipToS3(res.data, folderName)) {
          setIsConverting(false);
        }

        // download file
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // If IE, you must uses a different method.
          window.navigator.msSaveOrOpenBlob(res.data, folderName);
        } else {
          var fileurl = window.URL.createObjectURL(new Blob([res.data]));
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.href = fileurl;
          a.download = folderName;
          a.click();
          window.URL.revokeObjectURL(fileurl);
          document.body.removeChild(a);
        }
      }
    } catch (error) {
      notify(`${translate('invoice.convert_error')}`, 'error');
      setIsConverting(false);
    }
  }

  const downloadTemplate = async () => {
    const { data } = await apolloClient.query<signedDownloadQuery, SignedDownloadVariables>({
      query: DOWNLOAD_URL,
      fetchPolicy: "network-only",
      variables: {
        download_s3_input: {
          files: [encodeURI("incentive.xlsx")]
        }
      },
    });

    const url = data.generate_download_s3_url.signedUrls[0];

    const res = await axios.get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })

    return res.status === 200 ? res.data : null;
  }

  const splice = function(str: string, start: number, delCount: number, newSubStr: string) {
    return str.slice(0, start) + newSubStr + str.slice(start + Math.abs(delCount));
  };

  const fillData = (data: PdfDataProps, workbook: any) => {

    const { reseller, incentives } = data;
    const { name, bank_name, bank_branch_name, account_name, account_type, account_number, zip_code, address, other } = reseller;

    const payInfo = `振込先　/　　${bank_name}　${bank_branch_name}　${account_type === 1 ? '普' : '当'}）${account_number}　${account_name}`;

    const {month, year} = incentives[0] || {};
    const title = `電気削減クラウド ${year}年${month}月入金対象分`;
    const payDate = moment(`${payReceivedYear}-${payReceivedMonth}`, 'YYYY-MM').add(1, 'M').endOf('month');

    const oldCode = zip_code || "";

    const postCode = (oldCode.indexOf("-") > -1) ? `〒${oldCode}` : `〒${splice(oldCode, 3,0,"-")}`

    const dearTxt = `${name}　　御中`;
    const isLongText = dearTxt.length >= 22 ? true : false;

    // reseller
    workbook.sheet(0).cell("A2").value(postCode);
    workbook.sheet(0).cell("A3").value(address);
    workbook.sheet(0).cell("A4").value(other);
    workbook.sheet(0).cell("A6").value(dearTxt);
    workbook.sheet(0).cell("A88").value(dearTxt);
    if (isLongText) {
      // workbook.sheet(0).cell("A88").style("fontSize", 11);
      workbook.sheet(0).cell("A6").style("fontSize", 6);
    }

    workbook.sheet(0).cell("A19").value(name);
    workbook.sheet(0).cell("U11").value(bank_name);
    workbook.sheet(0).cell("U12").value(bank_branch_name);
    workbook.sheet(0).cell("U13").value(`${account_type === 1 ? '普通' : '当座'}  ${account_number}`);
    workbook.sheet(0).cell("U14").value(account_name);
    workbook.sheet(0).cell("A118").value(payInfo);
    workbook.sheet(0).cell("Q3").value(title);
    workbook.sheet(0).cell("U15").value(payDate.format('YYYY年MM月DD日 dddd'));
 
    // invoice details
    let startRowNum = 47;
    incentives.map((d: IncentiveProps) => {
      const { amount, reseller_rate, invoice_detail, company_name } = d;
      const { invoice_month, invoice_year, payment_received_amount, payment_received_date } = invoice_detail || {};
      const payDate = moment(payment_received_date).format(dateFormat);
      workbook.sheet(0).cell(`A${startRowNum}`).value(`${invoice_year}/${invoice_month}`);
      workbook.sheet(0).cell(`D${startRowNum}`).value(company_name);
      workbook.sheet(0).cell(`S${startRowNum}`).value("サービス料");
      workbook.sheet(0).cell(`Y${startRowNum}`).value(payment_received_amount);
      workbook.sheet(0).cell(`AB${startRowNum}`).value(payDate);
      workbook.sheet(0).cell(`AE${startRowNum}`).value(`${reseller_rate}%`);
      workbook.sheet(0).cell(`AI${startRowNum}`).value(amount);
      startRowNum++;
    })

    return workbook;
  }

  return <Button
    disabled={isConverting || props.disabled}
    variant="contained"
    color="secondary"
    onClick={handleClick}>
    {isConverting && <CircularProgress
      color="secondary"
      size={18}
      thickness={2}
      style={{ marginRight: 10 }} />}
    {translate('invoice.generate_pdf')}
  </Button>
}

GeneratePdfBtn.propTypes = {
  selectedIds: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  payReceivedMonth: PropTypes.number.isRequired,
  payReceivedYear: PropTypes.number.isRequired,
}

GeneratePdfBtn.defaultProps = {
  selectedIds: []
}

export default GeneratePdfBtn;