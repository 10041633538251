import UserIcon from '@material-ui/icons/People';
import ClientEdit from './ClientEdit';
import ClientList from './ClientList';
import ClientCreate from './ClientCreate';

export default {
    edit: ClientEdit,
    create: ClientCreate,
    list: ClientList,
    icon: UserIcon,
};
