import * as React from 'react';
import {FC, Fragment} from 'react';
import {
    DateField,
    Filter,
    List,
    SearchInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput, 
    ReferenceField,
    SelectField,
    SelectInput,
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import {FilterProps} from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import exporter from '../../utils/exporter';
import Dataset, {STATUS} from './dataset';
import BulkUpdateInvoiceDetailBtn from '../payment/BulkUpdateBtn';
import CustomNMasterNumber from './CustomField'
const ExportableFields = [{name: 'id'}, ...Dataset.filter(d => d.export)];

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            <BulkUpdateInvoiceDetailBtn {...props}/>
            {/* {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props}/>} */}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="n_number,n_facility_name" alwaysOn />
            <SelectInput source="status" choices={STATUS} label={translate('payment.status')} alwaysOn />
        </Filter>
    )
};

const NList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    if (permissions === undefined) {
        return null;
    }
    
    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'id', order: 'DESC'}}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'payment', ExportableFields, translate,fetchRelatedRecords)}
            actions={<ListActions to='payment' />}
            empty={<ListEmpty />}
            title={translate('menu.payment.list')}
        >
            <CustomizableDatagrid rowClick="edit" isRowSelectable={(record:any) => record.status === 0}>
                {/* <TextField source="id" label={translate('payment.id')} /> */}
                {/* <TextField source="n_number" label={translate('payment.n_number')} />  */}
                <CustomNMasterNumber source="n_number" label={translate('payment.n_number')} />
                <TextField source="n_facility_name" label={translate('payment.n_facility_name')} /> 
                <TextField source="payment_received_amount" label={translate('payment.payment_received_amount')} /> 
                <TextField source="type" label={translate('payment.type')} /> 
                <TextField source="payment_received" label={translate('payment.payment_received')} /> 
                <TextField source="invoice_no" label={translate('payment.invoice_no')} /> 
                <TextField source="division" label={translate('payment.division')} /> 
                <TextField source="division_name" label={translate('payment.division_name')} /> 
                <TextField source="c1" label={translate('payment.c1')} /> 
                <TextField source="c2" label={translate('payment.c2')} /> 
                <TextField source="c3" label={translate('payment.c3')} />
                <TextField source="c4" label={translate('payment.c4')} />
                <TextField source="payment_amount" label={translate('payment.payment_amount')} />
                <TextField source="tax" label={translate('payment.tax')} />
                <TextField source="c5" label={translate('payment.c5')} />
                <TextField source="c6" label={translate('payment.c6')} />
                <TextField source="payment_method_code" label={translate('payment.payment_method_code')} />
                <TextField source="payment_method_name" label={translate('payment.payment_method_name')} />
                <TextField source="target_detail" label={translate('payment.target_detail')} /> 
                <SelectField source="status" choices={STATUS} label={translate('payment.status')} /> 
                <TextField source="invoice_detail_id" label={translate('payment.invoice_detail_id')} /> 
                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
