import moment from 'moment';

export const toLocalTime = (utcTime: string | null): string => {
    if (!utcTime) return '';
    return moment.utc(utcTime).local().format('YYYY/MM/DD HH:mm:ss');
};

export const toLocalTimeFromJP = (jpTime: string | null): string => {
    if (!jpTime) return '';
    return moment(`${jpTime}+09:00`).local().format('YYYY/MM/DD HH:mm:ss');
};

export const toLocalHour = (utcHour: string | null): string => {
    if (!utcHour) return '';
    const date = `2020-01-02T${utcHour}:00`;
    console.log(date);
    return moment(date).local().format('HH:mm:ss');
};

export const toLocalTimeEndDate = (jpTime: string | null): string => {
    if (!jpTime) return '';
    return moment(`${jpTime}+09:00`).add(14,'days').local().format('YYYY/MM/DD HH:mm:ss');
};

export const random = (): number => {
    const min = 1;
    const max = 100000;
    return min + Math.random() * (max - min);
};

export const buildRoleOptions = (role: string, translate: any): Array<any> => {
    const options = [
        { id: 'user', name: translate('role.user') },
        // { id: 'owner', name: translate('role.owner') },
        // { id: 'vendor_admin', name: translate('role.vendor_admin') },
        // { id: 'vendor_manager', name: translate('role.vendor_manager') },
        // { id: 'vendor_employee', name: translate('role.vendor_employee') },
    ];
    if (role === 'admin') {
        options.push({ id: 'admin', name: translate('role.admin') });
        options.push({ id: 'client_admin', name: translate('role.client_admin') });
    } else if (role === 'client_admin') {
        options.push({ id: 'client_admin', name: translate('role.client_admin') });
    } else {
        return [];
    }
    return options;

};

export function numberWithCommas(x: number): string {
    return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function ProcessNmaster(n_master_id: string ): string {
    let n_master_processed = n_master_id;
    if(n_master_processed){
        const lengthNmaster = n_master_id.toString().length;
        if( lengthNmaster === 4){
            n_master_processed = `0${n_master_id}`
        }
    }
    return n_master_processed;
}
export function ProcessNFacility(n_facility_number: string ): string {
    let n_master_processed = n_facility_number;
    if(n_master_processed){
        const lengthNmaster = n_facility_number.toString().length;
        if( lengthNmaster === 1){
            n_master_processed = `0${n_facility_number}`
        }
    }
   
    return n_master_processed;
}
export function ProcessNmasterName(n_master: string): string {
    let n_master_processed = n_master;
    
    let splitString = n_master.split("-");
    let n_facility_number_split = splitString[1];
    if(splitString[1].length < 2){
        n_facility_number_split = `0${splitString[1]}`
    }
    let n_master_id_processed = splitString[0];
    let n_number = splitString[0];
    let splitMasterId = n_number.split("N");
    const lengthNmaster = splitMasterId[1].toString().length;
    if(lengthNmaster === 4){
        n_master_id_processed = `0${splitMasterId[1]}`
    }
    n_master_processed = `N${n_master_id_processed}-${n_facility_number_split}`
    return n_master_processed;
}
export function ProcessNmasterNamePayment(n_master: string): string {
    let n_master_processed = n_master;
    
    let splitString = n_master.split("-");
    let n_facility_number_split = splitString[1];
    if(splitString[1].length < 2){
        n_facility_number_split = `0${splitString[1]}`
    }
    let n_master_id_processed = splitString[0];
    let n_number = splitString[0];
    let splitMasterId = n_number.split("N");
    const lengthNmaster = splitMasterId[1].toString().length;
    if(lengthNmaster === 4){
        n_master_id_processed = `N0${splitMasterId[1]}`
    }
    n_master_processed = `${n_master_id_processed}-${n_facility_number_split}`
    return n_master_processed;
}


export function stringToNumber(x: string): number {
    return Number((x || '').replace(/,/g, ''));
}
export const array_move  = (arr:any, old_index:any, new_index:any) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };