import * as React from 'react';
import {FC} from 'react';
import {Link} from 'react-admin';

import FullNameField from './FullNameField';
import {FieldProps, User} from '../../types';

const UserLinkField: FC<FieldProps<User>> = props =>
    props.record ? (
        <Link to={`/user/${props.record.id}`}>
            <FullNameField {...props} />
        </Link>
    ) : null;

UserLinkField.defaultProps = {
    source: 'last_name',
    addLabel: true,
};

export default UserLinkField;
