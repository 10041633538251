
const DATA = [
  { name: 'service_id', type: 'reference', reference: 'service', searchField: 'name', nullable: false, editable: false, export: true, import: true },
  { name: 'issued', type: 'date', nullable: false, editable: false, export: true, import: true },
  { name: 'name', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'note', type: 'textinput', multiline: true, nullable: true, editable: true, export: true, import: true },
  { name: 'created', type: 'hidden', nullable: true, editable: false, export: true, import: true },
  { name: 'created_by', type: 'hidden', nullable: true, editable: true, export: true, import: true },
]

export default DATA;