import * as React from 'react';
import {FC, Fragment} from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    downloadCSV,
    EmailField,
    Filter,
    List,
    SearchInput,
    SelectInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput, 
    ReferenceInput,
    ReferenceField
} from 'react-admin';

import UserLinkField from './UserLinkField';
import GroupReferenceField from "./GroupReferenceField";
import RoleField from "./RoleField";
import StatusField from './StatusField';
import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import {FilterProps} from "../../types";
import ListPagination from "../components/ListPagination";
import UserLinkFieldKana from './UserLinkFieldKana';
import CustomizableDatagrid from '../components/CustomizableDatagrid';

const exporter = (items: any) => {
    const itemsForExport = items.map((item: any) => {
        return [ item.first_name, item.last_name, item.phone, item.email, item.owner_id, item.group_id, item.role, item.status, item.password, item.phoneVerified, item.emailVerified, item.title, item.company, items[0].client_id ? items[0].client_id : ''];
    });
    const csv = '\uFEFF' + convertToCSV({
        data: itemsForExport,
        encoding: 'UTF-8 BOM',
        fields: ['名', '姓', '携帯番号', 'メールアドレス', '販売代理店ID','グループID', '役割','ステータス', 'パスワード', '電話認証','メールアドレス認証', '役職', '会社名',items[0].client_id ? 'マルチテナント用' : ''],
    });
    downloadCSV(csv, 'users');
};

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props}/>}
        </Fragment>
    )
};

const UserFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="full_name,full_name_kana,email" alwaysOn />
            <TextInput source="id@_eq" label={translate('user_list.id')} alwaysOn />
            {(permissions === 'admin' || permissions === 'client_admin') && <SelectInput alwaysOn source="status" label={translate('user_list.status')} choices={[
                { id: '0', name: translate('common.status_inactive')},
                { id: '1', name: translate('common.status_active') },
            ]} />}
            <DateInput source="last_login_at" label={translate('user_list.last_login_at')} />

            <ReferenceInput 
                source="owner_id" 
                reference="owner" 
                label={translate('opportunity.owner_id')} 
                filterToQuery={(searchText: any) => ({ name: searchText })}>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </Filter>
    )
};

const UserList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    if (permissions === undefined) {
        return null;
    }
    console.log({permissions});

    return (
        <List
            {...props}
            filters={<UserFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={exporter}
            actions={<ListActions to='user' />}
            empty={<ListEmpty />}
            filterDefaultValues={{ status: 1 }}
            title={translate('menu.user.user_list')}
        >
            <CustomizableDatagrid rowClick="edit">
                <TextField source="id" label={translate('user_list.id')} />
                <UserLinkField label={translate('user_list.full_name')} /> 
                <UserLinkFieldKana label={translate('user_list.full_name_kana')} />              
                <EmailField source="email" label={translate('user_list.email')} />
                <TextField source="title" label={translate('user_list.title')} />
                {(permissions === "admin" || permissions === "client_admin") ?
                <BooleanField source="email_verified" size="small" label={translate('user_list.email_verified')} /> : <></>}
                {(permissions === "admin" || permissions === "client_admin") ?
                <GroupReferenceField link={false} label={translate('user_list.group')} /> : <></>}
                <RoleField source="role" label={translate('user_list.role')} />
                <DateField source="last_login_at" type="date" label={translate('user_list.last_login_at')} locales="ja-JP" />
                <StatusField source="status" label={translate('user_list.status')} />
            </CustomizableDatagrid>
        </List>

    );
};

export default UserList;
