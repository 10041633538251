import * as React from 'react';
import { FC, Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
  useAuthState,
  Loading,
  useRedirect,
  useGetIdentity,
  Confirm,
  EditButton,
} from 'react-admin';
import { gql, useApolloClient } from '@apollo/client';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import { Box, Paper, FormControl, Select, MenuItem, InputLabel, Checkbox, Button } from '@material-ui/core';
import moment from 'moment';
import { numberWithCommas as NF, stringToNumber as UNF } from '../../utils/Utils'
import { YEARS, MONTHS } from './dataset';
import { ProcessNmasterName } from '../../utils/Utils'; 
import { ProcessNmaster, ProcessNFacility } from "../../utils/Utils";

const FETCH_CURR_SALES = gql`
  query QuerySales($month: Int!, $year: Int!, $no_confirmed: Boolean, $start_date: date!, $end_date: date!) {
    sales(where: {month: {_eq: $month}, year: {_eq: $year}, confirmed: {_is_null: $no_confirmed}}) {
      total_incentive_amount
      service_id
      service {
        contract_number
        rate_type
        step1_reseller_rate
        step2_reseller_rate
        step3_reseller_rate
        n_facility_master {
          n_master_id
          n_facility_number
          name
        }
        service_discounts(where: {discount_end_date: {_gte: $end_date}, discount_start_date: {_lte: $start_date}}) {
          discount_amount
          increase_flag
        }
      }
      id
      month
      year
      discount_amount
      sales_amount
      sales_total_amount
      expected_sales_amount
      confirmed
      type
    }
  }`;

const FETCH_PREV_MONTH_SALES = gql`
  query QueryPrevSales($month: Int!, $year: Int!, $service_ids: [Int!]) {
    sales(where: {month: {_eq: $month}, year: {_eq: $year}, service_id: {_in: $service_ids}}) {
      service_id
      service {
        contract_number
      }
      month
      year
      type
      total_incentive_amount
      discount_amount
      sales_amount
    }
  }`;

const UPDATE_CONFIRMED_AT = gql`
  mutation UpdateConfirmedAt($id: Int!, $dt: timestamptz, $discount_amount: numeric, $sales_amount: numeric, $sales_total_amount: numeric, $user_id: Int) {
    update_sales_by_pk(pk_columns: {id: $id}, 
      _set: {
        confirmed: $dt, 
        discount_amount: $discount_amount, 
        sales_amount: $sales_amount,
        sales_total_amount: $sales_total_amount,
        confirmed_by: $user_id}) {
      confirmed
      discount_amount
    } 
  }`;

const BATCH_UPDATE_CONFIRMED_AT = gql`
  mutation BatchUpdateConfirmedAt($objects: [sales_insert_input!]!) {
    insert_sales(
      objects: $objects,
      on_conflict: {
        constraint: sales_pkey,
        update_columns: [confirmed, confirmed_by, discount_amount, sales_amount, sales_total_amount]
      }
    ) {
      returning {
        id
      }
    } 
  }`;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 15,
    marginBottom: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CONFIRMED_FLAG = [
  { id: 0, name: 'FALSE' },
  { id: 1, name: 'TRUE' }
]

const DIFF_FILTERING = [
  {id: 0, name: ''},
  {id: 1, name: '有'},
  {id: 2, name: '無'}
]

const NList = () => {
  const { loading, authenticated } = useAuthState();
  const redirect = useRedirect();
  const [rows, setRows] = useState([] as any);
  const [month, setMonth] = useState(moment().month() + 1);
  const [year, setYear] = useState(moment().year());
  const [confirmed, setConfirmed] = useState(0);
  const [selectedRows, setSelectedRows] = useState([] as any);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [diffCheck, setDiffCheck] = useState(0);
  const apolloClient = useApolloClient();
  const translate = useTranslate()
  const classes = useStyles();
  const { identity, loading: identityLoading } = useGetIdentity();

  const handleChange = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;
    const sale_id = params?.row?.id;
    const discount_amount = UNF(params?.row?.discount_amount);
    const sales_amount = UNF(params?.row?.total_incentive_amount) + UNF(params?.row?.sales_amount);
    
    const sales_total_amount = UNF(params?.row?.sales_total_amount);
    if (Boolean(sale_id)) {
      await apolloClient.mutate({
        mutation: UPDATE_CONFIRMED_AT,
        variables: {
          id: sale_id,
          dt: checked ? moment() : null,
          discount_amount,
          sales_amount,
          sales_total_amount,
          user_id: checked ? identity.id : null,
        }
      });
      // reload data
      fetchData();
    }
  }

  const renderActionCell = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={params?.row?.checked}
        onChange={(e: any) => handleChange(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <EditButton basePath='/sales' record={params?.row} label='' />
    </Box>
  }

  const columns = [
    { field: 'id', headerName: 'ID', typename: 'number', width: 100, hide: true },
    { field: 'diff', headerName: translate('sales.confirm.diff'), typename: 'string', flex: 1, disableColumnMenu: true },
    { field: 'n_number', headerName: translate('sales.confirm.n_number'), typename: 'string', flex: 1, disableColumnMenu: true},
    { field: 'n_facility_name', headerName: translate('sales.confirm.n_facility_name'), typename: 'string', flex: 1, disableColumnMenu: true},
    { field: 'prev_total_incentive_amount', headerName: translate('sales.confirm.prev_total_incentive_amount'), typename: 'number', flex: 1, disableColumnMenu: true},
    { field: 'prev_sales_amount', headerName: translate('sales.confirm.prev_sales_amount'), typename: 'number', flex: 1, disableColumnMenu: true},
    { field: 'prev_discount_amount', headerName: translate('sales.confirm.prev_discount_amount'), typename: 'number', flex: 1, disableColumnMenu: true },
    { field: 'total_incentive_amount', headerName: translate('sales.confirm.total_incentive_amount'), typename: 'number', flex: 1, disableColumnMenu: true },
    { field: 'sales_amount', headerName: translate('sales.confirm.sales_amount'), typename: 'number', flex: 1, disableColumnMenu: true },
    { field: 'discount_amount', headerName: translate('sales.confirm.discount_amount'), typename: 'number', flex: 1, disableColumnMenu: true },
    { field: 'sales_total_amount', headerName: translate('sales.confirm.sales_total_amount'), typename: 'number', flex: 1, disableColumnMenu: true },
    { field: 'action', headerName: translate('sales.confirm.action'), flex: 1, renderCell: renderActionCell },
  ]

  const hasDiff = (currData: any, prevData: any) => {
    const currTotalIncentiveAmount: number = (currData.type === 1) ? currData.expected_sales_amount : 0;
    const currSalesAmount: number = currData.type === 2 ? (currData.service.rate_type === 2 ? currData.sales_amount : currData.expected_sales_amount) : 0;
    const currDiscountAmount: number = currData?.service?.service_discounts_aggregate?.aggregate?.sum?.discount_amount || 0;

    return currTotalIncentiveAmount !== 0 ||
    currSalesAmount !== prevData.sales_amount ||
    currDiscountAmount !== prevData.discount_amount
  }

  const convertData = (data: [any], prevData: [any]) => {
    if (data) {
      console.log(prevData);
      return data.map((d: any) => {
        const prevMonthData = prevData.find(pd => pd.service_id === d.service_id && pd.type === d.type) || {};
        const nNumber = `N${ProcessNmaster(d?.service?.n_facility_master?.n_master_id)}` + '-' + ProcessNFacility(d?.service?.n_facility_master?.n_facility_number)
        const totalIncentiveAmount: number = (d.type === 1) ? (d.service.rate_type === 2 ? d.sales_amount :d.expected_sales_amount) : 0;
        const salesAmount: number = d.type === 2 ? ((d.service.rate_type === 2 ? d.sales_amount : d.expected_sales_amount)) : 0;
        const discountAmount: number = d?.service?.service_discounts.reduce((total:any, currentValue:any) => {
          return currentValue?.increase_flag ? total + currentValue?.discount_amount : total - currentValue?.discount_amount;
        }, 0);

        return {
          id: d.id,
          diff: hasDiff(d, prevMonthData) ? '有' : '無',
          n_number: nNumber,
          n_facility_name: d?.service?.n_facility_master?.name,
          prev_total_incentive_amount: NF(prevMonthData?.total_incentive_amount),
          prev_sales_amount: NF(prevMonthData?.sales_amount),
          prev_discount_amount: NF(prevMonthData?.discount_amount),
          total_incentive_amount: NF(totalIncentiveAmount), //NF(d.total_incentive_amount),
          sales_amount: NF(salesAmount), // NF(d.sales_amount),
          discount_amount: NF(Math.abs(discountAmount)),
          sales_total_amount: NF(totalIncentiveAmount + salesAmount +  discountAmount),//NF(d.sales_total_amount),
          checked: Boolean(d.confirmed),
        }
      })
    }

    return []
  }

  const fetchData = async () => {
    const { data, loading, error } = await apolloClient.query({
      query: FETCH_CURR_SALES,
      fetchPolicy: 'network-only',
      variables: {
        month: month,
        year: year,
        no_confirmed: !confirmed,
        start_date: moment(`${year}-${month}`).startOf('month').format('YYYY-MM-DD'),
        end_date: moment(`${year}-${month}`).endOf('month').format('YYYY-MM-DD'),
      }
    });
    if (!data) { return };

    const serviceIds = data.sales.map((s: any) => s.service_id);
    const prevData = await apolloClient.query({
      query: FETCH_PREV_MONTH_SALES,
      fetchPolicy: 'network-only',
      variables: {
        month: month > 1 ? month - 1 : 12,
        year: month > 1 ? year : year - 1,
        service_ids: serviceIds,
      }
    });

    let nData = convertData(data.sales, prevData?.data?.sales);
    // diff check
    //TODO: backend pagination
    if (diffCheck !== 0) {
      nData = nData.filter(d => d.diff === ((diffCheck === 1) ? '有' : '無'))
    }
    setRows(nData);
  }


  useEffect(() => {
    if (!loading && authenticated) {
      fetchData();
    }
  }, [month, year, confirmed, loading, diffCheck])

  const handleSelectBoxChange = (event: any) => {
    const { target: { value, name } } = event;
    if (name === 'year') {
      setYear(value);
    } else if (name === 'month') {
      setMonth(value);
    } else if (name === 'confirmed') {
      setConfirmed(value);
    } else if (name === 'diffCheck') {
      setDiffCheck(value);
    }
  }

  const batchConfirm = async () => {
    setShowConfirmPopup(true);
  }

  const handleBatchUpdate = async () => {
    setShowConfirmPopup(false);
    /*
    objects = [
      {
        id: 1, 
        confirmed: '2021-05-06', 
        confirmed_by: 4, 
        sales_amount: 2000, 
        discount_amount: 3000, 
        sales_total_amount: 5000}
    ]
    */
    const objects = rows.filter((o: any) => selectedRows.includes(o.id.toString())).map((o: any) => ({
      id: o.id,
      confirmed: moment(),
      confirmed_by: identity.id,
      discount_amount: UNF(o.discount_amount), 
      sales_amount: UNF(o.sales_amount), 
      sales_total_amount: UNF(o.sales_total_amount),
      service_id: 1, // upsert mutation
      year: 2021,
      month: 1,
      start_date: '2021-01-01',
      end_date: '2021-01-01',
      created_by: 1,
    }))

    await apolloClient.mutate({
      mutation: BATCH_UPDATE_CONFIRMED_AT,
      variables: {
        objects
      }
    });

    // reload data
    fetchData();
    setSelectedRows([]);
  }

  if (loading) {
    return <Loading />;
  }

  if (!authenticated) {
    redirect('/login');
  }

  if (identityLoading || !identity) {
    return <Loading />;
  }

  return <Box width='100%' height='100%'>
    <Paper className={classes.toolbar}>
      <FormControl className={classes.formControl}>
        <InputLabel id="year-select-label">{translate('sales.year')}</InputLabel>
        <Select
          labelId="year-select-label"
          id="year-select"
          value={year}
          name='year'
          onChange={handleSelectBoxChange}
        >
          {YEARS.map((m, idx) => {
            return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
          })}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="month-select-label">{translate('sales.month')}</InputLabel>
        <Select
          labelId="month-select-label"
          id="month-select"
          value={month}
          name='month'
          onChange={handleSelectBoxChange}
        >
          {MONTHS.map((m, idx) => {
            return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
          })}
        </Select>
      </FormControl>

      {/* 確認 */}
      <FormControl className={classes.formControl}>
        <InputLabel id="confirmed-select-label">{translate('sales.confirm.action')}</InputLabel>
        <Select
          labelId="confirmed-select-label"
          id="confirmed-select"
          value={confirmed}
          name='confirmed'
          onChange={handleSelectBoxChange}
        >
          {CONFIRMED_FLAG.map((m, idx) => {
            return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
          })}
        </Select>
      </FormControl>

     {/* 差分有無 */}
     <FormControl className={classes.formControl}>
      <InputLabel id="diff-check-select-label">{translate('sales.confirm.diff')}</InputLabel>
      <Select
        labelId="diff-check-select-label"
        id="diff-check-select"
        value={diffCheck}
        name='diffCheck'
        onChange={handleSelectBoxChange}
      >
        {DIFF_FILTERING.map((m, idx) => {
          return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
        })}
      </Select>
    </FormControl>

      {/* 一括確認ボタン */}
      <FormControl className={classes.formControl}>
        <Button
          disabled={(selectedRows.length === 0) || Boolean(confirmed)}
          variant="contained"
          color="primary"
          onClick={batchConfirm}>
          {translate('sales.confirm.batch_update')}
        </Button>
      </FormControl>
    </Paper>
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={20}
      checkboxSelection={!confirmed}
      disableSelectionOnClick
      onSelectionModelChange={(newSelections) => {
        const { selectionModel } = newSelections;
        setSelectedRows(selectionModel);
      }} />

    <Confirm
      isOpen={showConfirmPopup}
      title={translate('common.batch_update_confirm_title')}
      content={`${selectedRows.length}${translate('common.batch_update_confirm_content')} ${translate('common.batch_update_confirmation')}`}
      onConfirm={handleBatchUpdate}
      onClose={() => setShowConfirmPopup(false)} />
  </Box>
};

export default NList;
