import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  useNotify
} from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import xlsx from 'xlsx-populate/browser/xlsx-populate';
import { signedDownloadQuery, SignedDownloadVariables } from '../../types';
import { useApolloClient, gql } from "@apollo/client";
import axios from 'axios';
import moment from 'moment';


const PDF_CONVERTER_URL = process.env.REACT_APP_PDF_CONVERTER_URL;

const DOWNLOAD_URL = gql`
    query GetDownload($download_s3_input: Downloads3UrlInput!) {
        generate_download_s3_url(download_s3_input: $download_s3_input) {
            signedUrls
        }
    }
`;

const INVOICE_UPLOAD_PRESIGNED_URL = gql`
  mutation GetUploadUrl($filename: String!) {
    generate_upload_url_invoice(input: {filename: $filename}) {
      filename
      presigned_url
    }
  }
`;

const INVOICE_DOWNLOAD_PRESINGED_URL = gql`
  query GetDownloadUrl($filename: String!) {
    generate_download_invoice_s3_url(download_s3_input: {filename: $filename}) {
      signedUrl
    }
  }
`;

const FETCH_INVOICES_DATA = gql`
  query GetInvoicesInfo($invoice_ids: [Int!]) {
    invoice(where: {id: {_in: $invoice_ids}}) {
      id
      issued
      n_master_id
      month
      year
      total_amount
      invoice_type
      notice_change {
        issued
        id
      }
      n_facility_master {
        n_facility_number
      }
      invoicing {
        address
        company_name
        postcode
        n_master {
          n_facility_masters {
            n_facility_number
          }
        }
      }
      invoice_details(order_by: {sales_year: asc, sales_month: asc, type: asc, service: {n_facility_master: {id: asc}}}) {
        sales_month
        sales_year
        invoice_amount
        discount_amount
        type
        scheduled_implementation_date
        note
        service {
          initial_service_fee_amount
          n_facility_master {
            n_facility_number
            name
          }
          n_master {
            name
          }
        }
      }
    }
  }
`;

const dateFormat = 'YYYY年MM月DD日';

let count = 0;
let errCount = 0;
let fileUrls: string[] = [];

const GeneratePdfBtn = (props: any) => {
  const translate = useTranslate();
  const apolloClient = useApolloClient();
  const [isConverting, setIsConverting] = useState(false);
  const notify = useNotify();
  const { selectedIds } = props;
  const totalCount: number = selectedIds ? selectedIds.length : 0;

  const fetchInvoicesDataAndConvert = async () => {
    const template1 = await downloadTemplate('invoice.xlsx');
    const template2 = await downloadTemplate('invoice_bank.xlsx');
    const template3 = await downloadTemplate('invoice_2_pages.xlsx');
    const template4 = await downloadTemplate('invoice_3_pages.xlsx');
    const template5 = await downloadTemplate('invoice_bank_2_pages.xlsx');
    const template6 = await downloadTemplate('invoice_bank_3_pages.xlsx');
    if (!template1 || !template2) {
      console.log("Can't download the template file!");
      setIsConverting(false);
      return;
    }

    const { selectedIds } = props;
    const { data: { invoice } } = await apolloClient.query({
      query: FETCH_INVOICES_DATA,
      fetchPolicy: 'network-only',
      variables: {
        invoice_ids: selectedIds,
      }
    });

    if (invoice && invoice.length > 0) {
      fileUrls = [];
      invoice.forEach((invoice: any) => {
        const { invoice_details, invoice_type } = invoice;
        const count = invoice_details.length;
        console.log("Invoice detail count: ", count);
        if (invoice_type === 2) {
          // 振替
          if (count <= 22) {
            // 1 page
            fillDataAndConvert(invoice, template2);
          } else if (count <= 44) {
            // 2 pages
            fillDataAndConvert(invoice, template5);
          } else {
            // 3 pages
            fillDataAndConvert(invoice, template6);
          }
        } else {
          // 請求書
          if (count <= 12) {
            // 1 page
            fillDataAndConvert(invoice, template1);
          } else if (count <= 36) {
            // 2 pages
            fillDataAndConvert(invoice, template3);
          } else {
            // 3 pages
            fillDataAndConvert(invoice, template4);
          }
        }
      });
    }
  }

  const handleClick = async () => {
    count = 0;
    errCount = 0;
    setIsConverting(true);
    fetchInvoicesDataAndConvert();
  }

  const getDownloadUrl = async (filename: string) => {
    const { data } = await apolloClient.query({
      query: INVOICE_DOWNLOAD_PRESINGED_URL,
      fetchPolicy: 'network-only',
      variables: {
        filename
      }
    });
    if (data) {
      const { generate_download_invoice_s3_url: { signedUrl } } = data;
      return signedUrl;
    }

    return null;
  }

  const fillDataAndConvert = (data: any, template: any) => {
    const { id, n_master_id, invoicing, year, month, n_facility_master } = data;
    const filename = `【請求書】N${n_master_id}${invoicing?.company_name}_${year}${month}_${id}`;
    xlsx.fromDataAsync(template).then((workbook: any) => {
      fillData(data, workbook);
      workbook.outputAsync()
        .then(function (blob: any) {
          uploadXlsToS3(blob, filename);
        });
    });
  }

  const uploadXlsToS3 = async (data: any, filename: string) => {
    const { data: response } = await apolloClient.mutate({
      mutation: INVOICE_UPLOAD_PRESIGNED_URL,
      variables: {
        filename: `${filename}.xlsx`
      },
    });

    if (!response || !response.generate_upload_url_invoice.presigned_url) {
      return;
    }

    const presigned_url = response.generate_upload_url_invoice.presigned_url;
    try {
      const res = await axios.put(presigned_url, data);
      if (res.status === 200) {
        const downloadUrl = await getDownloadUrl(`${filename}.xlsx`);
        if (downloadUrl) {
          fileUrls.push(downloadUrl);
        }

        count++;
        if (count >= totalCount) {
          convertToPdf(filename);
        }
      }
    } catch (error) {
      errCount++;
      notify(`（${filename}）${translate('invoice.convert_error')}`, 'error');
    }
  }

  const uploadZipToS3 = async (data: any, filename: string) => {
    const { data: response } = await apolloClient.mutate({
      mutation: INVOICE_UPLOAD_PRESIGNED_URL,
      variables: {
        filename: encodeURI(filename)
      },
    });

    if (!response || !response.generate_upload_url_invoice.presigned_url) {
      return;
    }
    const presigned_url = response.generate_upload_url_invoice.presigned_url;
    const res = await axios.put(presigned_url, data);
    if (res.status === 200) {
      return true;
    }
    return false;
  }

  // pdf conversion
  const convertToPdf = async (filename: string) => {
    const res = await axios(
      {
        method: 'post',
        url: PDF_CONVERTER_URL,
        data: { urls: fileUrls },
        headers: { 'content-type': 'application/json' },
        responseType: 'arraybuffer',
      });

    if (res.status === 200) {

      const folderName = `${moment().format("YYYYMMDD_HHmmss")}.zip`;
      if (uploadZipToS3(res.data, folderName)) {
        setIsConverting(false);
      }

      // download file
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // If IE, you must uses a different method.
        window.navigator.msSaveOrOpenBlob(res.data, folderName);
      } else {
        var fileurl = window.URL.createObjectURL(new Blob([res.data]));
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = fileurl;
        a.download = folderName;
        a.click();
        window.URL.revokeObjectURL(fileurl);
        document.body.removeChild(a);
      }
    } else {
      notify(`${translate('invoice.convert_error')}`, 'error');
      setIsConverting(false);
    }
  }

  const downloadTemplate = async (tempName: string) => {
    const { data } = await apolloClient.query<signedDownloadQuery, SignedDownloadVariables>({
      query: DOWNLOAD_URL,
      fetchPolicy: "network-only",
      variables: {
        download_s3_input: {
          files: [tempName]
        }
      },
    });

    const url = data.generate_download_s3_url.signedUrls[0];

    const res = await axios.get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })

    return res.status === 200 ? res.data : null;
  }

  const fillData = (data: any, workbook: any) => {
    const { issued, n_master_id, month, year, invoicing, invoice_details, notice_change, invoice_type, n_facility_master } = data;
    const { postcode, address, company_name, n_master } = invoicing;
    const {n_facility_masters} = n_master;
    const monthProcess = month.toString().length === 1 ? `0${month}` : month;
    console.log('n_facility_number', n_facility_masters[0].n_facility_number);
    console.log('invoice_type',invoice_type);
    
    const listFacilityNumber = [];
    for (const invoice_detail of invoice_details) {
      listFacilityNumber.push(invoice_detail.service.n_facility_master.n_facility_number);
    }
    var ListFacilityNumberUnique = listFacilityNumber.filter((v, i, a) => a.indexOf(v) === i);
    const value_of_facility = ListFacilityNumberUnique.length > 1 ? '00' : (ListFacilityNumberUnique.length === 1 ? `0${ListFacilityNumberUnique[0]}` : ListFacilityNumberUnique[0]);
    const number_of_facility = ListFacilityNumberUnique.length < 10 ? `0${ListFacilityNumberUnique.length}` : ListFacilityNumberUnique.length;
    
    const { issued: notiIssued, id } = notice_change || {};
    // let invoice_details = [...ivd, ...ivd];
    const invoiceDetailCount = invoice_details.length;
    let number_of_page = '';
    // ****************************************** 口座振替 ******************************************
    if (invoice_type === 2) {
      // 1 page
      number_of_page = Math.ceil(invoiceDetailCount/22) < 9 ? `0${Math.ceil(invoiceDetailCount/22)}` : Math.ceil(invoiceDetailCount/22).toString();
      
      workbook.sheet(0).cell("G4").value(issued ? moment(issued).format(dateFormat) : moment().format(dateFormat));
      workbook.sheet(0).cell("G5").value(`E${year}${monthProcess}N${n_master_id}${value_of_facility}${number_of_facility}${number_of_page}`);
      //workbook.sheet(0).cell("A11").value(`N${n_master_id}`);
      workbook.sheet(0).cell("A11").value(` `);
      workbook.sheet(0).cell("A6").value(`〒${postcode}`);
      workbook.sheet(0).cell("A7").value(address);
      workbook.sheet(0).cell("A9").value(`${company_name} 御中`);

      if (invoiceDetailCount > 22) {
        // 2 pages
        workbook.sheet(0).cell("G55").value(issued ? moment(issued).format(dateFormat) : moment().format(dateFormat));
        workbook.sheet(0).cell("G56").value(`E${year}${monthProcess}N${n_master_id}${value_of_facility}${number_of_facility}${number_of_page}`);
        //workbook.sheet(0).cell("A63").value(`N${n_master_id}`);
        workbook.sheet(0).cell("A11").value(` `);
        workbook.sheet(0).cell("A57").value(`〒${postcode}`);
        workbook.sheet(0).cell("A58").value(address);
        workbook.sheet(0).cell("A60").value(`${company_name} 御中`);
      }
      if (invoiceDetailCount > 44) {
        // 3 pages
        workbook.sheet(0).cell("G107").value(issued ? moment(issued).format(dateFormat) : moment().format(dateFormat));
        workbook.sheet(0).cell("G108").value(`E${year}${monthProcess}N${n_master_id}${value_of_facility}${number_of_facility}${number_of_page}`);
        //workbook.sheet(0).cell("A114").value(`N${n_master_id}`);
        workbook.sheet(0).cell("A11").value(` `);
        workbook.sheet(0).cell("A109").value(`〒${postcode}`);
        workbook.sheet(0).cell("A110").value(address);
        workbook.sheet(0).cell("A112").value(`${company_name} 御中`);
      }

      // invoice details
      let startRowNum = 20;
      let invoiceNote = '';
      invoice_details.map((d: any, index: number) => {
        const { invoice_amount, sales_year, sales_month, scheduled_implementation_date, type, note, service: { n_master: { name } } } = d;
        invoiceNote = Boolean(invoiceNote) ? invoiceNote : note;
        workbook.sheet(0).cell(`A19`).value(name);
        const invoiceAmountIncludedTax = invoice_amount * 1.1;

        if (type === 1) {
          // initial fee
          workbook.sheet(0).cell(`A${startRowNum}`).value(translate('invoice.service_initial_fee'));
        } else {
          workbook.sheet(0).cell(`A${startRowNum}`).value(translate('invoice.service_fee'));
          workbook.sheet(0).cell(`C${startRowNum}`).value(`${sales_year}年${sales_month}月`);
          workbook.sheet(0).cell(`E${startRowNum}`).value(invoiceAmountIncludedTax);
          workbook.sheet(0).cell(`F${startRowNum}`).value(moment(scheduled_implementation_date).format(dateFormat));
        }

        startRowNum++;
        if (index + 1 == 22) {
          startRowNum = 70;
        } else if (index + 1 == 45) {
          startRowNum = 122;
        }
      })

      if (invoiceNote) {
        workbook.sheet(0).cell("B43").value(invoiceNote);
      }
    } else {

      //****************************************** 口座振替以外 ******************************************
      //invoice.(year, startDate(month + 2) - 1 day)	
      const expired = moment(`${year}-${month}`)
        .add(2, 'months')
        .subtract(1, 'days')
        .format(dateFormat);
      let invoiceNote = '';
      number_of_page = Math.ceil(invoiceDetailCount/12) < 10 ? `0${Math.ceil(invoiceDetailCount/12)}` : Math.ceil(invoiceDetailCount/12).toString();
      // page 1
      if (invoiceDetailCount <= 12) {
        // use template1
        workbook.sheet(0).cell("F4").value(issued ? moment(issued).format(dateFormat) : moment().format(dateFormat));
        workbook.sheet(0).cell("F5").value(`E${year}${monthProcess}N${n_master_id}${value_of_facility}${number_of_facility}${number_of_page}`);
        //workbook.sheet(0).cell("A11").value(`N${n_master_id}`);
        workbook.sheet(0).cell("A11").value(` `);
        workbook.sheet(0).cell("A6").value(`〒${postcode}`);
        workbook.sheet(0).cell("A7").value(address);
        workbook.sheet(0).cell("A9").value(`${company_name} 御中`);
        workbook.sheet(0).cell("B21").value(expired);

        // notice change history
        if (notice_change) {
          const issuedDate = moment(notiIssued).format('YYYY年MM月');
          const a16 = `${issuedDate}に発行した電気削減クラウド契約内容変更通知書（${id}）を基に電気削減クラウド利用料金を変更致します。`;
          const a17 = '下記のとおりご請求申し上げます。';
          workbook.sheet(0).cell("A16").value(a16);
          workbook.sheet(0).cell("A17").value(a17);
        }
      } else {
        // use template3
        workbook.sheet(0).cell("F4").value(issued ? moment(issued).format(dateFormat) : moment().format(dateFormat));
        workbook.sheet(0).cell("F5").value(`E${year}${monthProcess}N${n_master_id}${value_of_facility}${number_of_facility}${number_of_page}`);
        //workbook.sheet(0).cell("A11").value(`N${n_master_id}`);
        workbook.sheet(0).cell("A11").value(` `);
        workbook.sheet(0).cell("A6").value(`〒${postcode}`);
        workbook.sheet(0).cell("A7").value(address);
        workbook.sheet(0).cell("A9").value(`${company_name} 御中`);
        workbook.sheet(0).cell("B31").value(expired);

        // notice change history
        if (notice_change) {
          const issuedDate = moment(notiIssued).format('YYYY年MM月');
          const a17 = `${issuedDate}に発行した電気削減クラウド契約内容変更通知書（${id}）を基に電気削減クラウド利用料金を変更致します。`;
          const a19 = '下記のとおりご請求申し上げます。';
          workbook.sheet(0).cell("A17").value(a17);
          workbook.sheet(0).cell("A19").value(a19);
        }
      }

      if (invoiceDetailCount > 12) {
        // 2 pages
        workbook.sheet(0).cell("F67").value(issued ? moment(issued).format(dateFormat) : moment().format(dateFormat));
        workbook.sheet(0).cell("F68").value(`E${year}${monthProcess}N${n_master_id}${value_of_facility}${number_of_facility}${number_of_page}`);
        workbook.sheet(0).cell("A69").value(`〒${postcode}`);
        workbook.sheet(0).cell("A70").value(address);
        workbook.sheet(0).cell("A72").value(`${company_name} 御中`);
      }
      if (invoiceDetailCount > 36) {
        // 3 pages
        workbook.sheet(0).cell("F116").value(issued ? moment(issued).format(dateFormat) : moment().format(dateFormat));
        workbook.sheet(0).cell("F117").value(`E${year}${monthProcess}N${n_master_id}${value_of_facility}${number_of_facility}${number_of_page}`);
        workbook.sheet(0).cell("A118").value(`〒${postcode}`);
        workbook.sheet(0).cell("A119").value(address);
        workbook.sheet(0).cell("A121").value(`${company_name} 御中`);
      }

      // invoice details
      let startRowNum = invoiceDetailCount <= 12 ? 29 : 42;
      invoice_details.map((d: any, index: number) => {        
        const { discount_amount, type, invoice_amount, sales_year, sales_month, note, service: { initial_service_fee_amount, n_facility_master : {name} } } = d;
        invoiceNote = Boolean(invoiceNote) ? invoiceNote : note;
        //サービス利用料
        if (type === 2) {
          workbook.sheet(0).cell(`A${startRowNum}`).value(name);
          workbook.sheet(0).cell(`C${startRowNum}`).value("電気削減クラウド利用料");
          workbook.sheet(0).cell(`E${startRowNum}`).value(`${sales_year}年${sales_month}月`);
          workbook.sheet(0).cell(`F${startRowNum}`).value(invoice_amount);
          startRowNum++;
        }

        if (discount_amount && discount_amount > 0) {
          workbook.sheet(0).cell(`A${startRowNum}`).value(name);
          workbook.sheet(0).cell(`C${startRowNum}`).value("電気削減クラウド利用料　割引額");
          workbook.sheet(0).cell(`E${startRowNum}`).value(`${sales_year}年${sales_month}月`);
          workbook.sheet(0).cell(`F${startRowNum}`).value(discount_amount);
          startRowNum++;
        }

        // initial charge
        if (initial_service_fee_amount && initial_service_fee_amount > 0 && type === 1) {
          workbook.sheet(0).cell(`A${startRowNum}`).value(name);
          workbook.sheet(0).cell(`C${startRowNum}`).value("電気削減クラウド初期手数料");
          workbook.sheet(0).cell(`E${startRowNum}`).value(`${sales_year}年${sales_month}月`);
          workbook.sheet(0).cell(`F${startRowNum}`).value(initial_service_fee_amount);
          startRowNum++;
        }

        if (index + 1 == 12) {
          startRowNum = 79;
        } else if (index + 1 == 36) {
          startRowNum = 128;
        }
      })
      if (invoiceNote) {
        workbook.sheet(0).cell("B45").value(invoiceNote);
      }
    }

    return workbook;
  }

  return <Button
    disabled={isConverting}
    variant="contained"
    color="secondary"
    onClick={handleClick}>
    {isConverting && <CircularProgress
      color="secondary"
      size={18}
      thickness={2}
      style={{ marginRight: 10 }} />}
    {translate('invoice.generate_pdf')}
  </Button>
}

GeneratePdfBtn.propTypes = {
  selectedIds: PropTypes.array.isRequired,
}

GeneratePdfBtn.defaultProps = {
  selectedIds: []
}

export default GeneratePdfBtn;