import * as React from 'react';
import {FC} from 'react';
import {FieldProps} from '../../types';
import {EditButton} from 'react-admin';
import BtnCreateNew from '../components/BtnCreateNew';
import BtnShowList from '../components/BtnShowList';

const ActionsField: FC<FieldProps<any>> = ({ record, basePath, resource }) => {
  return record ? (
        <span>
            {/* <EditButton basePath={basePath} source={resource} record={record}/> */}
            <BtnShowList 
              target_name='n_facility_master'
              filter_name='n_master_id'
              filter_val={record.id} />
            <BtnCreateNew 
              resource_field_name='n_master_id' 
              resource_val={record.id} 
              target_name='n_facility_master' />
        </span>
    ) : null;
}

export default ActionsField;
