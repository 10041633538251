import React, {useEffect, useState} from 'react'
import {
    BooleanInput,
    Edit,
    FormDataConsumer, ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useTranslate,
} from 'react-admin';
import {useForm} from 'react-final-form';
import {Box, CardContent, Typography} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser'
import {AppState} from "../../types";
import {useSelector} from "react-redux";
import {buildActions, buildPreview} from "./buildActions";
import EditField from '../components/editField';
import RichTextInput from 'ra-input-rich-text';
import UpdateToolbar from "../components/UpdateToolbar";

const EmailEdit = (props: any) => {
    const translate = useTranslate();

    return (
        <Edit {...props} undoable={false} successMessage={translate('common.msg_update_success')}  title={<EditField titlePage={translate('menu.email_template.email_template_menu')}/>}>
            <SimpleForm redirect="list" toolbar={<UpdateToolbar />} className="form">
                <FormDataConsumer>
                    {(formDataProps: any) => (
                        <EmailGroupForm {...formDataProps} />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
};


const EmailGroupForm = (props: any) => {
    const translate = useTranslate();
    const client = useSelector((appState: AppState) => appState.client);
    const user = useSelector((appState: AppState) => appState.user);
    const actions = buildActions(user, client);
    const form = useForm();
    const [preview, setPreview] = useState<string | null>(null);
    const { record } = props;
    useEffect(() => {
        if (record) {
            setPreview(buildPreview(record.body, record.params));
        }
    }, [record]);

    return (
        <form>
            <CardContent>
                <SectionTitle label={translate('email_template.title_edit')} />
                <Separator />
                <Box display={{ md: 'block', lg: 'flex' }} style={{ width: '100%' }}>
                    <Box flex={1} mr={{ md: 0, lg: '1em' }} style={{ width: '50%' }}>
                        <TextInput
                            autoFocus
                            source="name"
                            validate={requiredValidate}
                            label={translate('email_template.name')}
                            fullWidth
                            variant="outlined"
                            resource="template"
                        />
                        <BooleanInput label={translate('email_template.is_active')} source="active" />
                        <TextInput
                            autoFocus
                            source="description"
                            validate={requiredValidate}
                            label={translate('email_template.description')}
                            fullWidth
                            resource="template"
                            variant="outlined"
                        />
                        <TextInput
                            source="title"
                            validation={{ email: true }}
                            validate={[required()]}
                            fullWidth
                            label={translate('email_template.subject')}
                            variant="outlined"
                        />
                        <ReferenceInput
                            source="user_group_id"
                            reference="user_group"
                            fullWidth
                            variant="outlined"
                            disabled
                            label={translate("email_template.user_group")}
                        >
                            <SelectInput source="name" />
                        </ReferenceInput>
                        <TextInput
                            source="callback_url"
                            label={translate('email_template.callback_url')}
                            fullWidth
                            variant="outlined"
                        />
                        <SelectInput source="action"
                                     resource="template"
                                     fullWidth
                                     disabled
                                     onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                         const action = actions.filter((action) => {
                                             return action.id === event.target.value;
                                         })[0];

                                         form.change('body', action.body);
                                         form.change('params', JSON.stringify(action.params));
                                         setPreview(buildPreview(action.body, JSON.stringify(action.params)));
                                     }}
                                     label={translate('email_template.action')}
                                     variant="outlined"
                                     choices={actions}
                        />
                        <TextInput
                            source="params"
                            fullWidth
                            multiline
                            rows={5}
                            resource="template"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const { values } = form.getState();
                                setPreview(buildPreview(values.body, event.target.value));
                            }}
                            variant="outlined"
                            label={translate('email_template.sample_data')}
                        />
                        <RichTextInput
                            source="body"
                            onChange={(text: string) => {
                                const { values } = form.getState();
                                setPreview(buildPreview(text, values.params));
                            }}
                            toolbar = {[
                                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                ['bold', 'italic', 'underline', 'strike', 'link'],        // toggled buttons
                                ['blockquote', 'code-block'],
                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                [{ 'direction': 'rtl' }],                         // text direction
                                ['clean']                                         // remove formatting button
                            ]}
                            fullWidth
                            multiline
                            rows={20}
                            resource="template"
                            label={translate('email_template.body')}
                            style={{border: '1px solid #000'}}
                        />
                    </Box>
                    <Box flex={1} mr={{ md: 0, lg: '1em' }} style={{ width: '50%' }}>
                        {preview && ReactHtmlParser(preview)}
                    </Box>
                </Box>
            </CardContent>
        </form>
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;
export default EmailEdit;
