import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {Field, withTypes} from 'react-final-form';
import Button from '@material-ui/core/Button';
import {createMuiTheme} from '@material-ui/core/styles';
import {CircularProgress, Fade, Tab, Tabs,} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {Notification} from 'react-admin';
import {useNotify, useTranslate} from 'ra-core';
import {lightTheme} from '../../themes/themes';
import {renderInput} from '../../utils/renderInput';
import useStyles from './style'
import gql from 'graphql-tag'
import {useLazyQuery, useMutation} from "@apollo/client";

import {getClient, getClientVariables} from "../../generated/getClient";
import {login as LoginRequest, loginVariables} from "../../generated/login";
import {Client} from '../../types';
import {updateClient, updateUser} from "../../configuration/actions";
import {useDispatch} from "react-redux";
import {signup, signupVariables} from "../../generated/signup";
import {forgotPassword, forgotPasswordVariables} from "../../generated/forgotPassword";
import useLocalStorage from "../../utils/useLocalStorage";
import authProvider from "../../authProvider";
import LogoNichiden from "../../assets/images/logo_nichiden.png";

const GET_CLIENT = gql`
    query getClient($domain: String!) {
        getClient(domain: $domain) {
            ... on ClientPayload {
                client {
                    id
                    name
                    domain
                    clientData
                }
            }
            ... on ValidationResult {
                code
                message
            }
        }
    }
`;

const REGISTER_USER = gql`
    mutation signup($user: UserCreateInput!) {
        signup(userCreateInput: $user) {
            ... on AuthPayload {
                token
            }
            ... on ValidationResult {
                code
                message
            }
        }
    }
`;

const FORGOT_PASSWORD = gql`
    mutation forgotPassword($clientId: Int!, $email: String!) {
        forgotPassword(clientId: $clientId, email: $email) {
            ... on ActionResult {
                success
            }
            ... on ValidationResult {
                code
                message
            }
        }
    }
`;

const LOGIN_USER = gql`
    mutation login($email: String!, $password: String, $clientId: Int!) {
        login(email: $email, password: $password, clientId: $clientId) {
            ... on AuthPayload {
                token
                tokenExpiry
                user {
                    email
                    id
                    firstName
                    lastName
                    firstNameKana
                    lastNameKana
                    fullName
                    fullNameKana
                    imageUrl
                    role
                }
            }
            ... on ValidationResult {
                code
                message
            }
        }
    }
`;

interface FormValues {
    email: string;
    password: string;
}

interface FormSignUpValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

interface FormSignUpError {
    firstName?: string;
    lastName?: string;
    confirmPassword?: string;
    email?: string;
    password?: string;
}

interface FormValuesError {
    email?: string;
    password?: string;
}

interface FormForgotValues {
    email: string;
}

interface FormForgotError {
    email?: string;
}


const { Form: LoginForm } = withTypes<FormValues>();
const { Form: SignUpForm } = withTypes<FormSignUpValues>();
const { Form: ForgotForm } = withTypes<FormForgotValues>();

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [isForgot, setIsForgot] = useState(false);
    const [activeTabId, setActiveTabId] = useState(0);
    const [error] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const [login] = useMutation<LoginRequest, loginVariables>(LOGIN_USER);
    const [signUp] = useMutation<signup, signupVariables>(REGISTER_USER);
    const [forgot] = useMutation<forgotPassword, forgotPasswordVariables>(FORGOT_PASSWORD);

    const dispatch = useDispatch();

    const hostname = new URL(window.location.href).hostname;
    const port = new URL(window.location.href).port;
    let webEndpoint = port === '' ? hostname : `${hostname}:${port}`;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        webEndpoint = "crm2-dev.mk1technology.com";
    }
    const [client, setClient] = useLocalStorage<Client>('client', null);

    // Load client base on domain
    const [loadClient, { called, data: clientResponse }]  = useLazyQuery<getClient, getClientVariables>(GET_CLIENT, {
        variables: {
            domain: webEndpoint
        }
    });

    if (!called) {
        loadClient();
    }

    if (!clientResponse || clientResponse.getClient.__typename === 'ValidationResult' ) {
        return null;
    }
    if (!client && clientResponse && clientResponse.getClient.__typename === 'ClientPayload' ) {
        setClient(clientResponse.getClient.client);
        dispatch(updateClient(clientResponse.getClient.client))
    }

    // Handle login request
    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login({
            variables: {
                ...auth,
                clientId: client.id
            }
        }).then(({data}) => {
            if (data !== null && data !== undefined) {
                if (data.login.__typename === 'AuthPayload') {
                    const userLogin = data.login.user;

                    dispatch(updateUser({
                        id: userLogin.id,
                        first_name: userLogin.firstName,
                        last_name: userLogin.lastName,
                        full_name: userLogin.fullName,
                        first_name_kana: userLogin.firstNameKana,
                        last_name_kana: userLogin.lastNameKana,
                        full_name_kana: userLogin.fullNameKana,
                        image_url: userLogin.imageUrl,
                        role: userLogin.role
                    }));
                    authProvider.login(data.login).then((ret: any) => {
                        document.location.href="/";
                        return ret;
                    });
                   // loginRequest(data.login, location.state ? location.state.nextPathname : '/').catch(() => {});
                } else {
                    notify(data.login.message, 'warning');
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    // Handle signUp request
    const handleSignUp = async (values: FormSignUpValues) => {
        delete values.confirmPassword;
        setLoading(true);
        const { data } = await signUp({
            variables: {
                user: {
                    ...values,
                    groupId: 1,
                    clientId: client.id
                }
            }
        });
        setLoading(false);
        if (data) {
            if (data.signup.__typename === 'AuthPayload') {
                notify(translate('common.ms_register_success'), 'info');
                setActiveTabId(0);
            } else {
                notify(data.signup.message, 'warning');
            }
        }
    };

    // Handle forgot password api
    const handleForgotPassword = async (values: FormForgotValues) => {
        setLoading(true);
        const { data } = await forgot({
            variables: {
                ...values,
                clientId: client.id
            }
        });
        setLoading(false);
        if (data) {
            if (data.forgotPassword.__typename === 'ActionResult') {
                notify(translate('forgot_password.mgs_recover_password'), 'info');
                setActiveTabId(0);
                setIsForgot(false);
            } else {
                notify(data.forgotPassword.message, 'warning');
            }
        }
    };

    const validate = (values: FormValues) => {
        const errors: FormValuesError = {};
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const validateForgot = (values: FormForgotValues) => {
        const errors: FormForgotError = {};
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        return errors;
    };

    const validateSignUp = (values: FormSignUpValues) => {
        const errors: FormSignUpError = {};
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }else  if (!pattern.test(values.email)) {
            errors.email = translate('common.emailValidate');
          }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }else if (values.password.length < 6) {
            errors.password = translate('common.minLenght');
        }else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = translate('common.msg_confirm_not_match');
        }
        
        return errors;
    };

    return (
        <div className={classes.main}>
            <div
                className={
                    !isForgot
                        ? classes.formContainer
                        : classes.customFormContainer
                }
            >
                <div className={classes.form}>
                    <img src={LogoNichiden} alt="Nichiden" style={{maxWidth: '100%', margin:'auto'}}/>
                    {isForgot ? (
                        <div>
                            <Typography component="h3" className={classes.titleForm}>
                                {translate('forgot_password.title')}
                            </Typography>
                            <Typography
                                component="div"
                                style={{
                                    marginBottom: 20,
                                }}
                            >
                                {translate('forgot_password.description')}
                            </Typography>
                            <ForgotForm
                                onSubmit={handleForgotPassword}
                                validate={validateForgot}
                                render={({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Field
                                            autoFocus
                                            name="email"
                                            // @ts-ignore
                                            component={renderInput}
                                            variant="outlined"
                                            label={translate('login.email_address')}
                                            disabled={loading}
                                            style={{ marginBottom: 35 }}
                                        />

                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disabled={loading}
                                            fullWidth
                                            size="large" 
                                        >
                                            {loading && (
                                                <CircularProgress
                                                    size={25}
                                                    thickness={2}
                                                />
                                            )}
                                            {translate('forgot_password.btn_send')}
                                        </Button>
                                    </form>
                                )}
                            />
                            <Button
                                color="primary"
                                size="large"
                                onClick={() => setIsForgot(!isForgot)}
                                className={classes.forgetButton}
                            >
                                {translate('forgot_password.back_login')}
                            </Button>
                        </div>
                    ) : (
                        <React.Fragment>
                            <Tabs
                                value={activeTabId}
                                onChange={(e, id) => setActiveTabId(id)}
                                indicatorColor="primary"
                                textColor="primary"
                                className={classes.tabsButtom}
                                centered
                            >
                                <Tab
                                    label={translate('login.title_login')}
                                    classes={{ root: classes.tab }}
                                />
                                <Tab
                                    label={translate('login.title_new_user')}
                                    classes={{ root: classes.tab }}
                                />
                            </Tabs>
                            {activeTabId === 0 && (
                                <React.Fragment>
                                    <Fade
                                        in={error}
                                        style={
                                            !error
                                                ? { display: 'none' }
                                                : { display: 'inline-block' }
                                        }
                                    >
                                        <Typography
                                            color="secondary"
                                            className={classes.errorMessage}
                                            style={{ marginBottom: 35 }}
                                        >
                                            {translate('login.error_password')}
                                        </Typography>
                                    </Fade>
                                    <LoginForm
                                        onSubmit={handleSubmit}
                                        validate={validate}
                                        render={({ handleSubmit }) => (
                                            <form onSubmit={handleSubmit} noValidate>
                                                <Field
                                                    autoFocus
                                                    name="email"
                                                    // @ts-ignore
                                                    component={renderInput}
                                                    variant="outlined"
                                                    label={translate('login.email_address')}
                                                    disabled={loading}
                                                    style={{ marginBottom: 35 }}
                                                />
                                                <Field
                                                    name="password"
                                                    // @ts-ignore
                                                    component={renderInput}
                                                    variant="outlined"
                                                    label={translate('ra.auth.password')}
                                                    type="password"
                                                    disabled={loading}
                                                    style={{ marginBottom: 35 }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    color="primary"
                                                    disabled={loading}
                                                    fullWidth
                                                    size="large" 
                                                >
                                                    {loading && (
                                                        <CircularProgress
                                                            size={25}
                                                            thickness={2}
                                                        />
                                                    )}
                                                    {translate('login.title_login')}
                                                </Button>
                                            </form>
                                        )}
                                    />
                                    <Button
                                        color="primary"
                                        size="large"
                                        onClick={() => setIsForgot(!isForgot)}
                                        className={classes.forgetButton}
                                    >
                                        {translate('login.forgot_password')}
                                    </Button>
                                </React.Fragment>
                            )}
                            {activeTabId === 1 && (
                                <React.Fragment>
                                    <SignUpForm
                                        onSubmit={handleSignUp}
                                        validate={validateSignUp}
                                        render={({ handleSubmit, form }) => (
                                            <form onSubmit={
                                                async (...args) => {
                                                    await handleSubmit(...args);
                                                    form.reset();
                                                }
                                            } noValidate>
                                                <Typography
                                                    component="h5"
                                                    style={{ marginBottom: 25 }}
                                                >
                                                    {translate('user_list.create_new')}
                                                </Typography>
                                                <Field
                                                    autoFocus
                                                    name="lastName"
                                                    // @ts-ignore
                                                    component={renderInput}
                                                    variant="outlined"
                                                    label={translate('user_list.last_name')}
                                                    disabled={loading}
                                                    style={{ marginBottom: 25 }}
                                                />
                                                <Field
                                                    name="firstName"
                                                    // @ts-ignore
                                                    component={renderInput}
                                                    variant="outlined"
                                                    label={translate('user_list.first_name')}
                                                    disabled={loading}
                                                    style={{ marginBottom: 25 }}
                                                />
                                                <Field
                                                    name="email"
                                                    // @ts-ignore
                                                    component={renderInput}
                                                    variant="outlined"
                                                    label={translate('user_list.email')}

                                                    disabled={loading}
                                                    style={{ marginBottom: 25 }}
                                                />
                                                <Field
                                                    name="password"
                                                    // @ts-ignore
                                                    component={renderInput}
                                                    variant="outlined"
                                                    label={translate('user_list.password')}
                                                    disabled={loading}
                                                    type="password"
                                                    style={{ marginBottom: 25 }}
                                                />
                                                <Field
                                                    name="confirmPassword"
                                                    // @ts-ignore
                                                    component={renderInput}
                                                    variant="outlined"
                                                    label={translate('user_list.confirm_password')}
                                                    disabled={loading}
                                                    type="password"
                                                    style={{ marginBottom: 25 }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    fullWidth
                                                    color="primary"
                                                    disabled={loading}
                                                    size="large" 
                                                >
                                                    {loading && (
                                                        <CircularProgress
                                                            size={25}
                                                            thickness={2}
                                                        />
                                                    )}
                                                    {translate('common.btn_create')}
                                                </Button>
                                            </form>
                                        )}
                                    />
                                </React.Fragment>
                            )}
                            <Notification />
                        </React.Fragment>
                    )
                    }

                </div>
            </div>
        </div>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
