import * as React from "react";
import {
  useTranslate,
  required,
  TextInput,
  useDataProvider,
  SelectInput
} from "react-admin";
import { useState, useEffect } from "react";
import { Box, Typography, Grid, Container } from "@material-ui/core";
import InputRenderer from "../../utils/InputRenderer";
import Fields from "./dataset";
import moment from "moment";
import { useForm } from "react-final-form";
import NMasterReferenceInput from "../n_master/ReferenceInput";
import InvoicingReferenceInput from "../invoicing/ReferenceInput";
import NFacilityMasterRI from "../n_facility_master/ReferenceInput";
import ContractReferenceInput from "../contract/ReferenceInput";
import jaLocale from "date-fns/locale/ja";
import { DateInput } from "../../utils/DatePicker";
import { ProcessNFacility, ProcessNmaster } from "../../utils/Utils";

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const { editForm, formData } = props;
  const [serviceFreeDefault, setServiceFreeDefault] = React.useState(0);
  const [changeValue, setChangeValue] = React.useState();
  const [requiredChangeServiceFeeDate, setRequiredChangeServiceFeeDate] =
    React.useState(false);
  const [requiredInitialServiceFeeAmount, setRequiredInitialServiceFeeAmount] =
    React.useState(true);
  const [disableInitialServiceFeeAmount, setDisableInitialServiceFeeAmount] =
    React.useState(true);
  const [initStatus, setInitStatus] = React.useState(1);

  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const form = useForm();
  const [nMasterId, setNMasterId] = useState(null);

  useEffect(() => {
    if (
      serviceFreeDefault &&
      changeValue &&
      changeValue != serviceFreeDefault
    ) {
      setRequiredChangeServiceFeeDate(true);
    } else {
      setRequiredChangeServiceFeeDate(false);
    }
  }, [changeValue, serviceFreeDefault]);

  useEffect(() => {
    setInitStatus(formData.status);
  }, []);

  useEffect(() => {
    if (formData.initial_service_fee) {
      setDisableInitialServiceFeeAmount(true);
      setRequiredInitialServiceFeeAmount(false);
    } else {
      setDisableInitialServiceFeeAmount(false);
      setRequiredInitialServiceFeeAmount(true);
    }
  }, [formData.initial_service_fee]);

  useEffect(() => {
    const {
      record: { contract_id },
    } = props || {};
    if (contract_id) {
      updateForm(contract_id);
    }
    setServiceFreeDefault(formData.service_fee);
  }, []);

  const getEndDate = (startDate: string, months: number) => {
    if (!startDate || !months) return "";
    const endD = moment(startDate)
      .add(months, "month")
      .subtract(1, "days")
      .format("YYYY/MM/DD");
    return endD;
  };

  const onChange = (event: any) => {
    const { name, value } = event.target;

    const endDate = getEndDate(
      name === "service_start_date" ? value : formData.service_start_date,
      name === "service_term" ? value : formData.service_term
    );
    form.change("service_end_date", endDate);
  };

  const updateForm = async (contractId: any) => {
    const { data, error } = await dataProvider.getOne("contract", {
      id: contractId,
    });
    if (!error) {
      form.change("n_master_id", data.n_master_id);
      form.change("company_name", data.name);
      setNMasterId(data.n_master_id);
    }
  };

  const onContractChange = async (
    contractId: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (contractId) {
      updateForm(contractId);
    }
  };

  // update n_number and facility_name
  const onFacilityMasterChange = async (
    facilityId: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (facilityId && nMasterId) {
      const { data, error } = await dataProvider.getOne("n_facility_master", {
        id: facilityId,
      });

      if (data && !error) {
        const { n_facility_number, name } = data;
        const nNumber = `N${(nMasterId)}-${ProcessNFacility(n_facility_number)}`;
        form.change("n_number", nNumber);
        form.change("facility_name", name);
      }
    }
  };

  return (
    <Container>
      <SectionTitle label={editForm ? "service.edit" : "service.add_new"} />
      <Separator />
      <Grid container justify="center" spacing={1}>
        {Fields.map((d, idx) => {
          if (d.name === "service_end_date") {
            return (
              <Grid item xs={3} key={idx}>
                <DateInput
                  source={d.name}
                  disabled={true}
                  validate={requiredValidate}
                  fullWidth
                  variant="outlined"
                  label={translate(`service.${d.name}`)}
                  providerOptions={{ locale: jaLocale }}
                  options={{
                    placeholder: "年/月/日",
                    format: "yyyy/MM/dd",
                    ampm: false,
                    cancelLabel: translate("common.btn_cancel"),
                    okLabel: translate("common.btn_ok"),
                  }}
                />
              </Grid>
            );
          } else if (d.name === "service_start_date") {
            return (
              <Grid item xs={3} key={idx}>
                <DateInput
                  source={d.name}
                  onChange={(val: any) =>
                    onChange({
                      target: { name: "service_start_date", value: val },
                    })
                  }
                  validate={requiredValidate}
                  fullWidth
                  variant="outlined"
                  label={translate(`service.${d.name}`)}
                  providerOptions={{ locale: jaLocale }}
                  options={{
                    placeholder: "年/月/日",
                    format: "yyyy/MM/dd",
                    ampm: false,
                    cancelLabel: translate("common.btn_cancel"),
                    okLabel: translate("common.btn_ok"),
                  }}
                />
              </Grid>
            );
          } else if (d.name === "service_term") {
            return (
              <Grid item xs={3} key={idx}>
                <TextInput
                  source={d.name}
                  onChange={onChange}
                  validate={requiredValidate}
                  fullWidth
                  variant="outlined"
                  label={translate(`service.${d.name}`)}
                />
              </Grid>
            );
          } else if (d.name === "contract_id") {
            return (
              <Grid item xs={3} key={idx}>
                <ContractReferenceInput
                  autoFocus
                  validate={requiredValidate}
                  label={translate("service.contract_id")}
                  onChange={onContractChange}
                />
              </Grid>
            );
          } else if (d.name === "invoicing_id") {
            return (
              <Grid item xs={3} key={idx}>
                <InvoicingReferenceInput
                  autoFocus
                  validate={requiredValidate}
                  filter={nMasterId ? { n_master_id: nMasterId } : {}}
                />
              </Grid>
            );
          } else if (d.name === "n_master_id") {
            return (
              <Grid item xs={3} key={idx}>
                <NMasterReferenceInput
                  autoFocus
                  validate={requiredValidate}
                  disabled={true}
                />
              </Grid>
            );
          } else if (d.name === "n_facility_master_id") {
            return (
              <Grid item xs={3} key={idx}>
                <NFacilityMasterRI
                  autoFocus
                  validate={requiredValidate}
                  disabled={editForm ? true : false}
                  filter={nMasterId ? { n_master_id: nMasterId } : {}}
                  onChange={onFacilityMasterChange}
                />
              </Grid>
            );
          } else if (d.type === "virtual_reference") {
            return <></>;
          } else if (d.name === "service_fee") {
            return (
              <Grid item xs={3} key={idx}>
                <TextInput
                  source={d.name}
                  disabled={!d.editable && editForm}
                  validate={d.nullable ? [] : requiredValidate}
                  label={translate(`service.${d.name}`)}
                  onChange={(e: any) => {
                    setChangeValue(e.target?.value);
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            );
          } else if (d.name === "initial_service_fee_amount") {
            return (
              <Grid item xs={3} key={idx}>
                <TextInput
                  source={d.name}
                  disabled={disableInitialServiceFeeAmount}
                  validate={
                    !requiredInitialServiceFeeAmount ? [] : requiredValidate
                  }
                  label={translate(`service.${d.name}`)}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            );
          } else if (d.name === "change_service_fee_date") {
            return (
              <Grid item xs={3} key={idx}>
                <DateInput
                  source={d.name}
                  disabled={!d.editable && editForm}
                  validate={
                    requiredChangeServiceFeeDate ? requiredValidate : []
                  }
                  fullWidth
                  variant="outlined"
                  label={translate(`service.${d.name}`)}
                  providerOptions={{ locale: jaLocale }}
                  options={{
                    placeholder: "年/月/日",
                    format: "yyyy/MM/dd",
                    ampm: false,
                    cancelLabel: translate("common.btn_cancel"),
                    okLabel: translate("common.btn_ok"),
                  }}
                />
              </Grid>
            );
          } else if (d.name === "payment_method") {
            return (
              <Grid item xs={3} key={idx}>
               <SelectInput
                  source={d.name}
                  validate={d.nullable ? [] : requiredValidate}
                  choices={(editForm && initStatus != 1) ? d.options : d.optionsCreate}
                  fullWidth
                  variant="outlined"
                  label={translate(`service.${d.name}`)}
                />
              </Grid>
            );
          } else if (d.name === "status") {
            return (
              <Grid item xs={3} key={idx}>
               <SelectInput
                  source={d.name}
                  validate={d.nullable ? [] : requiredValidate}
                  choices={(editForm && initStatus != 1) ? d.optionsEdit : d.options}
                  fullWidth
                  variant="outlined"
                  label={translate(`service.${d.name}`)}
                />
              </Grid>
            );
          } else if (d.name === "transferable_month") {
            return (
              <Grid item xs={3} key={idx}>
               <DateInput
                source={d.name}
                disabled={formData.payment_method == 2 ? false : (!d.editable)}
                validate={formData.payment_method == 2 ? requiredValidate : (d.nullable ? [] : requiredValidate)}
                views={["year", "month"]}
                fullWidth
                variant="outlined"
                label={translate(`service.${d.name}`)}
                providerOptions={{ locale: jaLocale }}
                options={{ 
                  placeholder: '年/月', 
                  format: 'yyyy/MM', 
                  ampm: false, 
                  cancelLabel: translate('common.btn_cancel'), 
                  okLabel: translate('common.btn_ok') }}
              />
              </Grid>
            );
          } else {
            return (
              <Grid item xs={3} key={idx}>
                <InputRenderer
                  source="service"
                  data={d}
                  isEditPage={editForm}
                />
              </Grid>
            );
          }
        })}
        <TextInput source="n_number" type="hidden" label="" />
        <TextInput source="facility_name" type="hidden" label=""/>
        <TextInput source="company_name" type="hidden" label=""/>
      </Grid>
    </Container>
  );
};

export default NFrom;
