import * as React from 'react';
import {FC} from 'react';

import {FieldProps, User} from '../../types';

const UserLinkFieldKana: FC<FieldProps<User>> = props =>
    props.record ? (
        <span>
            {props.record.last_name_kana} {props.record.first_name_kana}
        </span>
    ) : null;

UserLinkFieldKana.defaultProps = {
    source: 'last_name_kana',
    addLabel: true,
};

export default UserLinkFieldKana;
