import UserIcon from '@material-ui/icons/People';

import EmailFilter from './EmailList';
// import EmailCreate from './EmailCreate';
import EmailGroupForm from './EmailEdit';

export default {
    list: EmailFilter,
    // create: EmailCreate,
    edit: EmailGroupForm,
    icon: UserIcon,
};
