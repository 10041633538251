import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloClient} from "apollo-client";
import {ApolloLink} from "apollo-link";
import {setContext} from "apollo-link-context";
import {HttpLink} from "apollo-link-http";
import {onError} from "apollo-link-error";
import produce from "immer";
import moment from "moment";

const createHasuraClient = () => {
    const cache = new InMemoryCache();

    const errorLink = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, path }) => {
                    console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`);
                    if (message && message.includes('JWT')) {
                        localStorage.clear();
                        window.location.href = '/';
                    }
                }
            );
        }

        if (networkError) {
            console.log(
                `[Network error ${operation}]: ${networkError.message}`
            );
        }

    });

    const httpLink = new HttpLink({
        uri: process.env.REACT_APP_HASURA
    });

    const authLink = setContext((_, oldContext) => {
        return produce(oldContext, draft => {
            if (!draft.headers) {
                draft.headers = {
                    // 'x-hasura-admin-secret': process.env.REACT_APP_HASURA_SECRET
                };
            }
            const tokenExpiry = localStorage.getItem('tokenExpiry');
            if (!tokenExpiry) {
                return;
            }
            const tokenExpiryMoment = moment(tokenExpiry);

            if (!tokenExpiryMoment.isBefore(moment())) {
                const token1: String = localStorage.getItem('token1') || '';
                const token2: String = localStorage.getItem('token2') || '';
                const token = `${token1}${token2}`;

                // console.log(token);
                if (token  !== '') {
                    draft.headers = {
                        'Authorization': `Bearer ${token}`
                    }
                }
            }

        });
    });

    const client = new ApolloClient({
        cache,
        link: ApolloLink.from([errorLink, authLink, httpLink])
    });

    return client;
};

export default createHasuraClient;