import {AuthProvider} from 'ra-core';
import moment from "moment";

const authProvider: AuthProvider = {
    login: ({token, user, tokenExpiry}) => {
        const tokenString = token as string;

        localStorage.setItem('user', JSON.stringify(user));
        const token1 = tokenString.substring(0, tokenString.length / 2);
        const token2 = tokenString.substring(token1.length, tokenString.length);
        localStorage.setItem('token', tokenString);
        localStorage.setItem('token1', token1);
        localStorage.setItem('token2', token2);
        localStorage.setItem('role', user.role);
        localStorage.setItem('tokenExpiry', tokenExpiry);
        return Promise.resolve();
    },
    logout: () => {
        console.log(`logout`);
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        localStorage.removeItem('token1');
        localStorage.removeItem('token2');
        localStorage.removeItem('tokenExpiry');
        localStorage.removeItem('language');
        return Promise.resolve();
    },
    checkError: (error) => {
        console.log('check error');
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {

        const tokenExpiry = localStorage.getItem('tokenExpiry');
        if (!tokenExpiry) {
            return Promise.reject({ message: false });
        }
        const tokenExpiryMoment = moment(tokenExpiry);

        if (tokenExpiryMoment.isBefore(moment())) {
            //Expired
            localStorage.removeItem('user');
            localStorage.removeItem('role');
            localStorage.removeItem('token1');
            localStorage.removeItem('token2');
            localStorage.removeItem('tokenExpiry');
            return Promise.reject({ message: false });
        }
        return (localStorage.getItem('token1') && localStorage.getItem('token2')) ? Promise.resolve() : Promise.reject({ message: false });
    },
    getIdentity: () => {
        try {
            const user = JSON.parse(localStorage.getItem('user') || '');
            return Promise.resolve(user);
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};

export default authProvider;
