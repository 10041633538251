import * as React from 'react';
import {BooleanInput, Edit, required, SimpleForm, TextInput, usePermissions, useTranslate} from 'react-admin';
import {Box, Typography} from '@material-ui/core';
import EditField from '../components/editField';
import UpdateToolbar from "../components/UpdateToolbar";

const UserGroupEdit = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    return (
        <Edit undoable={false} successMessage="common.msg_update_success" className="formContent" title={<EditField titlePage={translate('menu.user_group.user_group_menu')}/>} {...props}>
            <SimpleForm redirect="list" toolbar={<UpdateToolbar />} className="form">
                <Box display={{ md: 'block', lg: 'flex' }} style={{ width: '100%' }}>
                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                        <SectionTitle label="user_group.update_title" />
                        <Separator />
                        <TextInput
                            source="name"
                            resource="user_group"
                            validate={requiredValidate}
                            fullWidth
                            label={translate('user_group.name')}
                            variant="outlined"
                        />
                        {permissions === 'admin' &&
                        <BooleanInput label={translate('user_group.is_admin')} source="is_admin" />}
                    </Box>
                </Box>
            </SimpleForm>
        </Edit>
    );
};


const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;
export default UserGroupEdit;
