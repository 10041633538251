import * as React from 'react';
import { FC, Fragment, useEffect, useState } from 'react';
import {
    DateField,
    Filter,
    List,
    TextField,
    usePermissions,
    useTranslate,
    ReferenceField,
    BooleanField,
    SelectField,
    SelectInput,
    DateInput,
    NumberField,
    useAuthState,
    downloadCSV
} from 'react-admin';

import ListActions from '../components/actions';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import { FilterProps } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields, { MONTHS, YEARS, STATUS } from './dataset';
import ServiceReferenceField from '../service/ReferenceField';
import ServiceReferenceField2 from '../service/ReferenceField2';
import Dataset from './dataset';
import { useApolloClient } from '@apollo/client';
import { FETCH_INVOICE_DETAIL_SERVICE } from "./gql";
import { ProcessNFacility, ProcessNmaster } from "../../utils/Utils";
import { SearchInput } from 'react-admin';


const ExportableFields = [{ name: 'id' }, ...Dataset.filter(d => d.export)];


const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
             <SearchInput
                placeholder={translate('common.searchByNNumber')}
                source="invoice_detail_sort@n_number_search._ilike" 
                parse={(v: string) => `%${v}%`} 
                format={(v: string) =>　Boolean(v) ? v.replaceAll('%','') : ''} 
                alwaysOn />
            <SelectInput
                source="invoice_year@_eq"
                choices={YEARS}
                label={translate('invoice_detail.invoice_year')} alwaysOn />
            <SelectInput
                source="invoice_month@_eq"
                choices={MONTHS}
                label={translate('invoice_detail.invoice_month')} alwaysOn />
            <SelectInput
                source="sales_year@_eq"
                choices={YEARS}
                label={translate('invoice_detail.sales_year')} alwaysOn />
            <SelectInput
                source="sales_month@_eq"
                choices={MONTHS}
                label={translate('invoice_detail.sales_month')} alwaysOn />
            <SelectInput
                source="status@_eq"
                choices={STATUS}
                alwaysOn
                label={translate('invoice_detail.status')} />
            <DateInput
                source="payment_received_date@_eq"
                label={translate('invoice_detail.payment_received_date')} />
            <SelectInput
                source="payment_received_flg@_eq"
                choices={[{ id: true, name: 'TRUE' }, { id: false, name: 'FALSE' }]}
                label={translate('invoice_detail.payment_received_flg')} />
        </Filter>
    )
};

const exporter = async (apolloClient: any, filter: any, translate: any) => {
    const result = await apolloClient.query({
        query: FETCH_INVOICE_DETAIL_SERVICE,
        fetchPolicy: 'network-only',
        variables: {
            invoice_year: filter['invoice_year@_eq'],
            invoice_month: filter['invoice_month@_eq'],
            sales_year: filter['sales_year@_eq'],
            sales_month: filter['sales_month@_eq'],
            status: filter['status@_eq'],
            payment_received_date: filter['payment_received_date@_eq'],
            payment_received_flg: filter['payment_received_flg@_eq']
        }
    });

    const dataInvoiceDetailService = result?.data?.invoice_detail;

    if (dataInvoiceDetailService && dataInvoiceDetailService.length > 0) {
        let fieldName = ExportableFields.map((f: any) => f.name)
        const data = dataInvoiceDetailService.map((element: any) => {
            let newData = { ...element, n_master_id: `N${ProcessNmaster(element?.service?.n_master?.id)}-${ProcessNFacility(element?.service?.n_facility_master?.n_facility_number)}`, n_master_name: element?.service?.n_master?.name }
            delete newData.service
            return newData
        })
        const itemsForExport = data.map((item: any) => {
            return fieldName.map((f: any) => item[f])
        });
        const csv = '\uFEFF' + convertToCSV({
            data: itemsForExport,
            encoding: 'UTF-8 BOM',
            fields: fieldName.map((f: any) => translate(`invoice_detail.csv_fields.${f}`)),
        });
        downloadCSV(csv, 'invoice_detail');
    }
};

const NList = (props: any) => {
    const apolloClient = useApolloClient();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const module = 'invoice_detail';
    const [filter, setFilter] = useState();

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={() => exporter(apolloClient, filter, translate)}
            actions={<ListActions to='invoice_detail' setFilter={setFilter} />}
            empty={<ListEmpty />}
            title={translate('invoice_detail.menu_list')}
        >
            <CustomizableDatagrid rowClick="edit">
                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat } = f || {}
                    if (type === 'textinput') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'reference') {
                        if (name === 'service_id') {
                            return <ServiceReferenceField sortBy={"invoice_detail_sort.n_number_sort"} label={translate('service.refer_field')} source={name} reference={reference} key={idx} />
                        } else {
                            return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference} key={idx}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }
                    }

                    if (type === 'virtual_reference') {
                        if (name === 'n_facility_master_name') {
                            return <ServiceReferenceField2 label={translate('service.refer_field2')} source={'service_id'} reference={reference} key={idx} />
                        }
                    }

                    if (type === 'date') {
                        return <DateField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }

                    return <></>
                })}

                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
