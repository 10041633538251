import moment from 'moment';

const YEARS = [3,2,1,0,-1,-2,-3,-4,-5,-6,-7,-8,-9].map(num => ({ id: moment().year() - num, name: `${moment().year() - num}` }))

const TYPES = [
  {id: 0, name: '請求書月払'},
  {id: 1, name: '請求書年払'},
  {id: 2, name: '口座振替'},
]

const DATA = [
  {
    name: 'year', type: 'selectbox',
    options: YEARS, nullable: false, editable: true
  },
  {
    name: 'month', type: 'selectbox',
    options: [1,2,3,4,5,6,7,8,9,10,11,12].map(m=>({id: m, name: `${m}`})), nullable: false, editable: true
  },
  { name: 'total_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true },
  { name: 'invoice_type', type: 'selectbox', options: TYPES, nullable: false, editable: true},
  { name: 'issued', type: 'date', nullable: true, editable: true },
  { name: 'n_master_id', type: 'reference', reference: 'n_master', searchField: 'name', nullable: false, editable: true },
  { name: 'n_master_name', type: 'virtual_reference', reference: 'n_master', searchField: 'name' },
  { name: 'invoicing_id', type: 'reference', reference: 'invoicing', nullable: false, editable: true },
  { name: 'n_facility_master_id', type: 'reference', reference: 'n_facility_master', searchField: 'name', nullable: true, editable: false, export: true, import: true },
  { name: 'n_facility_master_name', type: 'virtual_reference', reference: 'n_facility_master', searchField: 'name' },
  { name: 'confirmed', type: 'datetime', nullable: true, editable: true },
  { name: 'confirmed_by', type: 'reference', reference: 'user', searchField: 'full_name', nullable: true, editable: true },
  { name: 'notice_change_id', type: 'reference', reference: 'notice_change', searchField: 'note', nullable: false, editable: true },
  { name: 'note', type: 'textinput', multiline: true, nullable: true, editable: true },
  {},
]

export default DATA;