import * as React from 'react';
import {FC, Fragment} from 'react';
import {
    DateField,
    DateInput,
    downloadCSV,
    Filter,
    List,
    SearchInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput, 
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import {FilterProps} from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import DownloadButton from './DownloadButton';

const exporter = (items: any) => {
    const itemsForExport = items.map((item: any) => {
        return [ item.id, item.received, item.title, item.note, item.filename, item.created, item.created_by];
    });
    const csv = '\uFEFF' + convertToCSV({
        data: itemsForExport,
        encoding: 'UTF-8 BOM',
        fields: [ 'エビデンスID', '受領日', '名称', '内容', 'ファイル名', '登録日', '登録ユーザーID'],
    });
    downloadCSV(csv, 'evidence');
};

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props}/>}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="title,filename" alwaysOn />
            <TextInput source="id@_eq" label={translate('evidence.id')} alwaysOn />
        </Filter>
    )
};

const NList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={exporter}
            actions={<ListActions to='evidence' />}
            empty={<ListEmpty />}
            title={translate('menu.evidence.list')}
        >
            <CustomizableDatagrid rowClick="edit">
                <TextField source="id" label={translate('evidence.id')} />
                <DateField source="received" type="date" label={translate('evidence.received')} locales="ja-JP" />
                <TextField source="title" label={translate('evidence.title')} /> 
                <TextField source="note" label={translate('evidence.note')} /> 
                <TextField source="filename" label={translate('evidence.filename')} /> 
                <DownloadButton label={translate('evidence.file')} />
                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
