import {Button, SaveButton, Toolbar, useTranslate, Confirm} from "react-admin";
import {useHistory} from "react-router";
import * as React from "react";
import { useState } from "react";

const UpdateToolbar = (props: any) => {
    const translate = useTranslate();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const cancelButton = () => {
        history.goBack();
    };

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        setOpen(false);
        props.handleSubmitWithRedirect();
    };    
    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'center' }} >
            <SaveButton
                disabled={props.pristine}
                undoable={false}
                label={translate('common.btn_update')}
                style={ props.pristine ? { color: 'rgba(255, 255, 255, 0.4)', marginRight: '25px' } : { marginRight: '25px' }}
                handleSubmitWithRedirect={
                    () => {
                    !props.invalid ?   handleClick(): handleDialogClose();
                    }
                  }
            />
            <Button label={translate('common.btn_cancel')} onClick={cancelButton} variant="contained" size="medium" color="default"/>
            <Confirm
                isOpen={open}
                title={translate('service.update_confirmation_title')}
                content={translate('service.update_confirmation')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Toolbar>
    );
};
export default UpdateToolbar;