export const DiscountTypes = [
    { id: 1, name: '年間割引' },
    { id: 2, name: '違約金の一部負担' },
    { id: 3, name: '見直し調整額' }
]

const DATA = [
    
    { name: 'service_id', type: 'reference', reference: 'service', searchField: '', nullable: false, editable: true, export: true, import: true },
    { name: 'discount_type', type: 'selectbox', options: DiscountTypes, editable: true, export: true, import: true },
    { name: 'discount_condition', type: 'textinput', nullable: false, editable: true, export: true, import: true },
    { name: 'discount_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
    { name: 'discount_start_date', type: 'date', nullable: false, editable: true, export: true, import: true },
    { name: 'discount_end_date', type: 'date', nullable: true, editable: true, export: true, import: true },
    { name: 'increase_flag', type: 'checkbox', nullable: false, editable: true, export: true, import: true },
    { name: 'created', type: 'hidden', nullable: true, editable: false, export: true, import: true },
    { name: 'created_by', type: 'hidden', nullable: true, editable: true, export: true, import: true },
    { name: 'id', type: 'hidden', export: true, import: true },
]

export default DATA;