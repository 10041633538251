import * as React from "react";
import PropTypes from 'prop-types';
import { numberWithCommas as NF } from '../../utils/Utils'

const TextField = (props: any) => {
    const {record} = props || {}
    const amount = (record['step1_reseller_amount'] || 0) + (record['step2_reseller_amount'] || 0) + (record['step3_reseller_amount'] || 0)
    return <span>{NF(amount)}</span>;
}

TextField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};

export default TextField;