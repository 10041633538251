import * as React from 'react';
import {FC} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslate} from 'react-admin';
import GetApp from '@material-ui/icons/GetApp';

import {signedDownloadQuery,FieldProps, Import,SignedDownloadVariables} from '../../types';
import {useApolloClient} from "@apollo/client";
import gql from "graphql-tag";

const GET_DOWNLOAD_URL = gql`
    query GetDownload($download_s3_input: Downloads3UrlInput!) {
        generate_download_s3_url(download_s3_input: $download_s3_input) {
            signedUrls
        }
    }
`;

const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const DownloadButton: FC<FieldProps<Import>> = ({ record }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const apolloClient = useApolloClient();
    return record && record.filename ? (
        <Button
            size="small"
            color="primary"
            onClick={async (e: any)=> {
                e.stopPropagation();
                const { data } = await apolloClient.query<signedDownloadQuery, SignedDownloadVariables>({
                    query: GET_DOWNLOAD_URL,
                    fetchPolicy: "network-only",
                    variables: {
                        download_s3_input: {
                            files: [record.filename || ""]
                        }
                    },
                });

                if (data) {
                    window.location.href = data.generate_download_s3_url.signedUrls;
                }
            }}
            className={classes.link}
        >
            <GetApp className={classes.icon} />
            {translate('import.download')}
        </Button>
    ) : null;
};

export default DownloadButton;
