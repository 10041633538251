export const STATUS = [
  {id: 1, name: '確認中'},
  {id: 2, name: '履行中'},
  {id: 3, name: '中途解約'},
  {id: 4, name: '満了解約'},
  {id: 5, name: '強制解約'},
]

export const STATUS_EDIT = [
  {id: 2, name: '履行中'},
  {id: 3, name: '中途解約'},
  {id: 4, name: '満了解約'},
  {id: 5, name: '強制解約'},
]

const INCENTIVE_TYPES = [
  {id: 1, name: 'ストック'}, 
  {id: 2, name: 'ショット'}
]

const RATE_TYPES = [
  {id: 1, name: '定額'},
  {id: 2, name: '変動'},
]

export const PAYMENT_METHODS = [
  {id: 1, name: '請求書'},
  {id: 2, name: '口座振替'},
]

export const PAYMENT_METHODS_CREATE = [
  {id: 1, name: '請求書'},
]

const DATA = [
  { name: 'contract_id', type: 'reference', reference: 'contract', searchField: 'name', nullable: false, editable: false, export: true, import: true },
  { name: 'status', type: 'selectbox', options: STATUS, optionsEdit: STATUS_EDIT, nullable: false, editable: true, export: true, import: true },
  {},
  {},
  { name: 'n_master_id', type: 'reference', reference: 'n_master', searchField: 'name', nullable: false, editable: false, export: true, import: true },
  { name: 'n_master_name', type: 'virtual_reference', reference: 'n_master', searchField: 'name' },
  { name: 'n_facility_master_id', type: 'reference', reference: 'n_facility_master', searchField: 'name', nullable: false, editable: false, export: true, import: true },
  { name: 'n_facility_master_name', type: 'virtual_reference', reference: 'n_facility_master', searchField: 'name' },
  { name: 'user_id', type: 'reference', reference: 'user', searchField: 'full_name', nullable: false, editable: true, export: true, import: true },
  {},
  { name: 'applied', type: 'date', nullable: false, editable: false, export: true, import: true },
  {},{},{},
  { name: 'contract_number', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'service_fee', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'initial_service_fee_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  {},
  { name: 'contract_date_confirmed', type: 'date', nullable: false, editable: true, export: true, import: true },
  {},
  { name: 'initial_service_fee', type: 'checkbox', default: true, editable: true, export: true, import: true },
  {},
  { name: 'service_start_date', type: 'date', nullable: false, editable: true, export: true, import: true },
  { name: 'service_end_date', type: 'date', nullable: false, editable: false, export: true, import: true },
  { name: 'rate_type', type: 'selectbox', options: RATE_TYPES, editable: true, export: true, import: true },
  {},
  { name: 'service_term', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'payment_method', type: 'selectbox', options: PAYMENT_METHODS, optionsCreate: PAYMENT_METHODS_CREATE, nullable: true, editable: true, export: true, import: true  },
  { name: 'annual_payment', type: 'checkbox', default: false, editable: true, export: true, import: true },
  {},
  { name: 'notification_sent_date', type: 'date', nullable: false, editable: true, export: true, import: true },
  { name: 'change_service_fee_date', type: 'date', nullable: false, editable: true, export: true, import: false },
  {},{},
  { name: 'service_close_date', type: 'date', nullable: true, editable: true, export: true, import: true },
  { name: 'close_reason', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  {},{},
  { name: 'invoicing_id', type: 'reference', reference: 'invoicing', searchField: 'company_name', nullable: false, editable: true, export: true, import: true },
  { name: 'note', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  {},{},
  { name: 'plan_code', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'transfer_flag', type: 'checkbox', default: false, editable: true, export: true, import: true },
  { name: 'transferable_month', type: 'yearmonth', nullable: true, editable: false, export: true, import: true },
  {},
  { name: 'step3_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: false, editable: true, export: true, import: true },
  { name: 'step3_reseller_rate', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'step3_reseller_incentive_type', type: 'selectbox', options: INCENTIVE_TYPES, editable: true, export: true, import: true},
  { name: 'step3_reseller_months', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'step2_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: false, editable: true, export: true, import: true },
  { name: 'step2_reseller_rate', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'step2_reseller_incentive_type', type: 'selectbox', options: INCENTIVE_TYPES, editable: true, export: true, import: true},
  { name: 'step2_reseller_months', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'step1_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: true, editable: true, export: true, import: true },
  { name: 'step1_reseller_rate', type: 'textinput', nullable: true, editable: true, numberFormat: true, export: true, import: true },
  { name: 'step1_reseller_incentive_type', type: 'selectbox', nullable: true, options: INCENTIVE_TYPES, editable: true, export: true, import: true},
  { name: 'step1_reseller_months', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  // { name: 'evidence_id', type: 'reference', reference: 'evidence', searchField: 'title', nullable: true, editable: true, export: true, import: true },
  {},
  { name: 'created', type: 'hidden', nullable: true, editable: false, export: true, import: true },
  { name: 'created_by', type: 'hidden', nullable: true, editable: true, export: true, import: true },
  {name: 'id', type: 'hidden', export: true, import: true},
]

export default DATA;