import * as React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    minWidth: 20,
  },
});
const stopPropagation = (e: any) => e.stopPropagation();

const BtnShowList = (props: any) => {
  const classes = useStyles();
  const { target_name, filter_name, filter_val } = props || {}
  return <Button
    size="small"
    color="primary"
    component={Link}
    onClick={stopPropagation}
    to={{
      pathname: `/${target_name}`,
      search: stringify({
        page: 1,
        perPage: 25,
        sort: 'id',
        order: 'DESC',
        filter: JSON.stringify({ [filter_name]: filter_val }),
      }),
    }}
    className={classes.link}
  >
    <ListIcon />
  </Button>
};

export default BtnShowList;
