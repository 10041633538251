import * as React from 'react';
import {
  useTranslate,
  required,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';
import ServiceReferenceInput from '../service/ReferenceInput';

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;
const requiredValidate = [required()];

const NFrom = (props: any) => {
  const { editForm } = props;
  return <Container>
    <SectionTitle label={editForm ? "service_discount.edit" : "service_discount.add_new"} />
    <Separator />
    <Grid container justify='center' spacing={2}>
      {Fields.map((d, idx) => {
        if (d.name === 'service_id') {
          return <Grid item xs={4} key={idx}>
            <ServiceReferenceInput
              autoFocus
              validate={requiredValidate} />
          </Grid>
        } else {
          return <Grid item xs={4} key={idx}>
            <InputRenderer source='service_discount' data={d} />
          </Grid>
        }
      })}
    </Grid>
  </Container>;
};

export default NFrom;