import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import CustomReferenceInput from '../components/CustomReferenceInput';
import Fields from './qcDataset';
import { ProcessNmaster } from "../../utils/Utils";


const QCFields = Fields.filter(f => !f.nullable);

const invoicingFilterToQuery = (searchText: any) => {
  if (!searchText) {
    return {};
  }

  const nNumber = parseInt(searchText.replace('N', ''));
  if (!isNaN(nNumber)) {
    return { 'n_master_id,postcode': nNumber }
  }
  return { 'company_name,postcode': searchText}
}

const ReferenceInput = (props: any) => {
  const translate = useTranslate();

  return <CustomReferenceInput
    source="invoicing_id"
    reference="invoicing"
    label={translate('invoicing.refer_input')}
    filterToQuery={invoicingFilterToQuery}
    optionText={(r: any) => r.n_master_id ? `N${ProcessNmaster(r.n_master_id)} 〒${r.postcode} ${r.company_name} ${r.address}` : `〒${r.postcode} ${r.company_name} ${r.address}`}
    resource={'invoicing'}
    quickCreateFields={QCFields}
    {...props} />
}

export default ReferenceInput;