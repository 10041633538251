import * as React from 'react';
import { FC, useState } from 'react';
import {
    ArrayInput,
    Create,
    FormDataConsumer,
    SaveButton,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    Toolbar,
    useNotify,
    useRedirect,
    useTranslate,
    required,
    FileField
} from 'react-admin';
import { Box, Button, Typography } from '@material-ui/core';
import {
    AppState,
    CreateComponentProps,
    DownloadUrlVariables,
    GetDownload,
    signedQuery,
    SignedVariables,
    StringMap
} from '../../types';
import ImportKeys from './ImportKeys';
import { useSelector } from "react-redux";
import gql from "graphql-tag";
import { useApolloClient } from "@apollo/client";
import * as Papa from 'papaparse';
import { useLocation } from "react-router";
import GetApp from '@material-ui/icons/GetApp';
import axios from 'axios';
import { FileInput } from 'react-admin';
var _ = require('lodash');

const requiredValidate = [required()];

const GET_SIGNED_URL = gql`
    query GetSignedUrl($signed_input: SignedInput!) {
        getSignedUrl(signed_input: $signed_input) {
            signedUrl
        }
    }`;

const GET_DOWNLOAD_URL = gql`
    query GetDownload($signed_input: DownloadSignedInput!) {
        getDownloadUrl(signed_input: $signed_input) {
            signedUrl
        }
    }
`;

interface IChoose {
    id: string,
    name: string
}

const convertKey = (key: string) => {
    if (key === 'id' || key === 'name' || key === "ID") {
        return `_${key}`;
    }

    return _.camelCase(key)
}


const ImportCreate: FC<CreateComponentProps> = (props: any) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const [uploading, setUploading] = useState<boolean>(false);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();


    const onFailure = (error: any) => {
        notify(`${translate('common.could_not')} ${error.message}`, 'warning');
    };
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [chooseMap, setChooseMap] = useState<IChoose[] | undefined>(undefined);
    const apolloClient = useApolloClient();

    const client = useSelector((appState: AppState) => appState.client);
    const user = useSelector((appState: AppState) => appState.user);

    const table = query.get('table');
    if (!table) {
        return null;
    }

    const target_name = translate(`menu.${table}.menu`);

    const tableFields = ImportKeys[table];

    const selects = tableFields.map((key: string) => {
        return { id: convertKey(key), name: translate(`${table}.csv_fields.${key}`) };
    });

    const PostCreateToolbar = (props: any) => (
        <Toolbar {...props}>
            <SaveButton
                label={translate(uploading ? 'import.uploading' : 'common.btn_save')}
                transform={(data: any) => ({
                    ...data,
                    client_id: client.id,
                    user_id: user.id,
                    input_link: fileName,
                    to: table,
                    target_name
                })}
                submitOnEnter={false}
                disabled={!fileName || uploading}
                redirect='list'
            />
        </Toolbar>
    );

    const onRemove = () => {
        setFileName(undefined);
        setChooseMap(undefined);
    };

    const upload = async (file: any) => {
        setUploading(true);
        Papa.parse(file, {
            preview: 1,
            complete: function (results: any) {
                const { data } = results;
                if (data && data.length > 0) {
                    let choose: IChoose[] = data[0].map((value: string) => {
                        return {
                            id: value,
                            name: value
                        };
                    });
                    const filteredArr = choose.reduce((acc, current: IChoose) => {
                        const x = acc.find((item: IChoose) => item.id === current.id);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, [] as IChoose[]);

                    setChooseMap(filteredArr);
                }
            }
        });
        const { data: response } = await apolloClient.query<signedQuery, SignedVariables>({
            query: GET_SIGNED_URL,
            fetchPolicy: 'network-only',
            variables: {
                signed_input: {
                    objectName: file.name,
                    contentType: file.type,
                    bucketType: 'import'
                }
            },
        });
        const signedUrl = response.getSignedUrl.signedUrl;

        if (!response || !signedUrl) {
            return;
        }

        await axios.put(signedUrl, file);

        const publicURl = signedUrl.split('?').shift();
        if (publicURl) {
            setFileName(publicURl.split('/').pop());
        }
        setUploading(false);
    };

    return (
        <Create {...props} className="formContent"
            onSuccess={() => {
                notify(`${translate('common.msg_add_success')}`, 'info');
                redirect("/import");
            }}
            onFailure={onFailure}
            title={translate('import.label')}
        >
            <SimpleForm className="form" redirect="list"
                toolbar={<PostCreateToolbar />}
            >
                <Button className="buttonDownload"
                    variant="contained"
                    onClick={async () => {
                        const { data } = await apolloClient.query<GetDownload, DownloadUrlVariables>({
                            query: GET_DOWNLOAD_URL,
                            variables: {
                                signed_input: {
                                    file_name: `template_${table}.csv`,
                                    key: `template_${table}.csv`,
                                    bucketType: 'import'
                                }
                            },
                        });

                        if (data) {
                            window.location.href = data.getDownloadUrl.signedUrl;
                        }
                    }}
                    color="primary">
                    <GetApp /> {translate('import.download_template')}
                </Button>
                <SectionTitle label="import.label" />
                <Separator />
                <FileInput
                    source="input_link"
                    validate={requiredValidate}
                    accept=".csv, application/vnd.ms-excel, text/csv"
                    label={translate('import.csv')}
                    placeholder={<p>{translate('import.drop_file')}</p>}
                    options={{
                        onDrop: async (files: [any], fileRejections: [any]) => {
                            console.log({ fileRejections });
                            if (files && files.length > 0) {
                                await upload(files[0]);
                            } else if (fileRejections && fileRejections.length > 0) {
                                const file = fileRejections[0] as File;
                                if (file.type === 'application/vnd.ms-excel' && file.name.endsWith('.csv')) {
                                    await upload(file);
                                }
                            }
                        },
                        onRemove: onRemove
                    }}
                >
                    <FileField source="src" title="title" />
                </FileInput>
                {chooseMap && <ArrayInput label={translate('import.maps')}
                    source="maps" initialValue={selects}>
                    <SimpleFormIterator disableRemove disableAdd >
                        <FormDataConsumer>
                            {({ scopedFormData, getSource }: any) => {
                                let found = false;
                                for (let i = 0; i < chooseMap.length; i++) {
                                    if (chooseMap[i].name === scopedFormData.name) {
                                        found = true;
                                        break;
                                    }
                                }

                                return <SelectInput label={scopedFormData.name}
                                    source={getSource(scopedFormData.id)}
                                    record={scopedFormData}
                                    fullWidth
                                    initialValue={found ? scopedFormData.name : null}
                                    variant="outlined" choices={chooseMap} />
                            }
                            }
                        </FormDataConsumer>

                    </SimpleFormIterator>
                </ArrayInput>}
            </SimpleForm>
        </Create>
    );
};


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default ImportCreate;
