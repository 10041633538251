import * as React from 'react';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { SvgIcon, Theme, useMediaQuery, Box } from '@material-ui/core';
import { MenuItemLink, usePermissions, useTranslate } from 'react-admin';
import SubMenu from './SubMenu';
import { AppState } from '../types';
import GroupIcon from '@material-ui/icons/Group';
import ApartmentIcon from '@material-ui/icons/Apartment';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {useRouteMatch} from 'react-router-dom';

import { ReactComponent as UserListIcon } from '../assets/icons/icon-side-01.svg';
import { ReactComponent as NMasterIcon } from '../assets/icons/icon-side-02.svg';
import { ReactComponent as NFMasterIcon } from '../assets/icons/icon-side-03.svg';
import { ReactComponent as InvoicingIcon } from '../assets/icons/icon-side-04.svg';
import { ReactComponent as EvidenceIcon } from '../assets/icons/icon-side-05.svg';
import { ReactComponent as ContractIcon } from '../assets/icons/icon-side-06.svg';
import { ReactComponent as ServiceIcon } from '../assets/icons/icon-side-07.svg';
import { ReactComponent as ResellerIcon } from '../assets/icons/icon-side-08.svg';
import { ReactComponent as SDiscountIcon } from '../assets/icons/icon-side-09.svg';
import { ReactComponent as SalesIcon } from '../assets/icons/icon-side-10.svg';
import { ReactComponent as InvoiceIcon } from '../assets/icons/icon-side-11.svg';
import { ReactComponent as InvoiceDetailIcon } from '../assets/icons/icon-side-12.svg';
import { ReactComponent as IncentiveIcon } from '../assets/icons/icon-side-13.svg';
import { ReactComponent as UserGroupIcon } from '../assets/icons/icon-side-14.svg';
import { ReactComponent as MTemplateIcon } from '../assets/icons/icon-side-15.svg';
import { ReactComponent as ImportHistIcon } from '../assets/icons/icon-side-16.svg';
import { ReactComponent as SettingIcon } from '../assets/icons/icon-side-17.svg';

type MenuName = 'mainMenuInvoice' | 'mainMenuApplication' | 'menuUser' | 'menuCatalog' | 'menuNMaster' | 'menuNFacilityMaster' |'menuPayment'| 'menuInvocingMaster' | 'menuEvidence'
    | 'menuService' | 'menuServiceDiscount' | 'menuSales' | 'menuTemplates' | 'menuImport' | 'menuClient' | 'menuSettings'
    | 'menuContract' | 'menuReseller' | 'menuInvoice' | 'menuInvoiceDetail' | 'menuIncentive' | 'menuInvoiceDetailConfirm'

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Separator = () => <Box pt="1em" />;

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        mainMenuInvoice: true,
        mainMenuApplication: false,
        menuUser: false,
        menuCatalog: false,
        menuSales: false,
        menuNMaster: false,
        menuNFacilityMaster: false,
        menuInvocingMaster: false,
        menuEvidence: false,
        menuService: false,
        menuServiceDiscount: false,
        menuTemplates: false,
        menuImport: false,
        menuClient: false,
        menuSettings: false,
        menuContract: true,
        menuReseller: false,
        menuInvoice: false,
        menuInvoiceDetail: false,
        menuIncentive: false,
        menuInvoiceDetailConfirm: false,
        menuPayment: false,
    });
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    const client = useSelector((appState: AppState) => appState.client);
    const user = useSelector((appState: AppState) => appState.user);
    if (!user) {
        return null;
    }
    return (
        <div>
            <SubMenu
                handleToggle={() => handleToggle('mainMenuInvoice')}
                isOpen={state.mainMenuInvoice}
                sidebarIsOpen={open}
                name="menu.mainMenuInvoice"
                icon={<ApartmentIcon />}
                dense={dense}
            >
                {/* N Master */}
                {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuNMaster')}
                        isOpen={state.menuNMaster}
                        sidebarIsOpen={open}
                        name="menu.n_master.menu"
                        icon={<SvgIcon component={NMasterIcon} viewBox="0 0 13.125 15" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/n_master`}
                            primaryText={translate(`menu.n_master.list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') && <MenuItemLink
                            to={`/n_master/create`}
                            primaryText={translate(`menu.n_master.add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />}
                    </SubMenu>
                }

                {/* N Facility Master */}
                {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuNFacilityMaster')}
                        isOpen={state.menuNFacilityMaster}
                        sidebarIsOpen={open}
                        name="menu.n_facility_master.menu"
                        icon={<SvgIcon component={NFMasterIcon} viewBox="0 0 15 13.125" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/n_facility_master`}
                            primaryText={translate(`menu.n_facility_master.list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    </SubMenu>
                }

                {/* Contract */}
                {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuContract')}
                        isOpen={state.menuContract}
                        sidebarIsOpen={open}
                        name="menu.contract.menu"
                        icon={<SvgIcon component={ContractIcon} viewBox="0 0 16.875 15" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/contract`}
                            primaryText={translate(`menu.contract.list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/contract/create`}
                            primaryText={translate(`menu.contract.add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    </SubMenu>
                }

                 {/* Invoicing master */}
                 {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuInvocingMaster')}
                        isOpen={state.menuInvocingMaster}
                        sidebarIsOpen={open}
                        name="menu.invoicing.menu"
                        icon={<SvgIcon component={InvoicingIcon} viewBox="0 0 18.75 15" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/invoicing`}
                            primaryText={translate(`menu.invoicing.list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') && <MenuItemLink
                            to={`/invoicing/create`}
                            primaryText={translate(`menu.invoicing.add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />}
                    </SubMenu>
                }

                {/* Service  */}
                {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuService')}
                        isOpen={state.menuService}
                        sidebarIsOpen={open}
                        name="menu.service.menu"
                        icon={<SvgIcon component={ServiceIcon} viewBox="0 0 11.25 15" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/service`}
                            primaryText={translate(`menu.service.list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/service/create`}
                            primaryText={translate(`menu.service.add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    </SubMenu>
                }

                 {/* Service discount  */}
                 {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuServiceDiscount')}
                        isOpen={state.menuServiceDiscount}
                        sidebarIsOpen={open}
                        name="service_discount.menu_title"
                        icon={<SvgIcon component={SDiscountIcon} viewBox="0 0 15 15" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/service_discount`}
                            primaryText={translate(`service_discount.menu_list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/service_discount/create`}
                            primaryText={translate(`service_discount.menu_add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    </SubMenu>
                }

                {/* Sales  */}
                {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuSales')}
                        isOpen={state.menuSales}
                        sidebarIsOpen={open}
                        name="sales.menu_title"
                        icon={<SvgIcon component={SalesIcon} viewBox="0 0 15 11.25" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/sales`}
                            primaryText={translate(`sales.menu_list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/sales/confirm`}
                            primaryText={translate(`sales.menu_confirm`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/sales/cost_confirm`}
                            primaryText={translate(`sales.menu_cost_confirm`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    </SubMenu>
                }

                {/* Invoice Detail */}
                {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuInvoiceDetail')}
                        isOpen={state.menuInvoiceDetail}
                        sidebarIsOpen={open}
                        name="invoice_detail.menu_title"
                        icon={<SvgIcon component={InvoiceDetailIcon} viewBox="0 0 11.25 15" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/invoice_detail`}
                            primaryText={translate(`invoice_detail.menu_list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                            isActive={(match: any, location: any) => {
                                if (!match) {
                                  return false;
                                }
                                if (location?.search !== '') {
                                    return false;
                                }

                                return true;
                            }}
                        />
                        <SubMenu
                            handleToggle={() => handleToggle('menuInvoiceDetailConfirm')}
                            isOpen={state.menuInvoiceDetailConfirm}
                            sidebarIsOpen={open}
                            name="invoice_detail.menu_confirm"
                            icon={<ArrowRightIcon />}
                            dense={dense}
                        >
                            <MenuItemLink
                                to={`/invoice_detail/confirm?invoice_type=1`}
                                primaryText={translate(`invoice_detail.menu_confirm_1`, {
                                    smart_count: 4,
                                })}
                                leftIcon={<ArrowRightIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                                isActive={(match: any, location: any) => {
                                    if (!match) {
                                    return false;
                                    }
                                    if (location?.search !== '?invoice_type=1') {
                                        return false;
                                    }

                                    return true;
                                }}
                            />

                            <MenuItemLink
                                to={`/invoice_detail/confirm?invoice_type=2`}
                                primaryText={translate(`invoice_detail.menu_confirm_2`, {
                                    smart_count: 4,
                                })}
                                leftIcon={<ArrowRightIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                                isActive={(match: any, location: any) => {
                                    if (!match) {
                                    return false;
                                    }
                                    if (location?.search !== '?invoice_type=2') {
                                        return false;
                                    }

                                    return true;
                                }}
                            />

                            <MenuItemLink
                                to={`/invoice_detail/confirm?invoice_type=3`}
                                primaryText={translate(`invoice_detail.menu_confirm_3`, {
                                    smart_count: 5,
                                })}
                                leftIcon={<ArrowRightIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                                isActive={(match: any, location: any) => {
                                    if (!match) {
                                    return false;
                                    }
                                    if (location?.search !== '?invoice_type=3') {
                                        return false;
                                    }

                                    return true;
                                }}
                            />
                        </SubMenu>
                        
                    </SubMenu>
                }

                 {/* Invoice */}
                 {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuInvoice')}
                        isOpen={state.menuInvoice}
                        sidebarIsOpen={open}
                        name="invoice.menu_title"
                        icon={<SvgIcon component={InvoiceIcon} viewBox="0 0 11.25 15" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/invoice`}
                            primaryText={translate(`invoice.menu_list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/invoice/create`}
                            primaryText={translate(`invoice.menu_add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    </SubMenu>
                }

                {/* Payment */}
                {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuPayment')}
                        isOpen={state.menuPayment}
                        sidebarIsOpen={open}
                        name="menu.payment.menu"
                        icon={<SvgIcon component={NMasterIcon} viewBox="0 0 13.125 15" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/payment`}
                            primaryText={translate(`menu.payment.list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') && <MenuItemLink
                            to={`/payment/create`}
                            primaryText={translate(`menu.payment.add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />}
                    </SubMenu>
                }
                
                {/* Incentive */}
                {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuIncentive')}
                        isOpen={state.menuIncentive}
                        sidebarIsOpen={open}
                        name="incentive.menu_title"
                        icon={<SvgIcon component={IncentiveIcon} viewBox="0 0 11.25 15.003" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/incentive`}
                            primaryText={translate(`incentive.menu_list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                            isActive={(match: any, location: any) => {
                                if (!match) {
                                  return false;
                                }

                                return match.isExact;
                            }}
                        />
                         <MenuItemLink
                            to={`/incentive/confirm`}
                            primaryText={translate(`incentive.menu_confirm`, {
                                smart_count: 1,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                            isActive={(match: any, location: any) => {
                                if (!match) {
                                return false;
                                }
                                if (location?.pathname !== '/incentive/confirm') {
                                    return false;
                                }

                                return true;
                            }}
                        />
                    </SubMenu>
                }

                <Separator />

                {/* Evidence */}
                {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuEvidence')}
                        isOpen={state.menuEvidence}
                        sidebarIsOpen={open}
                        name="menu.evidence.menu"
                        icon={<SvgIcon component={EvidenceIcon} viewBox="0 0 11.25 15.003" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/evidence`}
                            primaryText={translate(`menu.evidence.list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') && <MenuItemLink
                            to={`/evidence/create`}
                            primaryText={translate(`menu.evidence.add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />}
                    </SubMenu>
                }

                {/* User  */}
                {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuUser')}
                        isOpen={state.menuUser}
                        sidebarIsOpen={open}
                        name="menu.user.user_menu"
                        icon={<SvgIcon component={UserListIcon} viewBox="0 0 16.875 13.125" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/user`}
                            primaryText={translate(`menu.user.user_list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        {(permissions === 'admin' || permissions === 'client_admin') && <MenuItemLink
                            to={`/user/create`}
                            primaryText={translate(`menu.user.user_add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />}
                    </SubMenu>
                }

                {/* User group */}
                {/* {(permissions === 'admin' || permissions === 'client_admin') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuCatalog')}
                        isOpen={state.menuCatalog}
                        sidebarIsOpen={open}
                        name="menu.user_group.user_group_menu"
                        icon={<SvgIcon component={UserGroupIcon} viewBox="0 0 18.75 13.125" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/user_group`}
                            primaryText={translate(`menu.user_group.user_group_list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/user_group/create`}
                            primaryText={translate(`menu.user_group.user_group_add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    </SubMenu>
                } */}

                {/* Reseller  */}
                {(permissions === 'admin' || permissions === 'client_admin' || permissions === 'user') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuReseller')}
                        isOpen={state.menuReseller}
                        sidebarIsOpen={open}
                        name="menu.reseller.menu"
                        icon={<SvgIcon component={ResellerIcon} viewBox="0 0 13.125 15" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/reseller`}
                            primaryText={translate(`menu.reseller.list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/reseller/create`}
                            primaryText={translate(`menu.reseller.add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    </SubMenu>
                }

                {/* Email Template */}
                {/* {(permissions === 'admin' || permissions === 'client_admin') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuTemplates')}
                        isOpen={state.menuTemplates}
                        sidebarIsOpen={open}
                        name="menu.email_template.email_template_menu"
                        icon={<SvgIcon component={MTemplateIcon} viewBox="0 0 15 11.25" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/template`}
                            primaryText={translate(`menu.email_template.email_template_list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    </SubMenu>
                } */}

                {/* Import History  */}
                {(permissions === 'admin' || permissions === 'client_admin') &&
                    <SubMenu

                        handleToggle={() => handleToggle('menuImport')}
                        isOpen={state.menuImport}
                        sidebarIsOpen={open}
                        name="import.menu"
                        icon={<SvgIcon component={ImportHistIcon} viewBox="0 0 14.531 14.531" />}
                        dense={dense}
                    >
                        <MenuItemLink
                            to={`/import`}
                            primaryText={translate(`import.list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        {permissions === 'admin' &&
                            <MenuItemLink
                                to={`/message`}
                                primaryText={translate(`menu.message.message_menu`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<ArrowRightIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                dense={dense}
                            />}
                    </SubMenu>
                }

                {/* Client setting */}
                {/* {(permissions === 'admin') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuClient')}
                        isOpen={state.menuClient}
                        sidebarIsOpen={open}
                        name="menu.client.client_menu"
                        icon={<ApartmentIcon />}
                        dense={true}
                    >
                        <MenuItemLink
                            to={`/client`}
                            primaryText={translate(`menu.client.client_list`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                        <MenuItemLink
                            to={`/client/create`}
                            primaryText={translate(`menu.client.client_add`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ArrowRightIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />
                    </SubMenu>
                } */}

                {/* Setting */}
                {/* {(permissions === 'admin' || permissions === 'client_admin') &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuSettings')}
                        isOpen={state.menuSettings}
                        sidebarIsOpen={open}
                        name="menu.settings.setting_client"
                        icon={<SvgIcon component={SettingIcon} viewBox="0 0 13.125 13.125" />}
                        dense={dense}
                    >
                        {client && <MenuItemLink
                            to={`/client/${client.id}`}
                            primaryText={translate(`common.configClient`, {
                                smart_count: 2,
                            })}
                            leftIcon={<GroupIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            dense={dense}
                        />}

                    </SubMenu>
                } */}
                {isXSmall && logout}

            </SubMenu>
        </div>
    );
};

export default Menu;
