import * as React from 'react';
import {Route} from 'react-router-dom';
import Configuration from './configuration/Configuration';
import ResetPassword from './pages/reset-password/ResetPassword';
import ActiveUser from './pages/active-user/ActiveUser';
import ChangePassword from './pages/change-password/ChangePassword';
import SalesConfirmPage from './pages/sales/Confirm';
import InvoiceDetailConfirmPage from './pages/invoice_detail/ConfirmPage';
import CostConfirmPage from './pages/sales/CostConfirm';
import IncentiveConfirmPage from './pages/incentive/ConfirmPage';

export default [
    <Route exact path="/change-password" render={() => <ChangePassword />} />,
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/reset-password/:token" component={ResetPassword} noLayout/>,
    <Route exact path="/confirm/:token" component={ActiveUser} noLayout/>,
    <Route exact path="/sales/confirm" render={() => <SalesConfirmPage />} />,
    <Route exact path="/invoice_detail/confirm" render={() => <InvoiceDetailConfirmPage />} />,
    <Route exact path="/sales/cost_confirm" render={() => <CostConfirmPage />} />,
    <Route exact path="/incentive/confirm" render={() => <IncentiveConfirmPage />} />,
];
