import { gql } from "@apollo/client";

export const DOWNLOAD_URL = gql`
    query GetDownload($download_s3_input: Downloads3UrlInput!) {
        generate_download_s3_url(download_s3_input: $download_s3_input) {
            signedUrls
        }
    }
`;

export const INCENTIVE_UPLOAD_PRESIGNED_URL = gql`
  mutation GetUploadUrl($filename: String!) {
    generate_upload_url_incentive(input: {filename: $filename}) {
      filename
      presigned_url
    }
  }
`;

export const INCENTIVE_DOWNLOAD_PRESINGED_URL = gql`
  query GetDownloadUrl($filename: String!) {
    generate_download_incentive_s3_url(download_s3_input: {filename: $filename}) {
      signedUrl
    }
  }
`;

export const FETCH_INCENTIVES_DATA = gql`
  query GetIncentive($resellerId: Int!, $month: Int!, $year: Int!) {
    incentive(where: {
      reseller_id: {_eq: $resellerId},
      month: {_eq: $month},
      year: {_eq: $year},
    }) {
      id
      month
      year
      amount
      reseller_rate
      reseller_id
      invoice_detail {
        invoice_month
        invoice_year
        payment_received_amount
        payment_received_date
      }
      service {
        n_master {
          name
        }
      }
    }
  }
`;

export const FETCH_RESELLERS = gql`
  query GetResellers($ids: [Int!]) {
    reseller(where: {id: {_in: $ids}}) {
      id
      account_name
      account_number
      account_type
      zip_code
      other
      address
      bank_branch_code
      bank_branch_name
      bank_code
      bank_name
      name
    }
  }
`;
