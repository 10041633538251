
import * as React from 'react';
import {
  required,
  TextInput,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  SelectInput,
} from 'react-admin';
import jaLocale from "date-fns/locale/ja";
import { DateInput, DateTimeInput } from './DatePicker';
import FileInput from '../pages/components/FileInput';

const requiredValidate = [required()];

const InputComponent = (props: any) => {
  const translate = useTranslate();
  const { data, source, isEditPage } = props || {}
  // empty cell
  if (!Boolean(data)) return <></>
  
  const { name, reference, searchField, type, nullable, options, editable, placeholder } = data || {}

  if (type === 'textinput') {
    return <TextInput
      source={name}
      disabled={!editable && isEditPage}
      validate={nullable ? [] : requiredValidate}
      label={translate(`${source}.${name}`)}
      placeholder={placeholder}
      fullWidth
      multiline={data.multiline}
      variant="outlined"
    />
  }

  if (type === 'reference') {
    return <ReferenceInput
      allowEmpty={nullable ? true : false}
      source={name}
      reference={reference}
      disabled={!editable && isEditPage}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      filterToQuery={(searchText: any) => ({ [searchField]: searchText })}>
      <AutocompleteInput
        optionText={searchField}
      />
    </ReferenceInput>
  }

  if (type === 'date') {
    return <DateInput
      source={name}
      disabled={!editable && isEditPage}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      providerOptions={{ locale: jaLocale }}
      options={{ 
        placeholder: '年/月/日', 
        format: 'yyyy/MM/dd', 
        ampm: false, 
        cancelLabel: translate('common.btn_cancel'), 
        okLabel: translate('common.btn_ok') }}
    />
  }

  if (type === 'datetime') {
    return <DateTimeInput
      source={name}
      disabled={!editable && isEditPage}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      providerOptions={{ locale: jaLocale }}
      options={{ 
        placeholder: '年/月/日 時:分', 
        format: 'yyyy/MM/dd HH:mm', 
        ampm: false, 
        cancelLabel: translate('common.btn_cancel'), 
        okLabel: translate('common.btn_ok') }}
    />
  }

  if (type === 'yearmonth') {
    return <DateInput
      source={name}
      disabled={!editable && isEditPage}
      validate={nullable ? [] : requiredValidate}
      views={["year", "month"]}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      providerOptions={{ locale: jaLocale }}
      options={{ 
        placeholder: '年/月', 
        format: 'yyyy/MM', 
        ampm: false, 
        cancelLabel: translate('common.btn_cancel'), 
        okLabel: translate('common.btn_ok') }}
    />
  }

  if (type === 'checkbox') {
    return <BooleanInput
      source={name}
      fullWidth
      disabled={!editable && isEditPage}
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }

  if (type === 'selectbox') {
    return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      disabled={!editable && isEditPage}
      choices={options}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }

  if (type === 'fileinput') {
    return <FileInput
      source="fileobj"
      validate={requiredValidate}
      label={translate('evidence.filename')}
      className="errorInput"
      maxSize={50000000}
    />
  }


  return <></>;
}

export default InputComponent;