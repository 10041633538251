import React, {useEffect, useState} from 'react';
import {Admin, Resource} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {ApolloProvider} from "@apollo/react-hoc";
import buildHasuraProvider from 'ra-data-hasura-graphql';
import authProvider from './authProvider';
import themeReducer from './themeReducer';
import clientReducer from './reducers/clientReducer';
import {Layout, Login} from './layout';
import customRoutes from './routes';
import japanMessages from './i18n/ja';
import enMessages from './i18n/en';
import users from './pages/users';
import n_master from './pages/n_master';
import imports from './pages/import';
import usersGroup from './pages/user-group';
import template from './pages/email';
import client1 from './pages/client';
import contract from './pages/contract';
import createAuthClient from "./apolloClient";
import createHasuraClient from "./hasuraApolloClient";
import userReducer from "./reducers/userReducer";
import useLocalStorage from "./utils/useLocalStorage";
import {Client, User} from './types';
import {login_login_AuthPayload_user} from "./generated/login";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import NotFound from './pages/NotFound';
import reseller from './pages/reseller';
import n_facility_master from './pages/n_facility_master';
import invoicing from './pages/invoicing';
import evidence from './pages/evidence';
import service from './pages/service';
import service_discount from './pages/service_discount';
import invoice from './pages/invoice';
import invoice_detail from './pages/invoice_detail';
import incentive from './pages/incentive';
import sales from './pages/sales';
import notice_change from './pages/notice_change';
import payment from './pages/payment';

require('dotenv').config();
const language = localStorage.getItem('language') || 'ja';

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'en') {
        return enMessages;
    }
    return japanMessages;
}, language);

const authClient = createAuthClient();
const hasuraClient = createHasuraClient();

const App = () => {
    const [dataProvider, setDataProvider] = useState();
    const [client] = useLocalStorage<Client>('client', null);

    const initialState: { client: Client, user?: User } = {
        client: client
    };
    const [user] = useLocalStorage<login_login_AuthPayload_user>('user', null);
    if (user) {
        const newUser = user as User;
        newUser.last_name = user.lastName ? user.lastName : user.last_name;
        newUser.first_name = user.firstName ? user.firstName : user.first_name;
        newUser.full_name = user.fullName ? user.fullName : user.full_name;
        newUser.last_name_kana = user.lastNameKana ? user.lastNameKana : user.last_name_kana;
        newUser.first_name_kana = user.firstNameKana ? user.firstNameKana : user.first_name_kana;
        newUser.full_name_kana = user.fullNameKana ? user.fullNameKana : user.full_name_kana;
        newUser.image_url = user.imageUrl ? user.imageUrl : user.image_url;
        initialState.user = newUser;
    }
    useEffect(() => {
        (async () => {
            if (!dataProvider) {

                const hasuraProvider = await buildHasuraProvider({
                    client: hasuraClient,
                    // introspection: schema
                });
                // @ts-ignore
                setDataProvider(() => hasuraProvider)
            }
        })()
    }, [dataProvider, user]);
    
    if (!dataProvider) {
        return null;
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ApolloProvider client={authClient}>
                <Admin
                    title=""
                    dataProvider={dataProvider}
                    customReducers={{ theme: themeReducer, client: clientReducer, user: userReducer }}
                    customRoutes={customRoutes}
                    authProvider={authProvider}
                    loginPage={Login}
                    layout={Layout}
                    initialState={initialState}
                    i18nProvider={i18nProvider}
                    catchAll={NotFound}
                    disableTelemetry
                >
                    <Resource name="contract" {...contract} />
                    <Resource name="user" {...users} />
                    <Resource name="n_master" {...n_master} />
                    <Resource name="n_facility_master" {...n_facility_master} />
                    <Resource name="invoicing" {...invoicing} />
                    <Resource name="evidence" {...evidence} />
                    <Resource name="service" {...service} />
                    <Resource name="service_discount" {...service_discount} />
                    <Resource name="sales" {...sales} />
                    <Resource name="user_group" {...usersGroup} />
                    <Resource name="template" {...template} />
                    <Resource name="import" {...imports} />
                    <Resource name="client" {...client1} />
                    <Resource name="reseller" {...reseller} />
                    <Resource name="invoice" {...invoice} />
                    <Resource name="invoice_detail" {...invoice_detail} />
                    <Resource name="incentive" {...incentive} />
                    <Resource name="log" />
                    <Resource name="notice_change" {...notice_change} />
                    <Resource name="payment" {...payment} />
                    <Resource name="service_reference_view" />
                    
                </Admin>
            </ApolloProvider>
        </MuiPickersUtilsProvider>

    );
};

// @ts-ignore
export default App;
