import * as React from 'react';
import {FC, Fragment} from 'react';
import {
    DateField,
    Filter,
    List,
    SearchInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput, 
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import {FilterProps} from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import ActionsField from './Actions';
import exporter from '../../utils/exporter';
import Dataset from './dataset';

const ExportableFields = [{name: 'id'}, ...Dataset.filter(d => d.export)];

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props}/>}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name,company_number,representative_name,address" alwaysOn />
            <TextInput source="postcode" label={translate('n_master.postcode')} />
        </Filter>
    )
};

const NList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'created', order: 'DESC'}}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'n_master', ExportableFields, translate,fetchRelatedRecords)}
            actions={<ListActions to='n_master' />}
            empty={<ListEmpty />}
            title={translate('menu.n_master.list')}
        >
            <CustomizableDatagrid rowClick="edit">
                {/* <TextField source="id" label={translate('n_master.id')} /> */}
                <ActionsField label={translate('common.actions')} />
                <TextField source="name" label={translate('n_master.name')} /> 
                <TextField source="company_number" label={translate('n_master.company_number')} /> 
                <TextField source="representative_name" label={translate('n_master.representative_name')} /> 
                <TextField source="postcode" label={translate('n_master.postcode')} /> 
                <TextField source="address" label={translate('n_master.address')} /> 
                <TextField source="contact_person" label={translate('n_master.contact_person')} /> 
                <TextField source="contact_person_dept" label={translate('n_master.contact_person_dept')} /> 
                <TextField source="contact_person_title" label={translate('n_master.contact_person_title')} /> 
                <TextField source="contact_phone" label={translate('n_master.contact_phone')} /> 
                <TextField source="contact_fax" label={translate('n_master.contact_fax')} /> 
                <TextField source="contact_email" label={translate('n_master.contact_email')} /> 
                <TextField source="note" label={translate('n_master.note')} /> 
                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
