import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
} from 'react-admin';

const CustomField = (props: any) => {
  const { record } = props;
  // 法人名
  return <span>{record['name']}</span>;
}

const CustomReferField = (props: any) => {
  return <ReferenceField {...props} link={false}>
    <ReferenceField source="n_master_id" reference="n_master" link={false}>
      <CustomField />
    </ReferenceField>
  </ReferenceField>
}

CustomReferField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
};

export default CustomReferField;