import * as React from "react";
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import { Reseller } from '../../types';

const ConfirmCheckbox = (props: any) => {
    const {record, onChecked} = props || {};

    const handleChange = async (e: any) => {
      const { target: { checked } } = e;
      onChecked(checked, record.id);
    }

    return <Checkbox
      checked={Boolean(record.step1_confirmed_at)}
      onChange={(e: any) => handleChange(e)}
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
}

ConfirmCheckbox.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    onChecked: PropTypes.func.isRequired,
};

export default ConfirmCheckbox;