import moment from 'moment';
import { TYPES } from '../sales/dataset';

export const YEARS = [3,2,1,0,-1,-2,-3,-4,-5,-6,-7,-8,-9].map(num => ({ id: moment().year() - num, name: `${moment().year() - num}` }))

export const MONTHS = [1,2,3,4,5,6,7,8,9,10,11,12].map(m=>({id: m, name: `${m}`}))

export const STATUS = [
  {id: 1, name: '予定'}, 
  {id: 2, name: '確認済み'}, 
  {id: 3, name: ' 請求書発行済み'}, 
  {id: 4, name: '入金済み'},
  {id: 5, name: '手数料支払済み'},
  {id: 6, name: '解約済み'},
]

const DATA = [
  { name: 'id', type: 'hidden', export: true, import: true},
  { name: 'service_id', type: 'reference', reference: 'service', searchField: '', options: [], nullable: false, editable: true, export: true, import: true },
  { name: 'n_facility_master_name', type: 'virtual_reference', reference: 'service'},
  { name: 'n_master_id', type: 'virtual_reference', export: true },
  { name: 'n_master_name', type: 'virtual_reference', export: true },
  { name: 'invoice_year', type: 'selectbox', options: YEARS, nullable: false, editable: true, export: true, import: true },
  { name: 'invoice_month', type: 'selectbox', options: MONTHS, nullable: false, editable: true, export: true, import: true },
  { name: 'sales_year', type: 'selectbox', options: YEARS, nullable: false, editable: true, export: true, import: true },
  { name: 'sales_month', type: 'selectbox', options: MONTHS, nullable: false, editable: true, export: true, import: true },
  { name: 'invoice_id', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'invoice_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'expected_invoice_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'service_usage', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'number_of_month', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'total_months', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'type', type: 'selectbox', options: TYPES, nullable: false, editable: false, export: true, import: true},
  { name: 'step3_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: false, editable: true, export: true, import: true },
  { name: 'step3_reseller_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'step3_reseller_number_of_month', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'step3_reseller_total_months', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'step2_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: false, editable: true, export: true, import: true },
  { name: 'step2_reseller_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'step2_reseller_number_of_month', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'step2_reseller_total_months', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'step1_reseller_id', type: 'reference', reference: 'reseller', searchField: 'name', nullable: true, editable: true, export: true, import: true },
  { name: 'step1_reseller_amount', type: 'textinput', nullable: true, editable: true, numberFormat: true, export: true, import: true },
  { name: 'step1_reseller_number_of_month', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'step1_reseller_total_months', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'payment_received_date', type: 'date', nullable: true, editable: true, export: true, import: true },
  { name: 'payment_received_flg', type: 'checkbox', default: false, editable: true, export: true, import: true },
  { name: 'payment_method', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'payment_received_amount', type: 'textinput', nullable: false, editable: true, numberFormat: true, export: true, import: true },
  { name: 'adjustment_amount', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'adjustment_reason', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'confirmed', type: 'datetime', nullable: true, editable: true, export: true, import: true },
  { name: 'confirmed_by', type: 'reference', reference: 'user', searchField: 'full_name', nullable: true, editable: true, export: true, import: true },
  { name: 'incentive_paid', type: 'date', nullable: true, editable: true, export: true, import: true },
  { name: 'status', type: 'selectbox', options: STATUS, editable: true, export: true, import: true },
  { name: 'scheduled_transfer_date', type: 'yearmonth', nullable: true, editable: true, export: true, import: true },
  { name: 'scheduled_implementation_date', type: 'date', nullable: true, editable: true, export: true, import: true },
  { name: 'note', type: 'textinput', multiline: true, nullable: true, editable: true, export: true, import: true },
  { name: 'created', type: 'hidden', nullable: true, editable: false, export: true, import: true },
  { name: 'created_by', type: 'hidden', nullable: true, editable: true, export: true, import: true },
]

export default DATA;