import React, { useState, useCallback } from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
// import PropTypes from 'prop-types';
import QuickCreateButton from './QuickCreateBtn';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
});

const CustomReferenceInput = (props: any) => {
  const { quickCreateFields, disabled, hideAddNewBtn } = props;
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <div className={classes.root}>
      <ReferenceInput
        key={version} 
        variant="outlined"
        fullWidth
        {...props}>
        <AutocompleteInput
          optionText={props.optionText || 'name'}
          resettable={true}
        />
      </ReferenceInput>
      { quickCreateFields && !disabled && !hideAddNewBtn && <QuickCreateButton onChange={handleChange} {...props} /> }
    </div>
  );
};

// CustomReferenceInput.propTypes = {
//   quickCreateFields: PropTypes.array,
//   resource: PropTypes.string.isRequired,
// }

export default React.memo(CustomReferenceInput);
