export const ACCOUNT_TYPES = [
  {id: 1, name: '普通'},
  {id: 2, name: '当座'},
]

const DATA = [
  { name: 'code', type: 'textinput', nullable: false, editable: true },
  { name: 'name', type: 'textinput', nullable: false, editable: true },
  { name: 'bank_code', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'bank_name', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'bank_branch_code', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'bank_branch_name', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'account_name', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'account_type', type: 'selectbox', options: ACCOUNT_TYPES, nullable: true, editable: true, export: true, import: true  },
  { name: 'account_number', type: 'textinput', nullable: true, editable: true, export: true, import: true  },
  { name: 'zip_code', type: 'textinput', nullable: false, editable: true, export: true, import: true  },
  { name: 'address', type: 'textinput', nullable: false, editable: true, export: true, import: true  },
  { name: 'other', type: 'textinput', nullable: false, editable: true, export: true, import: true  },
]

export default DATA;