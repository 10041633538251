import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    DateField,
    Filter,
    List,
    SearchInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import { FilterProps } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import NMasterReferenceField from '../n_master/ReferenceField';
import exporter from '../../utils/exporter';
import Dataset from './dataset';
import NMasterRI from '../n_master/ReferenceInput';
import CustomNFacilityNumber from './CustomField'

const ExportableFields = [{ name: 'id' }, ...Dataset.filter(d => d.export)];

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name,contact_person,address" alwaysOn />
            <NMasterRI
                alwaysOn
                hideAddNewBtn={true}
            />
            <TextInput source="postcode" label={translate('n_facility_master.postcode')} />
        </Filter>
    )
};

const NList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any, fetchRelatedRecords:any) => exporter(items, 'n_facility_master', ExportableFields, translate,fetchRelatedRecords)}
            actions={<ListActions to='n_facility_master' />}
            empty={<ListEmpty />}
            title={translate('menu.n_facility_master.list')}
        >
            <CustomizableDatagrid rowClick="edit">
                {/* <TextField source="id" label={translate('n_facility_master.id')} /> */}
                <NMasterReferenceField source="n_master_id" label={translate('n_facility_master.n_master_id')} reference='n_master' />
                <ReferenceField label={translate('n_master.name')} source="n_master_id" reference='n_master'>
                    <TextField source="name" />
                </ReferenceField>
                <CustomNFacilityNumber source="n_facility_number" label={translate('n_facility_master.n_facility_number')} />
                {/* <TextField source="n_facility_number" label={translate('n_facility_master.n_facility_number')} /> */}
                <TextField source="name" label={translate('n_facility_master.name')} />
                <TextField source="postcode" label={translate('n_facility_master.postcode')} />
                <TextField source="address" label={translate('n_facility_master.address')} />
                <TextField source="contact_person" label={translate('n_facility_master.contact_person')} />
                <TextField source="contact_person_dept" label={translate('n_facility_master.contact_person_dept')} />
                <TextField source="contact_person_title" label={translate('n_facility_master.contact_person_title')} />
                <TextField source="contact_phone" label={translate('n_facility_master.contact_phone')} />
                <TextField source="contact_fax" label={translate('n_facility_master.contact_fax')} />
                <TextField source="contact_email" label={translate('n_facility_master.contact_email')} />
                <TextField source="note" label={translate('n_facility_master.note')} />
                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
