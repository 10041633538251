import { gql } from "@apollo/client";

export const BATCH_UPDATE_INVOICE_DETAIL = gql`
mutation BatchUpdate($objects: [invoice_detail_insert_input!]!) {
  insert_invoice_detail(
    objects: $objects,
    on_conflict: {
      constraint: invoice_detail_pkey,
      update_columns: [payment_received_date, payment_received_year, payment_received_month, payment_received_flg, payment_method_text, payment_received_amount, status]
    }
  ) {
    returning {
      id
    }
  } 
}`;

export const UPDATE_INVOICE_DETAIL = gql`
mutation Update($id: Int!, $paymentMethod: String, $paymentDate: date, $paymentAmount: numeric) {
  update_invoice_detail_by_pk(
    pk_columns: {id: $id}, 
    _set: {
      payment_method_text: $paymentMethod, 
      payment_received_amount: $paymentAmount, 
      payment_received_date: $paymentDate, 
      payment_received_flg: true,
      status: 4
    }) {
    id
  }
}
`;

export const UPDATE_PAYMENT = gql`
mutation Update($id: Int!, $invoiceDetailId: Int) {
  update_payment_by_pk(
    pk_columns: {id: $id}, 
    _set: {
      status: 1
    }) {
    id
  }
}
`;

export const FETCH_UNPAID_INVOICE_DETAILS = gql`
query GetInvoiceDetails($nMasterId: Int!, $facilityNumber: Int) {
  invoice_detail(
    where: {
      service: {
        n_master_id: {_eq: $nMasterId}, 
        n_facility_master: {
          n_master_id: {_eq: $nMasterId}, 
          n_facility_number: {_eq: $facilityNumber}
        }
      },
      payment_received_flg: {_eq: false}
    }, order_by: {total_months: asc, sales_year: asc, sales_month: asc}) {
    service_id
    sales_month
    sales_year
    invoice_year
    total_months
    invoice_month
    created_by
    type
    invoice_amount
    payment_received_amount
    id
  }
}
`;

export const FETCH_PAYMENTS_DATA = gql`
query GetPayments($payment_ids: [Int!]) {
  payment(where: {id: {_in: $payment_ids}}) {
    n_number
    payment_method_name
    payment_received
    payment_received_amount
    target_detail
    id
  }
}
`;