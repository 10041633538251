import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    DateField,
    Filter,
    List,
    SearchInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput,
    ReferenceField,
    BooleanField,
    SelectField,
    NumberField,
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import { FilterProps } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import ServiceReferenceField from '../service/ReferenceField';
import exporter from '../../utils/exporter';
import Dataset from './dataset';
import ServiceRI from '../service/ReferenceInput';

const ExportableFields = [{name: 'id'}, ...Dataset.filter(d => d.export)];

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="discount_condition" alwaysOn />
            <ServiceRI alwaysOn />
        </Filter>
    )
};

const NList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const module = 'service_discount';

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'created', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'service_discount', ExportableFields, translate,fetchRelatedRecords)}
            actions={<ListActions to='service_discount' />}
            empty={<ListEmpty />}
            title={translate('service_discount.menu_list')}
        >
            <CustomizableDatagrid rowClick="edit">
                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat } = f || {}
                    if (type === 'textinput') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} key={idx}/>
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} key={idx} />
                    }

                    if (type === 'reference') {
                        if (name === 'service_id') {
                            return <ServiceReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference} key={idx}/>
                        } else {
                            return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference} key={idx}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }
                    }

                    if (type === 'date') {
                        return <DateField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" key={idx} />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} key={idx}/>
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                            key={idx}
                        />
                    }

                    return <></>
                })}

                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
