import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
} from 'react-admin';
import { ProcessNmaster } from "../../utils/Utils";

const CustomField = (props: any) => {
  const { record } = props;
  return <span>{Boolean(record.n_master_id) ? `N${ProcessNmaster(record.n_master_id)} ${record.company_name}` : `${record.company_name}`}</span>;
}

const CustomReferField = (props: any) => {
  return <ReferenceField {...props}>
    <CustomField />
  </ReferenceField>
}

CustomReferField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
};

export default CustomReferField;