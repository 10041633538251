import {usePermissions} from "react-admin";
import {default as React, Fragment} from "react";
import BulkDeleteButton from "./actionsBulkDelete";

const BulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions ==='admin' || permissions ==='client_admin') && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};
export default BulkActionButtons;