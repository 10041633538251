import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    DateField,
    Filter,
    List,
    SearchInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput,
    ReferenceField,
    SelectField,
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import { FilterProps } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import ActionsField from './Actions';
import { STATUS } from './dataset';
import NMasterReferenceField from '../n_master/ReferenceField';
import exporter from '../../utils/exporter';
import Dataset from './dataset';
import EvidenceRF from '../evidence/ReferenceField';

const ExportableFields = [{name: 'id'}, ...Dataset.filter(d => d.export)];

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name" alwaysOn />
        </Filter>
    )
};

const NList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'contract', ExportableFields, translate,fetchRelatedRecords)}
            actions={<ListActions to='contract' />}
            empty={<ListEmpty />}
            title={translate('menu.contract.list')}
        >
            <CustomizableDatagrid rowClick="edit">
                {/* <TextField source="id" label={translate('contract.id')} /> */}
                <ActionsField label={translate('common.actions')} />
                <NMasterReferenceField source="n_master_id" label={translate('contract.n_master_id')} reference='n_master' />
                <ReferenceField label={translate('n_master.name')} source="n_master_id" reference='n_master'>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="name" label={translate('contract.name')} />
                <TextField source="opportunity_id" label={translate('contract.opportunity_id')} />
                <DateField source="contract_date" type="date" label={translate('contract.contract_date')} locales="ja-JP" />
                <SelectField choices={STATUS} source="status" label={translate('contract.status')} />
                <EvidenceRF source="evidence_id" label={translate('contract.evidence')} reference="evidence"/>
                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
