import * as React from 'react';
import {FC} from 'react';
import {Chip} from '@material-ui/core';
import {FieldProps, User} from '../../types';
import {useTranslate} from 'react-admin';

const StatusField: FC<FieldProps<User>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            <Chip
                label={
                    record.status === 1 ? translate('common.status_active') : translate('common.status_inactive')
                }
                style={{
                    color: '#fff',
                    height: 16,
                    backgroundColor:
                        record.status === 1 ? '#3CD4A0' : '#FF5C93',
                    fontSize: 11,
                    fontWeight: 'bold',
                }}
            />
        </span>
    ) : null;
};
export default StatusField;



