import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {useTranslate} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {Box, IconButton, Typography,} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {DownloadUrlVariables, EditComponentProps, GetDownload, importQuery, ImportVariables} from '../../types';
import {useQuery, useSubscription} from "@apollo/client";
import gql from "graphql-tag";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {readRemoteFile} from 'react-papaparse'

const GET_DOWNLOAD_URL = gql`
    query GetDownload($signed_input: DownloadSignedInput!) {
        getDownloadUrl(signed_input: $signed_input) {
            signedUrl
        }
    }
`;

const SUB_IMPORT = gql`
    subscription importQuery($id: Int!) {
        import_by_pk(id: $id) {
            client_id
            total_row
            to
            status
            error_file
            success_count
            result_link
            proceed_count
            is_create_new
            input_link
            id
            fail_count
            user_id
        }
    }`;

const useStyles = makeStyles(theme => ({
    root: {
        // padding:'10px 10px 10px',
        height:'100%',
        display: 'flex',
        boxSizing:'border-box',
        flexDirection:'column'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '3em 1em 1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    boxList: {
        border: '1px solid #d9d9d9',
        borderRadius: '5px',
        padding: '10px',
        marginBottom: '15px',
        width:'100%',
        boxSizing:'border-box',
        flexWrap:'wrap'
    },
    card:{
        height:'100%',
        display:'block',
        boxSizing:'border-box',
    },
    cardContent:{
        height:'100%',
        display:'block',
        boxSizing:'border-box',        
    }
}));

interface Props extends EditComponentProps {
    onCancel: () => void;
}
const ImportLinkDetail: FC<Props> = ({ id, onCancel }) => {
    const { data } = useSubscription<importQuery, ImportVariables>(SUB_IMPORT, {
        variables: {
            id: Number(id)
        }
    });

    const fileName = data != null && data.import_by_pk != null &&  data.import_by_pk.error_file ? data.import_by_pk.error_file : "";

    const { data: linkData} = useQuery<GetDownload, DownloadUrlVariables>(GET_DOWNLOAD_URL,{
        skip: fileName === "",
        fetchPolicy: "network-only",
        variables: {
            signed_input: {
                file_name: fileName,
                key: fileName,
                bucketType: 'import'
            }
        }
    });
    const [results, setResults] = useState<any[]>([]);

    useEffect(() => {
        setResults([]);
        }
    ,[id]);

    useEffect(() => {

        if(linkData && results.length === 0) {
            console.log(linkData.getDownloadUrl.signedUrl);
            readRemoteFile(linkData.getDownloadUrl.signedUrl, {
                complete: function (res: any) {
                    if (res.data && res.data.length > 1) {
                        const csvMessages = [];
                        for (let i = 1; i < res.data.length - 1; i++) {
                            if (res.data[i].length === 2) {
                                csvMessages.push(res.data[i]);
                            }
                        }
                        setResults(csvMessages);
                    }
                }
            })
        }
    }, [linkData, results]);


    const classes = useStyles();
    const translate = useTranslate();

    if (!id || !data || !data.import_by_pk) {
        return null;
    }

    const imp = data.import_by_pk;

    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <div className={classes.root}>
                    <div className={classes.title}>
                        <Typography variant="h6">
                            {translate('import.results')}
                        </Typography>
                        <IconButton onClick={onCancel}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className="box_content_auto">
                            <Box flexGrow={1} className={classes.boxList} display="flex">
                                <Box display="flex" mb="1em" style={{ width: '50%' }}>
                                    <Box mr="1em">
                                        <CheckCircleIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'import.total_row'
                                            )}
                                        </Typography>
                                        <Typography>
                                            {imp.total_row}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" mb="1em" style={{ width: '50%' }}>
                                    <Box mr="1em">
                                        <CheckCircleIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'import.success_count'
                                            )}
                                        </Typography>
                                        <Typography>
                                            {imp.success_count}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" mb="1em" style={{ width: '50%' }}>
                                    <Box mr="1em">
                                        <CheckCircleIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'import.proceed_count'
                                            )}
                                        </Typography>
                                        <Typography>
                                            {imp.proceed_count}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" mb="1em" style={{ width: '50%' }}>
                                    <Box mr="1em">
                                        <CheckCircleIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'import.fail_count'
                                            )}
                                        </Typography>
                                        <Typography>
                                            {imp.fail_count}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        {results.map((result) => {
                            return (
                                <Box flexGrow={1} className={classes.boxList} display="flex">
                                    <Box display="flex" mb="1em">
                                        <Box mr="1em">
                                            <CheckCircleIcon
                                                fontSize="small"
                                                color="disabled"
                                            />
                                        </Box>
                                        <Box flexGrow={1}>
                                            <Typography>
                                                {`${translate(
                                                    'import.line'
                                                )} ${result[0]}`}
                                            </Typography>
                                            <Typography>
                                                {`${result[1]}`}
                                            </Typography>

                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })}
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default ImportLinkDetail;
