import * as React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles({
    link: {
        display: 'inline-flex',
        alignItems: 'center',
        minWidth: 20,
    },
});

const stopPropagation = (e: any) => e.stopPropagation();

const BtnCreateNew = (props: any) => {
    const classes = useStyles();
    const { resource_field_name, resource_val, target_name } = props || {};
    return resource_val !== null ? (
        <Button
            size="small"
            color="primary"
            component={Link}
            onClick={stopPropagation}
            to={{
                pathname: `/${target_name}/create`,
                state: { record: { [resource_field_name]: resource_val } },
            }}
            className={classes.link}
        >
            追加<AddIcon />
        </Button>
    ) : null;
};

export default BtnCreateNew;
