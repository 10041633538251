import * as React from 'react';
import {
  useTranslate,
  required,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';
import ServiceReferenceInput from '../service/ReferenceInput';
import { useApolloClient } from '@apollo/client';
import { GET_DATA_SERVICE, GET_SERVICE_BY_ID } from './gql';

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

const NFrom =  (props: any) => {
  const { editForm } = props;
  return <Container>
    <SectionTitle label={editForm ? "invoice_detail.edit" : "invoice_detail.add_new"} />
    <Separator />
    <Grid container justify='center' spacing={2}>
      {Fields.map((d, idx) => {
        if (d.type === 'hidden') {
          return <></>
        }
        if (d.name === 'service_id') {
          return <Grid item xs={4} key={idx}>
            <ServiceReferenceInput
              autoFocus
              validate={[required()]} />
          </Grid>
        } else if (d.type === 'virtual_reference') {
          return <></>
        } else {
          return <Grid item xs={4} key={idx}>
            <InputRenderer editForm = {editForm} source='invoice_detail' data={d} />
          </Grid>
        }
      })}
    </Grid>
  </Container>;
};

export default NFrom;