import * as React from 'react';
import {FC} from 'react';
import {
    Datagrid,
    DateField,
    downloadCSV,
    Filter,
    List,
    ReferenceField,
    SearchInput,
    TextField,
    useTranslate
} from 'react-admin';
import ActionsField from './Actions';
import StatusField from './StatusField';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import {FilterProps} from "../../types";

const exporter = (items:any) => {
    const itemsForExport = items.map((item:any) => {
        return [item.name,item.description,item.action,item.title, item.body,item.active,items[0].client_id  ? item.client_id : ''];
    });
    const csv = '\uFEFF' + convertToCSV({
        data: itemsForExport,
        encoding: 'UTF-8 BOM',
        fields: ['名前','説明','アクション','タイトル','サンプルデータ','アクティブフラグ',items[0].client_id ? 'マルチテナント用' : ''],
    });
    downloadCSV(csv, 'template');
};


const EmailFilter: FC<Omit<FilterProps, 'children'>> = props => {
    return (
        <Filter {...props}>
            <SearchInput source="name" alwaysOn />
        </Filter>
    )
};

const EmailList = (props: any) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            filters={<EmailFilter/>}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            exporter={exporter}
            empty={<ListEmpty />}
            title={translate('menu.email_template.email_template_list')}
        >
            <Datagrid optimized rowClick="edit">
                <TextField source="name" label={translate('email_template.name')}/>
                <TextField source="description" label={translate('email_template.description')}/>
                <TextField source="title" label={translate('email_template.title')}/>
                <DateField source="created_at" type="date" />
                <ReferenceField source="user_group_id" reference="user_group" label={translate('email_template.user_group')}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="action" label={translate('email_template.action')}/>
                <StatusField source="active" />
                <ActionsField/>
            </Datagrid>
        </List>

    );
};

export default EmailList;
