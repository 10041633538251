import React from 'react';
import {
  ReferenceInput,
  useTranslate,
  AutocompleteInput,
} from 'react-admin';
import { ProcessNmasterName, ProcessNmasterNamePayment } from '../../utils/Utils'; 
import { ProcessNmaster } from "../../utils/Utils";

var _ = require('lodash');



const filterToQuery = (searchText: any) => {  
  return { n_number: searchText }
}

const ServiceReferenceInput = (props: any) => {
  const translate = useTranslate();
  const rate_type = props["rate_type"];
  return <ReferenceInput
    variant="outlined"
    fullWidth
    disabled = {(rate_type && rate_type === 1) ? 'disabled': ''}
    label={translate('service.refer_input')}
    source="service_id"
    reference="service_reference_view"
    filterToQuery={filterToQuery}
    {...props}>
    <AutocompleteInput
    optionText={(r: any) =>
      Boolean(r?.n_number) ? `${ProcessNmasterNamePayment(r?.n_number)} ${r?.name}` : ''
     } 
    resettable={true}
  />
  </ReferenceInput>
}

export default React.memo(ServiceReferenceInput);