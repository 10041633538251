const DATA = [
  { name: 'n_master_id', type: 'reference', reference: 'n_master', searchField: '', options: [], nullable: false, editable: true, export: true, import: true },
  { name: 'n_facility_number', type: 'textinput', placeholder: '1', nullable: false, editable: true, export: true, import: true },
  { name: 'name', type: 'textinput', placeholder: '渋谷施設', nullable: false, editable: true, export: true, import: true },
  { name: 'name_furigana', type: 'textinput', placeholder: 'シブヤシセツ', nullable: true, editable: true, export: true, import: true },
  { name: 'postcode', type: 'textinput', placeholder: '999-9999', nullable: false, editable: true, export: true, import: true },
  { name: 'address', type: 'textinput', placeholder: '東京都渋谷区９９－９９', nullable: false, editable: true, export: true, import: true },
  { name: 'contact_person', type: 'textinput', placeholder: 'テスト 一郎', nullable: false, editable: true, export: true, import: true },
  { name: 'contact_person_furigana', type: 'textinput', placeholder: 'テスト', nullable: true, editable: true, export: true, import: true },
  { name: 'contact_person_dept', type: 'textinput', placeholder: '総務', nullable: true, editable: true, export: true, import: true },
  { name: 'contact_person_title', type: 'textinput', placeholder: '専務', nullable: true, editable: true, export: true, import: true },
  { name: 'contact_phone', type: 'textinput', placeholder: '09-0000-9999', nullable: true, editable: true, export: true, import: true },
  { name: 'contact_fax', type: 'textinput', placeholder: '09-0000-0001', nullable: true, editable: true, export: true, import: true },
  { name: 'contact_email', type: 'textinput', placeholder: 'test@test.com', nullable: true, editable: true, export: true, import: true },
  { name: 'note', type: 'textinput', multiline: true, nullable: true, editable: true, export: true, import: true },
  { name: 'created', type: 'datetime', nullable: true, editable: false, export: true, import: true },
  { name: 'created_by', type: 'hidden', nullable: true, editable: true, export: true, import: true },
]

export default DATA;