import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    DateField,
    downloadCSV,
    Filter,
    List,
    SearchInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import { FilterProps } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';

const exporter = (items: any) => {
    const itemsForExport = items.map((item: any) => {
        return [item.id, item.code, item.name];
    });
    const csv = '\uFEFF' + convertToCSV({
        data: itemsForExport,
        encoding: 'UTF-8 BOM',
        fields: ['代理店マスタID', '販売店コード', '販売店名'],
    });
    downloadCSV(csv, 'reseller');
};

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="code,name" alwaysOn />
            <TextInput source="id@_eq" label={translate('reseller.id')} alwaysOn />
        </Filter>
    )
};

const NList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={exporter}
            actions={<ListActions to='reseller' />}
            empty={<ListEmpty />}
            title={translate('menu.reseller.list')}
        >
            <CustomizableDatagrid rowClick="edit">
                <TextField source="id" label={translate('reseller.id')} />
                <TextField source="code" label={translate('reseller.code')} />
                <TextField source="name" label={translate('reseller.name')} />
                <TextField source="zip_code" label={translate('reseller.zip_code')} />
                <TextField source="address" label={translate('reseller.address')} />
                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
