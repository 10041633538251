import * as React from 'react';
import {
  useTranslate,
  required,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';
import GeneratePdfBtn from './GeneratePdfBtn';
import NMasterReferenceInput from '../n_master/ReferenceInput';
import InvoicingReferenceInput from '../invoicing/ReferenceInput';
import NFacilityMasterRI from '../n_facility_master/ReferenceInput';
import NoticeChangeRF from '../notice_change/ReferenceInput';

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;
const requiredValidate = [required()];

const NFrom = (props: any) => {
  const { editForm } = props;
  return <Container>
    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
      <SectionTitle label={editForm ? "invoice.edit" : "invoice.add_new"} />
      {/* {editForm && <GeneratePdfBtn {...props} />} */}
    </Box>
    <Separator />
    <Grid container justify='center' spacing={2}>
      {Fields.map((d, idx) => {
        if (d.name === 'invoicing_id') {
          return <Grid item xs={4} key={idx}>
            <InvoicingReferenceInput
              autoFocus
              validate={requiredValidate} />
          </Grid>
        } else if (d.name === 'n_master_id') {
          return <Grid item xs={4} key={idx}>
            <NMasterReferenceInput
              autoFocus
              validate={requiredValidate}
            />
          </Grid>
        } else if (d.name === 'n_facility_master_id') {
          return <Grid item xs={4} key={idx}>
            <NFacilityMasterRI
              autoFocus
              validate={ d.nullable ? [] : requiredValidate}
            />
          </Grid>
        } else if (d.name === 'notice_change_id') {
          return <Grid item xs={4} key={idx}>
            <NoticeChangeRF validate={requiredValidate} key={idx} />
          </Grid>
        } else if (d.type === 'virtual_reference') {
          return <></>
        } else {
          return <Grid item xs={4} key={idx}>
            <InputRenderer source='invoice' data={d} />
          </Grid>
        }
      })}
    </Grid>
  </Container>;
};

export default NFrom;