import * as React from 'react';
import {FC} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {useTranslate} from 'react-admin';
import {stringify} from 'query-string';

import users from '../users';
import {FieldProps, Import} from '../../types';

const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const LinkToRelatedResults: FC<FieldProps<Import>> = ({ record }) => {
    const translate = useTranslate();
    const classes = useStyles();
    return record && record.success_count > 0 ? (
        <Button
            size="small"
            color="primary"
            component={Link}
            onClick={ (e: any) => { e.stopPropagation();} }
            to={{
                pathname: `/${record.to}`,
                search: stringify({
                    page: 1,
                    perPage: 25,
                    sort: 'id',
                    order: 'DESC',
                    filter: JSON.stringify({ import_id: record.id }),
                }),
            }}
            className={classes.link}
        >
            <users.icon className={classes.icon} />
            {translate('import.results')}
        </Button>
    ) : null;
};

export default LinkToRelatedResults;
