import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
  TextField
} from 'react-admin';
import { ProcessNFacility, ProcessNmaster, ProcessNmasterNamePayment } from "../../utils/Utils";


const CustomField = (props: any) => {
  const { record } = props;
  return <span>{ProcessNmasterNamePayment(record.n_number)}</span>;
}
const CustomReferField = (props: any) => {
  return <>
    <CustomField {...props} />
  </>
}
CustomReferField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
};

export default CustomReferField;