import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    DateField,
    downloadCSV,
    Filter,
    List,
    TextField,
    usePermissions,
    useTranslate,
    TextInput,
    ReferenceField,
    BooleanField,
    SelectField,
    SelectInput,
    NumberField,
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import { FilterProps } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import NMasterReferenceField from '../n_master/ReferenceField';
import InvoicingReferenceField from '../invoicing/ReferenceField';
import NFacilityMasterRF from '../n_facility_master/ReferenceField';
import ConvertToPdfBtn from './GeneratePdfBtn';
import DownloadPdfBtn from './DownloadPdfBtn';
import { ProcessNFacility, ProcessNmaster } from "../../utils/Utils";



const exporter = async (items: any,fetchRelatedRecords:any) => {
    var data:any = items
    await fetchRelatedRecords(items, 'n_master_id', 'n_master').then((n_master:any) => {
        const data_company = items.map((item: any) => (
            {
                ...item,
                n_master_id: `N${ProcessNmaster(item.n_master_id)}`,
                company_name: n_master[item.n_master_id].name,
        }
        ));   
        data = data_company;
    });
    await fetchRelatedRecords(data, 'n_facility_master_id', 'n_facility_master').then((facility:any) => {
        const data_facility = data.map((item: any) => ({
                ...item,
                n_number: item.n_facility_master_id ? `${ProcessNmaster(item.n_master_id)}-${ProcessNFacility(facility[item.n_facility_master_id]?.n_facility_number)}` : ''
        }));   
        data = data_facility;
    });
    const itemsForExport = data.map((item: any) => {
        return [item.company_name,item.id, item.year, item.month, item.n_master_id, item.n_number, item.invoicing_id, item.issued,
        item.total_amount, item.schedule_amount, item.confirmed, item.confirmed_by, item.note, item.created, item.created_by];
    });
    const csv = '\uFEFF' + convertToCSV({
        data: itemsForExport,
        encoding: 'UTF-8 BOM',
        fields: ['会社名','請求書ID', '年', '月', 'N親番号', 'N番号', '請求先ID', '請求書発行日', '請求金額合計', '請求予定金額', '確認日', '確認ユーザーID', '備考', '登録日', '登録ユーザーID'],
    });
    downloadCSV(csv, 'invoice');
};

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {/* {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props} />} */}
            <ConvertToPdfBtn {...props}/>
            {/* <DownloadPdfBtn /> */}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            {Fields.map(f => {
                if (f.name === 'year') {
                    return <SelectInput source="year@_eq" choices={f.options} label={translate('invoice.year')} alwaysOn />
                }

                if (f.name === 'month') {
                    return <SelectInput source="month@_eq" choices={f.options} label={translate('invoice.month')} alwaysOn />
                }

                return null;
            })}
        </Filter>
    )
};

const NList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const module = 'invoice';

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'payment_date', order: 'DESC'}}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons/>}
            pagination={<ListPagination/>}
            exporter={exporter}
            actions={<ListActions to='invoice' />}
            empty={<ListEmpty showImport={false} />}
            title={translate('invoice.menu_list')}
        >
            <CustomizableDatagrid rowClick="edit">
                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat } = f || {}
                    if (type === 'textinput') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'reference') {
                        if (name === 'n_master_id') {
                            return <NMasterReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference} />
                        } else if (name === 'n_master_name') {
                            return <ReferenceField label={translate('n_master.name')} source="n_master_id" reference='n_master'>
                                <TextField source="name" />
                            </ReferenceField>
                        } else if (name === 'invoicing_id') {
                            return <InvoicingReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference} />
                        } else if (name === 'n_facility_master_id') {
                            return <NFacilityMasterRF label={translate(`${module}.${name}`)} source={name} reference={reference} />
                        } else {
                            return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }   
                    }

                    if (type === 'virtual_reference') {
                        if (name === 'n_master_name') {
                            return <ReferenceField label={translate('n_master.name')} source="n_master_id" reference='n_master'>
                                <TextField source="name" />
                            </ReferenceField>
                        }
                        if (name === 'n_facility_master_name') {
                            return <ReferenceField label={translate('n_facility_master.name')} source="n_facility_master_id" reference='n_facility_master'>
                                <TextField source="name" />
                            </ReferenceField>
                        }
                    }

                    if (type === 'date' || type === 'datetime') {
                        return <DateField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }

                    return <></>
                })}

                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
