import CloudUpload from '@material-ui/icons/CloudUpload';

import ImportCreate from './ImportCreate';
import ImportList from "./ImportList";

export default {
    create: ImportCreate,
    list: ImportList,
    show: ImportList,
    icon: CloudUpload,
};
