import * as React from 'react';
import {
  ReferenceManyField,
  TextField,
  ReferenceField,
  Datagrid,
  Show,
  DateField,
  useTranslate,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import ServiceReferenceField from '../service/ReferenceField';
import BtnCreateNew from '../components/BtnCreateNew'

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h4" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const NoticeChanges = (props: any) => {
  const translate = useTranslate();

  return <>
    <SectionTitle label='service.notice_changes' />
    <Box>
      <BtnCreateNew
        resource_field_name='service_id'
        resource_val={parseInt(props.id)}
        target_name='notice_change' />
    </Box>
    <Box className="formContent full100">
      <Show actions={<></>} component="div" title={" "}  {...props} >
        <ReferenceManyField
          addLabel={false}
          reference="notice_change"
          target="service_id"
        >
          <Datagrid optimized rowClick="edit">
            <TextField source="id" label={translate('notice_change.id')} />
            <ServiceReferenceField label={translate('service.refer_field')} source={'service_id'} reference={'service'} />
            <DateField source="issued" type="date" label={translate('notice_change.issued')} locales="ja-JP" />
            <TextField source="note" label={translate('notice_change.note')} />
            <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
            <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
              <TextField source="full_name" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Show>
    </Box>
  </>
}

export default NoticeChanges;