import * as React from 'react';
import {FC} from 'react';
import {Client, FieldProps} from '../../types';
import {EditButton} from 'react-admin';

const ActionsField: FC<FieldProps<Client>> = ({ record, basePath, resource }) =>
    record ? (
        <span>
            <EditButton basePath={basePath} source={resource} record={record}/>
            {/* <DeleteButton label=""/> */}
        </span>
    ) : null;

export default ActionsField;

