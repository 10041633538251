import * as React from 'react';
import {Fragment, useCallback} from 'react';
import {Filter, List, SearchInput, useTranslate} from 'react-admin';
import classnames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import {Drawer} from '@material-ui/core';
import {Route, RouteChildrenProps, useHistory} from 'react-router-dom';
import ImportDataGrid from './ImportDataGrid';
import ImportLinkDetail from './ImportLinkDetail';
import ListActions from '../components/actions';
import ListPagination from "../components/ListPagination";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
        width:'395px'
    },
}));

interface IObject {
    [key: string]: string;
}

const ImportFilter = (props: any) => {
    const translate = useTranslate();
    return <Filter {...props}>
        <SearchInput 
        source="target_name"
        alwaysOn />
    </Filter>
};

const ImportList = (props: any) => {
    const classes = useStyles();
    const history = useHistory();
    const translate = useTranslate();
    const handleClose = useCallback(() => {
        history.push('/import');
    }, [history]);

    return (
        <div className={classes.root}>
            <Route path="/import/:id">
                {({ match }: RouteChildrenProps<{ id: string }>) => {
                    const isMatch = !!(
                        match &&
                        match.params &&
                        match.params.id !== 'show'
                    );

                    return (
                        <Fragment>
                            <List
                                {...props}
                                className={classnames(classes.list, {
                                    [classes.listWithDrawer]: isMatch,
                                })}
                                pagination={<ListPagination />}
                                filters={<ImportFilter />}
                                sort={{ field: 'id', order: 'DESC' }}
                                perPage={25}
                                actions={<ListActions to='user' />}
                                title={translate('import.list')}
                            >
                                <ImportDataGrid
                                    {...props}
                                    selectedRow={
                                        isMatch
                                            ? parseInt(
                                                (match as any).params.id,
                                                10
                                            )
                                            : undefined
                                    }
                                />
                            </List>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {isMatch ? (
                                    <ImportLinkDetail
                                        id={(match as any).params.id}
                                        onCancel={handleClose}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        </Fragment>
                    );
                }}
            </Route>
        </div>
    );
};

export default ImportList;
