import * as React from 'react';
import {FC, memo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FieldProps} from '../../types';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
}));

interface Props extends FieldProps {
    titlePage?: string;
}

const EditField: FC<Props> = ({ record, titlePage }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            {titlePage}ID {record.id} 編集
        </div>
    ) : null;
};

EditField.defaultProps = {
    source: 'last_name',
};

export default memo<Props>(EditField);
