import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  useNotify
} from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import { useApolloClient } from "@apollo/client";
import {
  FETCH_PAYMENTS_DATA,
  FETCH_UNPAID_INVOICE_DETAILS,
  UPDATE_PAYMENT,
  UPDATE_INVOICE_DETAIL,
  BATCH_UPDATE_INVOICE_DETAIL
} from './gql';
import moment from 'moment';


let count = 0;
let errCount = 0;

const BulkUpdateBtn = (props: any) => {
  const translate = useTranslate();
  const apolloClient = useApolloClient();
  const [isConverting, setIsConverting] = useState(false);
  const notify = useNotify();
  const { selectedIds } = props;
  const totalCount: number = selectedIds ? selectedIds.length : 0;

  const fetchPayments = async () => {
    const { selectedIds } = props;
    const { data: { payment } } = await apolloClient.query({
      query: FETCH_PAYMENTS_DATA,
      fetchPolicy: 'network-only',
      variables: {
        payment_ids: selectedIds,
      }
    });

    if (payment && payment.length > 0) {
      payment.forEach((p: any) => {
        updateInvoiceDetail(p);
      });
    }
  }

  const fetchInvoiceDetails = async (nMasterId: number, facilityNumber: number) => {
    const { data: { invoice_detail } } = await apolloClient.query({
      query: FETCH_UNPAID_INVOICE_DETAILS,
      fetchPolicy: 'network-only',
      variables: {
        nMasterId,
        facilityNumber,
      }
    });

    return invoice_detail;
  }

  const updateInvoiceDetail = async (data: any)=>{
    const { id:paymentId, n_number, payment_method_name, payment_received, payment_received_amount } = data;
    if (!n_number || !payment_received) {
      console.log('skip!');
      errCount++;
    } else {
       // nmaster, facility number
      const nNumberStrs = n_number.split('-');
      const nMasterId = parseInt(nNumberStrs[0].substring(1));
      const facilityNumber = nNumberStrs.length > 1 ? parseInt(nNumberStrs[1]) : 1;

      if (!nMasterId || !facilityNumber) {
        console.log("invalid parameters");
        errCount++;
      } else {
        console.log("Parsed: ", nMasterId, facilityNumber); 
        const objs = {
          nMasterId, 
          facilityNumber, 
          paymentId,
          paymentDate: payment_received,
          paymentAmount: payment_received_amount,
          paymentMethod: payment_method_name,
        };

        fetchInvoiceDetailAndUpdateData(objs);
      }
    }
  }

  const fetchInvoiceDetailAndUpdateData = async (data: any) => {
    const {
      nMasterId, 
      facilityNumber, 
      paymentId,
      paymentDate,
      paymentAmount,
      paymentMethod
    } = data;
    const invoiceDetails= await fetchInvoiceDetails(nMasterId, facilityNumber);
    if (invoiceDetails.length === 0 ) {
      console.log("Invoice detail does not exist!")
      errCount++;
    } else {
      // console.log("InvoiceDetails: ", invoiceDetails);
      // update Invoice detail

      let updateObjs: any[] = [];
      let receivedAmount = paymentAmount;
      const pDate = moment(paymentDate);
      let targetMonth = pDate.month()+1+1; // 入金日の次月
      const targetYear = targetMonth > 12 ? pDate.year()+1 : pDate.year();
      targetMonth = targetMonth > 12 ? 1 : targetMonth;
      invoiceDetails.forEach((i: any) => {
        if (i.invoice_amount <= 0) { return };
        if ((receivedAmount + i.payment_received_amount) >= i.invoice_amount) {
          let obj = {
            ...i,
            payment_received_date: paymentDate,
            payment_received_amount: i.invoice_amount,
            payment_method_text: paymentMethod,
            payment_received_flg: true,
            status: 4, //入金済み
            payment_received_month: targetMonth,
            payment_received_year: targetYear,
          };
          delete obj.__typename;
          updateObjs.push(obj)
          receivedAmount = receivedAmount - i.invoice_amount + i.payment_received_amount;
        } else if (receivedAmount > 0) {
          // 入金不足
          let obj = {
            ...i,
            payment_received_date: paymentDate,
            payment_received_amount: receivedAmount + i.payment_received_amount,
            payment_method_text: paymentMethod,
            payment_received_flg: false,
            payment_received_month: targetMonth,
            payment_received_year: targetYear,
          };
          delete obj.__typename;
          updateObjs.push(obj)
          receivedAmount = 0;
        } else {
          return;
        }
      })

      console.log("Update InvoiceDetails: ", updateObjs);


      const { data:response } = await apolloClient.mutate({
        mutation: BATCH_UPDATE_INVOICE_DETAIL,
        variables: {
          objects: updateObjs,
        }
      })
      if (response) {
        const {insert_invoice_detail: {returning}} = response;
        if (returning) {
          count++;
          await updatePayment(paymentId);
        } else {
          errCount++;
        }
      }
    }

    // check finish updating
    if (count + errCount >= totalCount) {
      setIsConverting(false);
      if (count > 0) {
        notify(`${count}${translate('payment.update_invoice_detail_success')}`);
      }
      if (errCount > 0) {
        notify(`${errCount}${translate('payment.update_invoice_detail_failed')}`, 'error');
      }
    } 

  }

  const updatePayment = async (id: number) => {
    // 取り込み済み
    const { data } = await apolloClient.mutate({
      mutation: UPDATE_PAYMENT,
      variables: {
        id
      }
    })
  }

  const handleClick = async () => {
    count = 0;
    errCount = 0;
    setIsConverting(true);
    fetchPayments();
  }

  return <Button
    disabled={isConverting}
    variant="contained"
    color="secondary"
    onClick={handleClick}>
    {isConverting && <CircularProgress
      color="secondary"
      size={18}
      thickness={2}
      style={{ marginRight: 10 }} />}
    {translate('payment.update_invoice_detail')}
  </Button>
}

BulkUpdateBtn.propTypes = {
  selectedIds: PropTypes.array.isRequired,
}

BulkUpdateBtn.defaultProps = {
  selectedIds: []
}

export default BulkUpdateBtn;