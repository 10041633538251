import * as React from 'react';
import { useEffect } from 'react';
import {cloneElement} from 'react';
import {
    Button,
    CreateButton,
    ExportButton,
    sanitizeListRestProps,
    TopToolbar,
    useListContext,
    usePermissions,
    useTranslate,
} from 'react-admin';
import PublishIcon from '@material-ui/icons/Publish';

const ListActions = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const {
        className,
        exporter,
        filters,
        maxResults,
        to,
        setFilter,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();

    useEffect(() => {
        if (filterValues && setFilter) {
            setFilter(filterValues)
        }
    }, [filterValues]);

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {(permissions === 'admin' || permissions === 'client_admin' || resource !== 'user') && resource !== 'import' && resource !== 'n_facility_master'
            && <CreateButton basePath={basePath} />}
            {resource !== 'import' && 
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={3000}
                label={translate('common.export')}
            />}
            {(permissions === 'admin' || permissions === 'client_admin') && resource !== 'import' && resource !== 'invoice' 
            && resource !== 'reseller' && resource !== 'user' && resource !== 'user_group' && resource !== 'evidence' 
            && resource !== 'incentive' && resource !== 'notice_change' &&
            <Button
                onClick={() => { window.location.href=`/#/import/create?table=${to}`}}
                label={translate('import.label')}
            >
                <PublishIcon />
            </Button>
            }
        </TopToolbar>
    );
};
export default ListActions;