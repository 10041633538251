import { gql } from '@apollo/client';
import moment from 'moment';

const FETCH_CURR_INVOICE_DETAILS = gql`
  query QueryInvoiceDetails($invoice_month: Int!, $invoice_year: Int!, $no_confirmed: Boolean, $payment_method: Int, $annual_payment: Boolean, $start_date: date, $end_date: date,$n_master: String) {
    invoice_detail_confirm(where: {
      invoice_year: {_eq: $invoice_year}, 
      invoice_month: {_eq: $invoice_month}, 
      confirmed: {_is_null: $no_confirmed}, 
      payment_method: {_eq: $payment_method}, 
      annual_payment: {_eq: $annual_payment},
      status: {_in: [1, 2]}, n_number_search: {_ilike: $n_master}}) {
        adjustment_amount
        adjustment_reason
        confirmed
        contract_number
        discount_type
        initial_service_fee_amount
        expected_invoice_amount
        invoice_amount
        type
        invoice_month
        invoice_year
        payment_received_amount
        rate_type
        sales_month
        sales_year
        service_id
        service_usage
        status
        step1_reseller_rate
        step2_reseller_rate
        step3_reseller_rate
        annual_payment
        payment_method
        service_start_date
        discount_amount
        discount_amount_sales
        invoice_detail_id
        month
        n_facility_number
        n_master_id
        name
        sales_amount
        year
        master_id
        master_name
        service_discounts
        discount_end_date
        discount_start_date
    }
  }
  `;

  const FETCH_PRE_ALL_INVOICE_DETAILS = gql`
  query QueryInvoiceDetails($invoice_month: Int!, $invoice_year: Int!, $payment_method: Int, $annual_payment: Boolean, $start_date: date, $end_date: date) {
    invoice_detail_confirm(where: {
      confirmed: {_neq: null}, 
      payment_method: {_eq: $payment_method}, 
      annual_payment: {_eq: $annual_payment},
      type: {_neq: 1},
      _or: [
        {invoice_year: {_lt: $invoice_year}},
        {_and: [{invoice_year: {_eq: $invoice_year}}, {invoice_month: {_lt: $invoice_month}}]}
      ],

      status: {_in: [1, 2]}}) {
        payment_received_amount
        invoice_amount
        type
        service_id
    }
  }
  `;

// const FETCH_PREV_MONTH_INVOICE_DETAILS = gql`
//   query QueryInvoiceDetails($invoice_month: Int!, $invoice_year: Int!, $payment_method: Int, $annual_payment: Boolean, $service_ids: [Int!]) {
//     invoice_detail(where: {
//       invoice_month: {_eq: $invoice_month}, 
//       invoice_year: {_eq: $invoice_year},
//       service_id: {_in: $service_ids},
//       status: {_in: [1, 2]},
//       service: {payment_method: {_eq: $payment_method}, annual_payment: {_eq: $annual_payment}}}) {
//       expected_invoice_amount
//       discount_amount
//       discount_type
//       adjustment_amount
//       invoice_amount
//       payment_received_amount
//       service_usage
//       service_id
//       confirmed
//       sales_service_invoice_detail {
//         sales_amount
//       }
//     }
//   }`;

  const FETCH_PREV_MONTH_INVOICE_DETAILS = gql`
  query QueryInvoiceDetails($invoice_month: Int!, $invoice_year: Int!,$payment_method: Int, $annual_payment: Boolean, $service_ids: [Int!]) {
    invoice_detail_confirm(where: {
      invoice_month: {_eq: $invoice_month}, 
      invoice_year: {_eq: $invoice_year}, 
      service_id: {_in: $service_ids},
      status: {_in: [1, 2, 3]},
      payment_method: {_eq: $payment_method}, 
      annual_payment: {_eq: $annual_payment}
    }) {
      expected_invoice_amount
      discount_amount
      discount_amount_sales
      discount_type
      adjustment_amount
      initial_service_fee_amount
      type
      invoice_amount
      payment_received_amount
      service_usage
      service_id
      confirmed
      sales_amount
    }
  }
  `;

const UPDATE_CONFIRMED_AT = gql`
  mutation UpdateConfirmedAt($id: Int!, $dt: timestamptz, $user_id: Int, $invoice_amount: numeric, $service_usage: numeric, $scheduled_implementation_date: date, $status: Int, $step1ResellerAmount: numeric, $step2ResellerAmount: numeric, $step3ResellerAmount: numeric) {
    update_invoice_detail_by_pk(pk_columns: {id: $id}, 
      _set: {
        confirmed: $dt, 
        confirmed_by: $user_id,
        invoice_amount: $invoice_amount,
        service_usage: $service_usage,
        scheduled_implementation_date: $scheduled_implementation_date,
        status: $status
        step1_reseller_amount: $step1ResellerAmount
        step2_reseller_amount: $step2ResellerAmount
        step3_reseller_amount: $step3ResellerAmount
      }) {
      confirmed
    } 
  }`;

const UPDATE_SERVICE = gql`
mutation UpdateConfirmedAt($service_id: Int!, $initial_service_fee_amount: numeric) {
  update_service_by_pk(pk_columns: {id: $service_id}, 
    _set: {
      initial_service_fee_amount: $initial_service_fee_amount
    }) {
      status
  } 
}`;

const BATCH_UPDATE_CONFIRMED_AT = gql`
  mutation BatchUpdateConfirmedAt($objects: [invoice_detail_insert_input!]!) {
    insert_invoice_detail(
      objects: $objects,
      on_conflict: {
        constraint: invoice_detail_pkey,
        update_columns: [confirmed, confirmed_by, invoice_amount, service_usage, scheduled_implementation_date, status]
      }
    ) {
      returning {
        id
      }
    } 
  }`;

// 請求書作成
const INSERT_ACTION_JOURNAL = gql`
  mutation POST_ACTION($object: action_journal_insert_input!) {
    action: insert_action_journal(objects: [$object]) {
      returning {
        response
      }
    }
  }`;

const UPDATE_INVOICE_DETAIL_STS = gql`
  mutation UpdateInvoiceDetailStatus($ids: [Int!], $sts: Int!){
    update_invoice_detail(where: {id: {_in: $ids}}, _set: {status: $sts}) {
      returning {
        id
      }
    }
  }`;

const Providers = {
  fetchCurrInvoiceDetails: async (invoiceMonth, invoiceYear, confirmed, invoiceType,n_master,apolloClient) => {
    let year, month;
    if(invoiceMonth === 1){
      month = 12;
      year = invoiceYear - 1;
    } else {
      month = invoiceMonth - 1;
      year = invoiceYear;
    }
    
    return await apolloClient.query({
      query: FETCH_CURR_INVOICE_DETAILS,
      fetchPolicy: 'network-only',
      variables: {
        invoice_month: invoiceMonth,
        invoice_year: invoiceYear,
        no_confirmed: !confirmed,
        payment_method: invoiceType === '3' ? 2 : 1,
        annual_payment: invoiceType === '2' ? true : false,
        start_date: moment(`${year}-${month}`).startOf('month').format('YYYY-MM-DD'),
        end_date: moment(`${year}-${month}`).endOf('month').format('YYYY-MM-DD'),
        n_master: `%${n_master}%`
        // has_diff: diffCheck === 0 ? null : (diffCheck === 1 ) ? true : false,
      }
    });
  },

  fetchPreAllInvoiceDetails: async (invoiceMonth, invoiceYear, invoiceType, apolloClient) => {
    let year, month;
    if(invoiceMonth === 1){
      month = 12;
      year = invoiceYear - 1;
    } else {
      month = invoiceMonth - 1;
      year = invoiceYear;
    }

   
    return await apolloClient.query({
      query: FETCH_PRE_ALL_INVOICE_DETAILS,
      fetchPolicy: 'network-only',
      variables: {
        invoice_month: invoiceMonth,
        invoice_year: invoiceYear,
        payment_method: invoiceType === '3' ? 2 : 1,
        annual_payment: invoiceType === '2' ? true : false,
        start_date: moment(`${year}-${month}`).startOf('month').format('YYYY-MM-DD'),
        end_date: moment(`${year}-${month}`).endOf('month').format('YYYY-MM-DD'),
        // has_diff: diffCheck === 0 ? null : (diffCheck === 1 ) ? true : false,
      }
    });
  },

  fetchPrevMonthInvoiceDetails: async (invoiceMonth, invoiceYear, serviceIds, invoiceType, apolloClient) => {
    return await apolloClient.query({
      query: FETCH_PREV_MONTH_INVOICE_DETAILS,
      fetchPolicy: 'network-only',
      variables: {
        invoice_month: invoiceMonth > 1 ? invoiceMonth - 1 : 12,
        invoice_year: invoiceMonth > 1 ? invoiceYear : invoiceYear - 1,
        payment_method: invoiceType === '3' ? 2 : 1,
        annual_payment: invoiceType === '2' ? true : false,
        service_ids: serviceIds,
      }
    });
  },
  
  updateInvoiceDetailStatus: async (ids, apolloClient) => {
    const res = await apolloClient.mutate({
      mutation: UPDATE_INVOICE_DETAIL_STS,
      variables: { 
        ids,
        sts: 2,
      }
    });
    const okIds = res?.data?.update_invoice_detail?.returning;
    return okIds && okIds.length > 0;
  },
  batchUpdateConfirmedAtFlag: async (objects, apolloClient) => {
    return await apolloClient.mutate({
      mutation: BATCH_UPDATE_CONFIRMED_AT,
      variables: {
        objects
      }
    });
  },
  updateConfirmedAtFlag: async (id, dt, userId, invoiceAmount, serviceUsage, apolloClient, scheduledImplementationDate=null, status, step1ResellerAmount, step2ResellerAmount, step3ResellerAmount) => {
    return await apolloClient.mutate({
      mutation: UPDATE_CONFIRMED_AT,
      variables: { 
        id,
        dt,
        user_id: userId,
        invoice_amount: invoiceAmount,
        service_usage: serviceUsage,
        scheduled_implementation_date: scheduledImplementationDate,
        status,
        step1ResellerAmount,
        step2ResellerAmount,
        step3ResellerAmount
      }
    });
  },

  updateService: async (serviceId, initialServiceFeeAmount, apolloClient) => {
    return await apolloClient.mutate({
      mutation: UPDATE_SERVICE,
      variables: { 
        service_id: serviceId,
        initial_service_fee_amount: initialServiceFeeAmount
      }
    });
  },

  createInvoice: async (userId, ids, apolloClient) => {
    const object = {
      "action_id": "create_invoice",
      "request": { "user_id": userId }
    };
    const res = await Providers.updateInvoiceDetailStatus(ids, apolloClient);
    if (res) {
      const res2 = await apolloClient.mutate({
        mutation: INSERT_ACTION_JOURNAL,
        variables: { 
          object
        }
      });
      if (res2){
        const returning = res2?.data?.action?.returning;
        if (returning && returning.length > 0) {
          return returning[0].response?.status === 'success'
        }
      }
    } else {
      console.log("error when updating invoice detail's status");
    }

    return false;
  }
}

export default Providers;