import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    DateField,
    Filter,
    List,
    SearchInput,
    TextField,
    usePermissions,
    useTranslate,
    ReferenceField,
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import { FilterProps } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import exporter from '../../utils/exporter';
import Dataset from './dataset';
import ServiceReferenceField from '../service/ReferenceField';

const ExportableFields = [{name: 'id'}, ...Dataset.filter(d => d.export)];

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="note" alwaysOn />
        </Filter>
    )
};

const NList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'notice_change', ExportableFields, translate,fetchRelatedRecords)}
            actions={<ListActions to='notice_change' />}
            empty={<ListEmpty />}
            title={translate('menu.notice_change.list')}
        >
            <CustomizableDatagrid rowClick="edit">
                <TextField source="id" label={translate('notice_change.id')} />
                <ServiceReferenceField label={translate('service.refer_field')} source={'service_id'} reference={'service'} />
                <DateField source="issued" type="date" label={translate('notice_change.issued')} locales="ja-JP" />
                <TextField source="note" label={translate('notice_change.note')} />
                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
