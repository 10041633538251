import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
} from 'react-admin';
import DownloadBtn from './DownloadButton';

const CustomReferField = (props: any) => {
  return <ReferenceField {...props}>
    <DownloadBtn />
  </ReferenceField>
}

CustomReferField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
};

export default CustomReferField;