import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import {
  required,
  TextInput,
  useTranslate,
  Loading,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import { gql, useApolloClient } from '@apollo/client';
import NMasterReferenceInput from '../n_master/ReferenceInput';

const GET_N_FACILITY_NUMBER = gql`
  query getNextId($id: Int!) {
    n_facility_master_aggregate(where: {n_master_id: {_eq: $id}}) {
      aggregate {
        max {
          n_facility_number
        }
      }
    }
  }`;

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

const NFacilityMasterFrom = (props: any) => {
  const [nFacilityNumber, setNFacilityNumber] = useState(null); 
  const [loading, setLoading] = useState(true);
  const translate = useTranslate();
  const { editForm, record: {n_master_id} } = props;
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (!editForm && Boolean(n_master_id)) {
      fetchNFacilityNumber(n_master_id);
    } else {
      setLoading(false);
    }
  }, [])

  const fetchNFacilityNumber = async (n_master_id: number) => {
    const { data } = await apolloClient.query({
      query: GET_N_FACILITY_NUMBER,
      fetchPolicy: 'network-only',
      variables: {
        id: n_master_id
      }
    });

    if (data) {
      const {n_facility_master_aggregate: {aggregate: {max: {n_facility_number}}}} = data;
      setNFacilityNumber(n_facility_number ? n_facility_number + 1 : 1);
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  return <Container>
    <SectionTitle label={editForm ? "n_facility_master.edit" : "n_facility_master.add_new"} />
    <Separator />
    <Grid container spacing={3}>
      {/* row 1 */}
      <Grid item xs={4}>
        <NMasterReferenceInput
          autoFocus
          validate={requiredValidate}
          disabled={(editForm || props.record.n_master_id) ? true : false}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="n_facility_number"
          disabled={(editForm || nFacilityNumber) ? true : false}
          label={translate('n_facility_master.n_facility_number')}
          fullWidth
          defaultValue={nFacilityNumber ? nFacilityNumber : 1}
          variant="outlined"
          format={(v: any) =>  {
            if(v < 9){
              return `0${v}`
            }
            return v;
          } } 
        />
      </Grid>

      <Grid item xs={4}>
        <TextInput
          source="name"
          placeholder="テスト工場"
          validate={requiredValidate}
          label={translate('n_facility_master.name')}
          fullWidth
          variant="outlined"
        />
      </Grid>

      {/* row 2 */}
      <Grid item xs={4}>
        <TextInput
          source="name_furigana"
          placeholder="テストコウジョウ"
          label={translate('n_facility_master.name_furigana')}
          fullWidth
          variant="outlined"
        />
      </Grid>

      <Grid item xs={4}>
        <TextInput
          source="postcode"
          validate={requiredValidate}
          label={translate('n_facility_master.postcode')}
          placeholder="999-9999"
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="address"
          validate={requiredValidate}
          placeholder="東京都渋谷区９９－９９"
          label={translate('n_facility_master.address')}
          fullWidth
          variant="outlined"
        />
      </Grid>

      {/* row 3 */}
      <Grid item xs={4}>
        <TextInput
          source="contact_person"
          validate={requiredValidate}
          placeholder="テスト 一郎"
          label={translate('n_facility_master.contact_person')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="contact_person_furigana"
          placeholder="テスト"
          label={translate('n_facility_master.contact_person_furigana')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="contact_person_dept"
          placeholder="総務"
          label={translate('n_facility_master.contact_person_dept')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="contact_person_title"
          placeholder="専務"
          label={translate('n_facility_master.contact_person_title')}
          fullWidth
          variant="outlined"
        />
      </Grid>

      {/* row 4 */}
      <Grid item xs={4}>
        <TextInput
          source="contact_phone"
          placehoder="09-0000-9999"
          label={translate('n_facility_master.contact_phone')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="contact_fax"
          placeholder="09-0000-0001"
          label={translate('n_facility_master.contact_fax')}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="contact_email"
          placeholder="test@test.com"
          label={translate('n_facility_master.contact_email')}
          fullWidth
          variant="outlined"
        />
      </Grid>

      {/* row  5 */}
      <Grid item xs={4}>
        <TextInput
          source="note"
          multiline
          label={translate('n_facility_master.note')}
          fullWidth
          variant="outlined"
        />
      </Grid>
    </Grid>
  </Container>;
};

export default NFacilityMasterFrom;
const test = () => {
  return 100
}