import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import CustomReferenceInput from '../components/CustomReferenceInput';
import Fields from './dataset';
import { ProcessNmaster } from "../../utils/Utils";

const QCFields = Fields.filter(f => !f.nullable);

const filterToQuery = (searchText: any) => {
  if (!searchText) {
    return {};
  }
  
  const nNumber = parseInt(searchText.replace('N', ''));
  if (!isNaN(nNumber)) {
    return { id: nNumber }
  }
  return { name: searchText }
}

const ReferenceInput = (props: any) => {
  const translate = useTranslate();

  return <CustomReferenceInput
    source="n_master_id"
    reference="n_master"
    label={translate('n_master.refer_input')}
    filterToQuery={filterToQuery}
    optionText={(r: any) => Boolean(r.id) ? `N${ProcessNmaster(r.id)}${r.name}` : ''}
    resource={'n_master'}
    quickCreateFields={QCFields}
    {...props} />
}

export default React.memo(ReferenceInput);