import * as React from 'react';
import {FC} from 'react';
import {FieldProps, Import} from '../../types';
import {useTranslate} from 'react-admin';

const StatusField: FC<FieldProps<Import>> = ({ record }) =>  {
    const translate = useTranslate();
    return record ? (
        <span>
            {(function () {
                switch (record.status) {
                    case 'INIT':
                        return `${translate('import.status_init')}`;
                    case 'IMPORTING':
                        return `${translate('import.status_IMPORTING')}`;
                    case 'FINISHED':
                        return `${translate('import.status_FINISHED')}`;
                    default:
                        return record.status;
                }
            })()}
        </span>
    ) : null;
};
export default StatusField;


        

