import * as React from 'react';
import {FC, Fragment} from 'react';
import {
    DateField,
    DateInput,
    Filter,
    List,
    SearchInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput, 
    ReferenceField,
    SelectField,
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import {FilterProps} from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import { BillingTypes, PAYMENT_METHODS, ACCOUNT_TYPES } from './Form'
import NMasterReferenceField from '../n_master/ReferenceField';
import exporter from '../../utils/exporter';
import Dataset from './dataset';

const ExportableFields = [{name: 'id'}, ...Dataset.filter(d => d.export)];

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props}/>}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="company_name,billing_name" alwaysOn />
            <TextInput source="postcode" label={translate('invoicing.postcode')} />
        </Filter>
    )
};

const NList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'invoicing', ExportableFields, translate,fetchRelatedRecords)}
            actions={<ListActions to='invoicing' />}
            empty={<ListEmpty />}
            title={translate('menu.invoicing.list')}
        >
            <CustomizableDatagrid rowClick="edit">
                {/* <TextField source="id" label={translate('invoicing.id')} /> */}
                <NMasterReferenceField source="n_master_id" label={translate('invoicing.n_master_id')} reference='n_master' />
                <ReferenceField label={translate('n_master.name')} source="n_master_id" reference='n_master'>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="company_name" label={translate('invoicing.company_name')} /> 
                <TextField source="postcode" label={translate('invoicing.postcode')} /> 
                <TextField source="address" label={translate('invoicing.address')} /> 
                <TextField source="billing_name" label={translate('invoicing.billing_name')} /> 
                <TextField source="billing_dept" label={translate('invoicing.billing_dept')} /> 
                <TextField source="billing_title" label={translate('invoicing.billing_title')} /> 
                <TextField source="billing_phone" label={translate('invoicing.billing_phone')} /> 
                <TextField source="billing_fax" label={translate('invoicing.billing_fax')} /> 
                <TextField source="billing_email" label={translate('invoicing.billing_email')} /> 
                <SelectField source="billing_type" choices={BillingTypes} label={translate('invoicing.billing_type')} /> 
                <TextField source="bank_code" label={translate('invoicing.bank_code')} /> 
                <TextField source="bank_name" label={translate('invoicing.bank_name')} /> 
                <TextField source="bank_branch_code" label={translate('invoicing.bank_branch_code')} /> 
                <TextField source="bank_branch_name" label={translate('invoicing.bank_branch_name')} /> 
                <TextField source="account_name" label={translate('invoicing.account_name')} /> 
                <SelectField source="account_type" choices={ACCOUNT_TYPES} label={translate('invoicing.account_type')} /> 
                <TextField source="account_number" label={translate('invoicing.account_number')} /> 
                <DateField source="transferable_month" type="date" label={translate('invoicing.transferable_month')} locales="ja-JP" />
                <SelectField source="payment_method" choices={PAYMENT_METHODS} label={translate('invoicing.payment_method')} /> 
                <TextField source="note" label={translate('invoicing.note')} /> 
                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
