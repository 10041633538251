import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { ProcessNFacility, ProcessNmaster } from "./Utils"; 

const exporter = async (
  items: any,
  resource: string,
  fields: any,
  translate: any,
  fetchRelatedRecords: any,
  dataProvider?: any
) => {

  var data: any = items;
  var fieldName = fields.map((f: any) => f.name);
  if (items[0].n_master_id && resource !== "n_master") {
    //add data column company name
    await fetchRelatedRecords(items, "n_master_id", "n_master").then(
      (company: any) => {
        const data_company = items.map((item: any) => ({
          ...item,
          company_name: company[item.n_master_id] ? company[item.n_master_id]?.name : '',
        }));
        fieldName.splice(0, 0, "company_name");
        data = data_company;
      }
    );
  }
  if (items[0].n_facility_master_id && resource !== "n_facility_master") {
    //add data column facility name
    await fetchRelatedRecords(
      items,
      "n_facility_master_id",
      "n_facility_master"
    ).then((n_facility_master: any) => {
      const data_facility = data.map((item_facility: any) => ({
        ...item_facility,
        facility_name:
          n_facility_master[item_facility.n_facility_master_id] ? n_facility_master[item_facility.n_facility_master_id].name : "",
      }));
      fieldName.splice(0, 0, "facility_name");
      data = data_facility;
    });
  }
  // custom service exporting data
  if (items[0].n_facility_master_id && resource === "service") {
    await fetchRelatedRecords(items, "n_facility_master_id", "n_facility_master").then(
      (facilities: any) => {
        const data_service = data.map((item: any) => ({
          ...item,
          n_number: `N${ProcessNmaster(item.n_master_id)}-${ProcessNFacility(facilities[item.n_facility_master_id] ? facilities[item.n_facility_master_id].n_facility_number : '')}`,
        }));
        fieldName.splice(0, 0, "n_number");
        data = data_service;
      }
    );
  }

  // create header
  const itemsForExport = data.map((item: any) => {
    return fieldName.map((f: any) => item[f]);
  });
  const csv =
    "\uFEFF" +
    convertToCSV({
      data: itemsForExport,
      encoding: "UTF-8 BOM",
      fields: fieldName.map((f: any) =>
        translate(`${resource}.csv_fields.${f}`)
      ),
    });
  return downloadCSV(csv, resource);
};

export default exporter;

export const GetAllFacilityMaster = async (dataProvider: any) => {
  let data_facility = await dataProvider.getList("n_facility_master", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "id", order: "ASC" },
    filter: {},
  });
  return data_facility;
};
export const GetAllNMaster = async (dataProvider: any) => {
  let data_n_master = await dataProvider.getList("n_master", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "id", order: "ASC" },
    filter: {},
  });
  return data_n_master;
};
