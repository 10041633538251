import {TranslationMessages} from 'ra-core';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    app_name: 'HANOI CORE',
    import: {
        id: 'ID',
        label: 'Import',
        list: 'Imports',
        to: 'To table',
        total_row: 'Total Row',
        line: 'Line',
        success_count: 'Success',
        fail_count: 'Fail',
        proceed_count: 'Proceed',
        is_create_new: 'Create New',
        input_link: 'Input Link',
        result_link: 'Result Link',
        results: 'Results',
        status: 'status',
        download: 'Download',
        error_report: 'Error Report',
        user: 'User',
        company: 'Company',
        maps: 'CONFIRM FIELD MAPPINGS',
        status_init:'INIT',
        status_IMPORTING:'IMPORTING',
        status_FINISHED:'FINISHED',
        download_template: 'Download Template',
        drop_file: 'Drop a file here or click to select',
        csv: 'csv file',
        uploading: 'uploading'
    },
    contract: {
        title: 'Contract',
        client_id: 'Client ID',
        id: 'ID',
        bid_id: 'Bid ID',
        status: ' Status',
        user_id: 'User',
        company_id: 'Company',
        agreed_company_id: 'Agreed comany',
        agreed_by_company: 'Agreed date',
        agreed_user_id: 'Agreed user',
        agreed_by_user: 'User Agreed date',
        meeting_date_1: 'Meeting date 1',
        meeting_start_time_1: 'Start time 1',
        meeting_end_time_1: 'End time 1',
        meeting_date_2: 'Meeting date 2',
        meeting_start_time_2: 'Start time 2',
        meeting_end_time_2: 'End time 2',
        meeting_date_3: 'Meeting date 3',
        meeting_start_time_3: 'Start time 3',
        meeting_end_time_3: 'End time 3',
        face_to_face: 'Face-to-face',
        web: 'Web interview',
        upload_file_path: 'Application URL',
        upload_file_name: 'Filename',
        download_application: 'Download Application',
        action: "操作",
    },
    common:{
        btn_add: 'ADD',
        msg_delete: 'Deleted item is successfully.',
        btn_disagree:'Disagree',
        btn_agree:'Agree',
        btn_save:'Save',
        btn_update:'Update',
        btn_delete: 'Delete',
        btn_create:'Create',
        lbl_search:'Search',
        msg_add_success: 'Saved item is successfully.',
        msg_confirm_not_match: "Confirm password doesn't match",
        msg_update_success: 'Updated item is successfully.',
        msg_no_record:'No result matching the specified search criteria was found.',
        ms_active_error:'This link has been activated or expired, please try again later',
        ms_register_success:'This account has been successfully registered. Please check your email to activate your account',
        ms_active_success:'This account has been successfully activated. Please click the login button to login the system',
        msg_login_failed: 'Something is wrong with your login or password. Please enter again email or password',
        configuration: 'Settings',
        configClient: 'Settings Client',
        theme_light:'Light',
        theme_dark: 'Dark',
        language:'Language',
        theme:'Themes',
        btn_cancel:'Cancel',
        minute: "Minute",
        hours:"Hours",
        required: "required",
        msg_delete_confirm: 'Are you sure you want to delete this element?',
        msg_delete_fail: 'Error: Element not deleted',
        msg_title_delete:'Delete item',
        status_active:'Active',
        status_inactive:'InActive',
        could_not: "Could not create:",
        no_record:" No record yet.",
        import:"Create one or import from a file",
        btn_import:"Import",
        status:'Status',
        minLenght:'Must be 6 characters at least',
        emailValidate: "Must be a valid email",
        contract_usage_zero: "Contract usage should not be 0",
        total_base_usage_zero: "Total base usage should not be 0",
        mistalk_import:"The Supplier ID when importing is an error. Please re-enter.",
        btn_ok:'OK'   
    },
    role: {
        title: 'Role',
        client_admin: 'Client Admin',
        vendor_admin: 'Vendor Admin',
        vendor_manager: 'Vendor Manager',
        vendor_employee: 'Vendor Employee',
        admin: 'Admin',        
        user: 'User',
        owner: 'Owner',
    },
    menu:{
        user: {
            user_menu:'User (Vendor)',
            user_list:'User List',
            user_add:'User Add',
            user_import:'User Import',
        },
        user_favorite: {
            user_favorite_menu:'User Favorite',
            user_favorite_list:'User Favorite List',
            user_favorite_add:'User Favorite Add',
            user_favorite_import:'User Favorite Import',
            user_favorite_edit:"Edit User Favorite"
        },       
        user_notification: {
            user_notification_menu:'User Notification',
            user_notification_list:'User Notification List',
            user_notification_add:'User Notification Add',
            user_notification_import:'User Notification Import',
            user_notification_edit:"Edit User Notification",
        },           
        client: {
            client_menu:'Client',
            client_list:'Client List',
            client_add:'Client Add'
        },
        user_group: {
            user_group_menu:'User Group',
            user_group_list:'User Group List',
            user_group_add:'User Group Add'
        },
        email_template: {
            email_template_menu:'Email Template',
            email_template_list:'List Email Template',
            email_template_add:'Add Email Template'
        },
        settings:{
            setting_client: 'Settings',
            change_password: 'Change Password',
            profile: "My Profile"
        },
        opportunity:{
            opportunity_menu: 'Opportunity',
            list_opportunity: 'List Opportunity',
            add_opportunity: 'Add Opportunity',
            edit_opportunity: 'Edit Opportunity'
        },
        company:{
            company_menu: 'Company',
            list_company: 'List Company',
            add_company: 'Add Company',
            edit_company: 'Edit Company'
        },
        vendor:{
            vendor_menu: 'Vendor',
            list_vendor: 'List Vendor',
            add_vendor: 'Add Vendor',
            edit_vendor: 'Edit Vendor'
        },
        vendor_department:{
            vendor_department_menu: 'Vendor Department',
            vendor_department_list: 'List Vendor Department',
            add_vendor_department: 'Add Vendor Department',
            edit_vendor_department: 'Edit Vendor Department',
            filter_vendor_department: 'Departments'
        },
        facility:{
            facility_menu: 'Facility',
            list_facility: 'List Facility',
            add_facility: 'Add Facility',
            edit_facility: 'Edit Facility',
            filler_facility: 'List Facility',
        },
        bill: {
            bill_menu: 'Bill',
            list_bill: 'List bill',
            add_bill: 'Add bill',
            edit_bill: 'Edit bill',
            filler_bill: 'List bill',
            title_add_bill: 'Edit electricity bill'
        },  
        bid: {
            bid_menu: 'Bid',
            list_bid: 'List bid',
            add_bid: 'Add bid',
            edit_bid: 'Edit bid'
        },  
        message: {
            message_menu: 'Message',
            list_message: 'List message',
            add_message: 'Add message',
            edit_message: 'Edit message'
        },
        reseller: {
            reseller_menu: 'Reseller',
            list_reseller: 'List reseller',
            add_reseller: 'Add reseller',
            edit_reseller: 'Edit reseller'
        },
        owner: {
            owner_menu: 'Owner',
            list_owner: 'List owner',
            add_owner: 'Add owner',
            edit_owner: 'Edit owner'
        },
        supplier: {
            supplier_menu: 'Supplier',
            list_supplier: 'List supplier',
            add_supplier: 'Add supplier',
            edit_supplier: 'Edit supplier'
        },
        supplier_plan:{
            supplier_plan_menu: 'Supplier plan',
            list_supplier_plan: 'List Supplier plan',
            add_supplier_plan: 'Add supplier plan',
            edit_supplier_plan: 'Edit supplier plan'
        },
        bill_discount: {
            bill_discount_menu: 'Bill discount',
            list_bill_discount: 'List bill discount',
            add_bill_discount: 'Add bill discount',
            edit_bill_discount: 'Edit bill discount'         
        },
        question: {
            question_menu: 'Question',
            list_question: 'List Question',
            add_question: 'Add Question',
            edit_question: 'Edit Question'         
        },
        bid_condition: {
            bid_condition_menu: 'Bid condition',
            list_bid_condition: 'List Bid condition',
            add_bid_condition: 'Add Bid condition',
            edit_bid_condition: 'Edit Bid condition',
            filler_bid_condition: 'List Bid condition',
        }, 
        bid_condition_usage: {
            bid_condition_usage_menu: 'Bid usage',
            list_bid_condition_usage: 'List Bid usage',
            add_bid_condition_usage: 'Add Bid usage',
            edit_bid_condition_usage: 'Edit Bid usage',
            add_list:'Add Bid usage'
        },    
        bid_condition_discount: {
            bid_condition_discount_menu: 'Bid discount',
            list_bid_condition_discount: 'List Bid discount',
            add_bid_condition_discount: 'Add Bid discount',
            edit_bid_condition_discount: 'Edit Bid discount',
            add_list:'Add Bid discount'
        },
        contract: {
            contract_menu: 'Contract',
            list_contract: 'List Contract',
            add_contract: 'Add Contract',
            edit_contract: 'Edit Contract'
        },
    },    
    login: {
        title_login: 'Login',
        title_new_user: 'New User',        
        forgot_password: 'Forgot Password?',        
        full_name: 'Full Name',        
        email_address: 'Email Address',        
        password: 'Password',
        create_acount:'Create your account',
        error_password:'Something is wrong with your login or password :(',
        success_login: 'Login successfully'
    },
    forgot_password:{
        title:'Forgot Password',
        description:'Complete your contact email address below to receive your security code.',
        email:'Email',
        back_login:'Back to Login',
        btn_send: 'Send',
        mgs_recover_password:'This account has forgotten your password successfully. Please check login',
        mgs_recover_success:'You have successfully recovered your password. Please check login ',
    },
    activeUser:{
        title: 'ACTIVE USER',
        success:'Active user successfully',
        error:'There is an error while active this user, please contact Administrator',
        btn_login: 'Go to Login'
    },
    reset: {
        success: 'Reset password successfully',
        error: 'Reset password error',
        reset: 'RESET PASSWORD',
        back_to_login: 'Back to Login',
        password:'Password',
        confirm_password:'Confirm Password'
    },
    copyright:'© 2014-2020 MK1 Techonology. All rights reserved.',
    user_list: {
        id:'ID',
        name:'Name',
        gender:'Gender',
        owner_id: 'Owner',
        full_name:'Full name',
        first_name:'First Name',
        last_name:'Last Name',
        gender_male:'Male',
        gender_female:'Female',
        phone:'Phone',
        gender_orther:'Other',
        group:'Group',
        email:'Email',
        client:'Client',
        admin:'Admin',
        role:'Role',
        status:'Status',
        status_active:'Active',
        status_inactive:'InActive',
        password:'Password',
        is_admin:'Is Admin',
        url_image: 'Images',
        confirm_password:'Confirm Password',
        action:'Action',
        create_new:'Create New Account',
        update_user_title:'Update Account',
        msg_delete:'Are you sure that you want to delete user?',
        msg_will_delete:'User will be deleted.',
        msg_sure_delete:'Are you sure that you want to delete user?',
        last_login_at:"Last login",
        phone_verified:"Phone Verified",
        email_verified:"Email Verified",
        title :"Title",
        company :"Company",
        tab_profile: "Profile",
        tab_password: 'Password',
        first_name_kana:"First name kana",
        last_name_kana:"Last name kana",
        department:"Department",
        company_id:"Company",
        vendor_id: "Vendor",
        vendor_department_id: 'Vendor Department',
        full_name_kana:"Full name Kana"
    },
    user_favorite: {
        id:'ID',
        client_id:'Client ',
        user_id:'User ID',
        opportunity_id:'Opportunity',
        created_at: 'Create at',
        updated_at: 'Updated at',
        action: "Action" 
    },
    user_notification: {
        id:'ID',
        client_id:'Client ',
        user_id:'User ID',
        user_group_id:'User Group ID',
        status: 'Status',
        title:'Title',
        body:'body',
        action: "Action" 
    },    
    client_list: {
        id:'ID',
        name:'Name',
        credit:'Credit',
        domain:'Domain',
        from_email: 'From Email',
        mailer_host : 'Mailer host',
        mailer_port: 'Mailer Port',
        mailer_secure: 'Mailer secure',
        mailer_user :'Mailer User',
        mailer_password :'Mailer Password',
        twilio_sid:'Twilio SID',
        twilio_token: 'Twilio Token',
        twilio_phone :'Twilio Phone',
        s3_bucket: "S3 Bucket",
        s3_bucket_import: "S3 Bucket Import",
        s3_bucket_bill: "S3 Bucket Bill",
        s3_key: "S3 Key",
        s3_region: "S3 Region",
        s3_secret: "S3 Secret",
        auto_verify: "Auto verify",
        status:'Status',
        status_active:'Active',
        status_inactive:'InActive',
        action:'Action',
        create_new:'Create New Client',
        update_title:'Client Update',
        Logo:'Logo',
        is_active:'Is Active',
        msg_delete:'Are you sure that you want to delete client?',
        msg_will_delete:'Client will be deleted.',
        msg_sure_delete:'Are you sure that you want to delete client?',
    },
    user_group: {
        title: 'Add User Group',
        id:'ID',
        name:'Name',
        client:'Client',
        status:'Status',
        status_active:'Admin',
        status_inactive:'User',
        action:'Action',
        create_new:'Create New User Group',
        update_title:'Update User Group',
        is_admin:'Is Admin',
        msg_delete:'Are you sure that you want to delete user group?',
        msg_will_delete:'User Group will be deleted.',
        msg_sure_delete:'Are you sure that you want to delete user group?',
        update_user_title:'Update User Group'
    },
    email_template: {
        title: 'Title',
        id: 'ID',
        is_active: 'Active',
        callback_url: "Callback URL",
        title_add: 'Create New Email Template',
        title_edit: 'Edit Email Template',
        add_success: 'Add Email Template success',        
        name: 'Email Name',        
        description: 'Description',        
        action: 'Action',        
        subject: 'Subject',
        user_group:'User Group',
        sample_data:'Sample Data',
        body: 'Body',
        create: 'Create Template',
        save: 'SAVE',
        msg_name_required: 'Templates name is required',
        msg_description_required: 'Description is required',
        otp:'OPT',
        forgot: 'Forgot',
        confirm: 'Confirm'
    },    
    opportunity:{
        title:'Opportunity',
        id:'ID',
        client_id :'Client ID',
        company_id :'Company',
        owner_id: 'Owner',
        min_bid_amount: 'Min bid amount',
        due :'Deadline',
        tags :'Tags',
        action:"Action",
        btn_addQuestion:'Add Question',
        status:'Status',
        note:'Note',
        created_at: 'Created at',
        end_project: 'End date',
    },
    company:{
        title:'Company',
        id:'ID',
        client_id :'Client ID',
        company_cd :"Company CD",
        name :'Name',
        industry :'Industry',
        action: "Action",
        btn_addFac: "Add Facility",
        facility_count: "Number of facilities",
        logo_url:"Logo Url",
        post_code :"Post code",
        pref:"Pref",
        address1:"Address1",
        address2:"Address2",
        phone:"Phone",
        has_opportunity :"Has Opportunity",
    },
    vendor:{
        title:'Vendor',
        id:'ID',
        client_id :'Client ID',
        name :'Name',
        action: "Action",
        post_code :"Post code",
        pref:"Pref",
        address1:"Address1",
        address2:"Address2",
        phone:"Phone",
        btn_add_department: "Add Department",
    },
    vendor_department:{
        title:'Vendor Department',
        id:'ID',
        client_id :'Client ID',
        name :'Name',
        vendor :'Vendor',
        action: "Action",
        btn_add_department: "Add Department",
        update_title:"Update Vendor Department"
    },
    facility:{
        id: "ID",
        client_id: "Client ID",
        company_id : "Company",
        contract_number : "Contract number ",
        name: "Name",
        area_search_value: "Area",
        pref : "Pref ",
        address : "Address",
        provider: "Provider",
        contract_type: "Contract type ",
        usage_total: "Usage Total",
        contract_usage: "Contract usage",
        annual_load_factor_p: "Annual factor(p)",
        annual_load_factor_h : "Annual factor(h)",
        option_1 : "Option 1",
        option_2 : "Option 2",
        option_3: "Option 3",               
        option_4: "Option 4",               
        action: "Action",
        contract_start_date: "Contract start date",
        addBill:"Add Bill",
        bill_count: "Number of Bill",
        title_basic: "Basic information",
        title_auction: "Power Intelligence",
        title_discount:"Discount information",
        title_list_bill:"Electricity bill",
        plan_id: "Plan ID",
        list_bill: "List bill",
        supplier_id: 'Supplier ID',
        reseller: "Reseller ID",
        min_bid_amount: 'Min bid amount',
    },
    bill: {
        id: "ID",
        client_id: "Client ID",
        facility_id: "Facility",
        year: "Year",
        month: "Month",
        load_factor_p : "Load factor(p)",
        load_factor_h: "Load factor(h)",
        total_usage: "Total usage",
        action: "Action",
        supplier_id: "Supplier ID",
        plan_id: "Plan ID",
        contract_number: "Contract number",
        option_1: "Option 1",
        option_2: "Option 2",
        option_3: "Option 3",
        tax: "Tax",
        tax_included: "Tax incldued",
        contract_usage: "Contract usage",
        base_price: "Base price",
        power_factor_p: "Power factor p",
        usage_1: "Usage 1",
        usage_2: "Usage 2",
        usage_3: "Usage 3",
        usage_4: "Usage 4",
        usage_5: "Usage 5",
        usage_6: "Usage 6",
        usage_7: "Usage 7",
        usage_8: "Usage 8",
        usage_9: "Usage 9",
        usage_10: "Usage 10",
        usage_price_1: "Usage price 1",
        usage_price_2: "Usage price 2",
        usage_price_3: "Usage price 3",
        usage_price_4: "Usage price 4",
        usage_price_5: "Usage price 5",
        usage_price_6: "Usage price 6",
        usage_price_7: "Usage price 7",
        usage_price_8: "Usage price 8",
        usage_price_9: "Usage price 9",
        usage_price_10: "Usage price 10",
        total_base: "Total base",
        total_base_usage: "Total base usage",
        total_discount: "Total discount",
        total: "Total",         
    },
    bid: {
        id: "ID",
        client_id: "Client ID",
        vendor_id: "Vendor",
        opportunity_id: "Opportunity",
        mode: "Mode",
        created: "Created",
        modified: "Modified",
        status: "Status",
        download_quote: 'Download Quote',
        deleted_at :'Deleted at',
        updated_at :'Updated at',
        created_at :'Created at',          
        action: "Action",
        upload_file_path:"Upload file path",   
        upload_file_name:"Upload file name",
        approved: 'Approved',
        approved_user_id: 'Approved',
        selected: 'Selected',
        selected_company_id: 'Selected Company',
        link_to_bid_condition: 'Bid condition list'
    },
    changePassword: {
        title:'Change Password',
        error:'Change password error',
        oldPassword: 'Old Password',
        new_password: 'New password',
        confirm_password: 'Confirm new password',
        btn_change_password:'Change Password',
        msg_complete: "Your password has been changed successfully! Please log out to use your new password"
    },
    message: {
        id: "ID",
        code: "Code",
        message : "Message",
        ja_message : "Japanese",
        action: "Action"          
    },
    reseller: {
        client_id: 'client ID',
        id: 'ID',
        name: 'Name',
        data: 'Data',
        created_at: 'Create at',
        status: 'Status',
        action: 'Action'
    },
    owner: {
        client_id: 'client ID',
        id: 'ID',
        name: 'Name',
        data: 'Data',
        created_at: 'Create at',
        status: 'Status',
        action: "Action" 
    },
    supplier: {
        client_id: 'client ID',
        id: 'ID',
        name: 'Name',
        data: 'Data',
        created_at: 'Create at',
        updated_at: 'Updated at',
        status: 'Status',
        deleted_at: 'Delete at',
        action: "Action" 
    },
    supplier_plan:{
        client_id :'Client ID',
        id :'ID',
        supplier_id :'Supplier ID',
        display_order :'Display order',
        edit_supplier_plan: 'Edit Supplier Plan',
        name :'Name',
        usage_1 :'Usage 1',
        usage_2 :'Usage 2',
        usage_3 :'Usage 3',
        usage_4 :'Usage 4',
        usage_5 :'Usage 5',
        usage_6: "Usage 6",
        usage_7: "Usage 7",
        usage_8: "Usage 8",
        usage_9: "Usage 9",
        usage_10: "Usage 10",
        usage_price_1 :'Usage price 1',
        usage_price_2 :'Usage price 2',
        usage_price_3 :'Usage price 3',
        usage_price_4 :'Usage price 4',
        usage_price_5 :'Usage price 5',
        usage_price_6: "Usage price 6",
        usage_price_7: "Usage price 7",
        usage_price_8: "Usage price 8",
        usage_price_9: "Usage price 9",
        usage_price_10: "Usage price 10",
        created_at :'Created at',
        updated_at :'Update at',
        status :'Status',
        deleted_at :'Deleted at',
        action: "Action" 
    },
    bill_discount: {
        client_id :'Client ID',
        id :'ID',
        bill_id :'Bill ID',
        type :'Type',
        other :'Other',
        amount :'Amount',
        created_at :'Created at',
        updated_at :'Update at',
        deleted_at :'Deleted at',
        action: "Action",
        'bill_discount[0]': "契約継続割引",      
        'bill_discount[1]': "長期契約割引",      
        'bill_discount[2]': "基本料金割引",      
        'bill_discount[3]': "法人特別割引(関西：特約割引)",      
        'bill_discount[4]': "大口割引額(九州：大規模割引)",      
        'bill_discount[5]': "その他割引ﾒﾆｭｰ",      
        'bill_discount[6]': "蓄熱調整契約(蓄熱調整割引）",      
        'bill_discount[7]': "電化厨房契約割引",      
        'bill_discount[8]': "オール電化(関西：総合電化割引)",      
        'bill_discount[9]': "夏季休日割引",      
        'bill_discount[10]': "その他",      
    },
    question:{
        client_id:"Client",
        id:"ID",
        user_id:"User",
        opportunity_id:"Opportunity id",
        facility_key:"Facility key",
        parent_id:"Parent id",
        answered_user_id:"Answered user id",
        title:"Title",
        question:"Question",
        answer:"Answer",
        created_at:"Created at",
        deleted_at:"Deleted at",
        updated_at:"Updated at",
        action: "Action",
        read_flg: "Read flag",
        complete_flg: "Complete flag",
    },
    bid_condition: {
        id: "ID",
        client_id: "Client",
        user_id: "User",
        bid_id: "Bid",
        contract_type: "Contract type",
        is_custom: "Is Custom",
        name: "Name",
        base: "Base",
        contract_term: "Contract term",
        contract_cancel_by: "Contract cancel by",
        penalty: "Penalty",
        penalty_note: "Penalty note",
        created_at :'Created at',  
        updated_at :'Updated at',
        deleted_at:'Delete at',
        custom_check_1: "Custom check 1",
        custom_check_2: "Custom check 2",
        custom_check_3: "Custom check 3",
        penalty_fee :"Penalty fee",
        facility_id : "Facility ID",
        status: "Status",
        action: "Action",
        link_usage: "Link Usages",
        link_discount: "Link Discounts"
    },   
    bid_condition_usage: {
        id: "ID",
        client_id: "Client",        
        bid_condition_id : "Bid condition",
        usage_1 :'Usage 1',
        usage_2 :'Usage 2',
        usage_3 :'Usage 3',
        usage_4 :'Usage 4',
        usage_5 :'Usage 5',
        import_id : "Import",
        start_time : "Start time",
        end_time: "End time",
        created_at :'Created at', 
        action: "Action",
    },
    bid_condition_discount: {
        id: "ID",
        client_id: "Client",        
        bid_condition_id : "Bid condition",
        type :'Type',
        other :'Other',
        target :'Target',
        value :'Value',
        unit :'Unit',
        import_id : "Import",
        created_at :'Created at', 
        action: "Action",
    }    
};

export default customEnglishMessages;
