import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    DateField,
    Filter,
    List,
    SearchInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput,
    ReferenceField,
    BooleanField,
    SelectField,
    NumberField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import { FilterProps } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import ActionsField from './Actions';
import NMasterReferenceField from '../n_master/ReferenceField';
import NFacilityMasterRF from '../n_facility_master/ReferenceField';
import exporter from '../../utils/exporter';
import Dataset from './dataset';
import ContractReferenceInput from '../contract/ReferenceInput';
const ExportableFields = [{name: 'id'}, ...Dataset.filter(d => d.export)];
const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput  source="company_name,plan_code,contract_number" alwaysOn />
            <ContractReferenceInput
              allowEmpty
              alwaysOn
              label={translate('service.contract_id')} />
        </Filter>
    )
};

const NList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const module = 'service';

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'created', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords?: any) => exporter(items, 'service', ExportableFields, translate,fetchRelatedRecords)}
            actions={<ListActions to='service' />}
            empty={<ListEmpty />}
            title={translate('menu.service.list')}
        >
            <CustomizableDatagrid rowClick="edit">
                <ActionsField label={translate('common.actions')} />
            
                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat } = f || {}
                    if (type === 'textinput') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'reference') {
                        if (name === 'n_master_id') {
                            return <NMasterReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference} />
                        } else if (name === 'n_facility_master_id') {
                            return <NFacilityMasterRF label={translate(`${module}.${name}`)} source={name} reference={reference} />
                        } else {
                            return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }
                    }

                    if (type === 'virtual_reference') {
                        if (name === 'n_master_name') {
                            return <ReferenceField label={translate('n_master.name')} source="n_master_id" reference='n_master'>
                                <TextField source="name" />
                            </ReferenceField>
                        }
                        if (name === 'n_facility_master_name') {
                            return <ReferenceField label={translate('n_facility_master.name')} source="n_facility_master_id" reference='n_facility_master'>
                                <TextField source="name" />
                            </ReferenceField>
                        }
                    }

                    if (type === 'date') {
                        return <DateField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)}  />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                        source={name}
                        choices={options}
                        label={translate(`${module}.${name}`)}
                        />
                    }

                    return <></>
                })}

                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;


