import {Action, Client, User} from "../../types";

export const buildPreview = (body: string, paramsString: string): string => {
    try {
        let params = JSON.parse(paramsString);
        let output = body;
        for (const [key, value] of Object.entries(params)) {
            output = output.split(`#{${key}}`).join(value as string)
        }
        return output
    } catch (e) {
        return ''
    }
};

export const buildActions = (user: User, client: Client) : Action[] => {
    let actions: Action[];
    actions = [
        {
            id: 'confirm',
            name: 'email_template.confirm',
            params: {
                url: `https://${client.domain}/confirm/xxxxx`,
                name: `${user.last_name} ${user.first_name}`,
            },
            body: `<p><h1>#{name}</h1></p><a href="#{url}">#{url}</a>`,
        },
        {
            id: 'forgot',
            name: 'email_template.forgot',
            params: {
                url: `https://${client.domain}/reset-password/xxxxx`,
            },
            body: `<p><h1>This is reset password URL:</h1></p><a href="#{url}">#{url}</a>`,
        },
        {
            id: 'otp',
            name: 'email_template.otp',
            params: {
                otp: `123456`,
            },
            body: `<p><h1>This is login OTP</h1></p><h2>#{otp}</h2>`,
        },
    ];
    return  actions;
};
