import * as React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  useListContext,
  useUpdateMany,
  useNotify,
  useRefresh,
  useUnselectAll,
  useResourceContext
} from 'react-admin';

const BulkUpdateBtn = (props: any) => {
  const { selectedIds } = useListContext(props);
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const resource = useResourceContext(props);
  const refresh = useRefresh();

  const [updateMany, { loading }] = useUpdateMany('invoice_detail', selectedIds, { invoice_id: props.invoiceId }, {
    onSuccess: () => {
      notify(
        'ra.notification.updated',
        'info',
        { smart_count: selectedIds.length },
        true
      );
      unselectAll(resource);
      refresh();
    },
    onFailure: (error: any) =>
      notify(
        typeof error === 'string'
          ? error
          : error.message || 'ra.notification.http_error',
        'warning',
        {
          _:
            typeof error === 'string'
              ? error
              : error && error.message
                ? error.message
                : undefined,
        }
      ),
    undoable: true,
  });

  return <Fragment>
    <Button
      label="今回の請求書にする"
      onClick={updateMany}
      disabled={loading}
      {...props} />
  </Fragment>
}

BulkUpdateBtn.propTypes = {
  invoiceId: PropTypes.number.isRequired,
}

export default BulkUpdateBtn;