import * as React from 'react';
import {
  Edit,
  FormDataConsumer,
  SimpleForm,
  useTranslate,
  ReferenceManyField,
  TextField,
  ReferenceField,
  Datagrid,
  Show,
  DateField,
} from 'react-admin';
import EditField from '../components/editField';
import UpdateToolbar from "../components/UpdateToolbar";
import NFrom from './Form';
import { Tabs, Tab, Button } from '@material-ui/core';
import TabPanel, { a11yProps } from '../components/TabPanel';
import EditHistory from '../edit_history';
import { Box, Typography } from '@material-ui/core';
import DownloadButton from '../evidence/DownloadButton';
import BtnCreateNew from '../components/BtnCreateNew'



const NEdit = (props: any) => {
  const translate = useTranslate()
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return <div>
    <Tabs
      value={value}
      onChange={handleChange}
      textColor="primary"
      centered
    >
      <Tab label={translate('n_facility_master.edit')}  {...a11yProps(0)}>
      </Tab>
      <Tab label={translate('common.edit_history')}  {...a11yProps(1)}>
      </Tab>
    </Tabs>
    <TabPanel value={value} index={0}>
      <Edit
        {...props}
        successMessage="common.msg_update_success"
        undoable={false}
        className="formContent"
        title={<EditField titlePage={translate('menu.n_facility_master.menu')} />}>
        <SimpleForm redirect="list" toolbar={<UpdateToolbar />} className="form">
          <FormDataConsumer>
            {(formDataProps: any) => (
              <NFrom {...formDataProps} editForm={true} />
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>

      {/* Evidence  */}
      <Separator />
      <Box className="formContent full100">
        <SectionTitle label="エビデンス一覧" />
        <Show actions={<PostShowActions ref_id={props.id} />} component="div" title={" "}  {...props} >
          <ReferenceManyField
            addLabel={false}
            reference="evidence"
            target="n_facility_master_id"
          >
            <Datagrid optimized rowClick="edit">
              <TextField source="id" label={translate('evidence.id')} />
              <DateField source="received" type="date" label={translate('evidence.received')} locales="ja-JP" />
              <TextField source="title" label={translate('evidence.title')} />
              <TextField source="note" label={translate('evidence.note')} />
              <TextField source="filename" label={translate('evidence.filename')} />
              <DownloadButton label={translate('evidence.filename')} />
              <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
              <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                <TextField source="full_name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Show>
      </Box>

    </TabPanel>
    <TabPanel value={value} index={1}>
      <EditHistory {...props} />
    </TabPanel>
  </div>;
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

const PostShowActions = (props: any) => {
  const {ref_id} = props;
  return Boolean(ref_id) ? <Box>
    <BtnCreateNew
      resource_field_name='n_facility_master_id'
      resource_val={parseInt(ref_id)}
      target_name='evidence' />
  </Box> : <></>
};

export default NEdit;
