import * as React from 'react';
import { FC, Fragment, useEffect, useState } from 'react';
import {
    DateField,
    Filter,
    List,
    TextField,
    usePermissions,
    useTranslate,
    SearchInput,
    ReferenceField,
    BooleanField,
    SelectField,
    SelectInput,
    NumberField,
    useAuthState,
    downloadCSV
} from 'react-admin';

import ListActions from '../components/actions';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import { FilterProps } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import ServiceReferenceField from '../service/ReferenceField';
import ServiceReferenceField2 from '../service/ReferenceField2';
import Dataset from './dataset';
import { useApolloClient } from '@apollo/client';
import { FETCH_SALES_SERVICE } from './gql';
import { ProcessNFacility, ProcessNmaster } from "../../utils/Utils";
import moment from 'moment';



const ExportableFields = Dataset.filter(d => d.export);

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    return (
        <Fragment>
            {(permissions === 'client_admin' || permissions === 'admin') && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};

const NFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput
                placeholder={translate('common.searchByNNumber')}
                source="sales_service_view@n_number._ilike" 
                parse={(v: string) => `%${v}%`} 
                format={(v: string) =>　Boolean(v) ? v.replaceAll('%','') : ''} 
                alwaysOn />
            {Fields.map(f => {
                if (f.name === 'year') {
                    return <SelectInput source="year@_eq" choices={f.options} label={translate('sales.year')} alwaysOn />
                }

                if (f.name === 'month') {
                    return <SelectInput source="month@_eq" choices={f.options} label={translate('sales.month')} alwaysOn />
                }

                return null;
            })}
        </Filter>
    )
};

const exporter = async (apolloClient: any, filter: any, translate: any) => {
    const result = await apolloClient.query({
        query: FETCH_SALES_SERVICE,
        fetchPolicy: 'network-only',
        variables: {
            month: filter['month@_eq'],
            year: filter['year@_eq'],
            service: filter['service@n_facility_master']
        }
    });

    const dataSalesService = result?.data?.sales;

    if (dataSalesService && dataSalesService.length > 0) {
        let fieldName = ExportableFields.map((f: any) => f.name)
        // add reseller's code to the csv file
        fieldName.splice(14, 0, 'reseller');
        fieldName.splice(16, 0, 'reseller_by_step_2_reseller_id');
        fieldName.splice(18, 0, 'reseller_by_step_3_reseller_id');
        const data = dataSalesService.map((element: any) => {
        
        const cost_confirmed_date = element.cost_confirmed ? moment(element.cost_confirmed).format('YYYY-MM-DD') : element.cost_confirmed;
        const confirmed_date =  element.confirmed ? moment(element.confirmed).format('YYYY-MM-DD') : element.confirmed;
            let newData = { 
                ...element,
                n_master_id: `N${ProcessNmaster(element?.service?.n_master?.id)}-${ProcessNFacility(element?.service?.n_facility_master?.n_facility_number)}`, 
                 n_master_name: element?.service?.n_master?.name,
                 reseller: element?.reseller?.code,
                 reseller_by_step_2_reseller_id: element?.resellerByStep2ResellerId?.code,
                 reseller_by_step_3_reseller_id: element?.resellerByStep3ResellerId?.code, 
                 cost_confirmed: cost_confirmed_date, 
                 confirmed: confirmed_date
            }
            delete newData.service
            return newData
        })
        
        const itemsForExport = data.map((item: any) => {
            return fieldName.map((f: any) => item[f])
        });
        const csv = '\uFEFF' + convertToCSV({
            data: itemsForExport,
            encoding: 'UTF-8 BOM',
            fields: fieldName.map((f: any) => translate(`sales.csv_fields.${f}`)),
        });
        downloadCSV(csv, 'sales');
    }
};

const NList = (props: any) => {
    const apolloClient = useApolloClient();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const module = 'sales';
    const [filter, setFilter] = useState();

    if (permissions === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<NFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={()=>exporter(apolloClient, filter, translate)}
            actions={<ListActions to='sales' setFilter={setFilter}/>}
            empty={<ListEmpty />}
            title={translate('sales.menu_list')}
        >
            <CustomizableDatagrid rowClick="edit">
                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat } = f || {}
                    if (type === 'textinput') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'reference') {
                        if (name === 'service_id') {
                            return <ServiceReferenceField label={translate('service.refer_field')} source={name} reference={reference} key={idx} />
                        } else {
                            return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference} key={idx}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }
                    }

                    if (type === 'virtual_reference') {
                        if (name === 'n_facility_master_name') {
                            return <ServiceReferenceField2 label={translate('service.refer_field2')} source={'service_id'} reference={reference} key={idx} />
                        }
                    }

                    if (type === 'date' || type === 'datetime') {
                        return <DateField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }

                    return <></>
                })}

                <DateField source="created" type="date" label={translate('common.created')} locales="ja-JP" />
                <ReferenceField label={translate('common.created_by')} source="created_by" reference="user">
                    <TextField source="full_name" />
                </ReferenceField>
            </CustomizableDatagrid>
        </List>

    );
};

export default NList;
