import * as React from 'react';
import {FC} from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    Create,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    usePermissions,
    useTranslate
} from 'react-admin';
import {Box, Typography} from '@material-ui/core';
import {CreateComponentProps} from '../../types';
import CreateToolbar from "../components/CreateToolbar";

const UserGroupCreate: FC<CreateComponentProps> = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const { permissions } = usePermissions();
    const onFailure = (error: any) => {
        notify(`${translate('common.could_not')} ${error.message}`, 'warning');
    };

    return (
        <Create className="formContent"
            onSuccess={() => {
                notify(`${translate('common.msg_add_success')}`, 'info');
            }}
            onFailure={onFailure}
            title={translate('menu.user_group.user_group_add')}
            undoable={false}
            {...props}
        >
            <SimpleForm className="form" redirect="show"
                        toolbar={<CreateToolbar />}
            >
                <SectionTitle label="user_group.title" />
                <Separator />
                {permissions === 'admin' &&
                    <ReferenceInput
                        source="client_id"
                        reference="client"
                        fullWidth
                        variant="outlined"
                        label={translate('user_group.client')}
                        filterToQuery={(searchText: any) => ({ name: searchText })}>
                        <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                }
                <TextInput
                    autoFocus
                    source="name"
                    validate={requiredValidate}
                    label={translate('user_group.name')}
                    fullWidth
                    variant="outlined"
                />
                {permissions === 'admin' &&
                <BooleanInput label={translate('user_group.is_admin')} source="is_admin" /> }
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default UserGroupCreate;
