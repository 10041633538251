import { gql } from '@apollo/client';
export const FETCH_SALES_SERVICE = gql`
  query QuerySalesService($month: Int, $year: Int, $service: n_facility_master_bool_exp) {
      sales(where: {month: {_eq: $month}, service: {n_facility_master: $service}, year: {_eq: $year}}, limit: 100000, order_by: {id: desc}) {
          adjustment_amount
          created
          created_by
          days
          discount_amount
          end_date
          expected_sales_amount
          id
          import_id
          locked
          month
          sales_amount
          sales_total_amount
          service_id
          start_date
          step1_reseller_id
          step1_reseller_amount
          step2_reseller_id
          step2_reseller_amount
          step3_reseller_id
          step3_reseller_amount
          total_incentive_amount
          cost_confirmed 
          cost_confirmed_by 
          confirmed_by 
          confirmed 
          type
          year
          resellerByStep3ResellerId {
              code
          }
          resellerByStep2ResellerId {
              code
          }
          reseller {
              code
          }
          service {
              n_facility_master {
                  n_facility_number
                  name
              }
              n_master {
                  name
                  id
              }
          }
      }
  }`;