import {TranslationMessages} from 'ra-core';
import japaneseMessages from '@bicstone/ra-language-japanese';

const customJapanMessages: TranslationMessages = {
    ...japaneseMessages,
    app_name: "日本電気サービス CRM",
    import: {
        id: 'ID',
        label: "インポート",
        list: '一覧',
        menu: 'インポート履歴',
        to: 'インポート先',
        total_row: '入力件数',
        success_count: '成功',
        line: '行番号',
        fail_count: '失敗',
        proceed_count: '処理数',
        is_create_new: '新しいレコード',
        input_link: 'インプットリンク',
        result_link: '結果のリンク',
        results: '結果',
        status: 'ステータス',
        download: 'ダウンロード',
        error_report: 'エラーレポート',
        user: 'ユーザー',
        company: '企業',
        maps: 'フィールドのマッピングを確認する',
        status_init: '初期化',
        status_IMPORTING: 'インポート中',
        status_FINISHED: '完了',
        download_template: 'インポートファイルのテンプレートをダウンロード',
        drop_file: 'ここにファイルをドロップまたはファイルを選択してください',
        csv: 'csvファイル',
        uploading: 'アップロード',
        created_at: '登録日',
    },
    role: {
        title: '権限',
        client_admin: 'クライアント管理者',
        vendor_admin: '管理者',
        vendor_manager: '責任者',
        vendor_employee: '一般',
        admin: '管理者',
        user: 'ユーザー',
        owner: '販売店',
    },
    common: {
        status: "ステータス",
        btn_add: "追加",
        msg_delete: 'アイテムが正常に削除されました',
        msg_delete_user: "アイテムの削除は成功しました",
        btn_disagree: "同意しない",
        btn_agree: "同意する",
        btn_save: "保存",
        btn_update: '保存する',
        btn_delete: '削除する',
        btn_create: "ユーザーの追加",
        lbl_search: "検索",
        msg_add_success: "アイテムの追加に成功しました",
        msg_update_success: "更新しました。",
        msg_confirm_not_match: "パスワードが一致しません。",
        msg_no_record: "指定された検索条件に一致する結果は見つかりませんでした",
        ms_active_error: "このリンクは有効化されました、または期限が切れています。しばらくしてからもう一度お試しください。",
        ms_register_success: "このアカウントは正常に登録されました。アカウントを有効にするために、メールをご確認ください",
        ms_active_success: "このアカウントは正常に有効化されました。ログインボタンをクリックしてシステムにログインしてください",
        msg_login_failed: "メールアドレスまたはパスワードが正しくありません。",
        configuration: 'アプリケーション設定',
        configClient: 'クライアント設定',
        theme_light: 'Light',
        theme_dark: 'Dark',
        language: '言語',
        theme: 'テーマ',
        btn_cancel: 'キャンセル',
        minute: "分",
        hours: "時間",
        required: "required",
        msg_delete_confirm: '削除してもよろしいですか。',
        msg_delete_fail: '削除されませんでした',
        msg_title_delete: '削除の確認',
        status_active: "有効",
        status_inactive: "無効",
        could_not: "作成できませんでした：",
        no_record:"レコードがありません。",
        import:"新しいレコードを追加するか。またはファイルからインポートしてください。",
        btn_import:"インポート",
        minLenght:'6文字以上である必要があります',
        emailValidate: "メールアドレスである必要があります",
        contract_usage_zero: "契約電力にゼロ以外を指定してください。",
        total_base_usage_zero: "計(円) にゼロ以外を指定してください。",
        mistalk_import:"インポートする時のSupplier IDはエラーです。再入力してください。",
        btn_ok:'OK',
        created: '登録日',
        created_by: '登録者',
        actions: '操作',
        edit_history: '修正履歴',
        generate_pdf: 'ファイル生成',
        export: 'エクスポート',
        new_registration: '新規登録',
        batch_update_confirm_title: '確認',
        batch_update_confirm_content: '件のレコードが更新されます。',
        batch_update_confirmation: '実行してもよろしいですか。',
        searchByNNumber: 'N番号で検索'
    },
    menu: {
        mainMenuInvoice: '請求',
        mainMenuApplication: '申込書',
        user: {
            user_menu: "ユーザー",
            user_list: "一覧",
            user_add: "追加",
            user_import: 'インポート',
        },
        n_master: {
            menu: "Nマスタ",
            add: "追加",
            list: "一覧"
        },
        n_facility_master: {
            menu: "N施設マスタ",
            add: "追加",
            list: "一覧"
        },
        invoicing: {
            menu: "請求先マスタ",
            add: "追加",
            list: "一覧"
        },
        evidence: {
            menu: "エビデンス",
            add: "追加",
            list: "一覧"
        },
        service: {
            menu: "サービス確定データ",
            add: "追加",
            list: "一覧"
        },
        client: {
            client_menu: "クライアント",
            client_list: "一覧",
            client_add: "追加"
        },
        user_group: {
            user_group_menu: "ユーザーグループ",
            user_group_list: "一覧",
            user_group_add: "追加"
        },
        settings: {
            setting_client: '設定',
            change_password: 'パスワード変更',
            profile: "プロファイル"
        },
        email_template: {
            email_template_menu: "メールテンプレート",
            email_template_list: "一覧",
            email_template_add: "追加"
        },
        message: {
            message_menu: 'メッセージ',
            list_message: '一覧',
            add_message: '追加',
            edit_message: 'メッセージ編集'
        },
        reseller: {
            menu: '販売代理店',
            list: '一覧',
            add: '追加',
            edit: '販売代理店編集'
        },
        contract: {
            menu: '申込書',
            list: '一覧',
            add: '追加',
            edit: '申込書編集'
        },
        sales: {
            menu: '売上計上',
            list: '一覧',
            add: '追加',
            edit: '売上計上編集'
        },
        service_discount: {
            menu: '調整',
            list: '一覧',
            add: '追加',
            edit: '調整編集'
        },
        notice_change: {
            menu: '変更通知書',
            list: '一覧',
            add: '追加',
            edit: '変更通知書編集'
        },
        payment: {
            menu: '入金データ',
            list: '一覧',
            add: '追加',
            edit: '入金データ編集'
        },
        invoice_detail: {
            menu: '請求明細'
        }
    },
    login: {
        title_login: "ログイン",
        title_new_user: "新規ユーザー",
        forgot_password: "パスワードを忘れた場合",
        full_name: "フルネーム",
        email_address: "メールアドレス",
        password: "パスワード",
        create_acount: "アカウントを追加する",
        error_password: "ログイン情報かパスワードに誤りがあります :(",
        success_login: "ログインできました"
    },
    forgot_password: {
        title: "パスワードを忘れた場合",
        description: "認証コードを受け取るために、下記の連絡先メールアドレスを入力してください",
        email: "メールアドレス",
        back_login: "ログインへ",
        btn_send: "送信する",
        mgs_recover_password: "パスワードを忘れました。次のステップについては、メールを確認してください",
        mgs_recover_success:'パスワードの変更に成功しました。 ',
    },
    activeUser: {
        title: "有効ユーザー",
        success: "アカウントを有効化しました。",
        error: "このユーザーを有効にしているときにエラーが発生しました。管理者に連絡してください",
        btn_login: "ログインへ"
    },
    reset: {
        success: "パスワードをリセットしました。",
        error: "パスワードのリセットに失敗しました。",
        password: "パスワード",
        reset: "パスワードを再設定する",
        back_to_login: "ログインに戻る",
        confirm_password: "パスワード（再確認用）"
    },
    copyright: "© 2020 MK1 Techonology Co., Ltd.",
    user_list: {
        id: "ID",
        name: "名",
        full_name: '名前',
        first_name: "名",
        last_name: "姓",
        gender: "性別",
        gender_male: "男性",
        gender_female: "女性",
        phone: '携帯番号',
        gender_other: "他の性別",
        group: "グループ",
        email: "メールアドレス",
        client: "クライアント",
        owner_id: '販売代理店',
        admin: "管理者",
        role: "役割",
        status: "ステータス",
        status_active: "有効",
        status_inactive: "無効",
        password: "パスワード",
        is_admin: "管理者",
        url_image: '画像',
        confirm_password: "パスワードの確認",
        action: "操作",
        create_new: "新規アカウントを追加する",
        update_user_title: "アカウントをアップデートする",
        msg_delete: "ユーザーを削除してもよろしいですか",
        msg_will_delete: "ユーザーは削除されます.",
        msg_sure_delete: "ユーザーを削除してもよろしいですか",
        last_login_at: "前回のログイン日時",
        phone_verified: "電話認証",
        email_verified: "メールアドレス確認済み",
        title :"役職",
        company :"会社名",
        tab_profile: "プロファイル",
        tab_password: 'パスワード',
        first_name_kana:"氏名（名）カナ",
        last_name_kana:"氏名（姓）カナ",
        department:"所属",
        company_id:"企業",
        vendor_id: "電力会社",
        vendor_department_id: '電力会社の部署',
        full_name_kana:"氏名カナ"
    },
    client_list: {
        id: "ID",
        name: "名前",
        credit: "クレジット",
        domain: "ドメイン",
        from_email: 'From (アドレス)',
        mailer_host: 'SMTPサーバー',
        mailer_port: 'SMTPポート',
        mailer_secure: 'SMTPのSSLかTLSを有効',
        mailer_user: 'SMTPユーザー名',
        mailer_password: 'SMTPパスワード',
        twilio_sid: 'Twilio SID',
        twilio_token: 'Twilio Token',
        twilio_phone: 'Twilio Phone',
        s3_bucket: "S3 バケツ",
        s3_bucket_import: "S3バケット インポート",
        s3_bucket_bill: "S3バケット　電気料金明細用",
        s3_key: "S3 キー",
        s3_region: "S3 リージョン",
        s3_secret: "S3 シークレット",
        auto_verify: "自動検証",
        status: "ステータス",
        status_active: "有効",
        status_inactive: "無効",
        action: "操作",
        create_new: "新規クライアントを追加する",
        update_title: "クライアントを更新する",
        Logo: "ロゴ",
        is_active: "有効",
        msg_delete: "クライアントを削除してもよろしいですか",
        msg_will_delete: "クライアントは削除されます",
        msg_sure_delete: "クライアントを削除してもよろしいですか"
    },
    user_group: {
        title: 'ユーザーグループの追加',
        id: "ID",
        name: "名前",
        client: "クライアント",
        status: "ステータス",
        status_active: "有効",
        status_inactive: "無効",
        action: "操作",
        create_new: "新規ユーザーグループを追加する",
        update_title: "ユーザーグループを更新する",
        is_admin: "管理者",
        msg_delete: "ユーザーグループを削除してもよろしいですか",
        msg_will_delete: "ユーザーグループが削除されます",
        msg_sure_delete: "ユーザーグループを削除してもよろしいですか",
        update_user_title:'ユーザーグループを更新する'
    },
    email_template: {
        title: "タイトル",
        id: "ID",
        is_active: "有効",
        title_add: "新規メールテンプレートを追加する",
        title_edit: "メールテンプレートを修正する",
        add_success: "メールテンプレートを追加する",
        name: "名前",
        callback_url: "Callback URL",
        user_group:'ユーザーグループ',
        description: "説明",
        action: "操作",
        subject: "タイトル",
        sample_data: "サンプルデータ",
        body: "本文",
        create: "テンプレートを追加する",
        save: "保存",
        msg_name_required: "テンプレートの名前は必須です",
        msg_description_required: "説明が必要です",
        otp:'OPT',
        forgot: 'Forgot',
        confirm: 'Confirm'
    },
    changePassword: {
        title: 'パスワード変更',
        error: 'パスワード変更エラー',
        oldPassword: '現在のパスワード',
        new_password: '新しいパスワード',
        confirm_password: '新しいパスワードの確認',
        btn_change_password: '変更する',
        msg_complete: "パスワードの変更に成功しました。 ログアウトして新しいパスワードを使用してください"
    },
    message: {
        id: "ID",
        code: "コード",
        message: "英語 メッセージ",
        ja_message: "日本語 メッセージ",
        action: "操作"
    },
    n_master: {
        add_new: 'Nマスタの追加',
        edit: 'Nマスタの編集',
        id: "ID",
        name: "法人名",
        name_furigana: "法人名フリガナ",
        company_number: "法人番号",
        representative_name: "代表者名",
        representative_name_furigana: "代表者名フリガナ",
        postcode: "本社郵便番号",
        address: "本社住所",
        contact_person: "担当者名",
        contact_person_furigana: "担当者名フリガナ",
        contact_person_dept: "担当部署",
        contact_person_title: "役職",
        contact_phone: "電話番号",
        contact_fax: "FAX番号",
        contact_email: "Eメールアドレス",
        note: "備考",
        refer_input: 'Ｎ親番号 法人名',
        csv_fields: {
            id: "N親番号",
            name: "法人名",
            name_furigana: "法人名フリガナ",
            company_number: "法人番号",
            representative_name: "代表者名",
            representative_name_furigana: "代表者名フリガナ",
            postcode: "本社郵便番号",
            address: "本社住所",
            contact_person: "担当者名",
            contact_person_furigana: "担当者名フリガナ",
            contact_person_dept: "担当部署",
            contact_person_title: "役職",
            contact_phone: "電話番号",
            contact_fax: "FAX番号",
            contact_email: "Eメールアドレス",
            note: "備考",
            created: '登録日',
            created_by: '登録ユーザーID',
            import_id: 'インポートID'
        }
    },
    n_facility_master: {
        add_new: 'N施設マスタの追加',
        add_new_from_list: 'N施設マスタ追加',
        edit: 'N施設マスタの編集',
        show_list: 'N施設マスタ一覧',
        id: "ID",
        name: "施設名",
        name_furigana: '施設名フリガナ',
        n_master_id: "Ｎ親番号",
        n_facility_number: "N枝番号",
        postcode: "施設郵便番号",
        address: "施設住所",
        contact_person: "担当者名",
        contact_person_furigana: '担当者名フリガナ',
        contact_person_dept: "担当部署",
        contact_person_title: "役職",
        contact_phone: "電話番号",
        contact_fax: "FAX番号",
        contact_email: "Eメールアドレス",
        note: "備考",
        refer_input: 'N番号 N施設名',
        csv_fields: {
            id: "ID",
            name: "施設名",
            name_furigana: '施設名フリガナ',
            n_master_id: "N親番号",
            n_facility_number: "N枝番号",
            postcode: "施設郵便番号",
            address: "施設住所",
            contact_person: "担当者名",
            contact_person_furigana: '担当者名フリガナ',
            contact_person_dept: "担当部署",
            contact_person_title: "役職",
            contact_phone: "電話番号",
            contact_fax: "FAX番号",
            contact_email: "Eメールアドレス",
            note: "備考",
            created: '登録日',
            created_by: '登録ユーザーID',
            company_name: '会社名'
        }
    },
    payment: {
        id: "ID",
        add_new: '入金データの追加',
        edit: '入金データの編集',
        type: '区切',
        payment_received: '日付',
        invoice_no: "伝票No.",
        division: "借方部門コード",
        division_name: "借方部門名",
        c1: "借方勘定科目コード",
        c2: "借方勘定科目名",
        c3: "借方補助科目コード",
        c4: "借方補助科目名",
        payment_amount: "借方本体金額",
        tax: "借方消費税額",
        c5: "貸方部門コード",
        c6: "貸方部門名",
        payment_method_code: "貸方勘定科目コード",
        payment_method_name: "貸方勘定科目名",
        n_number: "貸方補助科目コード",
        n_facility_name: "貸方補助科目名",
        payment_received_amount: "貸方本体金額",
        target_detail: '摘要',
        status: 'ステータス',
        invoice_detail_id: '請求明細ID',
        created: "登録日", 
        created_by: "登録ユーザーID",
        update_invoice_detail: "請求明細の更新",
        update_invoice_detail_success: "件を更新しました。",
        update_invoice_detail_failed: "件エラーが発生しました。",
        step3_reseller_code: '獲得店コード',
        step2_reseller_code: '紹介店コード',
        step1_reseller_code: 'その他店コード',
        total_incentive_amount: '当月支払額',
        csv_fields: {
            id: "ID",
            type: '区切',
            payment_received: '日付',
            invoice_no: "伝票No.",
            division: "借方部門コード",
            division_name: "借方部門名",
            c1: "借方勘定科目コード",
            c2: "借方勘定科目名",
            c3: "借方補助科目コード",
            c4: "借方補助科目名",
            payment_amount: "借方本体金額",
            tax: "借方消費税額",
            c5: "貸方部門コード",
            c6: "貸方部門名",
            payment_method_code: "貸方勘定科目コード",
            payment_method_name: "貸方勘定科目名",
            n_number: "貸方補助科目コード",
            n_facility_name: "貸方補助科目名",
            payment_received_amount: "貸方本体金額",
            target_detail: '摘要',
            status: 'ステータス',
            invoice_detail_id: '請求明細ID',
            created: "登録日", 
            created_by: "登録ユーザーID",
            import_id: 'インポートID'
        }
    },
    invoicing: {
        add_new: '請求先マスタの追加',
        edit: '請求先マスタの編集',
        id: "ID",
        company_name: "法人名",
        postcode: "請求先郵便番号",
        address: "請求先住所",
        billing_name: '担当者名',
        billing_dept: '担当者部署',
        billing_title: '担当者役職',
        billing_phone: '担当者電話番号',
        billing_fax: '担当者FAX',
        billing_email: '担当者Eメールアドレス',
        billing_type: '送付方法',
        note: '備考',
        bank_code: '銀行コード',
        bank_name: '銀行名',
        bank_branch_code: '支店コード',
        bank_branch_name: '支店名',
        account_name: '名義',
        account_type: '口座種別',
        account_number: '口座番号',
        transferable_month: '振替開始可能月',
        payment_method: '支払情報',
        n_master_id: 'Ｎ親番号',
        refer_input: '請求先',
        csv_fields: {
            id: "ID",
            company_name: "法人名",
            postcode: "請求先郵便番号",
            address: "請求先住所",
            billing_name: '担当者名',
            billing_dept: '担当者部署',
            billing_title: '担当者役職',
            billing_phone: '担当者電話番号',
            billing_fax: '担当者FAX',
            billing_email: '担当者Eメールアドレス',
            billing_type: '送付方法',
            note: '備考',
            bank_code: '銀行コード',
            bank_name: '銀行名',
            bank_branch_code: '支店コード',
            bank_branch_name: '支店名',
            account_name: '名義',
            account_type: '口座種別',
            account_number: '口座番号',
            transferable_month: '振替開始可能月',
            payment_method: '支払情報',
            n_master_id: 'Ｎ親番号',
            created: '登録日',
            created_by: '登録ユーザーID',
        }
    },
    evidence: {
        add_new: 'エビデンスの追加',
        edit: 'エビデンスの編集',
        id: 'ID',
        received: '受領日',
        title: '名称',
        note: '内容',
        filename: 'ファイル名',
        file: 'ファイル',
        n_facility_master_id: 'N番号',
        add_new_from_list: '＋新規登録',
        refer_input: 'エビデンス',
    },
    contract: {
        add_new: '申込書の追加',
        edit: '申込書の編集',
        id: 'ID',
        opportunity_id: '案件ID',
        name: '会社名',
        n_master_id: 'Ｎ親番号',
        status: 'ステータス',
        contract_date: '申込日',
        evidence: 'エビデンス',
        csv_fields: {
            id: 'ID',
            opportunity_id: '案件ID',
            name: '会社名',
            n_master_id: 'N親番号',
            status: 'ステータス',
            contract_date: '申込日',
            created: '登録日',
            created_by: '登録ユーザーID',
            company_name: '会社名'
        }
    },      
    service: {
        add_new: 'サービス確定データの追加',
        edit: 'サービス確定データの編集',
        id: 'ID',
        contract_id: '申込書',
        contract_number: '供給地点番号',
        n_master_id: 'Ｎ親番号',
        n_facility_master_id: 'N番号',
        applied: '申込日',
        user_id: '営業担当者',
        step1_reseller_id: 'その他店',
        step1_reseller_rate: 'その他店手数料率 (%)',
        step1_reseller_incentive_type: 'その他店手数料支払い',
        step2_reseller_id: '紹介店',
        step2_reseller_rate: '紹介店手数料率 (%)',
        step2_reseller_incentive_type: '紹介店手数料支払い',
        step3_reseller_id: '獲得店',
        step3_reseller_rate: '獲得店手数料率 (%)',
        step3_reseller_incentive_type: '獲得店手数料支払い',
        step1_reseller_months: 'その他店対象月数',
        step2_reseller_months: '紹介店対象月数',
        step3_reseller_months: '獲得店対象月数',
        evidence_id: 'エビデンス',
        contract_date_plan: 'サービス確定日(予定)',
        contract_date_confirmed: 'サービス確定日',
        service_start_date: '業務開始日',
        service_fee: '月額サービス利用料',
        annual_payment: '年払いフラグ',
        fixed_rate: '定額/変動',
        payment_method: '支払情報',
        transferable_month: '振替開始可能月',
        initial_service_fee: '初期手数料 免除',
        initial_service_fee_amount: '初期手数料金額',
        service_term: '対象期間（月）',
        service_end_date: '業務終了日',
        service_close_date: '解約日',
        close_reason: '解約理由',
        notification_id: '通知書ID',
        notification_sent_date: '通知書発行日',
        status: 'ステータス',
        invoicing_id: '請求先',
        note: '備考', 
        plan_code: '企画コード',
        transfer_flag: '移管フラグ',
        show_list: 'サービス確定データ一覧',
        add_new_from_list: 'サービス確定データ追加',
        refer_input: 'サービス確定データ',
        refer_field: 'N番号',
        refer_field2: 'N施設名',
        refer_field3: '法人名',
        evidences: 'エビデンス一覧',
        rate_type: '定額/変動',
        notice_changes: '変更通知書一覧',
        update_confirmation_title: '確認',
        update_confirmation: '変更内容を保存しますか？',
        change_service_fee_date: '口座振替の料金変更開始年月',
        csv_fields: {
            id: 'ID',
            contract_id: '申込書ID',
            contract_number: '供給地点番号',
            n_master_id: 'Ｎ親番号',
            n_facility_master_id: 'N施設マスタID',
            applied: '申込日',
            user_id: '営業担当者ID',
            step1_reseller_id: 'その他店ID',
            step1_reseller_rate: 'その他店手数料率',
            step1_reseller_incentive_type: 'その他店手数料支払い',
            step2_reseller_id: '紹介店ID',
            step2_reseller_rate: '紹介店手数料率',
            step2_reseller_incentive_type: '紹介店手数料支払い',
            step3_reseller_id: '獲得店ID',
            step3_reseller_rate: '獲得店手数料率',
            step3_reseller_incentive_type: '獲得店手数料支払い',
            step1_reseller_months: 'その他店対象月数',
            step2_reseller_months: '紹介店対象月数',
            step3_reseller_months: '獲得店対象月数',
            evidence_id: 'エビデンスID',
            contract_date_plan: 'サービス確定日(予定)',
            contract_date_confirmed: 'サービス確定日',
            service_start_date: '業務開始日',
            service_fee: '月額サービス利用料',
            annual_payment: '年払いフラグ',
            rate_type: '定額/変動',
            payment_method: '支払情報',
            transferable_month: '振替開始可能月',
            initial_service_fee: '初期手数料 免除',
            initial_service_fee_amount: '初期手数料金額',
            service_term: '対象期間（月）',
            service_end_date: '業務終了日',
            service_close_date: '解約日',
            close_reason: '解約理由',
            notification_id: '通知書ID',
            notification_sent_date: '通知書発行日',
            status: 'ステータス',
            invoicing_id: '請求先ID',
            note: '備考', 
            plan_code: '企画コード',
            transfer_flag: '移管フラグ',
            created: '登録日',
            created_by: '登録ユーザーID',
            company_name: '会社名', 
            facility_name: '施設名',
            n_number: 'N番号'
        }
    },
    reseller: {
        add_new: '販売代理店の追加',
        edit: '販売代理店の編集',
        id: 'ID',
        code: '販売店コード',
        name: '販売店名',
        bank_code: '銀行コード',
        bank_name: '銀行名',
        bank_branch_code: '支店コード',
        bank_branch_name: '支店名',
        account_name: '名義',
        account_type: '口座種別',
        account_number: '口座番号',
        zip_code: '郵便番号',
        address: '住所',
        other: '建物名'
    },
    service_discount: {
        add_new_from_list: '調整追加',
        show_list: '調整一覧',
        menu_title: "調整",
        menu_add: "追加",
        menu_list: "一覧",
        add_new: '調整の追加',
        edit: '調整の編集',
        id: 'ID',
        service_id: 'N番号',
        discount_type: '調整種別',
        discount_condition: '備考',
        discount_amount: '調整月額',
        discount_start_date: '調整適用開始日',
        discount_end_date: '調整適用終了日（サービス終了日）',
        increase_flag: '増額フラグ',
        csv_fields: {
            id: 'ID',
            service_id: 'サービス確定データID',
            discount_type: '調整種別',
            discount_condition: '備考',
            discount_amount: '調整月額',
            discount_start_date: '調整適用開始日',
            discount_end_date: '調整適用終了日（サービス終了日）',
            increase_flag: '増額フラグ',
            created: '登録日',
            created_by: '登録ユーザーID',
        }
    },
    sales: {
        menu_title: "売上計上",
        menu_add: "追加",
        menu_list: "一覧",
        menu_confirm: '確認',
        menu_cost_confirm: "原価計上情報確認",
        add_new: '売上計上の追加',
        edit: '売上計上の編集',
        id: 'ID',
        service_id: 'サービス確定データ',
        year: '計上年',
        month: '計上月',
        start_date: '開始日',
        end_date: '終了日',
        days: '日数',
        sales_amount: '売上金額',
        expected_sales_amount: '売上予定金額',
        step1_reseller_id: 'その他店',
        step1_reseller_amount: 'その他店手数料',
        step2_reseller_id: '紹介店',
        step2_reseller_amount: '紹介店手数料',
        step3_reseller_id: '獲得店',
        step3_reseller_amount: '獲得店手数料',
        total_incentive_amount: '代理店手数料合計',
        locked: '修正不可フラグ',
        note: '備考',
        confirmed: '売上確認日',
        confirmed_by: '売上確認ユーザー',
        cost_confirmed: '原価確認日',
        cost_confirmed_by: '原価確認ユーザー',
        discount_amount: '当月調整料額',
        adjustment_amount: '当月調整額合計',
        adjustment_reason: '当月調整理由',
        sales_total_amount: '当月合計額',
        facility_name: '施設名',
        type: 'タイプ',
        csv_fields: {
            id: 'ID',
            service_id: 'サービス確定データID',
            year: '計上年',
            month: '計上月',
            start_date: '開始日',
            end_date: '終了日',
            days: '日数',
            reseller: "その他店コード",
            reseller_by_step_2_reseller_id:"紹介店コード",
            reseller_by_step_3_reseller_id: "獲得店コード",
            sales_amount: '売上金額',
            expected_sales_amount: '売上予定金額',
            step1_reseller_id: 'その他店ID',
            step1_reseller_amount: 'その他店手数料',
            step2_reseller_id: '紹介店ID',
            step2_reseller_amount: '紹介店手数料',
            step3_reseller_id: '獲得店ID',
            step3_reseller_amount: '獲得店手数料',
            total_incentive_amount: '代理店手数料合計',
            locked: '修正不可フラグ',
            note: '備考',
            confirmed: '売上確認日',
            confirmed_by: '売上確認ユーザーID',
            cost_confirmed: '原価確認日',
            cost_confirmed_by: '原価確認ユーザーID',
            discount_amount: '当月調整料額',
            adjustment_amount: '調整額',
            adjustment_reason: '当月調整理由',
            sales_total_amount: '当月合計額',
            created: '登録日',
            created_by: '登録ユーザーID',
            n_facility_master_name: '法人名',
            n_master_name: '法人名', 
            n_master_id: 'N番号',
            type: 'タイプ'
        },
        confirm: {
            diff: '差分有無',
            n_number: 'Ｎ番号',
            n_facility_name: '施設名',
            contract_number: '供給地点番号',
            prev_total_incentive_amount: '前月手数料額',
            prev_sales_amount: '前月利用料額',
            prev_discount_amount: '前月調整額',
            total_incentive_amount: '当月手数料額',
            sales_amount: '当月利用料額',
            discount_amount: '当月調整料額',
            sales_total_amount: '当月合計額',
            action: '確認',
            batch_update: '一括確認'
        },
        cost_confirm: {
            step1_reseller_code: 'その他店コード',
            step2_reseller_code: '紹介店コード',
            step3_reseller_code: '獲得店コード',
            step1_reseller_name: 'その他店名',
            step2_reseller_name: '紹介店名',
            step3_reseller_name: '獲得店名',
            step1_reseller_rate: 'その他店手数料率 (%)',
            step2_reseller_rate: '紹介店手数料率 (%)',
            step3_reseller_rate: '獲得店手数料率 (%)',
            step1_reseller_amount: 'その他店手数料額',
            step2_reseller_amount: '紹介店手数料額',
            step3_reseller_amount: '獲得店手数料額',
        }
    },
    invoice: {
        menu_title: "請求書",
        menu_add: "追加",
        service_fee: "電気削減クラウド利用料",
        service_initial_fee: "電気削減クラウド初期手数料",
        menu_list: "一覧",
        add_new: '請求書の追加',
        edit: '請求書の編集',
        id: 'ID',
        n_facility_master_id: 'N番号',
        year: '年',
        month: '月',
        n_master_id: 'Ｎ親番号',
        invoicing_id: '請求先',
        issued: '請求書発行日',
        total_amount: '請求金額合計',
        schedule_amount: '請求予定金額',
        note: '備考',
        confirmed: '確認日',
        confirmed_by: '確認ユーザー',
        invoice_detail_list: '請求明細一覧',
        unpaid_invoice_details: '未納請求明細一覧',
        invoice_type: '請求書タイプ',
        converted: 'ファイルが生成されました。',
        convert_error: 'エラーが発生しました。',
        generate_pdf: 'ファイル生成してダウンロード',
        download_pdf: 'ダウンロード',
        notice_change_id: '変更通知書'
    },
    invoice_detail: {
        menu_title: "請求明細",
        menu_add: "追加",
        menu_list: "一覧",
        menu_confirm: '確認',
        menu_confirm_1: "振込月払請求明細確認",
        menu_confirm_2: "振込年払請求明細確認",
        menu_confirm_3: "口座振替請求明細確認",
        add_new: '請求明細の追加',
        edit: '請求明細の編集',
        id: 'ID',
        service_id: 'サービス確定データID',
        invoice_year: '請求年',
        invoice_month: '請求月',
        sales_year: '対象年',
        sales_month: '対象月',
        invoice_id: '請求書ID',
        invoice_amount: '請求金額',
        service_usage: 'サービス利用料金',
        number_of_month: '対象月（数）',
        total_months: '対象月数（合計月数）',
        step1_reseller_id: 'その他店',
        step1_reseller_amount: 'その他店手数料',
        step1_reseller_number_of_month: 'その他店手数料対象月（数）',
        step1_reseller_total_months: 'その他店手数料対象月数（合計月数）',
        step2_reseller_id: '紹介店',
        step2_reseller_amount: '紹介店手数料',
        step2_reseller_number_of_month: '紹介店手数料対象月（数）',
        step2_reseller_total_months: '紹介店手数料対象月数（合計月数）',
        step3_reseller_id: '獲得店',
        step3_reseller_amount: '獲得店手数料',
        step3_reseller_number_of_month: '獲得店手数料対象月（数）',
        step3_reseller_total_months: '獲得店手数料対象月数（合計月数）',
        payment_received_date: '入金日',
        payment_received_flg: '入金フラグ',
        payment_method: '入金方法',
        payment_received_amount: '入金額',
        adjustment_amount: '調整額',
        adjustment_reason: '調整理由',
        status: 'ステータス',
        scheduled_transfer_date: '振替開始可能月',
        scheduled_implementation_date: '振替実施予定日',
        note: '備考',
        expected_invoice_amount: '予定請求金額',
        discount_amount: '調整額合計',
        discount_type: '調整種別',
        confirmed: '確認日',
        confirmed_by: '確認ユーザー',
        incentive_paid: '手数料支払日',
        updated_status: '請求書を作成しました。',
        type: 'タイプ',
        csv_fields: {
            id: 'ID',
            service_id: 'サービス確定データID',
            invoice_year: '請求年',
            invoice_month: '請求月',
            sales_year: '対象年',
            sales_month: '対象月',
            invoice_id: '請求書ID',
            invoice_amount: '請求金額',
            service_usage: 'サービス利用料金',
            number_of_month: '対象月（数）',
            total_months: '対象月数（合計月数）',
            step1_reseller_id: 'その他店ID',
            step1_reseller_amount: 'その他店手数料',
            step1_reseller_number_of_month: 'その他店手数料対象月（数）',
            step1_reseller_total_months: 'その他店手数料対象月数（合計月数）',
            step2_reseller_id: '紹介店ID',
            step2_reseller_amount: '紹介店手数料',
            step2_reseller_number_of_month: '紹介店手数料対象月（数）',
            step2_reseller_total_months: '紹介店手数料対象月数（合計月数）',
            step3_reseller_id: '獲得店ID',
            step3_reseller_amount: '獲得店手数料',
            step3_reseller_number_of_month: '獲得店手数料対象月（数）',
            step3_reseller_total_months: '獲得店手数料対象月数（合計月数）',
            payment_received_date: '入金日',
            payment_received_flg: '入金フラグ',
            payment_method: '入金方法',
            payment_received_amount: '入金額',
            adjustment_amount: '調整額',
            adjustment_reason: '調整理由',
            status: 'ステータス',
            scheduled_transfer_date: '振替開始可能月',
            scheduled_implementation_date: '振替実施予定日',
            note: '備考',
            expected_invoice_amount: '予定請求金額',
            discount_amount: '調整額合計',
            discount_type: '調整種別',
            confirmed: '確認日',
            confirmed_by: '確認ユーザーID',
            incentive_paid: '手数料支払日',
            created: '登録日',
            created_by: '登録ユーザーID',
            n_master_name: '法人名', 
            n_master_id: 'N番号',
            type: 'タイプ'
        },
        confirm: {
            diff :'差分有無',
            n_master_name:'企業名',
            contract_number:'供給地点番号',
            prev_expected_invoice_amount: '前月予定額',
            prev_service_usage: '前月利用料',
            prev_discount_amount:'前月通常調整額',
            prev_discount_type: '前月調整種別',
            prev_adjustment_amount: '前月特別調整額',
            prev_invoice_amount: '前月請求額',
            delay_payment_amount:'未収額合計',
            sales_amount: '当月計上額',
            expected_invoice_amount: '当月予定額',
            service_usage: '当月利用料',
            discount_amount: '当月通常調整額',
            discount_type:'当月調整種別',
            adjustment_amount: '当月特別調整額',
            adjustment_reason: '当月調整理由',
            invoice_amount:'当月請求額',
            confirmed:'確認'
        }
    },
    incentive: {
        menu_title: "支払い通知書",
        menu_add: "追加",
        menu_list: "一覧",
        add_new: '支払い通知書の追加',
        edit: '支払い通知書の編集',
        id: 'ID',
        service_id: 'サービス確定データID',
        year: '年',
        month: '月',
        notification_issued: '支払通知書発行日',
        reseller_id: '代理店ID',
        amount: '支払い合計金額',
        note: '備考',
        menu_confirm: "確認",
        pay_received_month: "対象月",
        pay_received_year: "対象年",
        confirm: "確認",
    },
    edit_history: {
        created_at: '日付',
        user_name: 'ユーザー名',
        operate: '操作',
        old: '旧値',
        new: '新値',
    },
    notice_change: {
        add_new: '変更通知書の追加',
        edit: '変更通知書の編集',
        id: 'ID',
        issued: '発行日',
        note: '変更内容',
        name: 'タイトル',
        refer_input: '変更通知書',
        csv_fields: {
        }
    },      
};

export default customJapanMessages;
