import React, { useEffect } from 'react';
import {
  required,
  useTranslate,
  useDataProvider,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import { useForm } from 'react-final-form';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';
import ServiceRF from '../service/ReferenceInput';
import { ProcessNFacility, ProcessNmaster } from "../../utils/Utils";


const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const { editForm } = props;
  const dataProvider = useDataProvider();
  const form = useForm();

  useEffect(() => {
    const {record:{service_id}} = props;
    if (service_id) {
      updateName(service_id);
    }
  }, []) 

  const updateName = async (serviceId: any) => {
    const { data } = await dataProvider.getOne('service', { id: serviceId });
    const { n_facility_master_id, n_master_id } = data;
    if (n_facility_master_id) {
      const { data:fData } = await dataProvider.getOne('n_facility_master', { id: n_facility_master_id });
      const { n_facility_number, name } = fData;
      form.change('name', n_facility_number ? `N${ProcessNmaster(n_master_id)}-${ProcessNFacility(n_facility_number)} ${name}` : '');
    }
  }

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      updateName(event);
    }
  }

  return <Container>
    <SectionTitle label={editForm ? "notice_change.edit" : "notice_change.add_new"} />
    <Separator />
    <Grid container justify='center' spacing={5}>
      <Grid item xs={6}>
        {Fields.map((d, idx) => {
          if (d.name === 'service_id') {
            return <ServiceRF
              autoFocus
              validate={requiredValidate}
              disabled={editForm ? true : false}
              onChange={onChange}
              key={idx}
            />
          } else {
            return <InputRenderer source='notice_change' data={d} key={idx} isEditPage={editForm}  />
          }
        })}
      </Grid>
    </Grid>
  </Container>;
};

export default NFrom;