import * as React from 'react';
import {
  ReferenceInput,
  AutocompleteInput,
  useTranslate,
} from 'react-admin';

const filterToQuery = (searchText: any) => {
  return { name: searchText }
}

const CustomReferenceInput = (props: any) => {
  const translate = useTranslate();
  return <ReferenceInput
    source="notice_change_id"
    reference="notice_change"
    fullWidth
    variant="outlined"
    filterToQuery={filterToQuery}
    label={translate('notice_change.refer_input')}
    {...props}>
    <AutocompleteInput
      optionText={(r: any) => r.name}
      resettable={true}
    />
  </ReferenceInput>
}

export default React.memo(CustomReferenceInput);