import * as React from 'react';
import {
  useTranslate,
  required,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';
import ServiceReferenceInput from '../service/ReferenceInput';

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const { editForm, record: {locked} } = props;
  return <Container>
    <SectionTitle label={editForm ? "sales.edit" : "sales.add_new"} />
    <Separator />
    <Grid container justify='center' spacing={2}>
      {Fields.map((d, idx) => {
        if (d.type === 'hidden') {
          return <></>
        }
        if (d.name === 'service_id') {
          return <Grid item xs={4} key={idx}>
            <ServiceReferenceInput
              autoFocus
              disabled={locked}
              validate={[required()]} />
          </Grid>
        } else if (d.type === 'virtual_reference') {
          return <></>
        } else {
          return <Grid item xs={4} key={idx}>
            <InputRenderer source='sales' data={{...d, editable: !locked}} isEditPage={editForm} />
          </Grid>
        }
      })}
    </Grid>
  </Container>;
};

export default NFrom;