import * as React from 'react';
import {
  useTranslate,
  List,
  ResourceContextProvider,
  TextField,
  DateField,
  Datagrid,
  ReferenceField,
  SelectField,
} from 'react-admin';
import ListPagination from "../components/ListPagination";
var _ = require('lodash');

const OP = [
  {id: 'MANUAL', name: 'MANUAL'},
  {id: 'INSERT', name: '新規'},
  {id: 'UPDATE', name: '更新'},
  {id: 'DELETE', name: '削除'},
]

const OldValues = (props: any) => {
  const translate = useTranslate();
  const { record = {} } = props;
  const { target_table:resouce } = record;
  const {old} = record || {};
  const newObj = record.new || {};
  const diff = _.omitBy(old, (v: any, k: string) => newObj[k] === v)
  const transDiff = Object.keys(diff).map((k: string) => {
    const jpKey = translate(`${resouce}.${k}`);
    return {[jpKey]: diff[k]};
  })

  return <span style={{maxWidth: '100px'}}>{JSON.stringify(transDiff)}</span>;
}

const NewValues = (props: any) => {
  const translate = useTranslate();
  const { record = {} } = props;
  const { target_table:resouce } = record;
  const old = record.old || {};
  const newObj = record.new || {};
  const diff = _.omitBy(newObj, (v: any, k: string) => old[k] === v)

  const transDiff = Object.keys(diff).map((k: string) => {
    const jpKey = translate(`${resouce}.${k}`);
    return {[jpKey]: diff[k]};
  })

  return <span style={{maxWidth: '100px'}}>{JSON.stringify(transDiff)}</span>;
}

const EditHistory = (props: any) => {
  const translate = useTranslate();
  const { resource } = props;
  // console.log(props);

  return <ResourceContextProvider value="log">
    <List
      hasCreate={false}
      hasShow
      hasEdit
      hasList
      actions={false}
      resource='log'
      bulkActionButtons={false}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={25}
      pagination={<ListPagination />}
      syncWithLocation
      basePath="/log"
      filter={{ 'target_table@_eq': `${resource}`, 'target_id@_eq':  props.id}}
      // filter={{ 'target_table@_eq': `${resource}` }}
      title=" ">
      <Datagrid>
        <DateField source="created_at" showTime={true} label={translate('edit_history.created_at')} />
        <ReferenceField label={translate('edit_history.user_name')} source="user_id" reference="user">
          <TextField source="full_name" />
        </ReferenceField>
        <SelectField source="operation" choices={OP} label={translate('edit_history.operate')} />
        <OldValues label={translate('edit_history.old')} />
        <NewValues label={translate('edit_history.new')} />
      </Datagrid>

    </List>
  </ResourceContextProvider>
};

export default EditHistory;
